import moment from 'moment'
//import validator from '../validate'
import * as _ from 'lodash'

import * as appTypes from '../../App/types'
import * as types from './types'


export const getPOs = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_COMPLETED_POS,

        })
    }
}
export const refresh_invno = (val) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.REFRESH_INVNO,
            payload: val
        })

    }
}

export const updateValue = (prop, val) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.UPDATE_VALUE,
            payload: val,
            prop: prop
        })

    }
}

export const toggleItem = (data) => {
    //console.log("<<<<<<<<<< data >>>>>>>>>>>")
    //console.log(data)
    return (dispatch, getState) => {
        dispatch({
            type: types.TOGGLE_ITEM,
            payload: data
        })

    }
}

export const approveItem = (data) => {
    //console.log("<<<<<<<<<< data >>>>>>>>>>>")
    //console.log(data)
    return (dispatch, getState) => {
        dispatch({
            type: types.APPROVE_ITEM_GM,
            payload: data
        })

    }
}

export const processPayment = (data) => {
    //console.log("<<<<<<<<<< processPayment >>>>>>>>>>>")
    //console.log(data)
    return (dispatch, getState) => {
        dispatch({
            type: types.PROCESS_PAYMENT_APPROVAL_NOTIFICATION,
            payload: {
                type: 'PROCESS_PAYMENT_APPROVAL_NOTIFICATION',
                data: data
            }
        })

    }
}
export const refresh_invamt = (val) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.REFRESH_INVAMT,
            payload: val
        })

    }
}

export const setLookupValue = (prop, value, text) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_SELECTED_ITEM,
            payload: {
                prop: prop,
                values: {
                    value: value,
                    text: text
                }
            }
        })
    }

}


export const setPaymentDocumentByIdPDF = (data) => {
    let _document = data.data.document
    //console.log("setPaymentDocumentByIdPDF")
    //console.log(data.data)
    let link = ''

    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'
        return (data, name) => {
            const blob = new Blob(data, { type: 'octet/stream' })
            const url = window.URL.createObjectURL(blob)
            link = url
            a.href = url
            a.download = name
            a.click()

            window.URL.revokeObjectURL(url)
        }
    }())

    saveByteArray([data.data.pdf.Body], 'Payment ' + _document + '.pdf')
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Document Downloaded!',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const getDocument = (data) => {
    //console.log(">>>>>>>>>>>>>>> getRemittance <<<<<<<<<<<")
    //console.log(data)
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Generating ' + data.document + '...',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })

        dispatch({
            type: types.GET_PAYMENT_DOCUMENT_BY_ID,
            payload: {
                type: types.GET_PAYMENT_DOCUMENT_BY_ID,
                data: data
            }
        })
    }
}
export const setPaymentNotification = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'Payment Loaded',
                additionalText: 'Payment processed for approval.',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const setPaymentDocumentPDF = (data) => {
    //console.log("Does it hit")
    //console.log(data)
    return (dispatch, getState) => {

        switch (data.data.document) {
            case 'Remittance': {
                //console.log(">>>>>>>>>>>>>>> setRemittancePDF <<<<<<<<<<<")
                setRemittancePDF(data)
            }
                break;
            case 'Recon': {
                //console.log(">>>>>>>>>>>>>>> setReconPDF <<<<<<<<<<<")
                setReconPDF(data)
            }
                break;
            case 'Requisition': {
                //console.log(">>>>>>>>>>>>>>> setRequisitionPDF <<<<<<<<<<<")
                setRequisitionPDF(data)
            }
                break;
        }
    }
}

export const setReconPDF = (data) => {
    let link = ''

    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'

        return (data, name) => {
            const blob = new Blob(data, { type: 'octet/stream' })
            const url = window.URL.createObjectURL(blob)

            a.href = url
            a.download = name
            a.click()

            window.URL.revokeObjectURL(url)
        }
    }())

    saveByteArray([data.data.pdf.Body], data.data.key)
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Document Generated!',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const setRemittancePDF = (data) => {

    return (dispatch, getState) => {
        const header = [
            { key: 'date', name: 'Date', default: '' },
            { key: 'reference', name: 'Invoice Number', default: '' },
            { key: 'description', name: 'Description', default: '' },
            { key: 'credit', name: 'Credit', default: '' },
            { key: 'maintenancefund', name: 'Maintenance Fund', default: '' },
            { key: 'rebill', name: 'Rebill', default: '' },
            { key: 'shortpaid', name: 'Short Payment', default: '' },
            { key: 'invoiceamount', name: 'Invoice Total', default: '' },
            { key: 'payable', name: 'Amount Payable', default: '' },
        ]

        dispatch({
            type: types.GET_EXCEL_GRID_EXPORT,
            payload: {
                type: 'GET_EXCEL_GRID_EXPORT',
                component: data.data.document,
                data: data.data.excel,
                header: header
            }
        })
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Document Generated!',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const setRequisitionPDF = (data) => {
}

export const addBlankRow = (type, id) => {
    //console.log("Blank ID")
    //console.log(id)

    return (dispatch, getState) => {
        let obj = []
        let prop = type
        switch (type) {
            case 'payments':
                {
                    obj = getState().paymentApprovalAdmin[type]
                    obj.push(
                        {
                            id: id,
                            date: '',
                            eft: 0.00,
                            amount: 0.00,
                            discount: 0.00,
                            total: 0.00
                        })
                } break;
            default:
                {
                    obj = getState().paymentApprovalAdmin[type]
                    obj.push(
                        {
                            id: 0,
                            date: '',
                            invoicenumber: '',
                            amount: 0.00,
                            reason: '',
                            total: 0.00
                        })
                }
        }


        dispatch({
            type: types.ADD_BLANK,
            payload: obj,
            prop: prop
        })
    }
}


export const setUpdateItem = (type, id, prop, value) => {
    return (dispatch, getState) => {
        let obj = getState().paymentApprovalAdmin[type]
        var data = obj.map((x, i) => {
            return (x.id === id && (prop === 'date')) ?
                {
                    ...x,
                    date: value
                } : (x.id === id && (prop === 'eft')) ?
                    {
                        ...x,
                        eft: (value),
                        amount: (value),
                        total: parseFloat(value) + parseFloat(x.discount)
                    } : (x.id === id && (prop === 'amount')) ?
                        {
                            ...x,
                            amount: (value),
                            total: (value)
                        } : (x.id === id && (prop === 'discount')) ?
                            {
                                ...x,
                                discount: value,
                                total: parseFloat(value) + parseFloat(x.amount)
                            }
                            : (x.id === id && (prop === 'reason')) ?
                                {
                                    ...x,
                                    reason: value,
                                } : (x.id === id && (prop === 'invoicenumber')) ?
                                    {
                                        ...x,
                                        invoicenumber: value,
                                    } : x
        })
        dispatch({
            type: types.UPDATE_ITEM_VALUE,
            payload: {
                data: data,
                prop: type
            }
        })
    }
}

export const removeRow = (type, id) => {
    return (dispatch, getState) => {
        let obj = getState().paymentApprovalAdmin[type].filter(x => x.id !== id)
        dispatch({
            type: types.UPDATE_ITEM_VALUE,
            payload: {
                data: obj,
                prop: type
            }
        })
    }
}


export const getSupplierTransactionDocument = (filename) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Generating Document...',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })

        dispatch({
            type: types.GET_SUPPLIER_TRANSACTION_DOCUMENT,
            payload: {
                type: "GET_SUPPLIER_TRANSACTION_DOCUMENT",
                filename: filename
            }
        })
    }
}



export const updateItem = (data) => {
    //console.log("<<<<<<<<<< data >>>>>>>>>>>")
    //console.log(data)
    return (dispatch, getState) => {
        dispatch({
            type: types.UPDATE_ITEM_GM,
            payload: data
        })

    }
}


export const getPaymentList = (search_parameters) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_PAYMENT_DATA_LIST_GM,
            payload: {
                type: 'get_payment_list',
                search: search_parameters
            }
        })

    }
}

export const getExceptionList = (search_parameters) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_EXCEPTION_DATA_LIST_GM,
            payload: {
                type: 'get_exception_list',
                search: search_parameters
            }
        })

    }
}


export const setPaymentList = (response) => {
    //console.log("response", response)
    return (dispatch, getState) => {
        let data = response.map(x => {
            return {
                ...x,
                showdetails: false,
                showcomments: false
            }
        })
        dispatch({
            type: types.SET_PAYMENT_DATA_LIST_GM,
            payload: data.filter(x => x.suppliertype.indexOf('Panel') >= 0)
        })
        dispatch({
            type: types.SET_PAYMENT_DATA_LIST_MAINT_GM,
            payload: data.filter(x => x.suppliertype.indexOf('Maint') >= 0)
        })
        dispatch({
            type: types.SET_PAYMENT_DATA_LIST_TYRES_GM,
            payload: data.filter(x => x.suppliertype.indexOf('Tyre') >= 0)
        })
        dispatch({
            type: types.SET_MAIN_PAYMENT_DATA_GM,
            payload: data
        })

    }
}



export const setExceptionList = (response) => {
    //console.log("response", response)
    return (dispatch, getState) => {
        let data = response.map(x => {
            return {
                ...x,
                showdetails: false,
                showcomments: false
            }
        })
        dispatch({
            type: types.SET_EXCEPTION_DATA_LIST_GM,
            payload: data
        })
    }
}

export const resetComponent = (type) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.RESET_COMPONENT,
            payload: null
        })
    }
}

export const toggleItemProps = (inx) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_PROP_INDEX_GM,
            payload: getState().paymentApprovalAdmin.prop_inx ? null : inx
        })
    }
}


export const toggleItemDetails = (inx) => {

    return (dispatch, getState) => {
        dispatch({
            type: types.SET_PROP_DRAWER_INDEX_GM,
            payload: getState().paymentApprovalAdmin.prop_drawer ? null : inx
        })
    }
}

export const setSelectedVehicleDetails = (result) => {
    window.glyco.log('SET_SELECTED_VEHICLE_DETAILS')
    window.glyco.log(result)
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_SELECTED_VEHICLE_DETAILS_GM,
            payload: {
                data: result.data
            }
        })
    }
}

export const getSelectedVehicleDetails = (value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_SELECTED_VEHICLE_DETAILS_GM,
            payload: {
                data: []
            }
        })
        dispatch({
            type: types.GET_SELECTED_VEHICLE_DETAILS_GM,
            payload: {
                query: value
            }
        })
    }
}


export const getUpdatePaymentListHeader = (input) => {
    //console.log(input, "input >>>>")
    return (dispatch, getState) => {
        let data = getState().paymentApprovalAdmin[input.type].filter(x => x.supplier_id == input.id)
        let dsc_perc = Number(data[0].discount ? data[0].discount : 0)
        let pay = Number(data[0].payments ? data[0].payments : 0)
        let dsc_amt = (pay * (dsc_perc / 100.00)).toFixed(2).toString()

        data[0].discountamount = dsc_amt
        data[0].payments = (pay - dsc_amt).toFixed(2).toString()

        //console.log(data, "data")
        dispatch({
            type: types.SET_LOADED_PAYMENT_GM,
            payload: {
                data: data,
                data_type: input.type
            }
        })
    }
}

export const getUpdatePaymentList = (input) => {
    return (dispatch, getState) => {
        let item_data = input.parent_id ?
            getState().paymentApprovalAdmin.main_payment_list.filter(x => x.supplier_id == input.parent_id) :
            getState().paymentApprovalAdmin.main_payment_list

        dispatch({
            type: types.SET_LOADED_PAYMENT_GM,
            payload: {
                data: item_data,
                data_type: input.type
            }
        })
    }
}

export const getUpdateAllPaymentList = (input) => {
    //console.log(input, "input <<<<<<<<<<")
    return (dispatch, getState) => {
        let item_data = getState().paymentApprovalAdmin[input.type]//.filter(x => x.supplier_id == input.parent_id)
        let notpaying = 0.00, payable = 0.00, payment = 0.00
        let result = []
        //effecting the change per field
        if (item_data.length) {
            for (let itm of item_data) {
                itm.items = itm.items.map(x => {
                    //id: i.suppliertransaction_id
                    return {
                        ...x,
                        dontpay: input.value ? false : x.dontpay,
                        selected: input.value
                    }
                })

                //Managing the totalpayment fiel seperate
                itm.items = itm.items.map(x => {
                    //id: i.suppliertransaction_id
                    return {
                        ...x,
                        totalpayment: x.dontpay ? -(Number(x.payment) - Number(x.credit) - Number(x.shortpay)) :
                            x.selected ? Number(x.payment) - Number(x.credit) - Number(x.shortpay) : 0.00
                    }
                })

                for (let x of itm.items) {
                    notpaying += x.dontpay ? Number(x.totalpayment) : 0.00
                    payable += x.selected ? Number(x.payment) - Number(x.credit) - Number(x.shortpay) : 0.00
                    payment += x.selected ? Number(x.payment) : 0.00
                }
                itm.dontpay = (Math.abs(notpaying)).toFixed(2).toString()
                itm.payments = (payable - Math.abs(notpaying)).toFixed(2).toString()
                itm.payment = (payment).toFixed(2).toString()

                let dsc_perc = Number(itm.discount ? itm.discount : 0)
                let pay = Number(itm.payments ? itm.payments : 0)
                let dsc_amt = (pay * (dsc_perc / 100.00)).toFixed(2).toString()

                itm.discountamount = dsc_amt
                itm.payments = pay - dsc_amt
                result.push(itm)
            }
        }

        //Focusing on the Supplier in action

        dispatch({
            type: types.SET_LOADED_PAYMENT_GM,
            payload: {
                data: result,
                data_type: input.type
            }
        })
        let obj = {
            type: 'payment_update',
            data: result,
            supplier_id: 0,
            user: getState().user
        }
        dispatch({
            type: types.COMMIT_PAYMENT_REFRESH,
            payload: obj
        })
    }
}


export const getUpdatePaymentListHeaderFinance = (input) => {
    //console.log(input, "input >>>>")
    return (dispatch, getState) => {
        let data = getState().supplierpaymentapprovalfinance[input.type].filter(x => x.supplier_id == input.id)
        if (input.column == "accept") {
            data[0].items = data[0].items.map(x => {
                //id: i.suppliertransaction_id
                return {
                    ...x,
                    selected: data[0].selected ? false : true
                }
            })
        }
        data[0].selected = data[0].selected ? false : true
        //console.log(data, "data")
        dispatch({
            type: types.SET_LOADED_PAYMENT_GM,
            payload: {
                data: data,
                data_type: input.type
            }
        })
    }
}

export const getUpdateAllPaymentListSupplier = (input) => {
    //console.log(input, "input <<<<<<<<<<")
    return (dispatch, getState) => {
        let item_data = getState().paymentApprovalAdmin[input.type].filter(x => x.supplier_id == input.parent_id)
        let notpaying = 0.00, payable = 0.00, payment = 0.00
        let result = []
        //effecting the change per field
        if (item_data.length) {
            for (let itm of item_data) {
                itm.items = itm.items.map(x => {
                    //id: i.suppliertransaction_id
                    return {
                        ...x,
                        dontpay: input.value ? false : x.dontpay,
                        selected: input.value
                    }
                })

                //Managing the totalpayment fiel seperate
                itm.items = itm.items.map(x => {
                    //id: i.suppliertransaction_id
                    return {
                        ...x,
                        totalpayment: x.dontpay ? -(Number(x.payment) - Number(x.credit) - Number(x.shortpay)) :
                            x.selected ? Number(x.payment) - Number(x.credit) - Number(x.shortpay) : 0.00
                    }
                })

                for (let x of itm.items) {
                    notpaying += x.dontpay ? Number(x.totalpayment) : 0.00
                    payable += x.selected ? Number(x.payment) - Number(x.credit) - Number(x.shortpay) : 0.00
                    payment += x.selected ? Number(x.payment) : 0.00
                }
                itm.dontpay = (Math.abs(notpaying)).toFixed(2).toString()
                itm.payments = (payable - Math.abs(notpaying)).toFixed(2).toString()
                itm.payment = (payment).toFixed(2).toString()

                let dsc_perc = Number(itm.discount ? itm.discount : 0)
                let pay = Number(itm.payments ? itm.payments : 0)
                let dsc_amt = (pay * (dsc_perc / 100.00)).toFixed(2).toString()

                itm.discountamount = dsc_amt
                itm.payments = pay - dsc_amt
                result.push(itm)
            }
        }

        //Focusing on the Supplier in action

        dispatch({
            type: types.SET_LOADED_PAYMENT_GM,
            payload: {
                data: result,
                data_type: input.type
            }
        })
        let obj = {
            type: 'payment_update',
            data: result,
            supplier_id: 0,
            user: getState().user
        }
        dispatch({
            type: types.COMMIT_PAYMENT_REFRESH,
            payload: obj
        })
    }
}

export const getCommitData = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'Action',
                additionalText: 'Refreshing Data...',
                overflowText: '',
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
        dispatch({
            type: types.COMMIT_PAYMENT_REFRESH,
            payload: data
        })
    }
}

export const getSupplierList = (search) => {
    return {
        type: types.GET_GM_SUPPLIER_PAYMENT_LIST,
        payload: {
            search_text: search
        }
    }
}

export const setSupplierList = (payload) => {
    //console.log("setSupplierList")
    //console.log(payload)
    let data = []
    data = payload.data.map(x => {
        return {
            text: x.suppliername,
            value: x.supplier_id
        }
    })
    return {
        type: types.SET_GM_SUPPLIER_PAYMENT_LIST,
        payload: data
    }
}

export const getMainPaymentList = (dataset) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'Action',
                additionalText: 'Refreshing Data...',
                overflowText: '',
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
        let data = {
            supplier_id: 0,
            search_text: ''
        }
        dispatch(getPaymentList(data))
    }
}

export const UpdatePaymentData = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'Action',
                additionalText: 'Refreshing Data...',
                overflowText: '',
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
        dispatch({
            type: types.UPDATE_PAYMENT_DATA_GM,
            payload: data
        })
    }
}

export const processPaymentGM = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.PROCESS_PAYMENT_GM_REVIEW,
            payload: {
                type: "PROCESS_PAYMENT_GM_REVIEW",
                data: data
            }
        })
    }
}

export const getUpdatedList = (data, action) => {
    return (dispatch, getState) => {
        let item_data = getState().paymentApprovalAdmin.payment_list
        //effecting the change per field
        item_data= item_data.map(x => {
            //id: i.suppliertransaction_id
            return (x.suppliertransaction_id === data.suppliertransaction_id) ? {
                ...x,
                rejected: action == 'accept' ? false : true,
                ps: action == 'accept' ? 3 : 0,
                status: action == 'accept' ? 'Approved' : 'Rejected',
                manager_comments: data.manager_comments
            } : x
        })
        dispatch({
            type: types.SET_PAYMENT_APPROVAL_DATA_LIST,
            payload: item_data
        })
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'Action',
                additionalText: 'Action Successful!',
                overflowText: '',
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
} 

export const getUpdateAllList = (data, action) => {
    return (dispatch, getState) => {
        let item_data = getState().paymentApprovalAdmin.payment_list
        //effecting the change per field
        item_data= item_data.map(x => {
            //id: i.suppliertransaction_id
            return {
                ...x,
                rejected: action == 'accept' ? false : true,
                ps: action == 'accept' ? 3 : 0,
                status: action == 'accept' ? 'Approved' : 'Rejected'
            }
        })
        dispatch({
            type: types.SET_PAYMENT_APPROVAL_DATA_LIST,
            payload: item_data
        })
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'Action',
                additionalText: 'Action Successful!',
                overflowText: '',
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
} 
export const setPaymentListData = (response) => {
    //console.log("response", response)
    return (dispatch, getState) => {
        let data = response.map(x => {
            return {
                ...x,
                showdetails: false,
                showcomments: false
            }
        })
        dispatch({
            type: types.SET_PAYMENT_APPROVAL_DATA_LIST,
            payload: data
        })
    }
}
