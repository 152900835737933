import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ReactTable from "react-table";
import moment from 'moment';
import _ from 'lodash';
import { Button, Card, CardContent, CardHeader, Dialog, IconButton, TextField } from '@mui/material';
import * as actions from './actions';
import * as styles from './styles';
import Icon from '../Icon/component';
import * as gridAction from '../Grid/actions';
import Loading from '../Loading/component';
import "react-table/react-table.css";

class ReportSchedulerGrid extends Component {
    componentDidMount() {
        this.props.actions.getSchedulerData()
    }

    getCommands(row) {
        let field = this.props.state.gridFields.filter(x => x.key === true)[0];
        const id = row.original[field.name];
        let i = row.index;
        let commands = [];
        commands.push(
            <IconButton 
                key={'command_view_' + field.name + '_' + id} 
                style={styles.iconButton} 
                iconStyle={styles.actionIcon}
                tooltip={'Edit Scheduler'} 
                color="primary"
                onClick={
                    () => {
                        let _data = this.props.state.data.filter(x => x.scheduler_id === id)[0];
                        this.props.actions.loadEditData(_data);
                        this.props.actions.getReportData();
                        this.props.actions.changeTabView(2);
                    }
                }>
                    <Icon 
                        istyle={{ fontSize: '22px', color: '#253053' }} 
                        iclass={'material-icons'} 
                        iname={'pageview'} 
                    />
            </IconButton>
        )
        
        commands.push(
            <IconButton 
                key={'command_view_' + field.name + '_' + id} 
                style={styles.iconButton} 
                iconStyle={styles.actionIcon}
                tooltip={'Edit Scheduler'} 
                color="primary"
                onClick={
                    () => {
                        let _data = this.props.state.data.filter(x => x.scheduler_id === id)[0];
                        this.props.actions.loadEditData(_data);
                        this.props.actions.getReportData();
                        this.props.actions.changeTabView(1);
                    }
                }>
                    <Icon 
                        istyle={{ fontSize: '22px', color: '#253053' }} 
                        iclass={'material-icons'} 
                        iname={'edit'} 
                    />
            </IconButton>
        )

        commands.push(
            <IconButton 
                key={'command_delete_' + field.name + '_' + id} 
                style={styles.iconButton} 
                iconStyle={styles.actionIcon}
                tooltip={'Delete Scheduler'}
                onClick={
                    () => {
                        this.props.actions.setStateData('confirmDeleteWindow', !this.props.state.confirmDeleteWindow);
                        this.props.actions.setStateData('deleteSchedulerId', id);
                    }
                }>
                    <Icon 
                        istyle={{ fontSize: '22px', color: '#253053' }} 
                        ihovercolor={styles.actionHoverColor} 
                        iclass={'material-icons'} 
                        iname={'delete_forever'} 
                    />
            </IconButton>
        )

        return commands;
    }

    getValue(row) {  
        let field = this.props.state.gridFields.filter(x => x.name === row.column.meta)[0];
        var value = field.related ? row.original.report?.report_name : row.original[field.name];
        switch (field.type) {
            case 'month':
                return moment(value).format('MM');
            case 'integer':
                return value ? value : 0;
            case 'date':
                return moment(value).format('YYYY-MM-DD');
            case 'dateTime':
                return moment(value).format('YYYY-MM-DD HH:mm ZZ');
            case 'time':
                return moment(value).format('HH:mm ZZ');
            case 'timestamp':
                return moment(value).format('YYYY-MM-DD HH:mm ZZ');
            case 'boolean':
                const toggle = field.toggle && field.toggle !== null ? field.toggle.split(',') : value;
                return value !== null
                    ? value
                        ? toggle[0]
                        : toggle[1]
                    : 'Not Set';
            case 'decimal':
                return !isNaN(value)
                    ? Number(value).toFixed(2).toString()
                    : value;
            case 'frequencyText':
                return this.formatFrequencyDisplay(value);
            default:
                return value;
        }
    }

    formatFrequencyDisplay = (frequency) => {
        let returnFrequency = '';
        switch (frequency) {
            case 'day':
                returnFrequency = 'Daily';
                break;
            case 'week':
                returnFrequency = 'Weekly';
                break;
            case 'month':
                returnFrequency = 'Monthly';
                break;
            case 'year':
                returnFrequency = 'Yearly';
                break;
            default:
                returnFrequency = '';
        }
    
        return returnFrequency;
    }

    getFormFieldLabel(row) {
        return <div
            style={{
                width: '100%',
                marginTop: '3px',
                verticalAlign: 'baseline',
                fontSize: '15px'
            }}
        >{this.getValue(row)}</div>
    }

    getColumns() {
        const fields = this.props.state.gridFields.filter(x => x.grid === true);
        let columns =
            fields.map(x => {
                return {
                    meta: x.name,
                    Header: x.title,
                    accessor: x.display,
                    filterable: x.filter,
                    Filter: ({ filter, onChange }) => (
                        x.type !== 'boolean'
                            ? <input
                                onChange={event => onChange(event.target.value)}
                                value={filter ? filter.value : ''}
                                style={{
                                    width: '100%',
                                    height: 25,
                                    border: '1px solid #E6E6E6',
                                    borderRadius: 25
                                }}
                            />
                            : <select
                                onChange={event => onChange(event.target.value)}
                                style={{
                                    width: '100%',
                                    height: 25,
                                    border: '1px solid #E6E6E6',
                                    borderRadius: 25
                                }}
                                value={filter ? filter.value : ''}
                            >
                                <option value={''}>Show All</option>
                                {
                                    x.toggle.split(',').map((x, i) => {
                                        return <option value={i === 0 ? 'true' : 'false'}>{x}</option>
                                    })
                                }
                            </select>
                    ),
                    // width: x.width ? x.width : undefined,
                    headerStyle: {
                        fontSize: '13px',
                        fontWeight: 'bold',
                        textAlign: 'left'
                    },
                    style: {
                        verticalAlign: 'middle',
                        fontSize: 12,
                        letterSpacing: 0,
                        color: 'black',
                        //: x.bold ? 'bold' : 'normal',
                        textAlign:  'left'
                    },
                    Cell: (row) => (
                        this.getFormFieldLabel(row)
                    )
                }
            })

            columns.unshift({
                //Header: '',
                accessor: 'options',
                filterable: false,
                maxWidth: 80,
                width: 80,
                Cell: (row) => (
                    this.getCommands(row)
                )
            })

        return columns;
    }

    getGrid() {
        return <div>
            <ReactTable
                data={this.props.state.data}
                columns={this.getColumns()}
                manual
                loading={this.loading}
                minRows={1}
                showPageJump={true}
                showPaginationTop={false}
                style={{
                    height: '100%'
                }}
                onFetchData={
                    (state) => {
                        //this.props.actions.getComponentGridData(this.props.state.sub_account, this.props.state.sub_account.link, null, this.props.state.sub_account.filter, this.props.state.sub_account.id)
                    }
                }
            >
                {(state, render, instance) => {
                    return (
                        render()
                    )
                }}
            </ReactTable>
           
            <Dialog
                open={this.props.state.confirmDeleteWindow}
                style={{ width: '50% !important', maxWidth: '50% !important' }}
                /* fullWidth={true} */
                maxWidth="xl"
                classes={{ paper: 'shadow-lg rounded' }}
            >
                <div className="text-center p-5">
                    <div className="avatar-icon-wrapper rounded-circle m-0"></div>
                    <h4 className="font-weight-bold mt-4">
                        Delete Schedule Report
                    </h4>
                    <p className="mb-0 font-size-lg text-muted">
                        Are you sure want to delete this schedule report record ?
                    </p>
                    <div className="pt-4">
                        <Button
                            onClick={
                                (event) => {
                                    this.props.actions.setStateData('confirmDeleteWindow', !this.props.state.confirmDeleteWindow);
                                    this.props.actions.setStateData('deleteSchedulerId', 0);
                                }
                            }
                            className="btn-pill mx-1"
                        >
                            <span className="btn-wrapper--label">Cancel</span>
                        </Button>
                        <Button
                            onClick={
                                (event) => {
                                    this.props.actions.deleteScheduler(this.props.state.deleteSchedulerId);
                                }
                            }
                            className=" btn-pill mx-1"
                        >
                            <span className="btn-wrapper--label">Confirm</span>
                        </Button>
                    </div>
                </div>
            </Dialog>
        </div>
    }

    getLayout() {
        return 1 !== 1
            ? <Loading message={'Loading....'} />
            : <Card initiallyExpanded={true}>
                <CardHeader
                    avatar={
                        <Icon 
                            iclass={'custom-icons'} 
                            iname={'organization'} 
                        />
                    }
                    title={'Report Scheduler List'}
                    subtitle={'Manage Report Scheduler List.'}
                />
                <CardContent expandable={false}>
                   { this.getGrid() }
                </CardContent>
            </Card>
    }

    render() {
        return this.props.state.loading
            ? <Loading message={'Loading component...'} />
            : this.getLayout()
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.reportScheduler
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...gridAction }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportSchedulerGrid);