

export const GET_JOURNAL_LIST = 'GET_JOURNAL_LIST'
export const GET_DEBTORS_LIST = 'GET_DEBTORS_LIST'
export const GET_BUSINESS_LIST = 'GET_BUSINESS_LIST'
export const GET_CONTRA_ACCOUNT_LIST = 'GET_CONTRA_ACCOUNT_LIST'
export const GET_ITEM_ACCOUNT_LIST = 'GET_ITEM_ACCOUNT_LIST'

export const SET_ANY_DATA_LIST = 'SET_ANY_DATA_LIST'
export const SET_ANY_SELECTED_ITEM = 'SET_ANY_SELECTED_ITEM'
export const SET_ANY_FIELD_INPUT_VALUE = 'SET_ANY_FIELD_INPUT_VALUE'
export const SET_ANY_ITEM_INPUT_VALUE = 'SET_ANY_ITEM_INPUT_VALUE'

export const SAVED_JOURNAL_DATA = 'SAVED_JOURNAL_DATA'
export const SAVE_JOURNAL_DATA = 'SAVE_JOURNAL_DATA'

export const GET_JOURNAL_DUPLICATE_REFERENCE_CHECK = 'GET_JOURNAL_DUPLICATE_REFERENCE_CHECK'
export const JOURNAL_DUPLICATE_FOUND = 'JOURNAL_DUPLICATE_FOUND'
export const JOURNAL_NO_DUPLICATE_FOUND = 'JOURNAL_NO_DUPLICATE_FOUND'