import * as actions from './actions'
import * as types from './types'

import * as config from '../../config'
import * as progressBarActions from '../ProgressBar/actions'
import * as requestActions from '../Request/actions'
import * as routerActions from '../Redirect/actions'
import * as requestTypes from '../Request/types'

export const getNavigationMenuEpic = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
        types.NAVIGATION_MENU_GET_MENU
    ),
        mergeMap(() =>
            ajax$({
                url: config.system.api + '/components/component/other',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'auth': store.value.cognito.authenticateUser.token
                },
                body: {
                    query: 'component_menu',
                    team_ids: store.value.accounts.teams.map(t => t.team_id)
                }
            })
                .pipe(
            mergeMap((result) => [
                    actions.setNavigationMenu(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/component/other')
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/component/other', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )

export const getStaticMenuEpic = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
    types.STATIC_MENU_GET_MENU
),
    mergeMap((payload) =>
        ajax$({
            url: config.system.api + '/components/static_menu/other',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
                'auth': store.value.cognito.authenticateUser.token
            },
            body: {
                ...payload
            }
        })
            .pipe(
        mergeMap((result) => [
                actions.setStaticMenu(result.response),
                requestActions.requestSucceeded(config.system.api + '/components/component/other')
            ])
            ,startWith(progressBarActions.progress(true))
            ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/component/other', error)))
            //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
    )
    )
    ,catchError((error) =>
        of$(routerActions.route('/support', false, error))
    )
    )

    // export const getStaticMenuEpic = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    // action$.pipe(ofType(
    //     types.STATIC_MENU_GET_MENU
    // ),
    //     mergeMap(({payload}) =>
    //         ajax$({
    //             url: config.system.api + '/components/static_menu/search',
    //             method: 'GET',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'salt': store.value.cognito.authenticateUser.jwt,
    //                 'datetime': store.value.cognito.authenticateUser.datetime,
    //                 'auth': store.value.cognito.authenticateUser.token
    //             },
    //             body: {
    //                 ...payload
    //             }
    //         })
    //             .pipe(
    //         mergeMap((result) => [
    //                 actions.setStaticMenu(result.response),
    //                 requestActions.requestSucceeded(config.system.api + '/components/component/other')
    //             ])
    //             ,startWith(progressBarActions.progress(true))
    //             ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/component/other', error)))
    //             //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
    //     )
    //     )
    //     ,catchError((error) =>
    //         of$(routerActions.route('/support', false, error))
    //     )
    //     )



export const getUserNotificationsEpic = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
        types.GET_USER_WORKFLOW_NOTIFICATIONS_MENUS
    ),
        mergeMap(() =>
            ajax$({
                url: config.system.api + '/components/workflow_task_user_notification/search?user_id=' + store.value.user.user_id,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'take': 200,
                    'related': 'user,workflow,workflow_task.workflow_task_team, workflow_task_type'
                }
            }).pipe(
                mergeMap(payload => [
                    actions.setUserNotifications(payload.response),
                    requestActions.requestSucceeded(config.system.api + '/components/team/query')
                ])
                //,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/team/query', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )

     

// API call for get document 360 token
export const getDocument360Token = (action$, store, { ajax$, of$, startWith, map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(ofType(
        types.GET_DOCUMENT_360_TOKEN
    )
        , mergeMap(({ payload }) => {
                return ajax$({
                    url: config.system.api + '/components/knowledgebase/other',
                    method: 'POST',
                    headers: {
                        'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                        'salt': store.value.cognito.authenticateUser.jwt,
                        'datetime': store.value.cognito.authenticateUser.datetime,
                        'client-id': store.value.accounts.selectedAccount,
                        'Content-Type': 'application/json'
                    },
                    body: {
                        type: 'GET_DOCUMENT_360_TOKEN',
                        user: {
                            "username": `${store.value.user?.firstname} ${store.value.user?.lastname}`,
                            "firstName": store.value.user?.firstname,
                            "lastName": store.value.user?.lastname,
                            "emailId": store.value.user?.email,
                            "tokenValidity": 60
                        }
                    }
                })
                    .pipe(
                        mergeMap((result) => [
                            actions.getDocument360TokenDone(result.response),
                            requestActions.requestSucceeded(config.system.api + '/components/component/other')
                        ])
                        , startWith(progressBarActions.progress(true))
                        , catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/component/other', error)))
                        //, take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
                    )
        })
        , catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )   
