//import * as colours from '@mui/material/colors'
//import Theme from '../../theme'

export const confirmSelection = {
    textAlign: 'right'
}

export const selectedFieldName = {
    fontWeight: 'bold'
}

export const right = {
    float: 'right'
}

export const textBox = {
    width: '100%'
}