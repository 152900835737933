import * as colours from '@mui/material/colors'
import Theme from '../../theme'

export const theme = Theme

export const card = {
    margin: 'auto',
    //marginLeft: 'auto',
    //marginRight: 'auto',
    width: '300px',
    //opacity: 0.85
}

export const cardActions = {
    textAlign: 'center'
}

export const login = {
    marginTop: 'auto',
  margin: '7% auto auto 0%' ,
  width: '20%',
}

export const cardimage = {
    textAlign: 'center',
    height: '150px'
}

export const center = {
    textAlign: 'center',
}

export const error = {
    color: colours.red[600]
}

export const valid = {
    color: colours.green[500]
}

export const remember = {
    marginTop: 20,
    textAlign: 'left'
}

export const keyword = {
    container: {
        position: 'relative',
        display: 'inline-block',
        verticalAlign: 'middle'
    }
}

export const resendButton = (stage) => {
    return {
        display: stage === '' ? 'inline-block' : 'none',
        marginBottom: 25
    }
}

export const loginButton = {
    root:{
    marginBottom: 25,
    border: 0,
    borderRadius: 3,
    height: 48,
    width:150,
    padding: '0 30px',
    }
}

export const showKeyword = {
    position: 'absolute',
    right: 15,
    top: 30,
    width: 20,
    height: 20
}


export const iconButton = {
    height: '25px',
    color: 'white',
    backgroundColor: '#fff',
    padding: 0,
    margin: 0
}


export const actionIcon = {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#fff',
}


export const actionHoverColor = {
    color: 'red',
}