import * as config from '../../../config'
import * as types from './types'
import * as requestTypes from '../../Request/types'
import * as actions from './actions'
import * as progressBarActions from '../../ProgressBar/actions'
import * as requestActions from '../../Request/actions'
import * as routerActions from '../../Redirect/actions'

export const adhocGridGetDataEpic = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.ADHOC_GRID_GET_DATA)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + payload.uri,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'client-id': store.value.accounts.selectedAccount,
                    'business-ids': store.value.businessCustomers.allBusinesses
                },
                body: {
                    ...payload.body,
                    search: store.value.workflowQueueTasks.value
                        ? store.value.workflowQueueTasks.value
                        : payload.body.search
                            ? payload.body.search
                            : '',
                }
            })
                .pipe(
            mergeMap((result) => [
                    actions.adhocGridSetData(payload.name, result.response),
                    progressBarActions.progress(false),
                    requestActions.requestSucceeded(config.system.api + payload.uri, result.response)
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + payload.uri, error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )


export const adhocGridGetComponentDataEpic = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.ADHOC_GRID_LOAD_COMPONENT_DATA)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + payload.component + payload.query,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'related': 'component_field,component_category',
                    'take': 20
                }
            })
                .pipe(
                    mergeMap((result) => [
                    actions.adhocGridSetData(payload.name, result.response),
                    progressBarActions.progress(false),
                    requestActions.requestSucceeded(config.system.api + payload.component, result.response)
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + payload.component, error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )