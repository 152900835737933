export const filepreview = ''
export const filename = ''
export const comments = null
export const completeddate = null
export const documents = []

export const invoicedate = ''
export const invoice_number = ''
export const invoice_amount = ''
export const isinvoice_uploaded = false


export const terminationdate = null
export const terminationodo = null
export const odochecked = false

export const fields =  { 

     
    odochecked: {
        errorText: 'Please ensure Odo is checked',
        state: "getState().defleetContractTermination.odochecked",
        value: null,
        valid: false,
        empty: true,
    },
    
    terminationodo: {
        errorText: 'Please enter Termination Odo',
        state: "getState().defleetContractTermination.terminationodo",
        value: null,
        valid: false,
        empty: true,
        validations : [{
            'schema': 'integer',
            'required': true,
            'maxLength': null,
            'errorText': 'Please enter a valid Termination Odo'
        },
        {
            'schema': 'greaterThanZero',
            'required': true,
            'maxLength': null,
            'errorText': 'Please enter a valid Termination Odo that is greater than zero.'
        }]
    },

    terminationdate: {
        errorText: 'Please enter Termination Date',
        state: "getState().defleetContractTermination.terminationdate",
        value: null,
        valid: false,
        empty: true,
    },


}