import * as types from './types'

export const getTrialbalanceData = (search) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_COMPONENT_TRIALBALANCE_RPT_DATA,
            payload: {
                type: 'get_trialbalance'
            }
        })
    }
}

export const setTrialbalanceData = (response) => {
    return (dispatch, getState) => {
        dispatch(setInputValue('report_data', response))
    }
}

export const setInputValue = (prop, data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_TRIALBALANCE_RPT_DATA,
            payload: {
                prop: prop,
                data: data
            }
        })
    }
}