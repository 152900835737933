import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system'
import _ from 'lodash'

import Card from '../../../controls/card'
import Checkbox from '../../../controls/checkbox'

import { Table } from '@mui/material'

import * as styles from './styles'
import * as actions from './actions'
import moment from 'moment';
import TextField from '@mui/material/TextField'
import Button from "@mui/material/Button";
import Upload from '../../Upload/component'
import Fields from '../../../controls/cardfields'
import MultiSelect from '../../../controls/multiselect'
import PdfViewer from '../../PDF/component'
import StatementGrid from './statementgrid'

import Dialog from '@mui/material/Dialog'

class StatementPreview extends Component {
    componentDidMount() {
        this.props.actions.reset()
        this.props.actions.setValue('refreshing',true)
        this.props.actions.getStatementData()
    }

    getSummaryDetails() {
        return  <Card title={window.t.en('Statement Summary')}
        subtitle={window.t.en('Billing details summary')}
        content={<Fields 
                fields={[
                    {label: 'Total Statements', value: this?.props?.state?.data?.length},
                ]}
                />
            }
            headerAction={
                <div style={{float: 'right'}}>
                    <Button
                        variant="contained"
                        color="primary"
                        className="btn-success"
                        onClick={(event, value) => {
                            event.preventDefault()
                            this.props.actions.setValue('confirm_win',true)
                        }}
                        >
                        {"Send Statements"}
                    </Button>
                </div>
            }
        />
    }

    getLayout() {  
        return <div> <Card title={window.t.en('Statements Run')}
                subtitle={window.t.en('Statement preview and run')}
                content={<Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={12}>
                                    <hr />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <StatementGrid />
                            </Col>
                        </Row>
                    </Container>
                }
                headerAction={
                    <div style={{float: 'right'}}>
                        <Button
                            variant="contained"
                            color="primary"
                            className="btn-default"
                            onClick={(event, value) => {
                                event.preventDefault()
                                this.props.actions.ExportStatementData()
                            }}
                            >
                            {"Export Statements"}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            className="btn-default"
                            onClick={(event, value) => {
                                event.preventDefault()
                                this.props.actions.setValue('refreshing',true)
                                this.props.actions.getStatementData()
                            }}
                            >
                            {"Refresh Data"}
                        </Button>
                    </div>
                }
            />
            
        <Dialog
            open={this.props.state.confirm_win}
            onClose={(event) => {
                event.preventDefault()
                this.props.actions.setValue('confirm_win', false)
            }}
            //classes={{ paper: 'shadow-lg rounded' }}
            >
             <div className="text-center p-5">
              <h4 className="font-weight-bold mt-4">
                STATEMENT SEND - COMFIRM
              </h4>
              <strong className="mb-0 font-size-lg text-muted bold">
                You are about to start sending statement
              </strong>
              <p className="mb-0 font-size-lg text-muted">
                <TextField 
                id="outlined-basic" 
                label="Enter Current PIN" 
                variant="outlined"
                
                value={this.props.state.confirm_pin}
                onChange={
                    (event, value) => {
                        this.props.actions.setValue('confirm_pin', event.target.value)
                    }
                } />
              </p>
              <hr />
              <strong className="mb-0 font-size-lg text-muted bold">
                Are you sure
              </strong>
              {/* <p className="mb-0 font-size-lg text-muted">
                <TextField id="outlined-basic" label="Enter New PIN" variant="outlined" />
              </p> */}
              <div className="pt-4">
                <Button
                  onClick={(event) => {
                        event.preventDefault()
                        this.props.actions.setValue('confirm_win', false)
                    }}
                  className=" btn-pill mx-1">
                  <span className="btn-wrapper--label">Cancel</span>
                </Button>
                <Button 
                    onClick={(event) => {
                        event.preventDefault()
                        this.props.actions.setValue('confirm_win', false)
                        this.props.actions.sendStatement(this.props.state.confirm_pin)
                    }}
                    disabled={this.props.state.confirm_pin !== '111'}
                    className="btn-pill mx-1">
                    <span className="btn-wrapper--label">Ok</span>
                </Button>
              </div>
            </div> 
          </Dialog>
            </div>
    }

    render() {
        return ([this.getSummaryDetails(),this.getLayout()])
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.statementPreview
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StatementPreview)