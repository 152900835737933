export const customer_id = {
    errorText: 'Vehicle selection category field required',
    value: null,
    valid: true,
    empty: true,
}

export const customer = {
    errorText: 'Vehicle selection category field required',
    value: '',
    valid: true,
    empty: true,
}

export const contractvehiclecategory = {
    errorText: 'Vehicle selection category field required',
    value: '',
    valid: false,
    empty: true,
}
export const contractvehiclecategory_id = {
    errorText: 'Vehicle selection category field required',
    value: null,
    valid: false,
    empty: true,
}

export const supplier = {
    errorText: 'SUpplier field required',
    value: '',
    valid: false,
    empty: true,
}
export const supplier_id = {
    errorText: 'SUpplier field required',
    value: null,
    valid: false,
    empty: true,
}
export const startdate = {
    errorText: 'Start date field required',
    value: '',
    valid: false,
    empty: true,
}
export const enddate = {
    errorText: 'End date field required',
    value: '',
    valid: false,
    empty: true,
}
export const months = {
    errorText: 'Month field required',
    value: '',
    valid: false,
    empty: true,
}
export const days = {
    errorText: 'Days field required',
    value: '',
    valid: false,
    empty: true,
}
export const contractperiod = {
    errorText: 'Contract period field required',
    value: '',
    valid: false,
    empty: true,
}
export const distancepermonth = {
    errorText: 'Distance per month field required',
    value: '',
    valid: false,
    empty: true,
}
export const contractdistance = {
    errorText: 'Contract distance name field required',
    value: '',
    valid: false,
    empty: true,
}
export const rentalamount = {
    errorText: 'Rental amount field required',
    value: '',
    valid: false,
    empty: true,
}
export const accesskm = {
    errorText: 'Access km field required',
    value: '',
    valid: false,
    empty: true,
}
export const quotevalidfor = {
    errorText: 'Quote valid for field required',
    value: '',
    valid: false,
    empty: true,
}
export const replacevehicle = {
    errorText: 'Replace vehicle field required',
    value: '',
    valid: false,
    empty: true,
}

export const cust_name = {
    errorText: '',
    value: '',
    valid: true,
    empty: true,
}
export const cust_person = {
    errorText: '',
    value: '',
    valid: true,
    empty: true,
}
export const cust_address = {
    errorText: '',
    value: '',
    valid: true,
    empty: true,
}
export const unit = {
    errorText: 'month',
    value: '',
    valid: true,
    empty: true,
}
export const adhoc = {
    errorText: '',
    value: false,
    valid: true,
    empty: true,
}

export const deliverycost = {
    errorText: '',
    value: '',
    valid: true,
    empty: true,
}

export const customer_data = []
export const supplier_data = []
export const contractvehiclecategory_data = []
export const units = [
    {
        text: 'Days',
        value: 'day' 
    },
    {
        text: 'Weeks',
        value: 'week' 
    },
    {
        text: 'Months',
        value: 'month' 
    }
]

export const finance = {
    errorText: 'Finance field empty',
    value: '',
    valid: false,
    empty: true,
}
export const vat_tot = {
    errorText: 'VAT total field empty',
    value: '',
    valid: false,
    empty: true,
}
export const waiver_tot = {
    errorText: 'Waiver total field empty',
    value: '',
    valid: false,
    empty: true,
}
export const sundry_tot = {
    errorText: 'Sundry total field empty',
    value: '',
    valid: false,
    empty: true,
}
export const accessory_tot = {
    errorText: 'Accessory total rate field empty',
    value: '',
    valid: false,
    empty: true,
}
export const monthly_str = {
    errorText: 'Montly str field empty',
    value: '',
    valid: false,
    empty: true,
}
export const monthly_excl = {
    errorText: 'Montly excl rate field empty',
    value: '',
    valid: false,
    empty: true,
}
export const monthly_incl = {
    errorText: 'Montly incl rate field empty',
    value: '',
    valid: false,
    empty: true,
}

export const monthly_vat = {
    errorText: 'Montly vat field empty',
    value: '',
    valid: false,
    empty: true,
}

export const validation = {
    fields: ['startdate','enddate','months','rentalamount','accesskm','supplier_id','contractvehiclecategory_id'],
    list: [],
    component: 'captureSTR',
    valid: false
}