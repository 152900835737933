import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'
import _ from 'lodash'

import IconButton from '@mui/material/IconButton';

import ReactTable from "react-table"
import "react-table/react-table.css"

import Icon from '../Icon/component'
import Theme from '../../theme'

import * as actions from './actions'
import * as styles from './styles'

import Iframe from 'react-iframe'
import Modal from 'react-responsive-modal';
import PdfViewer from '../PDF/component'
import { Container, Row, Col } from 'react-grid-system'
import Button from '@mui/material/Button';
// import * as readComponentActions from '../ReadComponent/actions'
// import * as infoSheetActions from '../InfoSheet/actions'
// import * as redirectActions from '../Redirect/actions'
// import * as masterComponentViewActions from '../../views/MasterComponentView/actions'

class QuoteSelectGridComponent extends Component {
    componentDidMount() {
        this.props.actions.getClearDocuments()
    }
    showDocument() {
        return this.props.state.document.data.content.text
            ? <Iframe url={this.props.state.document.data.content.text}
                width="100%"
                height="1000px"
                id="myId"
                className="myClassname"
                display="initial"
                position="relative"
                allowFullScreen />
            : 'Loading...'
    }

    getCommands(row) {
        const component = this.props.state.component
        const id = row.original[component.component_field.filter(x => x.key)[0].name]
        const quotenumber = row.original['quotenumber']

        let commands = []

        commands.push(
            <IconButton key={'command_download_rfq_' + component.name + '_' + id} style={styles.iconButton} iconStyle={styles.action}
                onClick={
                    () => {
                        //this.props.isOpen = true
                        this.props.actions.getQuoteDocument(id, quotenumber, 'rfq')

                        // this.props.actions.route(
                        //     '/view/' + component.name + '/' + id
                        // )
                    }
                }>
                <Icon istyle={styles.gridOptionsIcon} ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'cloud_download'} />
            </IconButton>
        )
        
        commands.push(
            <IconButton key={'command_download_worksheet_' + component.name + '_' + id} style={styles.iconButton} iconStyle={styles.action}
                onClick={
                    () => {
                        //this.props.isOpen = true
                        this.props.actions.getQuoteDocument(id, quotenumber, 'worksheet')

                        // this.props.actions.route(
                        //     '/view/' + component.name + '/' + id
                        // )
                    }
                }>
                <Icon istyle={styles.gridOptionsIcon} ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'cloud_download'} />
            </IconButton>
        )

        return commands
    }

    getValue(row, component, _field) {
        if (_field.related) {
            return row.value
        }
        else {
            const field = component.component_field.filter(_field => _field.name === row.column.meta)[0]

            switch (field.component_field_data_type.name) {
                case 'date':
                    return moment(row.value).format('YYYY-MM-DD HH:mm')
                case 'dateTime':
                    return moment(row.value).format('YYYY-MM-DD HH:mm ZZ')
                case 'time':
                    return moment(row.value).format('HH:mm ZZ')
                case 'timestamp':
                    return moment(row.value).format('YYYY-MM-DD HH:mm ZZ')
                case 'boolean':
                    const toggle = field.toggle && field.toggle && field.toggle !== null ? field.toggle.split(',') : field.value
                    return field.value != null
                        ? field.value
                            ? toggle[0]
                            : toggle[1]
                        : 'Not Set'
                case 'decimal':
                    return !isNaN(row.value)
                        ? parseFloat(row.value.toString().replace(/,/gi, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                        : row.value
                default:
                    return row.value
            }
        }
    }

    getOptions(field) {
        const component = field.display.split('.')[0]
        const prop = field.display.split('.')[1]
        const data = this.props.state.component.grid.data
        let arr = data.reduce((x, y) => x.includes(y) ? x : [...x, y], [])
        let options = arr.map(x => {
            return <option value={x[component][field.name]}>{x[component][prop]}</option>
        })
        return options
    }

    getColumns(component) {
        const fields = component.component_field
            .filter(x => x.grid)
            .sort((x, y) => x.order - y.order)

        let columns = fields.map(x => {
            return {
                meta: x.name,
                Header: window.t.en(x.title),
                accessor: (x.related) ? x.display : x.name,
                id: (x.related) ? x.display : x.name,
                width: x.width ? x.width : undefined,
                style: {
                    verticalAlign: 'middle',
                    backgroundColor: x.highlight ? styles.highlight : '',
                    fontWeight: x.bold ? 'bold' : 'normal',
                    textAlign: x.component_field_display_type.name !== 'autoComplete' && (x.component_field_data_type.name === 'decimal' || x.component_field_data_type.name === 'integer' || x.component_field_data_type.name === 'bigInteger')
                        ? 'right'
                        : 'left'
                },
                filterable: true,
                Cell: (row) => (
                    this.getValue(row, component, x)
                )
            }
        })
        // columns.unshift({
        //     //Header: '',
        //     accessor: 'options',
        //     filterable: false,
        //     maxWidth: 100,
        //     width: 100,
        //     Cell: (row) => (
        //         this.getCommands(row)
        //     )
        // })

        return columns
    }

    render() {
        const component = this.props.component
            ? this.props.component
            : this.props.state.component

        return <div>
            <ReactTable
                key={'grid_' + component.name + '_' + component.component_id}
                data={component.grid.data}
                columns={this.getColumns(component)}
                defaultPageSize={component.grid.paging.pageSize}
                filterable={true}
                loading={component.grid.loading}
                manual
                onFetchData={
                    (state) => {
                        this.props.actions.getComponentGridData(component, this.props.link, state, this.props.filter, this.props.id)
                    }
                }
                getTrProps={(state, rowInfo) => {
                    return {
                        onClick: (event) => {
                            this.props.actions.selectRow(component.name, rowInfo)
                        },
                        style: {
                            background: component.selected && rowInfo
                                ? component.selected.filter(x => x.index === rowInfo.index)[0] ? Theme.palette.primary1Color : 'white'
                                : 'white',
                            color: component.selected && rowInfo
                                ? component.selected.filter(x => x.index === rowInfo.index)[0] ? 'white' : 'black'
                                : 'black'
                        }
                    }
                }}
                minRows={1}
                pages={component.grid.paging.pageCount}
                showPageJump={true}
                showPaginationTop={false}
                style={{
                    height: '100%'
                }}
            >
                {(state, render, instance) => {
                    return (
                        render()
                    )
                }}
            </ReactTable>
            <Modal open={this.props.state.document.data.content.text ? true : false} style={styles.popup} little>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={12}>
                            <Iframe url={this.props.state.document.data.content.text}
                                width="900px"
                                height="900px"
                                id="myId"
                                className="myClassname"
                                display="initial"
                                position="relative"
                                allowFullScreen />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            < Button className={"global_button"}
                                secondary={true}
                                label="Close Window"
                                icon={<Icon iclass={'material-icons'} iname={'cancel'} />}
                                onClick={(event) => {
                                    this.props.actions.getClearDocuments()
                                }}
                                ><Icon iclass={'material-icons'} iname={'cancel'} /> Close Window</Button>
                        </Col>
                    </Row>
                </Container>
            </Modal>
        </div>
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state,
            component: {
                ...state.components[ownProps.name]
            },
            document: {
                ...state.components['document']
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuoteSelectGridComponent)