import * as types from './types'
import * as saveComponentDataActions from '../../components/Form/actions'

export const saveComponentData = (component) => {
    return (dispatch, getState) => {
        dispatch(saveComponentDataActions.saveComponentData(component))
    }
}

export const displayDashboard = (key) => {
    return {
        type: types.SHOW_DASHBOARD,
        payload: key
    }
}

export const displayReport = (key) => {
    return {
        type: types.SHOW_REPORT,
        payload: key
    }
}

export const isLoading = (status) => {
    return {
        type: types.SET_MASTER_LOADING_STATUS ,
        payload: status
    }
}

export const setSelectedDashboard = (id) => {
    return {
        type: types.SET_SELECTED_DASHBOARD,
        payload: id
    }
}

export const setSelectedReport = (id) => {
    return {
        type: types.SET_SELECTED_REPORT,
        payload: id
    }
}

export const changeMasterComponentTab = (value, component) => {
    //console.log('changeMasterComponentTab', value, component)
    return {
        type: types.CHANGE_MASTER_COMPONENT_TAB,
        payload: {
            component: component,
            value: value
        }
    }
}

export const clearMasterComponentVisualisations = () => {
    return {
        type: types.CLEAR_MASTER_COMPONENT_VISUALISATIONS
    }
}