import * as config from '../../config'

import * as actions from './actions'
import * as requestActions from '../Request/actions'
import * as routerActions from '../Redirect/actions'

import * as types from './types'
import * as requestTypes from '../Request/types'
import { action } from '../WorkflowPOHeader/POOfficeAdmin/actions'

export const getEvents = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.GET_CALENDAR_EVENTS)
        ,delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/events/search' + payload,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
'salt': store.value.cognito.authenticateUser.jwt,
'datetime': store.value.cognito.authenticateUser.datetime,
                    'order': '-event_id',
                    'take': 100
                },
            })
                .pipe(
            mergeMap((result) => [
                    actions.setEvents(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/events/search')
                ])
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/events/search', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )

export const getTasks = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.GET_GANTT_TASKS)
        ,delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/events/other',
                method: 'POST',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'Content-Type': 'application/json'
                },
                body: {type: 'get_tasks'}
            })
                .pipe(
            mergeMap((result) => [
                    actions.setTasks(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/events/search')
                ])
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/events/search', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )