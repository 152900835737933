import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Tabs,Box, Tab } from '@mui/material'
import GeneralContentView from '../../views/GeneralContentView/component'

import Loading from '../Loading/component'
import Form from './form'
import Grid from './grid'

import * as actions from './actions'

class SubAccount extends Component {

    render() {
        return <div>
                <Tabs
                    initialSelectedIndex={0}
                    value={this.props.state.tab}
                    inkBarStyle={{
                        height: 4
                    }}
                    onChange={(event, value) => {
                        for(let x of this.props.state.sub_account.component_field){
                            this.props.actions.setComponentFieldInput(x,null)
                        }
                        this.props.actions.changeTabView(value)
                    }}
                >
                    <Tab style={{minWidth:"50%"}} label={"Sub Account"} />
                    <Tab style={{minWidth:"50%"}} label={"Create Sub Account"} />
                </Tabs>
                <br/>
                <div>
                    {this.props.state.tab === 0 && <Box><Grid /></Box>}
                    {this.props.state.tab === 1 && <Box><Form /></Box>}
                </div>
            </div>
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.subAccount,
            sub_account: {
                ...state.components.sub_account
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubAccount)