import Theme from '../../theme'
import { grey } from '@mui/material/colors'

export const theme = Theme

export const documentButton = {
    width: '50px'
}

export const dropZone = {
    width: '100%',
    height: '150px',
    border: '2px dashed ' + grey[500]
}

export const commandRow = {
    textAlign: 'right'
}

export const actionIcon = {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#fff',
}

export const documentCard = {
    margin: '5px'
}

export const iconButton = {
    height: '10px',
    padding: 0,
    margin: 0
}

export const documentCardTitle = {
    fontWeight: 'bold'
}

export const tab = {
    textTransform: 'capatilize',
    //whiteSpace: 'nowrap'
}

export const showDropzone = {
    display: 'block'
}

export const hideDropzone = {
    display: 'none'
}