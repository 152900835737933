import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Iframe from 'react-iframe'
import 'react-quill/dist/quill.snow.css'

import { Container, Row, Col } from 'react-grid-system'

import { Table } from "@mui/material";

import AutoComplete from '../../controls/autocomplete'
import DateControl from '../../controls/dateControl'
import TextField from '../../controls/textField'
import Fields from '../../controls/cardfields'
import Card from '../../controls/card'
import ViewCustomer from '../../controls/view_customer'

import PdfViewer from '../PDF/component'
import UploadAccessory from '../UploadAccessory/component'
import Loading from '../Loading/component'

import * as actions from './actions'
import * as styles from './styles'

import * as workflowTaskInputActions from '../WorkflowTaskInput/actions'

class WorkflowPOReceiptAccessoriesComponent extends Component {

    componentDidMount() {
        this.props.actions.setValidationState('workflowPOReceiptAccessories')
        try {
            let data = this.props.workflow_queue_data.context_data.data.data.data[0]
            this.props.actions.setInputValue({ prop: 'vinnumber', value: data.vehicle.vinnumber })
        }
        catch (err) { 
        }
        // try {
        //     let data = this.props.workflow_queue_data.context_data.data.data.data[0]
        
        //     this.props.actions.setInputValue({prop: 'accessory_list', value: data.vehicle.accessory_list})
        //     this.props.actions.setInputValue({prop: 'service_list', value: data.vehicle.service_list})
        // }
        // catch (err) { }
    }

    componentWillUnmount() {
        this.props.actions.setValidationState(null)
    }

    getContract(data) {
        return <Card 
                title={window.t.en('Quote Confirmation')}
                subtitle={window.t.en('Please ensure all details are captured correctly before you proceed')}
                content={
                    <Fields nocard={true}
                        fields={[
                        {size: 4, label: 'Contract Type:', value: data.contractname},
                        {size: 4, label: 'Vehicle Category:', value: data.customerderivativecategory},
                        {size: 4, label: ' Monthly Distance:', value: data.distancepermonth},
                        {size: 4, label: 'Contract Period:', value: data.contractperiod},
                        {size: 4, label: 'Quote For:', value: data.quotefor},
                        {size: 4, label: 'RFQ Date:', value: data.quotedate},
                        {size: 4, label: 'Vehicle Color:', value: data.color},
                        {size: 4, label: 'Vehicle Location:', value: data.location},
                        {size: 4, label: 'Special Vehicle:', value: data.specialistvehicle},
                        {size: 4, label: 'Vehicle Group:', value: data.vehiclegroup},
                        {size: 4, label: 'Operating Terrain:', value: data.terrain},
                        {size: 4, label: 'Vehicle Usage:', value: data.usage},
                        {size: 4, label: 'Comments:', value: data.comments},
                    ]}
                />
                }
            />
    }

    getCustomer(customer) {
        let contact = customer.customercontact ? customer.customercontact.length ? customer.customercontact[0].contact : {} : {}
        return <Card 
                title={window.t.en('Customer Details')}
                subtitle={window.t.en('Customer quote created for')}
                content={
                    <ViewCustomer id={customer.customer_id} nocard={true} min={true} />
                    // <Fields nocard={true}
                    //     fields={[
                    //         { size: 4, label: 'Customer Name:', value: customer.customername },
                    //         { size: 4, label: 'Cost Centre:', value: customer.costcentre },
                    //         { size: 4, label: 'Account Number:', value: customer.accountnumber },
                    //         { size: 4, label: 'Contact Person Name:', value: contact.fullname },
                    //         { size: 4, label: 'Contact Number:', value: contact.mobile },
                    //         { size: 4, label: 'Contact Email:', value: contact.email },
                    //     ]}
                    // />
                }
            />
    }

    getSupplier(data) {
        return <Card 
                title={window.t.en('Supplier Details')}
                subtitle={window.t.en('Selected Vehicle Supplier ')}
                content={
                    <Fields nocard={true}
                        fields={[
                            {size: 4, label: 'Supplier Name:', value: data.suppliername},
                            {size: 4, label: 'Trading Name:', value: data.tradingnamename},
                            {size: 4, label: 'Account Number:', value: data.accountnumber},
                            {size: 4, label: 'VAT Number:', value: data.vatnumber},
                            {size: 4, label: 'Contact Person:', value: data.contactperson},
                            {size: 4, label: 'Contact Email:', value: data.contactemail},
                            {size: 4, label: 'Contact Number:', value: data.contactnumber},
                        ]}
                    />
                }
            />
    }

    getVehicle(data) {
        return <Card 
                title={window.t.en('Accepted Quote ' + data.quotenumber)}
                subtitle={window.t.en('Customer Quote Accepted ' + data.vinnumber)}
                content={
                    <Fields nocard={true}
                        fields={[
                            {size: 4, label: 'Quote Number:', value: data.quotenumber},
                            {size: 4, label: 'System PO Number:', value: data.ponumber},
                            {size: 4, label: 'VIN:', value: data.vinnumber},

                            {size: 12, header: 'Vehicle Details'},
                            {size: 4, label: 'Vehicle Make:', value: data.mmcode.make},
                            {size: 4, label: 'Vehicle Model:', value: data.mmcode.cdescription},

                            {size: 4, label: 'MM Code:', value: data.mmcode.mmcode},
                            {size: 4, label: 'Transmission:', value: data.mmcode.manualauto},

                            {size: 4, label: 'Introduction Year:', value: data.mmcode.regyear},
                            {size: 4, label: 'Body Type:', value: data.mmcode.bodytype},

                            {size: 4, label: 'Contract Type:', value: data.contractname},
                            {size: 4, label: 'Vehicle Category:', value: data.contractvehiclecategory_fktext},

                            {size: 4, label: 'Monthly Distance', value: data.distancepermonth},
                            {size: 4, label: 'Contract Period:', value: data.contractperiod},

                            {size: 4, label: 'Rate Card:', value: data.ratecard},
                            {size: 4, label: 'Delivery Period:', value: data.deliveryperiod},
                            
                            {size: 12, header: 'Rental Breakdown'},
                            {size: 4, label: 'Retail Price:', value: data.retailprice},
                            {size: 4, label: 'Discount:', value: data.discount},

                            {size: 4, label: 'Interest Rate:', value: data.primerate},
                            {size: 4, label: 'Vehicle Price:', value: data.vehicleprice},

                            {size: 12, header: 'Monthly Rental'},
                            {size: 4, label: 'Rental Excl:', value: data.monthly_excl},

                            {size: 4, label: 'Vat:', value: data.monthly_vat},
                            {size: 4, label: 'Rental Incl:', value: data.monthly_incl},
                        ]}
                    />
                }
            />
    }

    getAccessories(accessory) {
        //console.log('getAccessories accessory',accessory )
        return <Card 
                title={window.t.en('Receipt Accessories')}
                subtitle={window.t.en('Customer Quote Accepted')}
                content={
                <Container style={{ margin: 0 }} className="custom-container">
                    {
                        accessory.map((x, i) => {
                            //console.log('getAccessories x',x )
                            return <div>
                                <Row>
                                    <Col xl={6} style={styles.selectedFieldBorderL}>

                                        {'Sent Document Purchase Order: ' + x.ponumber}
                                    </Col>
                                    <Col xl={6}>
                                        {'Upload Invoice for Purchase Order: ' + x.ponumber}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={6} style={styles.selectedFieldBorderL}>
                                       <br></br>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={6} style={styles.selectedFieldBorderL}>
                                        {
                                            x.filename
                                            ? <PdfViewer
                                                key={'uplaod_customer_viewer_'+ x.ponumber}
                                                id={'uplaod_customer_viewer_'+ x.ponumber}
                                                prop={'accessory_po_' + x.ponumber}
                                                documents={[{filename:  x.filename, filetype: x.filename}]}
                                                multiple={true}
                                                inx={i + 1}
                                                height={'500px'}
                                                preview={'preview'}
                                            />
                                            : 'Loading...'
                                        }
                                    </Col>
                                    <Col xl={6}>
                                        <UploadAccessory
                                            id={x.ponumber}
                                            documents={x.documents}
                                            inx={i + 1}
                                            prefix={'Accessory_Inv'}
                                            header={false}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        })
                    }
                </Container>
                }
            />
    }

    getVehicleForm() {
        return <Card 
                title={window.t.en('Vehicle Details')}
                subtitle={window.t.en('Customer Quote Accepted')}
                content={
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={4}>
                                    <TextField
                                        variant="outlined"
                                        id={'txtVIN'}
                                        hintText={'Enter VIN Number'}
                                        label = {'VIN Number:'}
                                        fullWidth={true}
                                        disabled={this.props.state.vinnumber ? false : false}
                                        value={this.props.state.vinnumber}
                                        onChange={(event, value) => {
                                            this.props.actions.setInputValue({ prop: 'vinnumber', value: event.target.value })
                                        }}
                                    />
                            
                        </Col>
                        <Col xl={4}>
                                    <TextField
                                        variant="outlined"
                                        id={'txtEngine'}
                                        label = {'Engine Number:'}
                                        hintText={'Enter Engine Number'}
                                        fullWidth={true}
                                        value={this.props.state.enginenumber}
                                        onChange={(event, value) => {
                                            this.props.actions.setInputValue({ prop: 'enginenumber', value: event.target.value })
                                        }}
                                    />
                        </Col>
                        <Col xl={4}>
                                    <TextField
                                        variant="outlined"
                                        id={'txtRegnumber'}
                                        label ={'Registration Number:'}
                                        hintText={'Enter Registration Number'}
                                        fullWidth={true}
                                        value={this.props.state.registrationnumber}
                                        onChange={(event, value) => {
                                            this.props.actions.setInputValue({ prop: 'registrationnumber', value: event.target.value })
                                        }}
                                    />
                        </Col>
                        <Col xl={4}>
                                    <TextField
                                        variant="outlined"
                                        id={'txtodo'}
                                        label ={'Opening Odometer:'}
                                        hintText={'Enter Opening Odometer'}
                                        fullWidth={true}
                                        value={this.props.state.openingodometer}
                                        onChange={(event, value) => {
                                            this.props.actions.setInputValue({ prop: 'openingodometer', value: event.target.value })
                                        }}
                                    />
                        </Col>
                        <Col xl={4} style={{marginTop:'-15px'}}>
                                    <DateControl
                                        id={'dtRegistrationDate'}
                                        key={'dtRegistrationDate'}
                                        label={'Registration Date:'}
                                        name={'dtRegistrationDate'}
                                        fullWidth={true}
                                        value={this.props.state.registrationdate}
                                        onSelect={(args) => {
                                            this.props.actions.setInputValue({ prop: 'registrationdate', value: args })
                                        }}
                                        type={'date'}
                                    />  
                        </Col>
                        <Col xl={4} style={{marginTop:'-15px'}}>
                                    <DateControl
                                        id={'dtLicenceExpiryDate'}
                                        key={'dtLicenceExpiryDate'}
                                        label={'Licence Expiry Date'}
                                        name={'dtLicenceExpiryDate'}
                                        fullWidth={true}
                                        value={this.props.state.licenceexpirydate}
                                        onSelect={(args) => {
                                            this.props.actions.setInputValue({ prop: 'licenceexpirydate', value: args })
                                        }}
                                        type={'date'}
                                    />

                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12} style={styles.selectedFieldName}>
                            <br />
                        </Col>
                    </Row>
                </Container>
                }
            />
    }

    getView(data) {
        return <div>
                {this.getContract(data.vehicle)}
                {this.getCustomer(data.customer)}
                {this.getSupplier(data.supplier)}
                {this.getVehicle(data.vehicle)}
                {this.getAccessories(data.pos)}
                {this.getVehicleForm()}
        </div>
    }

    render() {
        const workflow_queue_data = this.props.workflow_queue_data
        //console.log('WorkflowPOReceiptAccessories workflow_queue_data', workflow_queue_data)
        let data = null
        try {
            data = this.props.workflow_queue_data.context_data.data.data.data[0]
            //console.log('WorkflowPOReceiptAccessories workflow_queue_data2', workflow_queue_data)
        }
        catch (err) { 
            //console.log('WorkflowPOReceiptAccessories err', err)
        }
        
        return (
            data
                ? this.getView(data)
                : <Loading message={'Retrieving data, please wait...'} />
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.workflowPOReceiptAccessories
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(
            { ...actions, ...workflowTaskInputActions },
            // { ...actions },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowPOReceiptAccessoriesComponent)