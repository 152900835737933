export const SOLVE_FOR_MONTHLY_PAYMENT = 'SOLVE_FOR_MONTHLY_PAYMENT'
export const SOLVE_FOR_PRESENT_VALUE = 'SOLVE_FOR_PRESENT_VALUE'
export const SOLVE_FOR_FUTURE_VALUE = 'SOLVE_FOR_FUTURE_VALUE'
export const SOLVE_FOR_INTEREST_RATE_PER_ANNUM = 'SOLVE_FOR_INTEREST_RATE_PER_ANNUM'
export const SET_FIELD_VALUE = 'SET_FIELD_VALUE'
export const RESET_FIELD_VALUE = 'RESET_FIELD_VALUE'
export const SET_ERROR = 'SET_ERROR'
export const SET_VALID = 'SET_VALID'
export const CHANGE_SELECT = 'CHANGE_SELECT'
export const CALCULATE = 'CALCULATE'
export const RESET_FINANCIAL_CALCULATOR = 'RESET_FINANCIAL_CALCULATOR'
export const CREATE_CALCULATOR_STATE = 'CREATE_CALCULATOR_STATE'
export const REMOVE_CALCULATOR_STATE = 'REMOVE_CALCULATOR_STATE'
export const GET_VEHICLES = 'GET_VEHICLES'
export const SET_VEHICLES = 'SET_VEHICLES'