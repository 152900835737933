import * as actions from './actions'
import * as cognitoActions from '../Cognito/actions'
import * as connectorActions from '../Connector/actions'
import * as routerActions from '../Redirect/actions'
import * as messengerActions from '../Messenger/actions'
import * as types from './types'

export const configureAppEpic = (action$, store, { concat$, of$, startWith,  map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.CONFIGURE_APP),
        mergeMap((action) => [
            actions.getUserAgent(),
            actions.getUserLocale(),
            messengerActions.connectToMessenger(),
            connectorActions.getConnector()
        ])
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
)

