export const QUOTE_EDIT_SET_FIELD_VALUE = 'QUOTE_EDIT_SET_FIELD_VALUE'
export const LOAD_QUOTE_EDIT_GRID_DATA_ROWS = 'LOAD_QUOTE_EDIT_GRID_DATA_ROWS'
export const RESET_QUOTE_EDIT_GRID_DATA_ROWS = 'RESET_QUOTE_EDIT_GRID_DATA_ROWS'
export const QUOTE_EDIT_SET_WORKFLOW = 'QUOTE_EDIT_SET_WORKFLOW'
export const QUOTE_EDIT_SET_COMPONENT_FIELD_INPUT_VALUE = 'QUOTE_EDIT_SET_COMPONENT_FIELD_INPUT_VALUE'
export const QUOTE_EDIT_UPDATE_ACCESSORY_INPUT_FIELD_VALUE = 'QUOTE_EDIT_UPDATE_ACCESSORY_INPUT_FIELD_VALUE'
export const QUOTE_EDIT_SERVICE_SET_FIELD_VALUE = 'QUOTE_EDIT_SERVICE_SET_FIELD_VALUE'
export const QUOTE_EDIT_SUNDRY_SET_FIELD_VALUE = 'QUOTE_EDIT_SUNDRY_SET_FIELD_VALUE'
export const SET_TASK = 'SET_TASK'
export const QUOTE_EDIT_DELETE_ACCESSORY_INPUT_FIELD_VALUE = 'QUOTE_EDIT_DELETE_ACCESSORY_INPUT_FIELD_VALUE'
export const QUOTE_EDIT_ADD_ACCESSORY_INPUT_FIELD_VALUE = 'QUOTE_EDIT_ADD_ACCESSORY_INPUT_FIELD_VALUE'
export const GET_ACCESSORY_LIST_LOOKUP_DATA = 'GET_ACCESSORY_LIST_LOOKUP_DATA'
export const SET_ACCESSORY_LIST_LOOKUP_DATA = 'SET_ACCESSORY_LIST_LOOKUP_DATA'
export const GET_QUOTEEDIT_ACCESSORY_LIST_LOOKUP_DATA = 'GET_QUOTEEDIT_ACCESSORY_LIST_LOOKUP_DATA'
export const SET_QUOTEEDIT_ACCESSORY_LIST_LOOKUP_DATA = 'SET_QUOTEEDIT_ACCESSORY_LIST_LOOKUP_DATA'
export const QUOTE_ADD_NEW_ACCESSORY_ITEM_TO_LIST = 'QUOTE_ADD_NEW_ACCESSORY_ITEM_TO_LIST'
