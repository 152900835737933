import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.RESET_COMPONENT:
            return {
                ...state,
                tab: 0,
                amount: '',
                labourrate: '',
                discount: '',
                supplierdocument: '',
                selectedSupplierId: '',
                selectedSupplierName: '',
                maintpdf_preview: '',
                geoVehiclePosition: [],
                servicebasket: [],
                suppliers: [],
                supplier_data: [],
                quotations: [],
                selectedsupplier: {},
                selected_supplier: {},
                customercontractderivative: null,
                limitwin: null,
                filename: {
                    errorText: 'Filename is required',
                    value: '',
                    valid: false,
                    empty: true,
                },
                invoicepreview: {
                    errorText: 'Upload supplier invoice',
                    value: '',
                    valid: false,
                    empty: true,
                },
                quotenumber: {
                    errorText: 'Quote number is required',
                    value: '',
                    valid: false,
                    empty: true,
                },
                quotedate: {
                    errorText: 'Quote number is required',
                    value: '',
                    valid: false,
                    empty: true,
                },
                fields: props.fields
            }
        case types.SET_COMPONENT_PO_QUOTE_FIELD_INPUT_VALUE:
            return {
                ...state,
                [action.payload.prop]: action.payload.value
            }
        case types.SET_BOOKING__INVOICE_COMPONENT_FIELD_INPUT_VALUE:
            return {
                ...state,
                [action.payload.prop]: {
                    ...state[action.payload.prop],
                    value: action.payload.value,
                    valid: action.payload.value ? true : false
                }
            }
        case types.SET_DEALER_BOOKING_INVOCE_DOCUMENT_PREVIEW:
            return {
                ...state,
                filename: action.payload.filename,
                invoicepreview: action.payload.preview,
            }
        case types.CLEAR_DEALER_BOOKING_INVOCE_DOCUMENT_PREVIEW:
            return {
                ...state,
                filename: action.payload,
                invoicepreview: action.payload,
            }
        case types.SET_DISCOUNT_LETTER_DOCUMENT_PREVIEW:
            return {
                ...state,
                supplierdocument: action.payload.preview
            }
        case types.SET_BOOKING_VEHICLE_CONTRACT_DEREVATIVE:
            return {
                ...state,
                customercontractderivative: action.payload
            }
        case types.SET_BOOKING_VEHICLE_SERVICE_PART_BASKET:
            return {
                ...state,
                servicebasket: action.payload
            }
        case types.SET_VEHICLE_LASTPOSITION:
            return {
                ...state,
                geoVehiclePosition: action.payload
            }
        case types.SET_COMPONENT_QUOTATION_SUPPLIERS_LIST:
            return {
                ...state,
                suppliers: action.payload,
                supplier_data: action.payload
            }
        case types.SET_COMPONENT_QUOTATION_SELECTED_SUPPLIER:
            return {
                ...state,
                selected_supplier: action.payload
            }
        case types.SET_COMPONENT_QUOTATION_SELECTED_SUPPLIER_NAME:
            return {
                ...state,
                selectedsupplier: action.payload
            }
        case types.TOGGLE_SUPPLIER_LIMIT_EXCEEDED_WIN:
            return {
                ...state,
                limitwin: action.payload
            }
        case types.SET_BOOKING__INVOICE_COMPONENT_FIELD_INPUT_VALIDATE:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    [action.payload.prop]: {
                        ...state[action.payload.prop],
                        value: action.payload.value,
                        valid: action.payload.valid
                    }
                }
            }
        default:
            return state
    }
}