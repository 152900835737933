export const po_filepreview = ''
export const po_filename = ''
export const sign_filepreview = ''
export const sign_filename = ''
export const comments = ''
export const bookingdate = null
export const documents = []

export const po_date = ''
export const po_number = ''
export const po_amount = 0.00
export const customer_po = true
export const rebill_dispute = false
export const rebill_dispute_comments = ''
export const rebill_dispute_date = null
export const dispute_resolved = null
export const dispute_confirmation = null