import * as actions from './actions'
import * as progressBarActions from '../ProgressBar/actions'
import * as requestActions from '../Request/actions'
import * as routerActions from '../Redirect/actions'
import * as types from './types'
import * as requestTypes from '../Request/types'
import * as config from '../../config'

/* export const getPurchaseorderinvoiceAutoCompleteEpic = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
        types.GET_PURCHASE_ORDER_INVOICE_ACTION,
    )
        ,mergeMap(({payload}) =>
            ajax$({
                url: config.system.api + '/components/purchaseorder/searchor?ps=1&supplier_id=' + payload.id +'&ponumber=' + payload.search +'&vehicle_fktext=' + payload.search,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
'salt': store.value.cognito.authenticateUser.jwt,
'datetime': store.value.cognito.authenticateUser.datetime,
                    'related': 'all',
                    'take': '200',
                    'order': '-ponumber'
                }
            })
                .pipe(
            mergeMap((result) => [
                    actions.setPurchaseorderinvoiceAutoCompleteSelected(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/purchaseorder/search?ps=1&ponumber=' + payload)
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/purchaseorder/search?ps=1&ponumber=' + payload, error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        ) */

        export const getPurchaseorderinvoiceAutoCompleteEpic = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
        types.GET_PURCHASE_ORDER_INVOICE_ACTION,
    )
        ,mergeMap(({payload}) =>
            ajax$({
                url: config.system.api + '/components/purchaseorder/searchor?ps=1&supplier_id=' + payload.id +'&ponumber=' + payload.search +'&vehicle_fktext=' + payload.search,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
'salt': store.value.cognito.authenticateUser.jwt,
'datetime': store.value.cognito.authenticateUser.datetime,
                    'related': 'all',
                    'take': '200',
                    'order': '-ponumber'
                }
            })
                .pipe(
            mergeMap((result) => [
                    actions.setPurchaseorderinvoiceAutoCompleteSelected(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/purchaseorder/search?ps=1&ponumber=' + payload)
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/purchaseorder/search?ps=1&ponumber=' + payload, error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )

        export const getSupplierAutoCompleteEpic = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(

            types.GET_SUPPLIER_INVOICE_ACTION,
        )
            ,mergeMap(({payload}) =>
                ajax$({
                    url: config.system.api + '/components/supplier/searchor?suppliername=' + payload + '&suppliertype_fktext=' + payload + '&accountnumber=' + payload,
                    method: 'GET',
                    headers: {
                        'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                        'salt': store.value.cognito.authenticateUser.jwt,
                        'datetime': store.value.cognito.authenticateUser.datetime,
                        'order': 'suppliername'
                    }
                }).pipe(
                    mergeMap((result) => [
                        actions.setSupplierAutoComplete(result.response),
                        requestActions.requestSucceeded(config.system.api + '/components/supplier/search?suppliername=' + payload)
                    ])
                    ,startWith(progressBarActions.progress(true))
                    ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/supplier/search?suppliername=' + payload, error)))
                    //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
            )
            )
            ,catchError((error) =>
                of$(routerActions.route('/support', false, error))
            )
            )


    

    export const getContraaccountinvoiceAutoCompleteEpic = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
            types.GET_CONTRA_ACCOUNT_INVOICE_ACTION,
        )
            ,mergeMap(({payload}) =>
                ajax$({
                    url: config.system.api + '/components/accountingitem/search?itemdescription=' + payload,
                    method: 'GET',
                    headers: {
                        'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                        'salt': store.value.cognito.authenticateUser.jwt,
                        'datetime': store.value.cognito.authenticateUser.datetime,
                        'take': 1000
                    }
                })
                    .pipe(
                    mergeMap((result) => [
                        actions.setContraaccountinvoiceAutoCompleteSelected(result.response),
                        requestActions.requestSucceeded(config.system.api +  '/components/accountingitem/search?itemdescription=' + payload)
                    ])
                    ,startWith(progressBarActions.progress(true))
                    ,catchError((error) => of$(requestActions.requestFailed(config.system.api +  '/components/accountingitem/search' + payload, error)))
                    //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
            )
            )
            ,catchError((error) =>
                of$(routerActions.route('/support', false, error))
            )
            )