import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.SET_COMPONENT_REVIEW_QUOTATIONS_DATA:
            return {
                ...state,
                quotations: action.payload,
            }
        case types.SET_COMPONENT_REVIEW_FIELD_INPUT_DATA_VALUE:
            return {
                ...state,
                quotations: state.quotations.map((x,i) =>
                    (i === action.payload.inx)
                        ? {
                            ...x,
                            [action.payload.prop]: action.payload.value
                        }
                        : x
                )
            }
        case types.SET_COMPONENT_REVIEW_ITEMS_FIELD_INPUT_DATA_VALUE:
            return {
                ...state,
                quotations: state.quotations.map((x,i) =>
                    (i === action.payload.inx)
                        ? {
                            ...x,
                            [action.payload.prop]: action.payload.value
                        }
                        : x
                )
            }
        default:
            return state
    }
}