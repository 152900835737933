export const GET_REVERSE_PAY_CREDITORS_LIST = 'GET_REVERSE_PAY_CREDITORS_LIST'
export const SET_REVERSE_PAY_CREDITORS_LIST = 'SET_REVERSE_PAY_CREDITORS_LIST'

export const SET_REVERSE_PAY_CREDITORS_LOOKUP = 'SET_REVERSE_PAY_CREDITORS_LOOKUP'
export const SET_REVERSE_PAY_FIELD_INPUT_VALUE = 'SET_REVERSE_PAY_FIELD_INPUT_VALUE'

export const GET_REVERSE_PAY_BUSINESS_LIST = 'GET_REVERSE_PAY_BUSINESS_LIST'
export const SET_REVERSE_PAY_BUSINESS_LIST = 'SET_REVERSE_PAY_BUSINESS_LIST'

export const GET_REVERSE_PAY_ACCOUNT_LIST = 'GET_REVERSE_PAY_ACCOUNT_LIST'
export const SET_REVERSE_PAY_ACCOUNT_LIST = 'SET_REVERSE_PAY_ACCOUNT_LIST'

export const GET_REVERSE_PAY_DISCOUNT_LIST = 'GET_REVERSE_PAY_DISCOUNT_LIST'
export const SET_REVERSE_PAY_DISCOUNT_LIST = 'SET_REVERSE_PAY_DISCOUNT_LIST'

export const GET_REVERSE_PAY_ACCOUNT = 'GET_REVERSE_PAY_ACCOUNT'
export const SET_REVERSE_PAY_ACCOUNT = 'SET_REVERSE_PAY_ACCOUNT'
export const SAVE_REVERSE_PAY_DATA = 'SAVE_REVERSE_PAY_DATA'
export const SAVED_PAYMENT_REVERSAL_DATA = 'SAVED_PAYMENT_REVERSAL_DATA'

export const SET_REVERSE_PAYMENT_LIST = 'SET_REVERSE_PAYMENT_LIST'
export const GET_REVERSE_PAYMENT_LIST = 'GET_REVERSE_PAYMENT_LIST'

export const GET_REVERSE_PAYMENT_DUPLICATE_REFERENCE_CHECK = 'GET_REVERSE_PAYMENT_DUPLICATE_REFERENCE_CHECK'
export const REVERSE_PAYMENT_DUPLICATE_FOUND = 'REVERSE_PAYMENT_DUPLICATE_FOUND'
export const REVERSE_PAYMENT_NO_DUPLICATE_FOUND = 'REVERSE_PAYMENT_NO_DUPLICATE_FOUND'