export const courier = false
export const khula = false

export const isreturn = false
export const courierprice = 0
export const khulaprice = 0
export const drivername = ''
export const deliverydate = ''
export const documentpreview = ''
export const documentfilename = ''
export const vinnumber = ''
export const enginenumber = ''
export const registrationnumber = ''
export const openingodometer = ''
export const registrationdate = ''
export const licenceexpirydate = ''

export const fuelsupplier = ''
export const fuelsupplier_id = 0
export const couriersupplier = ''
export const couriersupplier_id = 0

export const supplier_data = []

export const validation = {
  fields: [],
  component: 'workflowPODelivery',
  list: [],
  valid: false
}

export const fields = {
  courier_document_description: {
    errorText: 'Please select document description',
    data: '',
    valid: false,
    empty: true,
    popup: true,
    state: "getState().workflowPODelivery.khula ? getState().upload.documents[0]?.description : true",
  },
  courier_document: {
    errorText: 'Please upload document',
    data: '',
    valid: false,
    empty: true,
    popup: true,
    state: "getState().workflowPODelivery.khula ? getState().upload.documents.length : true",
  },
  licenseExpiryDate: {
    id: 'ddl_courier_input',
    name: 'ddl_courier_input',
    errorText: 'Please select Courier Supplier',
    state: 'getState().workflowPODelivery.khula ? getState().workflowPODelivery.couriersupplier : true',
    data: false,
    valid: false,
    empty: true,
    section: 'Courier Delivery Service'
  },
  registrationDate: {
    id: 'txt_poddate',
    name: 'txt_poddate',
    errorText: 'Please enter Expected Delivery Date',
    state: 'getState().workflowPODelivery.khula ? getState().workflowPODelivery.deliverydate : true',
    data: false,
    valid: false,
    empty: true,
    section: 'Courier Delivery Service'
  },
  courierPrice: {
    id: 'txt_cuorier_price',
    name: 'txtotxt_cuorier_pricedo',
    errorText: 'Please enter Invoice Amount',
    state: 'getState().workflowPODelivery.khula ? getState().workflowPODelivery.courierprice : true',
    data: false,
    valid: false,
    empty: true,
    section: 'Courier Delivery Service',
    validations: [
      {
        'schema': 'integer',
        'required': true,
        'maxLength': null,
        'errorText': 'Please input a valid Invoice Amount'
      }
    ]
  }
}