import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'
import { Container, Row, Col } from 'react-grid-system'
// import TextField from '@mui/material/TextField'
import * as workflowTaskInputAction from '../../WorkflowTaskInput/actions'
import * as styles from './styles'
import * as actions from './actions'
import ViewVehicle from '../../../controls/view_vehicle'
import AutoComplete from '../../../controls/autocomplete'
import Checkbox from '../../../controls/checkbox'
import Card from '../../../controls/card'
import Loading from '../../Loading/component'
import PdfViewer from '../../PDF/component'
import SupplierSpend from '../../../controls/view_supplier_spend'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import ViewSupplier from '../../../controls/view_supplier'
import TextField from '../../../controls/textField'

class DefleetCaptureQuote extends Component {
    componentDidMount() {
        this.props.actions.resetComponent()
        this.props.actions.resetPOComponent()
        this.props.actions.setValidationState('defleetCaptureQuote')
        this.props.actions.selectPurchaseOrderType(4)
    }
    checksheetComments(data) {
        return <Card
            title={window.t.en('Check Sheet Comments')}
            description={window.t.en('Comments captured on upload Check Sheet')}
            content={
                <Row>
                    <Col xl={12}>
                        <TextField
                            id={'txtcommentchecksheet'}
                            disabled={true}
                            label={'Comments captured on upload Check Sheet'}
                            fullWidth={true}
                            multiLine={false}
                            variant="outlined"
                            value={data.comments.value ? data.comments.value: ''}
                            onChange={(event, value) => {
                            }}
                        />
                    </Col>
                </Row>
            }
        />
    }
    commentsView() {
        return <Card
            title={window.t.en('Comments')}
            description={window.t.en('Capture Comments')}
            content={<Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={12}>
                        <TextField
                            id={'txtcomment'}
                            fullWidth={true}
                            multiLine={true}
                            label={'Capture Comments'}
                            variant="outlined"
                            value={this.props.state.comments.value}
                            onChange={(event, value) => {
                                this.props.actions.setInputValue('comments', event.target.value)
                            }}
                        />
                    </Col>
                </Row>
            </Container>}
        />
    }
    getDaysPast(d_date) {
        var oneDay = 1000 * 60 * 60 * 24;
        var difference_ms = Math.abs((new Date()).getTime() - (new Date(d_date)).getTime())
        var diffValue = Math.round(difference_ms / oneDay);
        return diffValue
    }
    checksheetDetails(steps) {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={3} style={styles.fieldLabel}>{'De-fleet Date:'}</Col>
                <Col xl={3} style={styles.selectedFieldName}>{moment(steps.step2.data.defleetdate ? steps.step2.data.defleetdate : steps.step2.datestamp).format("YYYY-MM-DD")}</Col>
                <Col xl={3} style={styles.fieldLabel}>{'Pre Auth Number:'}</Col>
                <Col xl={3} style={styles.selectedFieldName}>{steps.step1.data.defleetNumber}</Col>
            </Row>
            <Row>
                <Col xl={3} style={styles.fieldLabel}>{'Check Sheet Completed Date:'}</Col>
                <Col xl={3} style={styles.selectedFieldName}>{moment(steps.step2.data.completeddate).format("YYYY-MM-DD")}</Col>
                <Col xl={3} style={styles.fieldLabel}>{'Check Sheet Completed By:'}</Col>
                <Col xl={3} style={styles.selectedFieldName}>{steps.step2.data.completedby}</Col>
            </Row>
            <Row>
                <Col xl={3} style={styles.fieldLabel}>{'Check Sheet Uploaded Date:'}</Col>
                <Col xl={3} style={styles.selectedFieldName}>{moment(steps.step2.datestamp).format("YYYY-MM-DD HH:mm")}</Col>
                <Col xl={3} style={styles.fieldLabel}>{'Check Sheet Completed At:'}</Col>
                <Col xl={3} style={styles.selectedFieldName}>{steps.step2.data.location}</Col>
            </Row>
            <Row>
                <Col xl={3}><br></br></Col> </Row>
            <Row>
                <Col xl={12}>
                    <TextField
                        id={'txtcomment'}
                        label={'No Comments...'}
                        disabled={true}
                        fullWidth={true}
                        variant="outlined"
                        multiLine={false}
                        value={steps.step2.data.comments}
                        onChange={(event, value) => {
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    {
                        steps.documents.filter(x => x.filetype.indexOf('Dispute') >= 0).length > 0
                            ? <div style={styles.redbox}>
                                <h2>{'Important Notice'}</h2>
                                <div style={styles.redboxtext}>
                                    {'There was a dispute for this rebill. '}
                                </div>
                            </div>
                            : ''
                    }
                </Col>
            </Row>
        </Container>
    }
    checksheetUploadedDocs(steps) {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>
                    {
                        steps.documents
                            ? <PdfViewer
                                key={'document_viewer_approval'}
                                name={'booking'}
                                workflow={'fml_customer_quote'}
                                task={'tsk_defleet_review_and_approve_tech'}
                                documents={steps.documents}
                                edit={false}
                                workflow_queue_data={this.props.workflow_queue_data}
                            />
                            : <Loading message={'Retrieving Documents...'} />
                    }
                </Col>
            </Row>
        </Container>
    }
    capturedValues(steps) {
        return <div> <Card
            title={window.t.en('Check Sheet Details')}
            description={window.t.en('Check Sheet summary')}
            content={this.checksheetDetails(steps)}
        />
            <Card
                title={window.t.en('Uploaded Documents')}
                description={window.t.en('Uploaded Documents')}
                content={this.checksheetUploadedDocs(steps)}
            />
        </div>
    }
    renderView(workflow_queue_data) {
        let step1 = workflow_queue_data.step1.data
        let step2 = workflow_queue_data.step2.data
        let vehicle = step1.selectedVehicleDetails.data[0]
        return <div>
            {
                step1.defleetNumber
                    ? <Container style={{ margin: 0 }} className="custom-container">
                        {
                            vehicle && <ViewVehicle
                                key={vehicle.vehicle_id}
                                id={vehicle.vehicle_id}
                            />
                        }
                        {
                            this.props.state.fields.selectedSupplier.data && <ViewSupplier
                                key={this.props.state.fields.selectedSupplier.data.value}
                                id={this.props.state.fields.selectedSupplier.data.value}
                            />
                        }

                        <Dialog
                            open={this.props.state.suspended_win}
                            onClose={(event) => {
                                event.preventDefault()
                                this.props.actions.toggleSupplierSuspended(false)
                            }}
                        //classes={{ paper: 'shadow-lg rounded' }}
                        >
                            <div className="text-center p-5">
                                <h4 className="font-weight-bold mt-4">
                                    SUPPLIER SUSPENDED
              </h4>
                                <p className="mb-0 font-size-lg text-muted">
                                    {this.props.state.selectedSupplierSuspended ? this.props.state.selectedSupplierSuspended.suspendedreason : 'Supplier is suspended please select another supplier.'}
                                </p>
                                <div className="pt-4">
                                    <Button
                                        onClick={(event) => {
                                            event.preventDefault()
                                            this.props.actions.toggleSupplierSuspended(false)
                                        }}
                                        className=" btn-pill mx-1">
                                        <span className="btn-wrapper--label">Ok</span>
                                    </Button>
                                </div>
                            </div>
                        </Dialog>


                        <Dialog
                            open={this.props.state.upcoming.upcoming}
                            onClose={(event) => {
                                event.preventDefault()
                                this.props.actions.toggleUpcomingSupplierSuspended(false)
                            }}
                        //classes={{ paper: 'shadow-lg rounded' }}
                        >
                            <div className="text-center p-5">
                                <h4 className="font-weight-bold mt-4">
                                    UPCOMMING SUPPLIER SUSPENSION              </h4>
                                <p className="mb-0 font-size-lg text-muted">
                                    {this.props.state.upcoming.bee ? 'Supplier BEE certitifcate will expire with in 30 days.' : ''}
                                </p>
                                <p className="mb-0 font-size-lg text-muted">
                                    {this.props.state.upcoming.tax ? 'Supplier Tax certitifcate will expire with in 30 days.' : ''}
                                </p>
                                <p className="mb-0 font-size-lg text-muted">
                                    {this.props.state.upcoming.sla ? 'Supplier SLA agreement will expire with in 30 days.' : ''}
                                </p>
                                <div className="pt-4">
                                    <Button
                                        onClick={(event) => {
                                            event.preventDefault()
                                            this.props.actions.toggleUpcomingSupplierSuspended(false)
                                        }}
                                        className="btn-pill mx-1">
                                        <span className="btn-wrapper--label">Ok</span>
                                    </Button>
                                </div>
                            </div>
                        </Dialog>
                        {this.capturedValues(workflow_queue_data)}
                        {
                            step2.comments ?
                                    this.checksheetComments(step2)
                                : ''}
                     <Card
                     content={
                        <Row>
                            <Col xl={4}>
                                        <TextField
                                            id={'txtreg'}
                                            label={'Registration Number'}
                                            hintText={'Registration Number'}
                                            fullWidth={true}
                                            multiLine={false}
                                            disabled={true}
                                            variant="outlined"
                                            value={step1.registration}
                                        />
                            </Col>
                            <Col xl={4}>
                                        <TextField
                                            id={'txtmillage'}
                                            hintText={'Enter vehicle current odometer'}
                                            label={'Vehicle Odometer'}
                                            fullWidth={true}
                                            multiLine={false}
                                            disabled={true}
                                            variant="outlined"
                                            value={this.props.state.millage.value ? this.props.state.millage.value : step2.odoreading}
                                        />
                            </Col>
                            <Col xl={4}>
                                        <TextField
                                            id={'txtdays'}
                                            label={'Repair Days'}
                                            fullWidth={true}
                                            multiLine={false}
                                            variant="outlined"
                                            value={this.props.state.days.value}
                                            onChange={(event) => {
                                                this.props.actions.setInputValue('days', event.target.value)
                                            }}
                                        />
                            </Col>
                            <Col xl={4}>
                                        <Checkbox
                                            id={'txt_drivable'}
                                            key={'txt_drivable'}
                                            name={'txt_drivable'}
                                            label={'Non-Drivable'}
                                            field={'drivable'}
                                            checked={this.props.state.drivable.value}
                                            labelPlacement={'end'}
                                            // setComponentFieldInput={
                                            //     this.props.actions.setInputValue
                                            // }
                                            onCheck={(checked) => {
                                                this.props.actions.setInputValue('drivable', checked)
                                            }}
                                        />
                            </Col>
                            <Col xl={4}>
                                        <Checkbox
                                            id={'txt_mechanicalcheck'}
                                            key={'txt_mechanicalcheck'}
                                            name={'txt_mechanicalcheck'}
                                            field={'mechanicalcheck'}
                                            checked={this.props.state.mechanicalcheck.value}
                                            labelPlacement={'end'}
                                            label={window.t.en('Mechanical Check')}
                                            /* setComponentFieldInput={
                                                this.props.actions.setInputValue
                                            } */
                                            onCheck={(checked) => {
                                                this.props.actions.setInputValue('mechanicalcheck', checked)
                                            }}
                                        />
                            </Col>
                        </Row>
                     }
                     />
                        <Row>
                            <Col xl={12}> {this.commentsView()}</Col>
                        </Row>
                    </Container>
                    : <strong style={styles.box1}>{window.t.en('Kindly check if your not logged in twice, if the error persist please contact system administrator.')}</strong>
            }
        </div>

    }
    render() {
        let workflow_queue_data = null
        try {
            workflow_queue_data = this.props.workflow_queue_data.data[0].context_data.data.data.data[0]

        } catch (error) {

        }
        return (workflow_queue_data ? this.renderView(workflow_queue_data) : window.t.en('Please try again, contact system administrator if this persist'))
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.defleetCaptureQuote,
            task: {
                ...state.workflowTaskInput
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...workflowTaskInputAction }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DefleetCaptureQuote)
