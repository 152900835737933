import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Drawer, Tooltip, Button } from '@mui/material'

class _drawer extends Component {
    render() {
        return <div>
            <Drawer 
                anchor={this.props.anchor ? this.props.anchor : 'right'}
                open={this.props.open}
                width={this.props.width ? this.props.width : '800px'}
                onClose={(event, value) => {
                    event.preventDefault();
                    this.props.onClose()
                }}
                PaperProps={{
                    sx: { width: this.props.width ? this.props.width : '800px' },
                  }}
            >
            <div className="app-drawer-content-off" style={{width: '98%'}}>
                <Tooltip arrow title="Close drawer" placement="left">
                    <Button
                    size="small"
                    onClick={(event, value) => {
                        event.preventDefault();
                        this.props.onClose()
                    }}
                    className="close-drawer-btn bg-white p-0 d-40"
                    id="CloseDrawerTooltip">
                    <div>
                        <span className="hamburger-box">
                        <span className="hamburger-inner" />
                        </span>
                    </div>
                    </Button>
                </Tooltip>
                <div className="vh-10 shadow-overflow">
                    <div className="p-4">
                        <div className="text-center">
                        <div className="font-weight-bold font-size-lg mb-0 text-black">
                            {this.props.title}
                        </div>
                        <p className="text-black-50">
                            {this.props.subtitle}
                        </p>
                        </div>
                    </div>
                    <div className="divider" />
                    <div className="p-4">
                        <div className="rounded p-3 bg-secondary">
                            {this.props.content}
                        </div>
                    </div>
                </div>
                <div className="vh-100">
                    <div className="divider" />
                    <div className="p-4">
                        <div className="rounded p-3 bg-secondary">
                            {this.props.buttons}
                        </div>
                    </div>
                </div>
            </div>
        </Drawer>
      </div> 
    }
}

const mapStateToProps = (state) => {
    return {
        state: { ...state.empty }
    }
}

export default connect(mapStateToProps)(_drawer)