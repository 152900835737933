//import * as colours from '@mui/material/colors'
import Theme from '../../theme'

export const addComponentRow = {
    textAlign: 'right'
}

export const deleteIcon = {
    fontSize: '34px'
}

export const deleteIconHoverColor = {
    color: Theme.palette.primary1Color
}