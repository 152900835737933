import * as types from './types'
import moment from 'moment'
import get from 'lodash/get'
import _ from 'lodash'
//import validator from '../../validate'

export const setupWorkflowTaskInput = (data, meta, component, step) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.CONFIRM_MM_CONTRACT_HEADER_SELECTION,
            payload: {
                step: step,
                component: component,
                selected: data,
                meta: meta
            }
        })
    }
}
