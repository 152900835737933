import * as actions from './actions'
import * as types from './types'

import * as config from '../../../config'
import * as progressBarActions from '../../ProgressBar/actions'
import * as requestActions from '../../Request/actions'
import * as routerActions from '../../Redirect/actions'
import * as requestTypes from '../../Request/types'

export const getAssignedTasksEpic = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
        types.GET_ASSIGNED_TASKS
    )
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/workflow_queue_task/other',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'client-id': store.value.accounts.selectedAccount,
                    'business-ids': store.value.businessCustomers.allBusinesses
                },
                body: payload.body
            })
                .pipe(
            mergeMap((result) => [
                    actions.setAssignedTasks(payload.body.team_id, payload.body.search, result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/team/query')
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/team/query', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )