import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.LOAD_DOCUMENT_STATE:
            return {
                ...state,
                [action.payload]: {
                    data: [],
                    accepted: [],
                    rejected: [],
                    error: false,
                    completed: false,
                    documentTypes: [],
                    documentTypesLoading: true
                }
            }
        case types.DOCUMENT_GET_DOCUMENT_TYPES:
            return {
                ...state,
                [action.payload.name]: {
                    ...state[action.payload.name],
                    documentTypesLoading: true
                }
            }
        case types.DOCUMENT_SET_DOCUMENT_TYPES:
            return {
                ...state,
                [action.payload.name]: {
                    ...state[action.payload.name],
                    documentTypes: action.payload.data,
                    documentTypesLoading: false
                }
            }
        case types.SET_ACCEPTED_DOCUMENTS:
            //console.log('filesx red1', state[action.payload.name].accepted)
            //console.log('filesx red2', action.payload.accepted)
            //console.log('filesx red3', state[action.payload.name].accepted.concat(action.payload.accepted))
            return {
                ...state,
                [action.payload.name]: {
                    ...state[action.payload.name],
                    accepted: action.payload.accepted
                }
            }
        case types.DOCUMENT_CHANGE_SELECTED_DOCUMENT_TYPE:
            return {
                ...state,
                [action.payload.name]: {
                    ...state[action.payload.name],
                    accepted: state[action.payload.name].accepted.map((x, i) => {
                        return action.payload.index === i
                            ? {
                                ...x,
                                selected: action.payload.value
                            }
                            : x
                    })
                }
            }
        case types.DOCUMENT_UPLOAD_COMPONENT_DOCUMENTX:
            //console.log('filesx DOCUMENT_UPLOAD_COMPONENT_DOCUMENTX red',data)
            return {
                ...state,
                [action.payload.name]: {
                    ...state[action.payload.name],
                    accepted: state[action.payload.name].accepted.map((x, i) => {
                        return action.payload.index === i
                            ? {
                                ...x,
                                uploading: true
                            }
                            : x
                    })
                }
            }
        case types.DOCUMENT_UPLOAD_COMPONENT_DOCUMENT_FAILED:
            return {
                ...state,
                [action.payload.data.name]: {
                    ...state[action.payload.data.name],
                    accepted: state[action.payload.data.name].accepted.map((x, i) => {
                        return action.payload.data.index === i
                            ? {
                                ...x,
                                error: true,
                                reason: action.payload.data.reason
                            }
                            : x
                    })
                }
            }
        case types.DOCUMENT_UPLOAD_COMPONENT_DOCUMENT_SUCCESSFUL:
            return {
                ...state,
                [action.payload.data.name]: {
                    ...state[action.payload.data.name],
                    accepted: state[action.payload.data.name].accepted.map((x, i) => {
                        return action.payload.data.index === i
                            ? {
                                ...x,
                                uploading: false,
                                error: false,
                                key: action.payload.data.key,
                                document_id: action.payload.data.document_id
                            }
                            : x
                    })
                }
            }
        case types.DOCUMENT_UPLOAD_COMPONENT_DOCUMENT_FAILED:
            return {
                ...state,
                [action.payload.name]: {
                    ...state[action.payload.name],
                    error: true,
                    completed: false
                }
            }
        case types.DOCUMENT_LINK_UPLOADED_DOCUMENTS_SUCCESSFUL:
            
        //console.log('linkUploadedDocumentsx DOCUMENT_LINK_UPLOADED_DOCUMENTS_SUCCESSFUL' ,action.payload.data)
            return {
                ...state,
                [action.payload.data.name]: {
                    ...state[action.payload.data.name],
                    accepted: [],
                    rejected: [],
                    error: false,
                    completed: true
                }
            }
        case types.SET_REJECTED_DOCUMENTS:
            return {
                ...state,
                [action.payload.name]: {
                    ...state[action.payload.name],
                    rejected: state[action.payload.name].rejected.concat(action.payload.rejected)
                }
            }
        case types.VIEW_DOCUMENT:
            return {
                ...state,
                downloaded: action.payload
            }
        case types.SET_DOCUMENTS_CURRENT_TAB:
            return {
                ...state,
                tab: action.payload
            }
        default:
            return state
    }
}