import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'

import moment from 'moment'
import { Container, Row, Col } from 'react-grid-system'

import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import Icon from '../../Icon/component'
import QuoteItems from '../QuoteItems/component'

import Iframe from 'react-iframe'
import Dropzone from 'react-dropzone'
import { Table } from "@mui/material";
import AutoComplete from '../../../controls/autocomplete'
import Loading from '../../Loading/component'
import PdfViewer from '../../PDF/component'
import MinMap from '../../MinMap/component'

import Upload from '../../Upload/component'
import AdhocComponentFieldInput from '../../Custom/AdhocComponentFieldInput/component'
import ImageUploader from '../../ImageUploader/component'
import * as workflowTaskInputAction from '../../WorkflowTaskInput/actions'
import * as poBookingItemsAction from '../POBookingItems/actions'

import VehicleServicePlan from '../../../controls/view_mapo_serviceplan'
import SupplierSpend from '../../../controls/view_supplier_spend'
import ViewSupplier from '../../../controls/view_supplier'
import ViewMaintenance from '../../../controls/view_maintenance'
import DateControl from '../../../controls/dateControl'
import ViewVehicle from '../../../controls/view_vehicle'
import VehiclePlan from '../../../controls/view_vehicle plan'
import Card from '../../../controls/card'
import VehicleTabs from '../../../controls/vehicle_tabs'

import * as styles from './styles'
import * as actions from './actions'
import * as lang from './language'

class POBookingInvoice extends Component {

    componentDidMount() {
        console.log('componentDidMountx 1')
        this.props.actions.resetPOComponent()
        this.props.actions.setValidationState('poBookingInvoice')
    }

    // componentDidUpdate() {
    //     {
    //         if(this.props.state.supplier_monthly_spend[0])
    //             if((parseFloat(this.props.state.supplier_monthly_spend[0].curr_usage) + _.sumBy(this.props.state.items.quotations, (d) => { return parseFloat((d.Amount) ? d.Amount.toString().replace(/,/gi, '') : 0) })
    //                 > parseFloat(this.props.state.supplier_monthly_spend[0].spendlimit)) && this.props.state.limitwin === null)
    //                 this.props.actions.limitExceeded(true)
    //     }
    // }

    ItemsForm() {
        return <div>
            <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={12}>
                        <Upload
                            id={'upload_ctl'}
                            prefix={'Supplier_Quote_' + this.data.vehicle + '_'}
                            btnlabel={'Upload Quote'}
                            header={false}
                        //list={[{value: 0, text: 'Supplier Quotation'},{value: 0, text: 'Vehicle Images'},{value: 0, text: 'Vehicle Check Sheet'}]}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        <hr />
                    </Col>
                </Row>
                <Row>
                    <Col xl={4} style={{ paddingTop: '10px' }}>

                        <DateControl
                            id={'txtquotedate'}
                            key={'txtquotedate'}
                            label={'Enter supplier quote date'}
                            name={'txtquotedate'}
                            value={this.props.state.quotedate.value}
                            error={this.props.state.quotedate.value ? false : true}
                            helperText={this.props.state.quotedate.value ? '' : 'Enter supplier quote date'}
                            onSelect={(args) => {
                                //console.log('args date',args)
                                this.props.actions.setValue('quotedate', args)
                            }}
                            type={'date'}
                        />
                    </Col>
                    <Col xl={4} style={{ paddingTop: '20px' }}>

                        <TextField
                            id={'txtquotenumber'}
                            label={'Enter supplier quote number'}
                            fullWidth={true}
                            multiLine={false}
                            margin="normal"
                            variant="outlined"
                            size={"small"}
                            error={this.props.state.quotenumber.value ? false : true}
                            helperText={this.props.state.quotenumber.value ? '' : 'Enter supplier quote number'}
                            value={this.props.state.quotenumber.value}
                            onChange={(event, value) => {
                                this.props.actions.setValue('quotenumber', event.target.value)
                            }}
                        />

                    </Col>
                    <Col xl={4} style={styles.textfield}>

                        <TextField
                            id={'txtrepairdays'}
                            label={'Enter supplier repair days'}
                            fullWidth={true}
                            multiLine={false}
                            margin="normal"
                            variant="outlined"
                            size={"small"}
                            error={this.props.state.repairdays.value ? false : true}
                            helperText={this.props.state.repairdays.value ? '' : 'Enter supplier quote number'}
                            value={this.props.state.repairdays.value}
                            onChange={(event, value) => {
                                this.props.actions.setValue('repairdays', event.target.value)
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        <hr />
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                    </Col>
                </Row>
            </Container>
        </div>
    }

    getQuotation(quotation, data) {
        return <tr key={'quotation_inx_' + quotation.inx}>
            <td>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={12} style={{ margin: '5px' }}>
                            <Icon
                                iname="delete_forever"
                                iclass="material-icons"
                                istyle={{
                                    fontSize: '18px',
                                    color: 'red',
                                    float: 'right',
                                    fontSize: '18px'
                                }}
                                onClick={(event) => {
                                    this.props.actions.remQuotationRow(quotation.id)
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={4} style={{ marginTop: '15px' }}>
                            <AutoComplete name={'autocomplete_select_supplier_quote'}
                                title={window.t.en('Select Supplier.')}
                                label={''}
                                info={{
                                    show: false
                                }}
                                fullWidth={true}
                                data={this.props.state.supplier_data}
                                value={this.props.state.selected_supplier.supplier_name}
                                onSelect={(args) => {
                                    if (args) {
                                        let _supplier = this.props.state.supplier_data.filter(s => s.supplier_id == args.value)
                                        _supplier = _supplier.length ? _supplier[0] : {}
                                        this.props.actions.setQuotationInputValue(quotation, 'supplier', _supplier)
                                        this.props.actions.setQuotationInputValue(quotation, 'supplier_name', args.text)
                                        this.props.actions.setQuotationInputValue(quotation, 'supplier_id', args.value)
                                    }
                                }}
                                onClick={() => {
                                    this.props.actions.getSupplierListData('');
                                }}
                                onFilter={(search) => {
                                    //console.log('supplier_datax',this.props.state.supplier_data)
                                    if (search) {
                                        this.props.actions.getSupplierListData(search)
                                        let _supplier = this.props.state.supplier_data.filter(s => s.text == search)
                                        if (_supplier.length) {
                                            this.props.actions.setQuotationInputValue(quotation, 'supplier', _supplier)
                                            this.props.actions.setQuotationInputValue(quotation, 'supplier_name', _supplier?.suppliername)
                                            this.props.actions.setQuotationInputValue(quotation, 'supplier_id', _supplier?.supplier_id)
                                        }
                                    } else {
                                        this.props.actions.getSupplierListData('')
                                        this.props.actions.setQuotationInputValue(quotation, 'supplier', {})
                                        this.props.actions.setQuotationInputValue(quotation, 'supplier_name', '')
                                        this.props.actions.setQuotationInputValue(quotation, 'supplier_id', null)
                                    }
                                }} />
                        </Col>
                        <Col xl={4} style={{ marginTop: '10px' }}>
                            <TextField
                                id={'txtquotenumber'}
                                label={'Supplier Quote Number'}
                                fullWidth={true}
                                multiLine={false}
                                variant="outlined"
                                margin="normal"
                                size={"small"}
                                value={quotation.quotenumber ? quotation.quotenumber : ''}
                                onChange={(event) => {
                                    this.props.actions.setQuotationInputValue(quotation, 'quotenumber', event.target.value)
                                }}
                            />
                        </Col>
                        <Col xl={4} style={{ marginTop: '0px' }}>
                            <DateControl
                                id={'txt_quoteedate'}
                                key={'txt_quoteedate'}
                                name={'txt_quoteedate'}
                                label={window.t.en('Quote Date')}
                                field={'docke'}
                                fullWidth={true}
                                value={quotation.quotedate ? quotation.quotedate : ''}
                                onSelect={(args) => {
                                    //console.log('txt_quoteedatex',args)
                                    this.props.actions.setQuotationInputValue(quotation, 'quotedate', args)
                                }}
                                type={'date'}
                            />
                        </Col>
                        <Col xl={12}>
                            <Upload
                                colsize={4}
                                header={false}
                                inx={quotation.id}
                                key={quotation.id}
                                btnlabel={'Upload Supplier Quote'}
                                id={'upload_ctl_' + quotation.id}
                                prefix={'supplier_quote_' + quotation.id}
                                list={[{ value: 0, text: 'Supplier Attachment' }, { value: 0, text: 'Supplier Quotation' }, { value: 0, text: 'Pictures' }]}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            {
                                quotation.supplier_id
                                    ? <QuoteItems
                                        px={quotation.id}
                                        mmcode={data.mmcode}
                                        vattable={data.vattable}
                                        labour_rate={data.labour_rate}
                                        serviceinterval={data.serviceinterval}
                                    />
                                    // <QuoteItems 
                                    // px={quotation.id}
                                    // vattable={quotation.supplier ? quotation.supplier.vatnumber ? true : false : true} 
                                    // auto_rebill={auto_rebill} 
                                    // auto_rebill_tyres={auto_rebill_tyres} 
                                    // labour_rate={0} />
                                    : ''
                            }
                        </Col>
                    </Row>
                </Container>
            </td>
        </tr>
    }

    getQuoteItems() {
        return <div>
            <VehicleTabs key={this.data.vehicle_id} id={this.data.vehicle_id} />


            <br />
            {
                this.data.po === 'Service'
                    ? <VehicleServicePlan serviceinterval={this.data.serviceinterval ? this.data.serviceinterval : 0} mmcode={this.data.mmcode ? this.data.mmcode : 0} />
                    : ''
            }
            <br />

            <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={12}>
                        <VehiclePlan key={'quotation_plan'} />
                        <Container style={{ margin: 0 }} className="custom-container">
                            <Row>
                                <Col xl={12}>
                                    <div className="pt-4">
                                        <Button variant="contained"
                                            label={'Add Item'}

                                            className="d-40 py-0 px-4 w-auto mx-0 mr-3 mr-lg-0 mx-lg-3"
                                            primary={true}
                                            onClick={(event) => {
                                                this.props.actions.addQuotationRow(this.props.state.upload.documents)
                                            }}
                                        >{window.t.en('Add Quotation')}</Button>
                                    </div>
                                    <br />
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={12}>

                                    <Table className="table" style={{ border: '1px solid #DFDFDF' }}>
                                        <tbody style={{ zIndex: 1, fontWeight: 'normal' }}>
                                            {

                                                this.props.state.quotations.map((x, inx) => {
                                                    return this.getQuotation({ ...x, inx: inx }, {
                                                        vattable: this.props.state.supplier ? this.props.state.supplier.vatnumber ? true : false : true,
                                                        labour_rate: this.props.state.supplier ? this.props.state.supplier.slarateperhour : 0,
                                                        serviceinterval: this.data.serviceinterval ? this.data.serviceinterval : 0,
                                                        mmcode: this.data.mmcode ? this.data.mmcode : 0
                                                    })
                                                })
                                            }
                                        </tbody>
                                    </Table>

                                </Col>
                            </Row>
                            {
                                this.props.state.items.quotations.length ? <Row>
                                    <Col xl={9}>
                                    </Col>
                                    <Col xl={3}>
                                        <table style={{ width: '100%', fontSize: '16px', fontWeight: '600' }}>
                                            <tr>
                                                <td>Vatable Total</td>
                                                <td style={{ textAlign: 'right' }}>{_.sumBy(this.props.state.items.quotations.filter(x => x.VAT), (d) => { return parseFloat((d['Total']) ? d['Total'].toString().replace(/,/gi, '') : 0) }).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            </tr>
                                            <tr>
                                                <td>Non Vatable Total</td>
                                                <td style={{ textAlign: 'right' }}>{_.sumBy(this.props.state.items.quotations.filter(x => !x.VAT), (d) => { return parseFloat((d['Total']) ? d['Total'].toString().replace(/,/gi, '') : 0) }).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            </tr>
                                            <tr>
                                                <td>VAT</td>
                                                <td style={{ textAlign: 'right' }}>{_.sumBy(this.props.state.items.quotations, (d) => { return parseFloat((d['VAT']) ? d['VAT'].toString().replace(/,/gi, '') : 0) }).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            </tr>
                                            <tr>
                                                <td>Total</td>
                                                <td style={{ textAlign: 'right' }}>{_.sumBy(this.props.state.items.quotations, (d) => { return parseFloat((d['Amount']) ? d['Amount'].toString().replace(/,/gi, '') : 0) }).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            </tr>
                                        </table>
                                    </Col>
                                </Row> : ''
                            }
                        </Container>
                    </Col>
                </Row>
            </Container>
        </div>
        {/*: <div> 
                <div>
                <div>Running tot {_.sumBy(this.props.state.items.grid, (d) => { return parseFloat((d.Amount) ? d.Amount.toString().replace(/,/gi, '') : 0) })}</div>
                <div>Curent limit {this.props.state.supplier_monthly_spend[0] ? this.props.state.supplier_monthly_spend[0].curr_usage : '-'}</div>
                 
                <div>{
                    this.props.state.supplier_monthly_spend[0] 
                        ? (parseFloat(this.props.state.supplier_monthly_spend[0].curr_usage) + _.sumBy(this.props.state.items.quotations, (d) => { return parseFloat((d.Amount) ? d.Amount.toString().replace(/,/gi, '') : 0) })
                            > parseFloat(this.props.state.supplier_monthly_spend[0].spendlimit))
                            ? <div style={styles.info}>Supplier limit exceeded</div>
                            : <div></div>
                        : '-'
                    }
                    </div>
              </div>
        </div>*/}
    }

    renderView() {
        console.log('renderViewx this.data', this.data)
        return <div>
            <ViewVehicle key={this.data.vehicle_id} id={this.data.vehicle_id} />
            {/* <ViewSupplier key={this.data.supplier_id} id={this.data.supplier_id}  /> */}
            <ViewMaintenance key={this.data.maintenancetransaction_id} id={this.data.maintenancetransaction_id} taskno={this.data.tasknumber} />
            {/* <Card
                title={'Capture Supplier Quote'}
                subtitle={'Complete the form below to create booking'}
                content={this.ItemsForm()}
            /> */}
            <div>
                {
                    this.props.state.selectedsupplier.supplier_id === this.data.supplier_id
                        ? <SupplierSpend key={this.data.supplier_id} id={this.data.supplier_id} /> : ''}

                <Card
                    title={'Capture Quotation Items'}
                    subtitle={'Capture supplier quotation items'}
                    content={this.getQuoteItems()}
                />
            </div>

            <Dialog
                open={
                    this.props.state.selectedsupplier.supplier_name
                        ? this.props.state.selectedsupplier.supplier_id !== this.data.supplier_id //this.props.state.selectedsupplier.supplier_name !== this.props.state.supplier.suppliername
                        : false
                }
                onClose={(event) => {
                    event.preventDefault()
                    this.props.actions.setSelectedSupplierName(null, '')
                }}
            //classes={{ paper: 'shadow-lg rounded' }}
            >
                <div className="text-center p-5">
                    <h4 className="font-weight-bold mt-4">
                        Verification Failed
                    </h4>
                    <p className="mb-0 font-size-lg text-muted">
                        Selected supplier does not match quoted supplier
                    </p>
                    <p className="mb-0 font-size-lg text-muted">
                        Please review and try again
                    </p>
                    <div className="pt-4">
                        <Button
                            onClick={(event) => {
                                event.preventDefault()
                                this.props.actions.setSelectedSupplierName(null, '')
                            }}
                            className=" btn-pill mx-1">
                            <span className="btn-wrapper--label">Try Again</span>
                        </Button>
                    </div>
                </div>
            </Dialog>
            <Dialog
                open={this.props.state.limitwin}
                onClose={(event) => {
                    event.preventDefault()
                    this.props.actions.limitExceeded(false)
                }}
            //classes={{ paper: 'shadow-lg rounded' }}
            >
                <div className="text-center p-5">
                    <h4 className="font-weight-bold mt-4">
                        Supplier Limit
                    </h4>
                    <p className="mb-0 font-size-lg text-muted">
                        Supplier monthly limit exceeded
                    </p>
                    <p className="mb-0 font-size-lg text-muted">
                        Please use another supplier
                    </p>
                    <div className="pt-4">
                        <Button
                            onClick={(event) => {
                                event.preventDefault()
                                this.props.actions.limitExceeded(false)
                            }}
                            className=" btn-pill mx-1">
                            <span className="btn-wrapper--label">Close</span>
                        </Button>
                    </div>
                </div>
            </Dialog>
        </div>
    }

    render() {
        this.data = null
        try {
            this.data = this.props.workflow_queue_data[0].context_data.data.data.data.step1
        } catch (error) {

        }
        return this.data
            ? this.renderView()
            : <Loading message={'Retrieving data, please wait...'} />
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.poBookingInvoice,
            supplier: {
                ...state.controls.supplier
            },
            supplier_monthly_spend: {
                ...state.controls.supplier_monthly_spend
            },
            items: {
                ...state.quoteItems
            },
            upload: {
                ...state.upload
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...workflowTaskInputAction, ...poBookingItemsAction }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(POBookingInvoice)