export const empty = true
export const tab = 0
export const view = 'form'
export const workqueue_calendar = []
export const component_list = []
export const workflow_list = []
export const category_list = []
export const operator_list = []
export const escalation_list = []
export const schedule_list = []
export const actions_list = []
export const field_list = []
export const team_list = []
export const user_list = []
export const workqueue = []
export const rules = []
export const actions = []
export const workqueue_indicator = []
export const indicator = null
export const indicator_id = null
export const indicator_name = null
export const component_id = null
export const category = ''
export const operator = ''
export const escalation = ''
export const action = ''
export const action_id = ''
export const schedule = ''
export const user = ''
export const selected_escalation = []
export const escalation_types = []
export const operator_text = ''
export const operator_id = null
export const field = null
export const field_name = ''
export const team = ''
export const add_win = false
export const log_win = false
export const saving = false
export const chk_escalation = false
export const deleterecordwin = false
export const deletingrecord = false

export const ind_good = null
export const ind_low = null
export const ind_medium = null
export const ind_high = null
export const ind_critical = null

export const workflow = null
export const workflow_id = null
export const workflow_name = null

export const grid_fields = [
    {
        pk: true,
        grid: false,
        name: 'workqueue_id',
        title: 'workqueue_id',
        display: 'workqueue_id',
    },
    {
        grid: true,
        name: 'workqueue_description',
        title: 'Event Name',
        display: 'workqueue_description',
    },
    {
        grid: true,
        name: 'workqueue_user',
        title: 'Created By',
        display: 'workqueue_user',
    },
    {
        grid: true,
        name: 'created_at',
        title: 'Date Create',
        display: 'created_at',
        type: 'dateTime'
    }
]