import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Container, Row, Col } from 'react-grid-system'
import AutoComplete from '../../../controls/autocomplete'
import ViewVehicle from '../../../controls/view_vehicle'
import Card from '../../../controls/card'

import * as _ from 'lodash'
import '../../../assets/css/react-vertical-timeline.css'

import Icon from '../../Icon/component'
import Loading from '../../Loading/component'

import * as styles from './styles'
import * as actions from './actions'

class WorkflowTimeline extends Component {
    componentDidMount() {
        this.props.actions.loadingWorkflowVehicleHistory()
        this.props.actions.getWorkflowVehicleList('')
    }

    // VehicleDetails() {
    //     const data = this.props.state.vehicle[0]
    //     return <div>
    //         <Card>
    //             <CardHeader
    //                 actAsExpander={false}
    //                 showExpandableButton={false}
    //                 title={window.t.en('Vehicle Information: ' + data.business_fktext)}
    //                 subtitle={window.t.en('Selected vehicle details.')}
    //                 avatar={<Icon iclass={'material-icons'} iname={'chevron_right'} />}
    //             />
    //             <CardContent>
    //                 <Container style={{ margin: 0 }} className="custom-container">
    //                     <Row>
    //                         <Col xl={3} style={styles.fieldLabel}>{'Registration Number:'}</Col>
    //                         <Col xl={3} style={styles.selectedFieldName}>{data.licenseplateno}</Col>
    //                         <Col xl={3} style={styles.fieldLabel}>{'Fleet Number:'}</Col>
    //                         <Col xl={3} style={styles.selectedFieldName}>{data.fleetno}</Col>
    //                     </Row>
    //                     <Row>
    //                         <Col xl={3} style={styles.fieldLabel}>{'Vehicle Status:'}</Col>
    //                         <Col xl={3} style={styles.selectedFieldName}>{data.vehiclestatus_fktext}</Col>
    //                         <Col xl={3} style={styles.fieldLabel}>{'Vehicle Odometer:'}</Col>
    //                         <Col xl={3} style={styles.selectedFieldName}>{parseFloat(data.closingodometer).toLocaleString('en-EG', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Col>
    //                     </Row>
    //                     <Row>
    //                         <Col xl={3} style={styles.fieldLabel}>{'Vehicle Make:'}</Col>
    //                         <Col xl={3} style={styles.selectedFieldName}>{data.mmcode.make}</Col>
    //                         <Col xl={3} style={styles.fieldLabel}>{'Vehicle Model:'}</Col>
    //                         <Col xl={3} style={styles.selectedFieldName}>{data.mmcode.mgdescription}</Col>
    //                     </Row>
    //                     <Row>
    //                         <Col xl={3} style={styles.fieldLabel}>{'Vehicle MM Code:'}</Col>
    //                         <Col xl={3} style={styles.selectedFieldName}>{data.mmcode.mmcode}</Col>
    //                         <Col xl={3} style={styles.fieldLabel}>{'Vehicle First Registration Date:'}</Col>
    //                         <Col xl={3} style={styles.selectedFieldName}>{data.registrationdate ? moment(data.registrationdate).format('YYYY-MM-DD') : ''}</Col>
    //                     </Row>
    //                     <Row>
    //                         <Col xl={3} style={styles.fieldLabel}>{'Vehicle Chassis Number:'}</Col>
    //                         <Col xl={3} style={styles.selectedFieldName}>{data.chassisnumber}</Col>
    //                         <Col xl={3} style={styles.fieldLabel}>{'Vehicle Engine Number:'}</Col>
    //                         <Col xl={3} style={styles.selectedFieldName}>{data.enginenumber}</Col>
    //                     </Row>
    //                     <Row>
    //                         <Col xl={3} style={styles.fieldLabel}>{'Vehicle Fuel Type:'}</Col>
    //                         <Col xl={3} style={styles.selectedFieldName}>{data.fueltype_fktext}</Col>
    //                         <Col xl={3} style={styles.fieldLabel}>{'Vehicle Colour:'}</Col>
    //                         <Col xl={3} style={styles.selectedFieldName}>{data.color_fktext}</Col>
    //                     </Row>
    //                 </Container>
    //             </CardContent>
    //         </Card>
    //     </div>
    // }

    // WarrantyDetails() {
    //     const data = this.props.state.vehicle[0]


    //     let vehicle_age = null
    //     let warranty = null
    //     let serviceplan = null
    //     try {

    //         vehicle_age = moment().diff(data.registrationdate, "months")
    //         warranty = data.closingodometer > data.mmcode.warrantykm || vehicle_age > data.mmcode.warrantyplan
    //             ? "Out Of Warranty" : "In Warranty"
    //         serviceplan = data.closingodometer > data.mmcode.serviceplandistance || vehicle_age > data.mmcode.serviceplanperiod
    //             ? "Out Of Service Plan" : "In Service Plan"

    //     } catch (error) {

    //     }

    //     return <div><Card>
    //         <CardHeader
    //             actAsExpander={false}
    //             showExpandableButton={false}
    //             title={window.t.en('Service And Warranty')}
    //             subtitle={window.t.en('Vehicle servince, and warranty plan details.')}
    //             avatar={<Icon iclass={'material-icons'} iname={'chevron_right'} />}
    //         >
    //         </CardHeader>
    //         <CardContent>
    //             <Container style={{ margin: 0 }} className="custom-container">
    //                 <Row>
    //                     <Col xl={3} style={styles.fieldLabel}>{'Vehicle Age:'}</Col>
    //                     <Col xl={3} style={styles.selectedFieldName}>{vehicle_age + ' Months'}</Col>
    //                     <Col xl={3} style={styles.fieldLabel}>{'Vehicle Odometer:'}</Col>
    //                     <Col xl={3} style={styles.selectedFieldName}>{data.closingodometer + ' Km'}</Col>
    //                 </Row>
    //                 <Row>
    //                     <Col xl={3} style={styles.fieldLabel}>{'Warranty Status:'}</Col>
    //                     <Col xl={3} style={styles.selectedFieldName}>{warranty ? warranty : 'Not Set'}</Col>
    //                     <Col xl={3} style={styles.fieldLabel}>{'Service Plan:'}</Col>
    //                     <Col xl={3} style={styles.selectedFieldName}>{serviceplan ? serviceplan : 'Not Set'}</Col>
    //                 </Row>
    //                 <Row>
    //                     <Col xl={3} style={styles.fieldLabel}>{'Warranty Distance:'}</Col>
    //                     <Col xl={3} style={styles.selectedFieldName}>{data.mmcode.warrantykm + ' Km'}</Col>
    //                     <Col xl={3} style={styles.fieldLabel}>{'Service Plan Distance:'}</Col>
    //                     <Col xl={3} style={styles.selectedFieldName}>{data.mmcode.serviceplandistance + ' Km'}</Col>
    //                 </Row>
    //                 <Row>
    //                     <Col xl={3} style={styles.fieldLabel}>{'Warranty Period:'}</Col>
    //                     <Col xl={3} style={styles.selectedFieldName}>{data.mmcode.warrantyplan + ' Months'}</Col>
    //                     <Col xl={3} style={styles.fieldLabel}>{'Service Plan Period:'}</Col>
    //                     <Col xl={3} style={styles.selectedFieldName}>{data.mmcode.serviceplanperiod + ' Months'}</Col>
    //                 </Row>
    //             </Container>
    //         </CardContent>
    //     </Card>
    //     </div>
    // }

    // TyresCount() {
    //     let derivative = null
    //     try {
    //         //derivative = this.props.state.vehicle[0].customercontract[0].customercontractderivative[0]

    //         let _data = this.props.state.vehicle[0].customercontract.filter(x => x.activated)
    //         derivative = _data.length ? _data[0].customercontractderivative[0] : null
    //     }
    //     catch (err) { }

    //     return derivative
    //         ? <Card initiallyExpanded={false}>
    //             <CardHeader
    //                 actAsExpander={true}
    //                 showExpandableButton={true}
    //                 title={window.t.en('Tyres')}
    //                 subtitle={window.t.en('Vehicle tyres usage break down.')}
    //                 avatar={<Icon iclass={'material-icons'} iname={'chevron_right'} />}
    //             >
    //             </CardHeader>
    //             <CardContent expandable={true}>
    //                 {
    //                     derivative
    //                         ? <Container style={{ margin: 0 }} className="custom-container">
    //                             <Row>
    //                                 <Col xl={3} style={styles.fieldLabel}>{'Tyres Allocated:'}</Col>
    //                                 <Col xl={3} style={styles.selectedFieldName}>{(parseInt(derivative.tyrequantityfront) + parseInt(derivative.tyrequantityrear))}</Col>

    //                                 <Col xl={3} style={styles.fieldLabel}>{'Tyres Used:'}</Col>
    //                                 <Col xl={3} style={styles.selectedFieldName}>{derivative.tyresused}</Col>
    //                             </Row>
    //                             <Row>
    //                                 <Col xl={3} style={styles.fieldLabel}>{'Tyre Expenditure:'}</Col>
    //                                 <Col xl={3} style={styles.selectedFieldName}>{derivative.tyresexpenditure ? derivative.tyresexpenditure : 'Not Set'}</Col>
    //                                 <Col xl={3} style={styles.fieldLabel}>{'Tyre Balance:'}</Col>
    //                                 <Col xl={3} style={styles.selectedFieldName}>{derivative.tyresfundbalance ? derivative.tyresfundbalance : 'Not Set'}</Col>
    //                             </Row>
    //                             <Row>
    //                                 <Col xl={3} style={styles.fieldLabel}>{'Tyres Monthly Income:'}</Col>
    //                                 <Col xl={3} style={styles.selectedFieldName}>{derivative.tyresincome}</Col>
    //                                 <Col xl={3} style={styles.fieldLabel}>{'Tyres Monthly Selling:'}</Col>
    //                                 <Col xl={3} style={styles.selectedFieldName}>{derivative.tyresselling}</Col>
    //                             </Row>
    //                         </Container>
    //                         : <h4>{'No Records Found.'}</h4>
    //                 }
    //             </CardContent>
    //         </Card>
    //         : ''
    // }

    // burnRate() {
    //     let data = null

    //     try {

    //         let _data = this.props.state.vehicle[0].customercontract.filter(x => x.activated === true)
    //         data = _data.length ? _data[0].customercontractderivative[0] : null
    //     }
    //     catch (err) { }
    //     return data
    //         ? <Card initiallyExpanded={false}>
    //             <CardHeader
    //                 actAsExpander={true}
    //                 showExpandableButton={true}
    //                 title={window.t.en('Burn Rate')}
    //                 subtitle={window.t.en('Vehicle burn rate')}
    //                 avatar={<Icon iclass={'material-icons'} iname={'chevron_right'} />}
    //             >
    //             </CardHeader>
    //             <CardContent expandable={true}>
    //                 <Container style={{ margin: 0 }} className="custom-container">
    //                     <Row>
    //                         <Col xl={12} style={styles.hr}><h5>{'FD Opening Summary'}</h5></Col>
    //                     </Row>
    //                     <Row>
    //                         <Col xl={3} style={styles.fieldLabel}>{'Maintenance:'}</Col>
    //                         <Col xl={3} style={styles.selectedFieldName}>{data.fdmaintenanceopening}</Col>
    //                         <Col xl={3} style={styles.fieldLabel}>{'Tyre:'}</Col>
    //                         <Col xl={3} style={styles.selectedFieldName}>{data.fdtyreopening}</Col>
    //                     </Row>
    //                     <Row>
    //                         <Col xl={12} style={styles.hr}><h5>{'Variable Billing Summary'}</h5></Col>
    //                     </Row>
    //                     <Row>
    //                         <Col xl={3} style={styles.fieldLabel}>{'Last Excess Opening:'}</Col>
    //                         <Col xl={3} style={styles.selectedFieldName}>{data.lastexcessopeningodo}</Col>
    //                         <Col xl={3} style={styles.fieldLabel}>{'Last Excess Closing:'}</Col>
    //                         <Col xl={3} style={styles.selectedFieldName}>{data.lastexcessclosingodo}</Col>
    //                     </Row>
    //                     <Row>
    //                         <Col xl={3} style={styles.fieldLabel}>{'Current Excess Opening:'}</Col>
    //                         <Col xl={3} style={styles.selectedFieldName}>{data.currexcessopeningodo}</Col>
    //                         <Col xl={3} style={styles.fieldLabel}>{'Current Excess Closing:'}</Col>
    //                         <Col xl={3} style={styles.selectedFieldName}>{data.currexcessclosingodo}</Col>
    //                     </Row>
    //                     {/* <Row>
    //                         <Col xl={12} style={styles.hr}><h3>{'Billing Summary'}</h3></Col>
    //                     </Row>
    //                     <Row>
    //                         <Col xl={3} style={styles.fieldLabel}>{'Contract Period (Months):'}</Col>
    //                         <Col xl={3} style={styles.selectedFieldName}>{data.period}</Col>
    //                         <Col xl={3} style={styles.fieldLabel}>{'Current Billing Count:'}</Col>
    //                         <Col xl={3} style={styles.selectedFieldName}>{data.billingcount}</Col>
    //                     </Row>
    //                     <Row>
    //                         <Col xl={3} style={styles.fieldLabel}>{'Remaining Billing Count:'}</Col>
    //                         <Col xl={3} style={styles.selectedFieldName}>{data.remainingbillingcount}</Col>
    //                     </Row> */}
    //                     <Row>
    //                         <Col xl={12} style={styles.hr}><h5>{'Maintenance Fund'}</h5></Col>
    //                     </Row>
    //                     <Row>
    //                         <Col xl={3} style={styles.fieldLabel}>{'Maintenance Income:'}</Col>
    //                         <Col xl={3} style={styles.selectedFieldName}>{data.maintenanceincome}</Col>
    //                         <Col xl={3} style={styles.fieldLabel}>{'Maintenance Expenditure:'}</Col>
    //                         <Col xl={3} style={styles.selectedFieldName}>{data.maintenanceexpenditure}</Col>
    //                     </Row>
    //                     <Row>
    //                         <Col xl={3} style={styles.fieldLabel}>{'Maintenance Fund Balance:'}</Col>
    //                         <Col xl={3} style={styles.selectedFieldName}>{data.maintenancefundbalance}</Col>
    //                     </Row>
    //                     <Row>
    //                         <Col xl={3} style={styles.fieldLabel}>{'Maintenance Monthly Income:'}</Col>
    //                         <Col xl={3} style={styles.selectedFieldName}>{data.maintenancemonthlyselling}</Col>
    //                     </Row>
    //                     <Row>
    //                         <Col xl={3} style={styles.fieldLabel}>{'Maintenance Excess (CPK):'}</Col>
    //                         <Col xl={3} style={styles.selectedFieldName}>{data.maintenanceexcess}</Col>
    //                         <Col xl={3} style={styles.fieldLabel}>{'Maintenance and Finance Excess (CPK):'}</Col>
    //                         <Col xl={3} style={styles.selectedFieldName}>{data.excess}</Col>
    //                     </Row>
    //                     <Row>
    //                         <Col xl={12} style={styles.hr}><h5>{'Tyre Fund & Usage'}</h5></Col>
    //                     </Row>
    //                     <Row>
    //                         <Col xl={3} style={styles.fieldLabel}>{'Tyre Income:'}</Col>
    //                         <Col xl={3} style={styles.selectedFieldName}>{data.tyresincome}</Col>
    //                         <Col xl={3} style={styles.fieldLabel}>{'Tyre  Expense:'}</Col>
    //                         <Col xl={3} style={styles.selectedFieldName}>{data.tyresexpenditure}</Col>
    //                     </Row>
    //                     <Row>
    //                         <Col xl={3} style={styles.fieldLabel}>{'Tyre Fund Balance:'}</Col>
    //                         <Col xl={3} style={styles.selectedFieldName}>{data.tyresfundbalance}</Col>
    //                     </Row>
    //                     <Row>
    //                         <Col xl={3} style={styles.fieldLabel}>{'Tyres Monthly Income:'}</Col>
    //                         <Col xl={3} style={styles.selectedFieldName}>{data.tyresmonthlyselling}</Col>
    //                     </Row>
    //                     <Row>
    //                         <Col xl={3} style={styles.fieldLabel}>{'Tyres Allocated:'}</Col>
    //                         <Col xl={3} style={styles.selectedFieldName}>{(parseInt(data.tyrequantityfront) + parseInt(data.tyrequantityrear))}</Col>
    //                         <Col xl={3} style={styles.fieldLabel}>{'Tyres Used (Not Rebilled):'}</Col>
    //                         <Col xl={3} style={styles.selectedFieldName}>{data.tyresused}</Col>
    //                     </Row>
    //                     <Row>
    //                         <Col xl={3} style={styles.fieldLabel}>{'Tyres Allocated Left:'}</Col>
    //                         <Col xl={3} style={styles.selectedFieldName}>{data.ltdtyresused ? data.ltdtyresused : 0}</Col>
    //                         <Col xl={3} style={styles.fieldLabel}>{'Tyre Rebilled:'}</Col>
    //                         <Col xl={3} style={styles.selectedFieldName}>{data.rebilledtyresused ? data.rebilledtyresused : 0}</Col>
    //                     </Row>
    //                     <Row>
    //                         <Col xl={12} style={styles.hr}><h5>{'Utilisation Performance (Excess)'}</h5></Col>
    //                     </Row>
    //                     <Row>
    //                         <Col xl={3} style={styles.fieldLabel}>{'FD Take On Excess:'}</Col>
    //                         <Col xl={3} style={styles.selectedFieldName}>{data.fd_takeon_excess}</Col>
    //                     </Row>
    //                     <Row>
    //                         <Col xl={3} style={styles.fieldLabel}>{'Excess Debits:'}</Col>
    //                         <Col xl={3} style={styles.selectedFieldName}>{data.excess_debit}</Col>
    //                         <Col xl={3} style={styles.fieldLabel}>{'Excess Credits:'}</Col>
    //                         <Col xl={3} style={styles.selectedFieldName}>{data.excess_credit}</Col>
    //                     </Row>
    //                     <Row>
    //                         <Col xl={3} style={styles.fieldLabel}>{'Total Fund Income - Inclusive of Excess:'}</Col>
    //                         <Col xl={3} style={styles.selectedFieldName}>{data.totalfundincome_includingexcess}</Col>
    //                         <Col xl={3} style={styles.fieldLabel}>{'Total Fund Income - Exclusive of  Excess:'}</Col>
    //                         <Col xl={3} style={styles.selectedFieldName}>{data.totalfundincome_excludingexcess}</Col>
    //                     </Row>
    //                     <Row>
    //                         <Col xl={3}><h3>{''}</h3></Col>
    //                         <Col xl={3}><h3>{''}</h3></Col>
    //                         <Col xl={3} style={styles.selectedFieldName}>{'TOTAL:'}</Col>
    //                         <Col xl={3} style={styles.selectedFieldName}>{data.totalfundbalance}</Col>
    //                     </Row>
    //                 </Container>
    //             </CardContent>
    //         </Card>
    //         : ''
    // }

    // VehicleContract() {

    //     let data = null
    //     try {
    //         let _data = this.props.state.vehicle[0].customercontract.filter(x => x.activated)

    //         data = _data.length ? _data[0] : null
    //     }
    //     catch (err) { }
    //     return data
    //         ? <div>
    //             <Card>
    //                 <CardHeader
    //                     actAsExpander={false}
    //                     showExpandableButton={false}
    //                     title={window.t.en('Vehicle Contract Information')}
    //                     subtitle={window.t.en('Selected vehicle contract details')}
    //                     avatar={<Icon iclass={'material-icons'} iname={'chevron_right'} />}
    //                 >
    //                 </CardHeader>
    //                 <CardContent>
    //                     <Container style={{ margin: 0 }} className="custom-container">
    //                         <Row>
    //                             <Col xl={3} style={styles.fieldLabel}>{'Contract Number:'}</Col>
    //                             <Col xl={3} style={styles.selectedFieldName}>{data.contractnumber}</Col>
    //                             <Col xl={3} style={styles.fieldLabel}>{'Contract Type:'}</Col>
    //                             <Col xl={3} style={styles.selectedFieldName}>{data.contracttype_fktext}</Col>
    //                         </Row>
    //                         <Row style={data.activated ? styles.boxDefault : styles.boxError}>
    //                             <Col xl={3} style={styles.fieldLabel}>{'Contract Status:'}</Col>
    //                             <Col xl={3} style={styles.selectedFieldName}>{data.activated ? 'Active' : 'Not Active'}</Col>
    //                             <Col xl={3} style={styles.fieldLabel}>{'Contract Status:'}</Col>
    //                             <Col xl={3} style={styles.selectedFieldName}>{data.contractstatus_fktext}</Col>
    //                         </Row>
    //                         <Row>
    //                             <Col xl={3} style={styles.fieldLabel}>{'Start Date:'}</Col>
    //                             <Col xl={3} style={styles.selectedFieldName}>{data.startdate ? moment(data.startdate).format('YYYY-MM-DD') : 'Not Set'}</Col>
    //                             <Col xl={3} style={styles.fieldLabel}>{'End Date:'}</Col>
    //                             <Col xl={3} style={styles.selectedFieldName}>{data.terminationdate ? moment(data.terminationdate).format('YYYY-MM-DD') : 'Not Set'}</Col>

    //                         </Row>
    //                         <Row>
    //                             <Col xl={3} style={styles.fieldLabel}>{'Restructured:'}</Col>
    //                             <Col xl={3} style={styles.selectedFieldName}>{data.restructure ? 'Yes' : 'No'}</Col>
    //                             <Col xl={3} style={styles.fieldLabel}>{'Contract Period:'}</Col>
    //                             <Col xl={3} style={styles.selectedFieldName}>{data.period}</Col>
    //                         </Row>
    //                         <Row>
    //                             <Col xl={3} style={styles.fieldLabel}>{'Distance per Month:'}</Col>
    //                             <Col xl={3} style={styles.selectedFieldName}>{parseFloat(data.distancepermonth).toLocaleString('en-EG', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' Kms'}</Col>
    //                             <Col xl={3} style={styles.fieldLabel}>{'Contract Distance:'}</Col>
    //                             <Col xl={3} style={styles.selectedFieldName}>{parseFloat(data.distance).toLocaleString('en-EG', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' Kms'}</Col>
    //                         </Row>
    //                     </Container>
    //                 </CardContent>
    //             </Card>
    //         </div>
    //         : ''
    // }

    // VehicleMaintenance() {
    //     let data = this.props.state.vehicle[0].maintenancetransaction.filter(x => x.deleted_at === null)
    //     const vehicle_id = this.props.state.vehicle[0].vehicle_id
    //     const makemodelyear = this.props.state.vehicle[0].makemodelyear
    //     const licenseplateno = this.props.state.vehicle[0].licenseplateno
    //     const imagefilename = this.props.state.vehicle[0].mmcode.imagefilename
    //     let customercontractderivative = []
    //     try {
    //         customercontractderivative = this.props.state.vehicle[0].customercontract[0].customercontractderivative
    //     }
    //     catch (err) {

    //     }
    //     let servicehistory = this.props.state.servicehistory  //,this.props.state.tyrehistory
    //     data = servicehistory ? data.filter(x => x.maintenancetype_fktext === 'Service' || x.maintenancetype_fktext === 'Repairs') : data


    //     let list = data.filter(x => x.reference)

    //     let result = _.sortBy(list, [function (o) { return o.odometer; }])
    //     result = result.reduce(function (r, a) {
    //         r[a.odometer] = r[a.odometer] || [];
    //         r[a.odometer].push(a);
    //         return r;
    //     }, Object.create(null));

    //     list = Object.values(result)
    //     //list = list.filter(x => x.reference)
    //     data = data.filter(x => x.authnumber)
    //     data = _.sortBy(data, [function (o) { return o.datestamp; }])

    //     return <div>
    //         <Card initiallyExpanded={false}>
    //             <CardHeader
    //                 actAsExpander={true}
    //                 showExpandableButton={true}
    //                 title={window.t.en('Vehicle Maintencance History: ' + licenseplateno)}
    //                 subtitle={window.t.en('Selected vehicle service history details.')}
    //                 avatar={<Icon iclass={'material-icons'} iname={'chevron_right'} />}
    //             >
    //             </CardHeader>
    //             <CardContent expandable={true}>

    //                 <Container style={{ margin: 0 }} className="custom-container">
    //                     <Row>
    //                         <Col xl={8} style={styles.rowHeader}>
    //                         </Col>
    //                         <Col xl={2} style={styles.rowHeader}>
    //                             <Checkbox
    //                                 id={'servicehistory'}
    //                                 label="Service Only"
    //                                 value={this.props.state.servicehistory}
    //                                 onCheck={
    //                                     (event, checked) => {
    //                                         this.props.actions.chkHistoryType('servicehistory', checked)
    //                                     }
    //                                 }
    //                             />
    //                         </Col>
    //                         <Col xl={2} style={styles.rowHeader}>
    //                             < Button className={"global_button"}
    //                                 secondary={true}
    //                                 label="Download PDF"
    //                                 icon={<Icon iclass={'material-icons'} iname={'cloud_download'} />}
    //                                 onClick={(event) => {
    //                                     this.props.actions.downloadMaintanancePDF(data, list, vehicle_id, makemodelyear, licenseplateno, imagefilename, customercontractderivative)
    //                                 }}
    //                                 ><Icon iclass={'material-icons'} iname={'cloud_download'} /> Download PDF</Button>
    //                         </Col>
    //                     </Row>
    //                     <Row>
    //                         <Col xl={12} style={styles.rowHeader}>
    //                             <br />
    //                         </Col>
    //                     </Row>
    //                     {
    //                         list.map(_list => {
    //                             return <div>
    //                                 <Row>
    //                                     <Col xl={12} style={styles.rowHeader}>
    //                                         <br />
    //                                     </Col>
    //                                 </Row>

    //                                 <Row>
    //                                     <Col xl={12}>
    //                                         <h5 style={styles.hr}>{_list[0].reference + ': ' + _list[0].maintenancetype_fktext + ' @ ' + parseFloat(_list[0].odometer).toLocaleString('en-EG', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' Kms' + ' On ' + moment(_list[0].capturedate).format('DD/MM/YYYY') + ' By ' + _list[0].supplier_fktext}</h5>
    //                                     </Col>
    //                                 </Row>
    //                                 <Row>
    //                                     <Col xl={2}>
    //                                         <strong>{'Item Type'}</strong>
    //                                     </Col>
    //                                     <Col xl={2}>
    //                                         <strong>{'Item Description'}</strong>
    //                                     </Col>
    //                                     <Col xl={2}>
    //                                         <strong>{'Qty'}</strong>
    //                                     </Col>
    //                                     <Col xl={2} style={styles.numericDiv}>
    //                                         <strong style={styles.numericDiv}>{'Invoice Amount'}</strong>
    //                                     </Col>
    //                                     <Col xl={2} style={styles.numericDiv}>
    //                                         <strong style={styles.numericDiv}>{'Approved Amount'}</strong>
    //                                     </Col>
    //                                     <Col xl={2} style={styles.numericDiv}>
    //                                         <strong style={styles.numericDiv}>{'Paid Amount'}</strong>
    //                                     </Col>
    //                                 </Row>
    //                                 {
    //                                     _list
    //                                         .map(x => {
    //                                             return parseInt(x.invoicedamount) && _list[0].deleted_at === null
    //                                                 ? <Row>
    //                                                     <Col xl={2}>
    //                                                         {x.maintenancetype_fktext}
    //                                                     </Col>
    //                                                     <Col xl={2}>
    //                                                         {x.maintdescription}
    //                                                     </Col>
    //                                                     <Col xl={2}>
    //                                                         {x.quantity}
    //                                                     </Col>
    //                                                     <Col xl={2} style={styles.numericFields}>
    //                                                         {parseFloat(x.invoicedamount).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
    //                                                     </Col>
    //                                                     <Col xl={2} style={styles.numericFields}>
    //                                                         {parseFloat(x.approvedamount).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
    //                                                     </Col>
    //                                                     <Col xl={2} style={styles.numericFields}>
    //                                                         {parseFloat(x.paidamount).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
    //                                                     </Col>
    //                                                 </Row>
    //                                                 : ''
    //                                         })
    //                                 }
    //                             </div>
    //                         })
    //                     }
    //                 </Container>
    //                 <Container style={{ margin: 0 }} className="custom-container">
    //                     {
    //                         data.map(_list => {
    //                             return _list.maintenancetransactionitem.length
    //                                 ? <div>
    //                                     <Row>
    //                                         <Col xl={12} style={styles.rowHeader}>
    //                                             <br />
    //                                         </Col>
    //                                     </Row>

    //                                     <Row>
    //                                         <Col xl={12}>
    //                                             <h5 style={styles.hr}>{'Status: ' + _list.maintenancestatus_fktext}
    //                                                 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    //                                                 {_list.ps === 2 ? "Paid" : "Pending"}
    //                                                 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    //                                                 {"Rebill " + _list.rebill}
    //                                                 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    //                                                 {_list.authnumber + ': ' + _list.maintenancetype_fktext + ' @ ' + parseFloat(_list.odometer).toLocaleString('en-EG', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' Kms' + ' On ' + moment(_list.datestamp).format('DD/MM/YYYY') + ' By ' + _list.supplier_fktext}</h5>
    //                                         </Col>
    //                                     </Row>
    //                                     <Row  style={{fontWeight: 'normal !important'}}>
    //                                         <Col xl={2}>
    //                                             <p>{'Item Type'}</p>
    //                                         </Col>
    //                                         <Col xl={2}>
    //                                             <p>{'Item Description'}</p>
    //                                         </Col>
    //                                         <Col xl={1}>
    //                                             <p>{'Qty'}</p>
    //                                         </Col>
    //                                         <Col xl={2} style={styles.numericDiv}>
    //                                             <p style={styles.numericDiv}>{'Unit Price'}</p>
    //                                         </Col>
    //                                         <Col xl={2} style={styles.numericDiv}>
    //                                             <p style={styles.numericDiv}>{'Discount'}</p>
    //                                         </Col>
    //                                         <Col xl={1} style={styles.numericDiv}>
    //                                             <path style={styles.numericDiv}>{'VAT'}</path>
    //                                         </Col>
    //                                         <Col xl={2} style={styles.numericDiv}>
    //                                             <p style={styles.numericDiv}>{'Total'}</p>
    //                                         </Col>
    //                                     </Row>
    //                                     {
    //                                         _list.maintenancetransactionitem.map(x => {
    //                                             return <Row style={{fontWeight: 'normal !important'}}>
    //                                                 <Col xl={2}>
    //                                                     {x.itemtype}
    //                                                 </Col>
    //                                                 <Col xl={2}>
    //                                                     {x.itemdescription}
    //                                                 </Col>
    //                                                 <Col xl={1}>
    //                                                     {x.quantity}
    //                                                 </Col>
    //                                                 <Col xl={2} style={styles.numericFields}>
    //                                                     {parseFloat(x.amount).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
    //                                                 </Col>
    //                                                 <Col xl={2} style={styles.numericFields}>
    //                                                     {parseFloat(x.discountamount).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
    //                                                 </Col>
    //                                                 <Col xl={1} style={styles.numericFields}>
    //                                                     {parseFloat(x.vat).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
    //                                                 </Col>
    //                                                 <Col xl={2} style={styles.numericFields}>
    //                                                     {parseFloat(x.totalamount).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
    //                                                 </Col>
    //                                             </Row>
    //                                         })
    //                                     }
    //                                     <Row>
    //                                         <Col xl={10}>
    //                                         </Col>
    //                                         <Col xl={2} style={styles.numericFields}>
    //                                             <strong>{'Total'} {parseFloat(_list.totalamount).toLocaleString('en-EG', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</strong>
    //                                         </Col>
    //                                     </Row>
    //                                 </div>
    //                                 : ''
    //                         })
    //                     }

    //                 </Container>
    //             </CardContent>
    //         </Card>
    //     </div>
    // }

    getVehicleHistory() {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>
                    <div style={styles.fieldContainer}>
                        <div style={styles.fieldTitle}>{'Select Vehicle Registration:'}</div>
                        <div style={styles.fieldContent}>
                            <AutoComplete
                                name={'autocomplete_purchase_order_vehicle_reg'}
                                title={window.t.en('Select Vehicle Registration')}
                                info={{
                                    show: false
                                }}
                                fieldvalue={'vehicle_id'}
                                displayfields={['licenseplateno','makemodelyear']}
                                displaywidth={['20','80']}
                                fullWidth={true}
                                data={this.props.state.vehicles}
                                //value={this.props.state.selectedVehicle.data ? this.props.state.selectedVehicle.data.text : ''}
                                onSelect={(args) => {
                                    //console.log('onSelect Select Vehicle Registrati',args)
                                    this.props.actions.getWorkflowVehicleHistory(args.value)
                                }}
                                onClick={() => {
                                    this.props.actions.getWorkflowVehicleList('')
                                }}
                                onFilter={(args) => {
                                    //console.log(args)
                                    this.props.actions.getWorkflowVehicleList(args)
                                }}
                            />
                        </div>
                    </div>
                    <br />
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    {
                        this.props.state.vehicle.length
                            ? <ViewVehicle 
                                key={this.props.state.vehicle[0].vehicle_id} 
                                id={this.props.state.vehicle[0].vehicle_id} 
                            />
                            : ''
                    }
                </Col>
            </Row>
        </Container>
    }

    render() {
        return <Card 
                title={window.t.en('Vehicle History')}
                subtitle={window.t.en('Search vehicle history.')}
                content={this.getVehicleHistory()}
            />
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.workflowVehicleHistory,
            workflowQueueTasks: {
                ...state.workflowQueueTasks
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowTimeline)