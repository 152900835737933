import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'
//import jwt from 'jsonwebtoken'

import I18next from '../I18next/component'
//import '../../import'
//import '../../sass-sub'

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/system';


import * as actions from './actions'
import * as config from '../../config'
import { color } from '../WorkflowPayment/PaymentApprovalRelease/styles'

import { useMsal } from '@azure/msal-react';
import Hooks from '../Hooks/component'
import Logo from '../Logo/component'
import CssBaseline from '@mui/material/CssBaseline';
import { Card, Dialog, InputAdornment, IconButton, InputLabel, Avatar, OutlinedInput, FormControl } from '@mui/material'



const CustomTextField = styled(TextField)(({ theme, textColor }) => ({
    '& .MuiInputBase-input': {
        borderColor: `${textColor} !important`,
        color: `${textColor} !important`,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: `#253053 !important`, 
      color: `${textColor} !important`,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: `${textColor} !important`,
      color: `${textColor} !important`,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: `${textColor} !important`,
      color: `${textColor} !important`,
    },
  }));

  const CustomOutlinedInput = styled(OutlinedInput)(({ theme, textColor }) => ({
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: `#253053 !important`,
      color: `${textColor} !important`,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: `${textColor} !important`,
      color: `${textColor} !important`,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: `${textColor} !important`,
      color: `${textColor} !important`,
    },
  }));



  const ActiveDirectory = (props) => {    
    
    const { instance, accounts, inProgress } = useMsal();
    const setBackground = () => {
        document.body.className = 'logged_out'
    }

    const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleLogin()
        }
    }

    const handleLogin = (e) => {
        //console.log('authx username',props.state.username)
    }

    const getLogin = () => {
        return <div>
            <center>
                <Logo />
                <div variant="h6" style={{ color: `${config.system.textColor}` }}>
                    <br />
                    <h4>{window.t.en('Administrator Production Portal')}</h4>
                    <div style={{ fontSize: '10px !important' }}>{window.t.en('Enter your account login details')}</div>
                </div>
            </center>

            <div style={{ top: '0 px !important', marginTop:20 }}>
                <CustomTextField
                    textColor={`${config.system.textColor}`}
                    variant="outlined"
                    margin="normal"
                    name="email"
                    id="txt_login_username"
                    label={window.t.en('Enter Your Username')}
                    required
                    fullWidth
                    InputLabelProps={{
                        style: {color:`${config.system.textColor}`},
                        shrink: true
                    }}
                    style={{color: `${config.system.textColor} !important`}}
                    inputProps={{
                        style: {
                          color: `${config.system.textColor} !important`,
                          backgroundColor: 'transparent !important',
                          WebkitBoxShadow: `${config.system.textboxColor} 0px 0px 0px 1000px inset`,
                          caretColor: `${config.system.textColor} !important`
                        },
                      }}
                      sx={{
                        color: `${config.system.textColor} !important`,
                        caretColor: `${config.system.textColor} !important`,
                        '& input:-webkit-autofill': {
                            background: 'transparent !important',
                            color: `${config.system.textColor} !important`,
                            caretColor: `${config.system.textColor} !important`,
                            '-webkit-text-fill-color': `${config.system.textColor} !important`
                        },
                        marginBottom: 5
                      }}
                    value={props.state.username}
                    onChange={
                        (event, value) => {
                            //console.log('username', props.state.username)
                            props.actions.setInputValue('username', event.target.value )
                        }
                    }
                />
                <Button
                    fullWidth
                    variant="contained"
                    size="large"
                    id="btn_user_login"
                    disabled={props.state.loading}
                    onClick={(e) => {
                        e.preventDefault()
                        //console.log('authx username',props.state.username)
                        instance.loginRedirect({
                            loginHint: props.state.username,
                            prompt: 'login' 
                        });
                    }}
                    sx={{marginTop: 0}}
                >
                    <label style={{ fontSize: '20px' }}> {'Sign In'}</label>
                </Button>
                <br />
                {
                    props.state.error
                        ? <p style={{ color: '#a94442', borderRadius: "5px", border: '1px solid #ebccd1', width: '100%', backgroundColor: '#f2dede', margin: "8px 0", padding: '5px', fontSize: '18px' }}>{props.state.error}</p>
                        : <div></div>
                }
                {
                    props.state.success
                        ? <p style={{ color: '#3c763d', borderRadius: "5px", border: '1px solid #d6e9c6', width: '100%', backgroundColor: '#dff0d8', margin: "8px 0", padding: '5px', fontSize: '18px' }}>{props.state.success}</p>
                        : <div></div>
                }
                <br />
            </div>
        </div>
    }

    const getLayout = () => {
        {setBackground()}
        return <>
            <CssBaseline />
            <div  style={{ marginTop: '20px', right: '20px', width: '450px', position: 'absolute', alignItems: 'right' }}>
                <Card  style={config.system.clientTheme === 'bidvest' ? {
                    padding: '25px',
                    backgroundColor: 'rgba(255, 255, 255, 0.7)', 
                    //boxShadow: '#f8f9fa38 0 0 0 300px inset'
                }
                :{
                    backgroundColor: 'rgba(255, 255, 255, 0.5)', 
                    padding: '25px'
                }}>

                    {getLogin()}
                </Card>
            </div>
        </>
    }

    return (
        //this.setBackground()
        getLayout()
    )
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.activeDirectory
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ActiveDirectory)

// export default withAuthenticator(connect(mapStateToProps, mapDispatchToProps)(authAmplify,AmplifySignIn), false, [
//     <AmplifySignIn />
// ]);
