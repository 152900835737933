import { combineEpics } from 'redux-observable'

import * as accountEpics from './components/Account/epics'
import * as manageWorkFlowTaskEpics from './components/ManageWorkFlowTask/epics'
import * as oqlisEpics from './components/Oqlis/epics'
import * as adhocComponentFieldInputEpics from './components/Custom/AdhocComponentFieldInput/epics'
import * as adhocGridEpics from './components/Custom/AdhocGrid/epics'
import * as appEpics from './components/App/epics'
import * as assignedTaskCountEpics from './components/WorkflowQueueTasks/AssignedTaskCount/epics'
import * as assignedTasksEpics from './components/WorkflowQueueTasks/AssignedTasks/epics'
import * as calculatorEpics from './components/Calculator/epics'
import * as calendar from './components/CalendarControl/epics'
import * as captureSTR from './components/WorkflowSTRHeader/CaptureSTR/epics'
import * as clientsEpics from './components/Clients/epics'
import * as cognitoEpics from './components/Cognito/epics'
import * as completedTaskCountEpics from './components/WorkflowQueueTasks/CompletedTaskCount/epics'
import * as componentEpics from './components/Components/epics'
import * as connectorEpics from './components/Connector/epics'
import * as contractRestructureEpics from './components/WorkflowContractRestructure/ContractRestructure/epics'
import * as contractSelectionGridEpics from './components/WorkflowContractRestructure/ContractSelectionGrid/epics'
import * as customerAllocationEpic from './components/Custom/CUSTOMERALLOCATION/epics'
import * as supplierAllocationEpic from './components/Custom/SUPPLIERALLOCATION/epics'
import * as customerCreditNoteEpic from './components/Custom/CUSTOMERCREDITNOTE/epics'
import * as customerStatementEpic from './components/Custom/CUSTOMERSTATEMENT/epics'
//import * as customerInvoiceEpic from './components/Custom/CUSTOMERINVOICE/epics'
import * as accountingItemLinkEpic from './components/Custom/ACCOUNTINGITEMLINK/epics'
import * as customerTransactionEpic from './components/Custom/CUSTOMERTRANSACTION/epics'
import * as supplierTransactionEpic from './components/Custom/SUPPLIERTRANSACTION/epics'
import * as customerJournalEpic from './components/Custom/CUSTOMERJOURNAL/epics'
import * as customerReceiptEpic from './components/Custom/CUSTOMERRECEIPT/epics'
import * as customerReverseReceiptEpic from './components/Custom/CUSTOMERREVERSERECEIPT/epics'
import * as documentEpics from './components/Document/epics'
import * as formEpics from './components/Form/epics'
import * as gridEpics from './components/Grid/epics'
import * as invoiceap from './components/Invoiceap/epics'
import * as licenceRenewalEpics from './components/Custom/LICENCERENEWAL/epics'
import * as loadOnDemandEpics from './components/LoadOnDemand/epics'
import * as multiselectEpics from './components/MultiSelect/epics'
import * as navigationMenuEpics from './components/NavigationMenu/epics'
import * as poBookingInvoiceEpics from './components/WorkflowPOHeader/POBookingInvoice/epics'
import * as poBookingItems from './components/WorkflowPOHeader/POBookingItems/epics'
import * as poHeader from './components/WorkflowPOHeader/POHeader/epics'
import * as quoteItems from './components/WorkflowPOHeader/QuoteItems/epics'
import * as queuedTaskCountEpics from './components/WorkflowQueueTasks/QueuedTaskCount/epics'
import * as queuedTasksEpics from './components/WorkflowQueueTasks/QueuedTasks/epics'
import * as quoteHeader from './components/QuoteHeader/epics'
import * as quoteHeaderSTR from './components/QuoteHeaderSTR/epics'
import * as quoteSelectGridEpics from './components/QuoteSelectGrid/epics'
import * as readComponentEpics from './components/ReadComponent/epics'
import * as reassignEpics from './components/WorkflowQueueTasks/ReassignTask/epics'
import * as recaptchaEpic from './components/Recaptcha/epics'
import * as requestEpics from './components/Request/epics'
import * as signedInEpics from './components/SignedIn/epics'
import * as supplierPaymentAllocationEpic from './components/Custom/SUPPLIERPAYMENTALLOCATION/epics'
import * as supplierPaymentEpic from './components/Custom/SUPPLIERPAYMENT/epics'
import * as supplierPaymentReversalEpic from './components/Custom/SUPPLIERPAYMENTREVERSAL/epics'
import * as teamEpics from './components/Teams/epics'
import * as teamOptionEpics from './components/TeamManagement/TeamOptions/epics'
import * as cardManagement from './components/CardManagement/epics'
import * as tileEpics from './components/Tiles/epics'
import * as trackerEpics from './components/Tracker/epics'
import * as uploadEpics from './components/Upload/epics'
import * as userEpics from './components/User/epics'
import * as userOptionEpics from './components/TeamManagement/UserOptions/epics'
import * as validatorEpics from './components/Validator/epics'
import * as vehicleTransferEpics from './components/Custom/VEHICLETRANSFER/epics'
import * as workflowEpics from './components/Workflows/epics'
import * as workflowGridEpics from './components/WorkflowGrid/epics'
import * as workflowPOAccessoriesEpics from './components/WorkflowPOAccessories/epics'
import * as workflowPOContractActivationChecklist from './components/WorkflowPOContractActivationChecklist/epics'
import * as workflowPOHeader from './components/WorkflowPOHeader/epics'
import * as workflowPOReceiptVehicle from './components/WorkflowPOReceiptVehicle/epics'
import * as workflowPOSupplier from './components/WorkflowPOSupplier/epics'
import * as workflowQueueTasksEpics from './components/WorkflowQueueTasks/epics'
import * as workflowQueueTaskDocumentsEpics from './components/WorkflowQueueTasks/WorkflowQueueTaskDocuments/epics'
import * as workflowQuoteHeader from './components/WorkflowQuoteHeader/epics'
import * as workflowQuoteItemGridEpics from './components/WorkflowQuoteItemGrid/epics'
import * as workflowTaskApprovalEpics from './components/WorkflowTaskApproval/epics'
import * as workflowTaskInputEpics from './components/WorkflowTaskInput/epics'
import * as workflowTimelineEpics from './components/WorkflowQueueTasks/WorkflowTimeline/epics'
import * as workflowVehicleHistoryEpics from './components/WorkflowQueueTasks/WorkflowVehicleHistory/epics'
import * as workflowDocumentsEpics from './components/WorkflowQueueTasks/WorkflowDocuments/epics'
import * as workflowDisposalEpics from './components/WorkflowQueueTasks/WorkflowDisposal/epics'
import * as workflowTriggerEpics from './components/WorkflowQueueTasks/WorkflowTriggers/epics'
import * as workflowViewFMLQuoteSummaryEpics from './components/WorkflowViewFMLQuoteSummary/epics'
import * as strItemGridEpics from './components/WorkflowSTRHeader/STRItemGrid/epics'
import * as supplierTransactionCustomEpic from './components/Custom/SUPPLIERTRANSACTIONCUSTOM/epics'
import * as purchaseOrderEpics from './components/Custom/PURCHASEORDER/epics'
import * as supplierCreditNoteEpic from './components/Custom/SUPPLIERCREDITNOTE/epics'
import * as supplierJournalEpic from './components/Custom/SUPPLIERJOURNAL/epics'
import * as generelledgercashbookEpic from './components/Custom/GENERALLEDGERCASHBOOK/epics'
import * as createMMQuote from './components/WorkflowManageMaintenance/CreateMMQuote/epics'
import * as ActivateMMContract from './components/WorkflowManageMaintenance/ActivateMMContract/epics'
import * as billingRunViewEpic from './components/WorkflowBillingRun/BillingRunView/epics'
import * as strContractActivationEpics from './components/WorkflowSTRHeader/STRContractActivation/epics'
import * as customerTransactionCustomEpic from './components/Custom/CUSTOMERTRANSACTIONCUSTOM/epics'
import * as disposalEpic from './components/Custom/DISPOSAL/epics'
import * as billingModuleEpic from './components/Custom/BILLINGMODULE/epics'
import * as customerContactCustomEpic from './components/Custom/CUSTOMERCONTACTCUSTOM/epics'
import * as contractTerminationEpic from './components/Custom/CONTRACTTERMINATION/epics'
import * as trialbalanceEpic from './components/Custom/TRIALBALANCE/epics'
import * as costCentreChangeEpic from './components/Custom/CUSTOMCOSTCENTRECHANGE/epics'
import * as registrationChangeEpic from './components/Custom/CUSTOMREGISTRATIONCHANGE/epics'
import * as accessoryEpic from './components/Custom/ACCESSORY/epics'
import * as customerderivativeEpic from './components/Custom/CUSTOMERDERIVATIVE/epics'
import * as workflowQuoteEditEpics from './components/QuoteEdit/epics'
import * as workflowDefleetEpic from './components/WorkflowDefleet/epics'
import * as businessIntelligence from './components/BusinessIntelligence/epics'
import * as workflowDefleetUploadChecksheetEpic from './components/WorkflowDefleet/DefleetUploadChecksheet/epics'
import * as workflowCreateQuotetEpic from './components/WorkflowFMLQuote/WorkflowCreateQuote/epics'
import * as disposalEpics from './components/Custom/DISPOSALDECISION/epics'
import * as strCreateQuoteEpics from './components/WorkflowSTRQuote/WorkflowCreateQuote/epics'
import * as strActivation from './components/WorkflowSTRQuote/WorkflowActivation/epics'
import * as paymentmanagement from './components/Custom/SUPPLIERPAYMENTMANAGEMENT/epics'
import * as paymentmanagementapproval from './components/Custom/SUPPLIERPAYMENTMANAGEMENTAPPROVAL/epics'
import * as supplierpaymentapproval from './components/Custom/SUPPLIERPAYMENTAPPROVAL/epics'
import * as supplierpaymentapprovalfinance from './components/Custom/SUPPLIERPAYMENTAPPROVALFINANCE/epics'

import * as cashBookEpics  from './components/Custom/CASH_BOOK/epics'
import * as supplierpaymentapprovalgm from './components/Custom/SUPPLIERPAYMENTAPPROVALGM/epics'
// import * as DashboardViewEpics from './components/BusinessIntelligence/DashboardView/epics'
import * as workflowDefleetUploadQuotesEpic from './components/WorkflowDefleet/DefleetUploadQuotes/epics'
import * as workflowDefleetCloseBookingEpic from './components/WorkflowDefleet/DefleetCloseBooking/epics'
import * as controlsEpics from './controls/epics'
import * as profileEpics from './components/Profile/epics'
import * as workflowDefleetCaptureQuoteEpic from './components/WorkflowDefleet/DefleetCaptureQuote/epics'
import * as workflowPaymentApprovalTechEpic from './components/WorkflowPayment/PaymentApprovalTech/epics'
import * as workflowPaymentApprovalOPSEpic from './components/WorkflowPayment/PaymentApprovalOPS/epics'
import * as workflowPaymentApprovalFinanceEpic from './components/WorkflowPayment/PaymentApprovalFinance/epics'
import * as workflowPaymentApprovalReleaseEpic from './components/WorkflowPayment/PaymentApprovalRelease/epics'
import * as workflowPaymentApprovalAuthorisationEpic from './components/WorkflowPayment/PaymentApprovalAuthorisation/epics'
import * as workflowPaymentAllocationEpic from './components/WorkflowPayment/PaymentAllocation/epics'
import * as billingPreviewEpics from './components/WorkflowBillingRun/BillingPreview/epics'
import * as billingRefreshEpics from './components/WorkflowBillingRun/BillingRefresh/epics'
import * as HooksEpic from './components/Hooks/epics'
import * as JsEpic from './components/JSReport/epics'
import * as WorkqueueEpic from './components/Workqueue/epics'
import * as releaseNoteEpic from './components/ReleaseNote/epics'
import * as financeImport from './components/FinanceImports/epics'
import * as CISepics from './components/CIS/epics'
import * as CreateRepEpics from './components/CreateReport/epics'
import * as billingweeklycis from './components/WorkflowBillingRun/BillingWeeklyCIS/epics'
import * as enterestReviewEpics from './components/WorkflowStatementRun/EnterestReview/epics'
import * as statementPreviewEpics from './components/WorkflowStatementRun/StatementPreview/epics'
import * as paymentCaptureEpics from './components/WorkflowCreditorsPayment/PaymentCapture/epics'
import * as purchaseOrderCapture from './components/WorkflowCreditorsPayment/PurchaseOrderCapture/epics'
import * as paymentItemEpics from './components/WorkflowCreditorsPayment/PaymentItem/epics'
import * as licenseeAccountEpics from './components/LicenseeAccount/epics'
import * as subAccountEpics from './components/SubAccount/epics'
import * as workflowSTRQuote from './components/WorkflowSTRQuote/epics'
import * as clientAccountEpic from './components/ClientAccount/epics'
import * as reportScheduler from './components/ReportScheduler/epics'
import * as eventEpics from './components/Events/epics'

import * as xeroEpic from './components/Xero/epics'
import * as relatedGridEpic from './components/RelatedGrid/epics'
import * as relatedForm from './components/RelatedForm/epics'
import * as resellerExpics from './components/Reseller/epics'
import * as budgetManagementEpics from './components/BudgetManagement/epics'
import * as workflowGanttChart from './components/WorkflowSTRQuote/WorkflowGanttChart/epics'
import * as teamManagerEpics from './components/TeamManagement/TeamManager/epics'
import * as createQuotationEpics from './components/WorkflowSTRQuote/CaptureQuotation/epics'
import * as createQuotationsEpics from './components/WorkflowSTRQuote/WorkflowCreateQuote/epics'
import * as strBookoutEpics from './components/WorkflowSTRQuote/WorkflowBookout/epics'
import * as CustomerDerivativeCategoryEpics from './components/CustomerDerivativeCategory/epics'
import * as teamStructureEpics from './components/TeamStructure/epics'
import * as workflowViewFMLQuoteConfirm from './components/WorkflowViewFMLQuoteConfirm/epics'
import * as workflowPOContractActivationEpics from './components/WorkflowPOContractActivation/epics'
import * as workflowPODelivery from './components/WorkflowPODelivery/epics'

import * as userStructureEpics from './components/UserStructure/epics'

import * as dashboardViewEpics from './components/DashboardView/epics'
import * as reportViewEpics from './components/ReportView/epics'
import * as supplierMasterCaptureEpics from './components/SupplierMaster/SupplierMasterCapture/epics'
import * as customerMasterCaptureEpics from './components/CustomerMaster/CustomerMasterCapture/epics'
import * as AccessoryMasterCaptureEpics from './components/AccessoryMaster/AccessoryMasterCapture/epics'
import * as VehicleMasterCaptureEpics from './components/VehicleMaster/VehicleMasterCapture/epics'

import * as costCentreChangeCaptureEpics from './components/CostCentreChange/CostCentreChangeCapture/epics'
import * as ImportEpics from './components/Import/epics'
import * as HierarchyEpics from './components/Hierarchy/epics'
import * as ManageComponentEpics from './components/TeamManagement/ManageComponent/epics'


import * as creditNoteEpics from './components/CreditNote/CreditNoteCreate/epics'
import * as BalanceSheet_RptEpics from './components/Custom/BALANCESHEET_RPT/epics'
import * as CashFlow_RptEpics from './components/Custom/CASHFLOW_RPT/epics'
import * as ProfitLoss_RptEpics from './components/Custom/PROFITLOSS_RPT/epics'
import * as accountItemLinkEpics from './components/Custom/ACCOUNTINGITEMLINK/epics'
import * as TrialBalance_RptEpics from './components/Custom/TRIALBALANCE_RPT/epics'
import * as creditorsPaymentEpics from './components/CreditorsPayment/CreditorsPaymentCreate/epics'
import * as interestRateCreateEpics from './components/InterestRate/InterestRateCreate/epics'
import * as cashbookArchiveEpics from './components/CashbookArchive/CashbookArchiveCreate/epics'
import * as customerBankReconEpics from './components/Custom/CUSTOMERBANKRECON/epics'
import * as supplierBankReconEpics from './components/Custom/SUPPLIERBANKRECON/epics'
import * as bankReconEpics from './components/Custom/BANK_RECONCILIATION/epics'
import * as quoteItemsEpic from './components/WorkflowPOHeader/QuoteItems/epics'
import * as eNatisEpics from './components/eNatis/epics'
import * as faqEpics from './components/FAQ/epics'
import * as workflowQuoteReviewEpics from './components/WorkflowQuoteReview/epics'
import * as salesProcessCaptureEpics from './components/SalesProcess/SalesProcessCapture/epics'

export default combineEpics(
    salesProcessCaptureEpics.getLookupData,
    workflowQuoteReviewEpics.getLookupData,
    eNatisEpics.getVehicleDetails,
    faqEpics.loadFAQ,
    bankReconEpics.getBankReconDataLookup,
    bankReconEpics.getBankReconReport,
    quoteItemsEpic.getQuoteitemLookupValues,
    customerBankReconEpics.getPaymentData,
    customerBankReconEpics.updMatchTransactions,
    customerBankReconEpics.updateTransactionsRecon,
    customerBankReconEpics.getTransactionWithSameAmount,

    supplierBankReconEpics.getPaymentData,
    supplierBankReconEpics.updMatchTransactions,
    supplierBankReconEpics.updateTransactionsRecon,
    supplierBankReconEpics.getTransactionWithSameAmount,
    cashbookArchiveEpics.getCashBookTransactionList,
    cashBookEpics.getCashBookReport,
    cashBookEpics.getCashBookLookup,
    interestRateCreateEpics.getPrimeRate,
    interestRateCreateEpics.getInterestRateLookupData,
    interestRateCreateEpics.getInterestRateContractsList,
    creditNoteEpics.getCreditNoteLookupData,
    creditNoteEpics.getCreditNoteSupplierTransaction,
    eNatisEpics.saveEnatisData,
    workflowPOContractActivationEpics.getCustomerData,
    workflowPOContractActivationEpics.getLookupData,
    strActivation.getCustomerData,
    strActivation.getLookupValueData,
    ActivateMMContract.getCustomerData,
    ActivateMMContract.getLookupValueData,
    accountItemLinkEpics.getCustomerList,
    accountItemLinkEpics.getSalesTypeList,
    accountItemLinkEpics.deLinkAcountingItem,
    accountItemLinkEpics.getAccountingItemList,
    accountItemLinkEpics.saveAccountingItemLink,
    accountItemLinkEpics.getAccountingItemLinkList,
    //supplierAllocationEpic.filterAllocationData,
    supplierAllocationEpic.updateSupplierTransaction,
    supplierAllocationEpic.saveSupplierAllocationEpic,
    supplierAllocationEpic.filterSupplierAllocationData,
    supplierAllocationEpic.unallocatedPaymentAllocationEpic,
    customerAllocationEpic.unallocatedPaymentAllocationEpic,
    supplierAllocationEpic.getSupplierTransactions,
    supplierAllocationEpic.getAllocationSupplier,

    creditorsPaymentEpics.getComponentSupplierTransaction,
    creditorsPaymentEpics.getLookupData,
    BalanceSheet_RptEpics.getBalancesheetData,
    CashFlow_RptEpics.getCashflowData,
    ProfitLoss_RptEpics.getProfitlossData,
    TrialBalance_RptEpics.getTrialbalanceData,
    HierarchyEpics.getUserCustomer,
    ManageComponentEpics.updateComponent,
    ManageComponentEpics.updateComponentField,
    ImportEpics.uploadFileData,
    ImportEpics.getTemplateList,
    costCentreChangeCaptureEpics.getLookupData,
    VehicleMasterCaptureEpics.getLookupData,
    AccessoryMasterCaptureEpics.getLookupData,
    AccessoryMasterCaptureEpics.getSupplierAccessories,
    customerMasterCaptureEpics.getLookupData,
    supplierMasterCaptureEpics.getLookupData,
    dashboardViewEpics.loadReports,
    reportViewEpics.loadReports,
    workflowPOContractActivationEpics.getLookupData,
    workflowPODelivery.getLookupData,
    workflowViewFMLQuoteConfirm.getLookupValueData,
    CustomerDerivativeCategoryEpics.getCategory,
    CustomerDerivativeCategoryEpics.delCategory,
    CustomerDerivativeCategoryEpics.saveCategory,
    CustomerDerivativeCategoryEpics.getLookupValueData,
    CustomerDerivativeCategoryEpics.getDerivativeRelated,
    CustomerDerivativeCategoryEpics.getOtherLookupValueData,
    CustomerDerivativeCategoryEpics.getMMCodeLookupValueData,
    strBookoutEpics.getYardlist,
    strBookoutEpics.getLookupValueData,
    strBookoutEpics.getSupplierRateCardData,
    createQuotationEpics.saveSupplier,
    createQuotationsEpics.getCustomerList,
    createQuotationEpics.getLookupValueData,
    createQuotationEpics.getRateCardVehicles,
    createQuotationEpics.addressSearch,
    createQuotationEpics.getDistance,
    userStructureEpics.getTeamsData,
    userStructureEpics.getCustomerData,
    userStructureEpics.getLocaleData,
    userStructureEpics.getClientsData,
    teamStructureEpics.getCustomerData,
    teamStructureEpics.getClientsData,
    teamStructureEpics.getTeamsData,
    teamManagerEpics.saveTeam,
    teamManagerEpics.getTreeViewData,
    teamManagerEpics.getComponentFieldLookupData,
    resellerExpics.saveReseller,
    resellerExpics.deleteReselller,
    resellerExpics.getComponentFieldLookupData,
    workflowGanttChart.saveReteCard,
    budgetManagementEpics.deleteBuget,
    budgetManagementEpics.getTreeViewData,
    budgetManagementEpics.getBudgetManagement,
    budgetManagementEpics.saveBudgetManagement,
    budgetManagementEpics.getComponentFieldLookupData,
    reportScheduler.getReports,
    reportScheduler.getReportCategories,
    reportScheduler.getSchedulerData,
    reportScheduler.saveScheduler,
    relatedGridEpic.saveComponentData,
    relatedGridEpic.getRelatedGridData,
    relatedGridEpic.delCustomerContact,
    relatedGridEpic.updCustomerContact,
    relatedGridEpic.addCustomerContact,
    clientAccountEpic.getComponentFieldLookupData,
    clientAccountEpic.saveClientAccount,
    clientAccountEpic.getAccount,
    eventEpics.getEscalation,
    eventEpics.getSchedule,
    eventEpics.getTeam,
    eventEpics.getOperator,
    eventEpics.getField,
    eventEpics.getUser,
    eventEpics.getCategory,
    eventEpics.getActions,
    eventEpics.getWorkflow,
    eventEpics.loadEvent,
    eventEpics.loadEventLogs,
    eventEpics.getComponent,
    eventEpics.getIndicator,
    eventEpics.getEventsData,
    eventEpics.getEscalationTypes,
    eventEpics.saveEvent,
    eventEpics.sendEmail,
    workflowSTRQuote.getDashboard,
    subAccountEpics.getComponentFieldLookupData,
    subAccountEpics.saveSubAccount,
    licenseeAccountEpics.getLicenseeAccount,
    licenseeAccountEpics.saveLicenseeData,
    workflowDisposalEpics.uploadImportData,
    paymentItemEpics.getLookupValues,
    paymentCaptureEpics.getSupplierAutoCompleteMenuItems,
    paymentCaptureEpics.getUserAutoCompleteMenuItems,
    CreateRepEpics.getSubData,
    CreateRepEpics.saveReport,
    CISepics.getCISData,
    CISepics.getCISEmailData,
    CISepics.addCISEmail,
    CISepics.removeCISEmail,
    CISepics.getUser,
    CISepics.getCisHistoryTable,
    financeImport.uploadFileData,
    enterestReviewEpics.getEnterestReviewData,
    statementPreviewEpics.getStatementPreviewData,
    billingPreviewEpics.getUserAutoCompleteMenuItems,
    billingPreviewEpics.updateBillingBccUsers,
    billingPreviewEpics.getInvoiceData,
    billingPreviewEpics.getBillingTracking,
    billingRefreshEpics.getBillingTracking,
    billingRefreshEpics.getGridData,
    xeroEpic.getContacts,
    xeroEpic.getInvoice,
    xeroEpic.getConsentUrl,
    WorkqueueEpic.getItemData,
    WorkqueueEpic.getFormData,
    WorkqueueEpic.getUser,
    WorkqueueEpic.UpdateEventAck,
    controlsEpics.getHierarchyEpic,
    JsEpic.getReportFilter,
    JsEpic.submitFilter,
    HooksEpic.signupAmplify,
    profileEpics.updateUsertDefaultSetting,
    signedInEpics.getDocumentPrevData,
    controlsEpics.getAuditData,
    controlsEpics.updateCustomerAddress,
    controlsEpics.getTreeSelectData,
    controlsEpics.getAutoCompleteDate,
    controlsEpics.getComponentVehicleDate,
    controlsEpics.getWorkflowTasks,
    controlsEpics.getComponentSupplierDate,
    controlsEpics.getVehicleMapoServicePlan,
    controlsEpics.getComponentCustomerDate,
    controlsEpics.getComponentCustomerAddress,
    controlsEpics.getComponentContractDate,
    controlsEpics.getComponentMaintenanceData,
    controlsEpics.getComponentMaintenanceDataByTaskNo,
    controlsEpics.getComponentMultiMaintenanceData,
    cardManagement.getCards,
    cardManagement.getCard,
    cardManagement.getMandateCategory,
    cardManagement.getVehicles,
    cardManagement.getCostCenter,
    cardManagement.getCardLookupData,
    cardManagement.getDrivers,
    cardManagement.postCardData,
    cardManagement.getCardTransaction,
    oqlisEpics.getDashboardEpic,
    oqlisEpics.getDashboardDataUrl,
    oqlisEpics.getReportToken,
    oqlisEpics.getKPIData,
    // DashboardViewEpics.getDashboardViews,
    // DashboardViewEpics.getDashboardViewData,
    strCreateQuoteEpics.getRateCard,
    strCreateQuoteEpics.getRateCardVehicles,
    strCreateQuoteEpics.getLookupValueData,
    customerderivativeEpic.getProductTypeLookupData,
    customerderivativeEpic.getContractTypeLookupData,
    customerderivativeEpic.getCustomerLookupData,
    customerderivativeEpic.getCustomerSettingData,
    customerderivativeEpic.saveComponentData,
    workflowCreateQuotetEpic.getFMLCreateQuoteInputookupDataEpic,
    workflowCreateQuotetEpic.getCustomerDerivative,
    workflowCreateQuotetEpic.getReplaceVehicleDetails,
    workflowCreateQuotetEpic.getVehicleCategoryChip,
    workflowCreateQuotetEpic.getListLookupData,
    businessIntelligence.getScheduler,
    businessIntelligence.saveSchedule,
    businessIntelligence.getReportCategory,
    businessIntelligence.getTeamReports,
    businessIntelligence.getReportGridData,
    accessoryEpic.getSupplierAccessoryData,
    accessoryEpic.removeSingleRow,
    accessoryEpic.updateSupplierCostPrice,
    accessoryEpic.updateSupplierSellingPrice,
    accessoryEpic.getLookupValueData,
    accessoryEpic.addSupplierPricingItem,
    accessoryEpic.getAccessoriesList,
    accessoryEpic.updateSelectedSupplierItem,
    accountEpics.getLocaleList,
    accountEpics.getUserAccountsEpic,
    accountEpics.updateUserAccountDefaultSetting,
    accountEpics.updateOrganisationSettings,
    adhocComponentFieldInputEpics.getAdhocFieldInputData,
    adhocGridEpics.adhocGridGetDataEpic,
    adhocGridEpics.adhocGridGetComponentDataEpic,
    appEpics.configureAppEpic,
    assignedTaskCountEpics.getAssignedTaskCountEpic,
    assignedTasksEpics.getAssignedTasksEpic,
    calculatorEpics.getVehicles,
    calendar.getEvents,
    calendar.getTasks,
    captureSTR.getLookupValueData,
    clientsEpics.getClientsList,
    cognitoEpics.authenticateCognitoUserEpic,
    cognitoEpics.configureCognitoEpic,
    cognitoEpics.confirmRegistrationEpic,
    cognitoEpics.createCognitoUserEpic,
    cognitoEpics.getCurrentCognitoUser,
    cognitoEpics.getCurrentCognitoUserEpic,
    cognitoEpics.processCognitoUserRegistration,
    cognitoEpics.getCognitoUserAuthenticationDetails,
    cognitoEpics.getCognitoUserAuthenticationKeeplive,
    cognitoEpics.updateUserAuthenticationDetails,
    cognitoEpics.getCognitoUserNewPasswordDetails,
    cognitoEpics.registrationConfirmedEpic,
    cognitoEpics.resendRegistrationConfirmationCodeEpic,
    cognitoEpics.setCognitoUserDataEpic,
    cognitoEpics.setDeviceStatusEpic,
    cognitoEpics.signOutCognitoUserEpic,
    cognitoEpics.startCognitoUserAuthenticationEpic,
    cognitoEpics.startCognitoUserRegistrationEpic,
    cognitoEpics.submitCognitoUserForgotPassword,
    cognitoEpics.submitCognitoUserNewPassword,
    completedTaskCountEpics.getCompletedTaskCountEpic,
    componentEpics.getComponentEpic,
    componentEpics.getComponentNamesEpic,
    connectorEpics.connectorEpic,
    contractRestructureEpics.getAccessories,
    contractRestructureEpics.getContractParemets,
    contractRestructureEpics.getSupplierList,
    contractRestructureEpics.getAllContractParemets,
    contractRestructureEpics.getVehicleAmort,
    contractRestructureEpics.getAmortisationClientEntry,
    contractSelectionGridEpics.getRestructureGridData,
    customerAllocationEpic.updateCustomerTransaction,
    customerAllocationEpic.saveAllocationEpic,
    customerAllocationEpic.filterAllocationData,
    customerAllocationEpic.getCustomerTransactions,
    customerAllocationEpic.getAllocationCustomers,
    customerCreditNoteEpic.getBusinessList,
    customerCreditNoteEpic.getCreditNoteList,
    customerCreditNoteEpic.getDebtorsContraAccountList,
    customerCreditNoteEpic.getDebtorsList,
    customerCreditNoteEpic.getUnitMeasureList,
    customerCreditNoteEpic.saveCreditNoteEpic,
    // customerInvoiceEpic.getBusinessList,
    // customerInvoiceEpic.getDebtorsContraAccountList,
    // customerInvoiceEpic.getDebtorsList,
    // customerInvoiceEpic.getInvoiceList,
    // customerInvoiceEpic.getUnitMeasureList,
    // customerInvoiceEpic.saveInvoiceEpic,
    // customerInvoiceEpic.getTransactionTypeList,
    customerTransactionEpic.getBusinessList,
    customerTransactionEpic.getDebtorsContraAccountList,
    customerTransactionEpic.getDebtorsList,
    customerTransactionEpic.getVehicleList,
    customerTransactionEpic.getInvoiceList,
    customerTransactionEpic.getInvoiceDetails,
    customerTransactionEpic.getUnitMeasureList,
    customerTransactionEpic.saveInvoiceEpic,
    customerTransactionEpic.getTransactionTypeList,
    customerJournalEpic.getBusinessList,
    customerJournalEpic.getDebtorsContraAccountList,
    customerJournalEpic.getDebtorsList,
    customerJournalEpic.getJournalList,
    customerJournalEpic.saveJournalEpic,
    customerReceiptEpic.getBusinessList,
    customerReceiptEpic.getContraAccountList,
    customerReceiptEpic.getDebtorsContraAccountList,
    customerReceiptEpic.getDebtorsList,
    customerReceiptEpic.getReceiptList,
    customerReceiptEpic.saveReceiptEpic,
    customerReceiptEpic.getReceiptMonthListEpic,
    customerReceiptEpic.getReceiptDataListEpic,
    customerReceiptEpic.getBusinessUnitList,
    customerStatementEpic.getCustomerList,
    customerReverseReceiptEpic.getBusinessList,
    customerReverseReceiptEpic.getContraAccountList,
    customerReverseReceiptEpic.getDebtorsList,
    customerReverseReceiptEpic.getReverseReceiptList,
    customerReverseReceiptEpic.saveReversedReceiptEpic,
    documentEpics.getDocumentTypesEpic,
    manageWorkFlowTaskEpics.getWorkFlowTasksEpic,
    manageWorkFlowTaskEpics.updateWorkflowTaskUser,
    formEpics.getComponentData,
    formEpics.getComponentFieldLookupData,
    formEpics.getComponentRelationships,
    formEpics.getComponentSchema,
    formEpics.saveComponentData,
    formEpics.getTreeViewData,
    formEpics.saveUserCustomer,
    relatedForm.getComponentData,
    relatedForm.getComponentFieldLookupDataContact,
    relatedForm.getComponentFieldLookupData,
    relatedForm.getComponentRelatedRelationships,
    relatedForm.getComponentSchema,
    relatedForm.saveComponentData,
    relatedForm.getTreeViewData,
    gridEpics.getComponentGridData,
    gridEpics.getClientGridData,
    gridEpics.getExcelData,
    gridEpics.deleteRow,
    gridEpics.updateDowntimeVehicle,
    gridEpics.getGridExportDataExcel,
    invoiceap.getContraaccountinvoiceAutoCompleteEpic,
    invoiceap.getPurchaseorderinvoiceAutoCompleteEpic,
    invoiceap.getSupplierAutoCompleteEpic,
    licenceRenewalEpics.getVehicles,
    licenceRenewalEpics.getCustomer,
    licenceRenewalEpics.finalizeVehicleTransferEpic,
    loadOnDemandEpics.getLODComponentRelationshipsEpic,
    loadOnDemandEpics.loadOnDemand,
    multiselectEpics.getListEpics,
    navigationMenuEpics.getNavigationMenuEpic,
    navigationMenuEpics.getStaticMenuEpic,
    navigationMenuEpics.getUserNotificationsEpic,
    navigationMenuEpics.getDocument360Token,
    poBookingInvoiceEpics.getMMCodeByVIN,
    poBookingInvoiceEpics.getSupplierListData,
    poBookingInvoiceEpics.getServicePartBasket,
    poBookingItems.getLookupValues,
    poBookingItems.getRelatedRowItem,
    poBookingItems.getRelatedRowItemMax,
    poBookingItems.getMaintenanceItem,
    poHeader.getSelectedSupplierDetails,
    poHeader.getSelectedVehicleDetails,
    poHeader.checkDuplicateByClass,
    poHeader.checkDuplicate,
    poHeader.getSupplierAge,
    poHeader.getMaintenanceClass,
    poHeader.getSupplierTypes,
    poHeader.getVehicleListAutoCompleteMenuItems,
    poHeader.setSupplierAutoCompleteMenuItems,
    poHeader.setUserAutoCompleteMenuItems,
    poHeader.getCustomerContactsAutoCompleteItems,
    poHeader.getSupplierContactsAutoCompleteItems,
    queuedTaskCountEpics.getQueuedTaskCountEpic,
    queuedTasksEpics.getQueuedTasksEpic,
    quoteHeader.getMMCodeDetails,
    quoteHeader.getMMCodeVehicles,
    quoteHeader.getNonRateCardVehicle,
    quoteHeader.getYardList,
    quoteHeader.searchByMMCode,
    quoteHeader.getPrimerate,
    quoteHeader.getMMCodeToGrid,
    quoteHeader.getMMCodeList,
    quoteHeaderSTR.getMMCodeVehicles,
    quoteHeaderSTR.getRateCardDetails,
    quoteHeaderSTR.getStockVehicleDetails,
    quoteHeaderSTR.getSTRVehicleDetails,
    quoteSelectGridEpics.getComponentGridData,
    quoteSelectGridEpics.getQuoteDetails,
    quoteSelectGridEpics.getQuoteDocumentList,
    readComponentEpics.getRelatedComponentData,
    readComponentEpics.setReadComponentData,
    reassignEpics.getTeamsEpic,
    reassignEpics.getTaskEpic,
    //recaptchaEpic.recaptchaEpic,
    //requestEpics.requestGetEpic,
    //requestEpics.requestPostEpic,
    releaseNoteEpic.savePublishReleaseNote,
    releaseNoteEpic.updateReadNote,
    releaseNoteEpic.updateUserReadNote,
    releaseNoteEpic.getReleaseNote,
    signedInEpics.getDashboardListEpic,
    signedInEpics.getWorkqueueList,
    signedInEpics.getBusinessCustomerHierarchy,
    supplierPaymentAllocationEpic.getAllocationCreditorsList,
    supplierPaymentAllocationEpic.savePaymentAllocationEpic,
    supplierPaymentAllocationEpic.unallocatedPaymentAllocationEpic,
    supplierTransactionEpic.getBusinessList,
    supplierTransactionEpic.getContraAccountList,
    supplierTransactionEpic.getCreditorsList,
    supplierTransactionEpic.getDeliveryChargeAccountList,
    supplierTransactionEpic.getDiscountAccountList,
    supplierTransactionEpic.getExistingInvoiceEpic,
    supplierTransactionEpic.getItemAccountList,
    supplierTransactionEpic.getSupplierInvoiceList,
    supplierTransactionEpic.getUnitMeasureList,
    supplierTransactionEpic.saveInvoiceEpic,
    supplierTransactionEpic.getTransactionTypeList,
    supplierTransactionEpic.getInvoiceDetails,
    supplierPaymentEpic.getPaymentAccountList,
    supplierPaymentEpic.getPaymentBusinessList,
    supplierPaymentEpic.getPaymentCreditorsList,
    supplierPaymentEpic.getPaymentDiscountAccountList,
    supplierPaymentEpic.getSupplierPaymentList,
    supplierPaymentEpic.savePaymentEpic,
    supplierPaymentReversalEpic.getPaymentAccountList,
    supplierPaymentReversalEpic.getPaymentBusinessList,
    supplierPaymentReversalEpic.getPaymentCreditorsList,
    supplierPaymentReversalEpic.getPaymentDiscountAccountList,
    supplierPaymentReversalEpic.getReversedPaymentList,
    supplierPaymentReversalEpic.savePaymentReversalEpic,
    teamEpics.getTeamsList,
    teamOptionEpics.getAllUserCustomersEpic,
    teamOptionEpics.assignUserToCustomerEpics,
    teamOptionEpics.unassignUserToCustomerEpics,
    teamOptionEpics.getAccountBusinessCustomersEpic,
    teamOptionEpics.getAccountBusinessesEpic,
    teamOptionEpics.getAllCustomersEpic,
    teamOptionEpics.getAllAccountsEpic,
    teamOptionEpics.getAllComponentsEpic,
    teamOptionEpics.getAllTeamsEpic,
    teamOptionEpics.getAllUsersEpic,
    teamOptionEpics.getTeamAccountBusinessCustomersEpic,
    teamOptionEpics.getTeamAccountBusinessesEpic,
    teamOptionEpics.getTeamAccountsEpic,
    teamOptionEpics.getTeamComponentsEpic,
    teamOptionEpics.getTeamUsersEpic,
    teamOptionEpics.getLinkedTeamAndUser,
    teamOptionEpics.saveTeamAndUserChangesEpics,
    teamOptionEpics.saveTeamAssignmentChangesEpic,
    teamOptionEpics.getAllReportsEpic,
    teamOptionEpics.getTeamReportsEpic,
    teamOptionEpics.getEventsEpic,
    teamOptionEpics.saveTeamManagementChangesEpic,
    tileEpics.getComponentTileData,
    trackerEpics.getTrackingData,
    trackerEpics.locationSearch,
    uploadEpics.getLookupDataValues,
    userEpics.getUser,
    userEpics.getUserCustomer,
    uploadEpics.apiFileUploader,
    profileEpics.getDashboardList,
    userOptionEpics.saveUserData,
    userOptionEpics.getAllUsersEpic,
    userOptionEpics.resetUserPassword,
    userOptionEpics.unlinkUserTeam,
    userOptionEpics.saveUserManagementChanges,
    userOptionEpics.getComponentListLookupData,
    userOptionEpics.getUsersTeamEpic,
    validatorEpics.validateFieldEpic,
    validatorEpics.validateFormEpic,
    vehicleTransferEpics.getVehicles,
    vehicleTransferEpics.getCustomer,
    vehicleTransferEpics.finalizeVehicleTransferEpic,
    workflowEpics.getWorkflowEpic,
    workflowEpics.getWorkflowNamesEpic,
    workflowGridEpics.getComponentGridData,
    workflowPOAccessoriesEpics.getLookupData,
    workflowPOContractActivationChecklist.getFieldLookupData,
    workflowPOContractActivationChecklist.getMMCodeCPK,
    workflowPOContractActivationChecklist.getCurrentInterestRate,
    workflowPOHeader.getComponentFieldLookupData,
    workflowPOHeader.getQuoteDetails,
    workflowPOReceiptVehicle.getLookupData,
    workflowPOSupplier.getComponentFieldLookupData,
    workflowPOSupplier.getSupplierDetails,
    workflowQueueTasksEpics.getTeamsWorkflowQueueTasksEpic,
    workflowQueueTaskDocumentsEpics.getWorkflowQueueTaskDocumentsEpic,
    workflowQuoteHeader.getCategoryChipAccessories,
    workflowQuoteHeader.getComponentFieldLookupData,
    workflowQuoteHeader.getComponentFieldLookupValue,
    workflowQuoteHeader.getContractMandatoryAccessory,
    workflowQuoteHeader.getContractMandatoryService,
    workflowQuoteHeader.getContractMandatorySundry,
    workflowQuoteHeader.getContractTypesLookupValue,
    workflowQuoteHeader.getMMLookupValue,
    workflowQuoteHeader.getCustomerDerivativeLookupValue,
    workflowQuoteHeader.getCustomerDerivativeCategoryMetaData,
    workflowQuoteHeader.genBulkQuotation,
    workflowQuoteHeader.getLookupData,
    workflowQuoteHeader.getCustomerDerivativeCategoryLookupValue,
    workflowQuoteHeader.getContractVehicleOptions,
    workflowQuoteHeader.getRateCard,
    workflowQuoteHeader.getRatecardMMCodes,
    workflowQuoteHeader.getVehicleContractDetails,
    workflowQuoteHeader.searchWorkings,
    workflowQuoteHeader.getContractTypeDetails,
    workflowQuoteItemGridEpics.getLookupData,
    workflowQuoteItemGridEpics.getLookupItemsData,
    workflowQuoteItemGridEpics.loadChipAccessory,
    //workflowQuoteItemGridEpics.getQuoteComponentItems,
    //workflowQuoteItemGridEpics.getQuoteTypesComponents,
    workflowTaskApprovalEpics.getWorkflowTaskApprovalInformationEpic,
    workflowTaskApprovalEpics.getWorkflowTaskConnectorDataEpic,
    workflowTaskApprovalEpics.getWorkflowTaskApprovalDataEpic,
    workflowTaskApprovalEpics.getNextTeamUserListEpic,
    workflowTaskInputEpics.getWorkflowTaskActionDataEpic,
    workflowTaskInputEpics.getWorkflowTaskConnectorDataEpic,
    workflowTaskInputEpics.getWorkflowTaskInformationEpic,
    workflowTaskInputEpics.getWorkflowTaskTeamsEpic,
    workflowTimelineEpics.getWorkflowTimelineEpic,
    workflowTimelineEpics.assignToMe,
    workflowTimelineEpics.unAssign,
    workflowTriggerEpics.getWorkflowTriggersEpic,
    workflowViewFMLQuoteSummaryEpics.getSupplierContats,
    workflowViewFMLQuoteSummaryEpics.getSuppliersList,
    strItemGridEpics.getComponentGridData,
    supplierTransactionCustomEpic.getSupplierList,
    purchaseOrderEpics.approvePO,
    purchaseOrderEpics.rejectPO,
    purchaseOrderEpics.uploadPODocuments,
    purchaseOrderEpics.getposupplierlist,
    disposalEpics.approvePO,
    disposalEpics.rejectPO,
    disposalEpics.uploadPODocuments,
    disposalEpics.getposupplierlist,
    disposalEpics.getApprovalInvoice,
    supplierCreditNoteEpic.getCreditorsList,
    supplierCreditNoteEpic.getBusinessList,
    supplierCreditNoteEpic.getCreditorsContraAccountList,
    supplierCreditNoteEpic.saveCreditNoteEpic,
    supplierCreditNoteEpic.getCreditNoteList,
    supplierJournalEpic.getCreditorsList,
    supplierJournalEpic.getDebtorsContraAccountList,
    supplierJournalEpic.getBusinessList,
    supplierJournalEpic.saveJournalEpic,
    supplierJournalEpic.getJournalList,
    generelledgercashbookEpic.getContraAccountList,
    generelledgercashbookEpic.getLinkNumberList,
    generelledgercashbookEpic.getLinkNumberTrans,
    createMMQuote.getServiceData,
    createMMQuote.getLookupValueData,
    generelledgercashbookEpic.saveGLCashBookEpic,
    generelledgercashbookEpic.getLinkNumberTrans,
    billingRunViewEpic.loadBillingData,
    strContractActivationEpics.getMMcodeLookupData,
    customerTransactionCustomEpic.getCustomerList,
    disposalEpic.getVehicleList,
    disposalEpic.getVehicleDetails,
    disposalEpic.saveDisposalEpic,
    disposalEpic.getTerminationListEpic,
    disposalEpic.approveTerminationEpic,
    workflowVehicleHistoryEpics.getWorkflowVehicleHistoryEpic,
    workflowVehicleHistoryEpics.getWorkflowVehicleListEpic,
    workflowDocumentsEpics.getWorkflowDocsVehicleListEpic,
    workflowDocumentsEpics.searchDocumentsEpic,
    workflowDisposalEpics.getDisposalList,
    workflowDisposalEpics.submitSeeingStatus,
    workflowDisposalEpics.postDisposalData,
    workflowDisposalEpics.getBidders,
    workflowDisposalEpics.getOTP,
    workflowDisposalEpics.submitAndCloseComment,
    billingModuleEpic.getBillingList,
    billingModuleEpic.getFMLBillingInvoiceList,
    billingModuleEpic.getSTRBillingInvoiceList,
    billingModuleEpic.exportBillingList,
    billingModuleEpic.approvebilling,
    customerTransactionCustomEpic.getalltransactions,
    customerTransactionCustomEpic.getInvoiceQueryTypeList,
    customerTransactionCustomEpic.savedata,
    customerContactCustomEpic.getallcontacts,
    customerContactCustomEpic.getCustomerList,
    customerContactCustomEpic.getContactList,
    customerContactCustomEpic.linkContact,
    customerContactCustomEpic.removeContact,
    customerContactCustomEpic.getContactTypeList,
    customerContactCustomEpic.getTitleList,
    customerContactCustomEpic.newContact,
    customerStatementEpic.getallstatementcontacts,
    contractTerminationEpic.getVehicleList,
    contractTerminationEpic.getVehicleDetails,
    contractTerminationEpic.saveTerminationEpic,
    contractTerminationEpic.getTerminationListEpic,
    contractTerminationEpic.approveTerminationEpic,
    contractTerminationEpic.cancelTerminationEpic,
    customerContactCustomEpic.updateContact,
    trialbalanceEpic.getMonthList,
    trialbalanceEpic.getTrialBalance,
    costCentreChangeEpic.getCostCentreList,
    costCentreChangeEpic.getVehicleList,
    costCentreChangeEpic.getVehicleDetails,
    costCentreChangeEpic.saveCostCentreChangeEpic,
    costCentreChangeEpic.getCostCentreChangeListEpic,
    costCentreChangeEpic.cancelCostCentreChangeEpic,
    costCentreChangeEpic.approveCostCentreChangeEpic,
    registrationChangeEpic.getVehicleList,
    registrationChangeEpic.getVehicleDetails,
    registrationChangeEpic.saveRegistrationChangeEpic,
    registrationChangeEpic.cancelRegistrationChangeEpic,
    registrationChangeEpic.approveRegistrationChangeEpic,
    registrationChangeEpic.getRegistrationChangeListEpic,
    workflowDefleetEpic.getVehicleListAutoCompleteMenuItems,
    workflowDefleetUploadChecksheetEpic.getSelectedVehicleDetails,
    workflowDefleetUploadChecksheetEpic.getDefleetLocation,
    workflowDefleetEpic.getSelectedVehicleContract,
    workflowDefleetEpic.getDefleetTypes,
    paymentmanagement.toggleItem,
    paymentmanagement.getBusinessList,
    paymentmanagement.approveItem,
    paymentmanagementapproval.toggleItem,
    paymentmanagementapproval.getBusinessList,
    paymentmanagementapproval.approveItem,
    //supplierpaymentapproval.approveItem,
    supplierpaymentapprovalfinance.approveItem,
    supplierpaymentapprovalfinance.toggleItem,
    supplierpaymentapprovalfinance.updateItem,
    //supplierpaymentapproval.toggleItem,
    paymentmanagement.processPayment,
    supplierpaymentapprovalgm.approveItem,
    supplierpaymentapprovalgm.toggleItem,
    supplierpaymentapprovalgm.updateItem,
    paymentmanagement.updateItem,
    paymentmanagement.updateItemAdmin,
    paymentmanagement.approveItemAdmin,
    paymentmanagement.toggleItemAdmin,
    workflowDefleetUploadQuotesEpic.getSupplierListData,
    paymentmanagement.toggleDontPayItem,
    paymentmanagement.documentCheck,
    paymentmanagement.getPaymentList,
    paymentmanagement.getSupplierList,
    paymentmanagement.toggleShortPayItem,
    paymentmanagement.toggleCreditItem,
    workflowDefleetCloseBookingEpic.getBusinessDays,
    paymentmanagement.getPaymentListAdmin,
    paymentmanagement.linkDocuments,
    paymentmanagement.getPaymentListMaint,
    paymentmanagement.getPaymentListTyres,
   // supplierpaymentapproval.updateItem,
    supplierpaymentapprovalgm.getPaymentList,
    paymentmanagement.getCommitData,
    workflowDefleetCaptureQuoteEpic.getSupplierAutoCompleteMenuItems,
    workflowDefleetCaptureQuoteEpic.getSelectedSupplierDetails,
    workflowDefleetCaptureQuoteEpic.getSelectedSupplierDetailsDefleet,
    workflowPaymentApprovalTechEpic.updatePaymentData,
    workflowPaymentApprovalTechEpic.getPaymentList,
    workflowPaymentApprovalOPSEpic.updatePaymentData,
    workflowPaymentApprovalOPSEpic.getPaymentList,
    workflowPaymentApprovalFinanceEpic.updatePaymentData,
    workflowPaymentApprovalFinanceEpic.getPaymentList,
    workflowPaymentApprovalReleaseEpic.getPaymentList,
    workflowPaymentApprovalReleaseEpic.submitPayment,
    workflowPaymentApprovalReleaseEpic.updatePaymentData,
    workflowPaymentApprovalAuthorisationEpic.getPaymentList,
    workflowPaymentApprovalAuthorisationEpic.updatePaymentData,
    workflowPaymentAllocationEpic.getPaymentList,
    workflowPaymentAllocationEpic.updatePaymentData,
    JsEpic.getJSReportData,
    quoteItems.getExceptions,
    quoteItems.getRelatedRowItemMax,
    customerStatementEpic.gettimetz,
    customerStatementEpic.posttimetz,
    billingweeklycis.getCISContactsAutoCompleteItems,
    workflowQuoteItemGridEpics.getSupplierData,
    workflowQuoteItemGridEpics.getSupplierDataAccessoryData,
    workflowQuoteItemGridEpics.getSupplierDistanceData,
    workflowQuoteItemGridEpics.getSupplierAccessoryHistoryData,
    controlsEpics.getSupplierAccessoryHistoryData,
    controlsEpics.getComponentQuoteData

)