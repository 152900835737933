import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system'
import _ from 'lodash'
import moment from 'moment'
import ViewQuotation from '../../../controls/view_quotation'

import { Card, CardHeader, CardContent } from '@mui/material'

import Icon from '../../Icon/component'
import Loading from '../../Loading/component'
import PdfViewer from '../../PDF/component'

import AdhocComponentFieldInput from '../../Custom/AdhocComponentFieldInput/component'
import * as styles from './styles'
import * as actions from './actions'

class CreateMMQuoteConfirm extends Component {
    componentDidMount() {
        //this.props.actions.setStartdate()
        //this.props.actions.setValidationState(this.props.state.validation)

        const meta = [
            {
                header: true,
                title: 'Vehicle',
                description: 'Vehicle',
                meta_data: this.props.state.vehicle.value
            },
            {
                header: false,
                title: 'Customer',
                description: 'Customer',
                meta_data: this.props.state.customer.value
            }
        ]

            this.props.actions.setupWorkflowTaskInput(this.props.state, meta, 'customerrentalagreement', 'tsk_mm_capture_quote')
    }

    CustomerDetails() {
        const data = this.props.state.customer_data.filter(x => x.customer_id === this.props.state.customer_id.value)
        const customer = data[0]
        return data.length
            ? <div>
                <Card>
                    <CardHeader
                        actAsExpander={false}
                        showExpandableButton={false}
                        title={window.t.en('Contract Summary')}
                        subtitle={window.t.en('Contract details.')}
                        icon={<Icon iclass={'custom-icons'} iname={'vehicle_category'} />}
                        style={styles.row}
                    >
                    </CardHeader>
                    <CardContent>
                        <Container style={{ margin: 0 }} className="custom-container">
                            <Row>
                                <Col xl={2} style={styles.fieldLabel}>{'Customer Name:'}</Col>
                                <Col xl={2} style={styles.selectedFieldName}>{customer.customername}</Col>
                                <Col xl={2} style={styles.fieldLabel}>{'Business Unit:'}</Col>
                                <Col xl={2} style={styles.selectedFieldName}>{customer.businessunit}</Col>

                                <Col xl={2} style={styles.fieldLabel}>{'Cost Centre:'}</Col>
                                <Col xl={2} style={styles.selectedFieldName}>{customer.accountnumber}</Col>
                                <Col xl={2} style={styles.fieldLabel}>{'Cost Centre:'}</Col>
                                <Col xl={2} style={styles.selectedFieldName}>{customer.costcentre}</Col>

                                <Col xl={2} style={styles.fieldLabel}>{'Account Number:'}</Col>
                                <Col xl={2} style={styles.selectedFieldName}>{customer.accountnumber}</Col>
                                <Col xl={2} style={styles.fieldLabel}>{'VAT Number:'}</Col>
                                <Col xl={2} style={styles.selectedFieldName}>{customer.vatnumber}</Col>

                                <Col xl={2} style={styles.fieldLabel}>{'Customer Active:'}</Col>
                                <Col xl={2} style={styles.selectedFieldName}>{customer.customeractive ? 'Active' : 'Not Active'}</Col>
                                <Col xl={2} style={styles.fieldLabel}>{'Customer Suspended:'}</Col>
                                <Col xl={2} style={styles.selectedFieldName}>{customer.customersuspended ? 'Suspended' : 'Not Suspended'}</Col>
                            </Row>
                        <Row>
                            <Col xl={12} ><br></br></Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <AdhocComponentFieldInput
                                    title={window.t.en('')}
                                    description={''}
                                    icon={''}
                                    id={'customercontact'}
                                    parent={'customercontact'}
                                    parentFilters={[
                                        {
                                            field: 'customer_id',
                                            value: customer.customer_id
                                        }
                                    ]}
                                    child={'contact'}
                                    childFilters={[
                                        {
                                            field: 'contacttype',
                                            value: ''
                                        }
                                    ]}
                                    extractChildData={true}
                                    adhocFieldInput={[ //assumes Parent component fields if extractChild is set to false
                                        'fullname', 'email', 'mobile', 'contacttype'
                                    ]}
                                    selectMode={false}
                                    addMode={false}
                                />
                            </Col>
                        </Row>
                        </Container>
                    </CardContent>
                </Card>
            </div>
            : ''
    }

    Vehicledetails() {
        return <div>
                <Card>
                    <CardHeader
                        actAsExpander={false}
                        showExpandableButton={false}
                        title={window.t.en('Vehicle Summary')}
                        subtitle={window.t.en('Contract details.')}
                        icon={<Icon iclass={'custom-icons'} iname={'vehicle_category'} />}
                        style={styles.row}
                    >
                    </CardHeader>
                    <CardContent>
                        <Container style={{ margin: 0 }} className="custom-container">
                            <Row>
                                <Col xl={2} style={styles.fieldLabel}>{'MM Code:'}</Col>
                                <Col xl={2} style={styles.selectedFieldName}>{this.props.state.mmcode.value}</Col>
                                <Col xl={2} style={styles.fieldLabel}>{'Year Model:'}</Col>
                                <Col xl={2} style={styles.selectedFieldName}>{this.props.state.yearmodel.value}</Col>
                                <Col xl={2} style={styles.fieldLabel}>{'Vehicle Registration:'}</Col>
                                <Col xl={2} style={styles.selectedFieldName}>{this.props.state.licenseplateno.value}</Col>
                                <Col xl={2} style={styles.fieldLabel}>{'Vehicle Register:'}</Col>
                                <Col xl={2} style={styles.selectedFieldName}>{this.props.state.licensediskno.value}</Col>
                                <Col xl={2} style={styles.fieldLabel}>{'License Expiry:'}</Col>
                                <Col xl={2} style={styles.selectedFieldName}>{this.props.state.licenseexpiry.value}</Col>
                                <Col xl={2} style={styles.fieldLabel}>{'Color:'}</Col>
                                <Col xl={2} style={styles.selectedFieldName}>{this.props.state.color.value}</Col>
                            </Row>
                        </Container>
                    </CardContent>
                </Card>
            </div>
    }

    AccessoriesList() {
        const data = this.props.state.mm_service_data.filter(x => x.checked)

        window.glyco.log('AccessoriesList')
        window.glyco.log(data)
        return data.length
            ? <Card style={styles.workflowTaskStepComponentCard} expandable={false}>
                <CardHeader
                    title={window.t.en('Accessory List')}
                    subtitle={window.t.en('Selected vehicle accessories')}
                    actAsExpander={false}
                    style={styles.row}
                />
                <CardContent expandable={false}>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row style={styles.headerrow}>
                            <Col xl={8}>
                                {'Item Description'}
                            </Col>
                            <Col xl={2}>
                                {'Cost Price'}
                            </Col>
                            <Col xl={2}>
                                {'Selling Price'}
                            </Col>
                        </Row>
                        {data.map((x, i) => {
                            return <Row style={styles.itemrow}>
                                <Col xl={8}>
                                    {x.servicedescription}
                                </Col>
                                <Col xl={2} style={styles.numericFields}>
                                    {parseFloat(x.costprice.toString()).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                </Col>
                                <Col xl={2} style={styles.numericFields}>
                                    {parseFloat(x.sellingprice.toString()).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                </Col>
                            </Row>
                        })
                        }
                    </Container>
                </CardContent>
            </Card>
            : ''
    }

    ContractDetails() {
        const data = this.props.state
        return <div>
                <Card>
                    <CardHeader
                        actAsExpander={false}
                        showExpandableButton={false}
                        title={window.t.en('Contract Summary')}
                        subtitle={window.t.en('Contract details.')}
                        icon={<Icon iclass={'custom-icons'} iname={'vehicle_category'} />}
                        style={styles.row}
                    >
                    </CardHeader>
                    <CardContent>
                        <Container style={{ margin: 0 }} className="custom-container">
                            <Row>
                                <Col xl={2} style={styles.fieldLabel}>{'Contract Type:'}</Col>
                                <Col xl={2} style={styles.selectedFieldName}>{this.props.state.contracttype.value}</Col>
                                <Col xl={2} style={styles.fieldLabel}>{'Customer:'}</Col>
                                <Col xl={2} style={styles.selectedFieldName}>{this.props.state.customer.value}</Col>
                                <Col xl={2} style={styles.fieldLabel}>{'Contract period:'}</Col>
                                <Col xl={2} style={styles.selectedFieldName}>{this.props.state.period.value}</Col>
                                <Col xl={2} style={styles.fieldLabel}>{'Start Date:'}</Col>
                                <Col xl={2} style={styles.selectedFieldName}>{this.props.state.startdate.value}</Col>
                                <Col xl={2} style={styles.fieldLabel}>{'End Date:'}</Col>
                                <Col xl={2} style={styles.selectedFieldName}>{this.props.state.enddate.value}</Col>
                                <Col xl={2} style={styles.fieldLabel}>{'Quotation Notes:'}</Col>
                                <Col xl={2} style={styles.selectedFieldName}>{this.props.state.quotationnotes.value}</Col>
                            </Row>
                        </Container>
                    </CardContent>
                </Card>
            </div>
    }

    RentalSummary() {
        const data = this.props.state
        return <div>
                <Card>
                    <CardHeader
                        actAsExpander={false}
                        showExpandableButton={false}
                        title={window.t.en('Rental Summary')}
                        subtitle={window.t.en('Contract details.')}
                        icon={<Icon iclass={'custom-icons'} iname={'vehicle_category'} />}
                        style={styles.row}
                    >
                    </CardHeader>
                    <CardContent>
                        <Container style={{ margin: 0 }} className="custom-container">
                            <Row>
                                <Col xl={2} style={styles.fieldLabel}>{'Sub Total:'}</Col>
                                <Col xl={2} style={styles.selectedFieldName}>
                                {parseFloat(data.monthly_excl.value.toString()).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                </Col>
                                <Col xl={2} style={styles.fieldLabel}>{'VAT Amount:'}</Col>
                                <Col xl={2} style={styles.selectedFieldName}>
                                {parseFloat(data.monthly_vat.value.toString()).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                </Col>
                                <Col xl={2} style={styles.fieldLabel}>{'Total Monthly:'}</Col>
                                <Col xl={2} style={styles.selectedFieldName}>
                                {parseFloat(data.monthly_incl.value.toString()).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                </Col>
                            </Row>
                        </Container>
                    </CardContent>
                </Card>
            </div>
    }

    documentsList() {
        const documents = this.props.state.upload.documents
        return documents.length
            ? <Card>
                <CardHeader
                    actAsExpander={false}
                    showExpandableButton={false}
                    title={window.t.en('Documents')}
                    subtitle={window.t.en('Documents list')}
                    style={styles.row}
                >
                </CardHeader>
                <CardContent>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={12}>
                                {
                                    documents
                                        ? <PdfViewer
                                            key={'quotation_viewer_'}
                                            documents={documents}
                                            multiple={true}
                                            height={500}
                                            edit={false}
                                        />
                                        : <Loading message={'Retrieving Purchase Order document...'} />
                                }
                            </Col>
                        </Row>
                    </Container>
                </CardContent>
            </Card>
            : ''
    }
  
    renderView() {
        return <div>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={12}>
                                {this.CustomerDetails()}
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                {this.Vehicledetails()}
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                {this.ContractDetails()}
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                {this.AccessoriesList()}
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                {this.RentalSummary()}
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                {this.documentsList()}
                            </Col>
                        </Row>
                    </Container>
        </div>
    }

    render() {

        window.glyco.log('CaptureSTRConfirm')
        return (
            this.renderView()
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.createMMQuote,
            upload: {
                ...state.upload
            },
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateMMQuoteConfirm)