import * as colours from '@mui/material/colors'
import Theme from '../../theme'

// export const back = {
//     cursor: 'pointer',
//     marginTop: 15,
//     marginLeft: 15,
//     width: 30,
//     height: 30,
//     // backgroundColor: Theme.red[500]
// }

// export const badge = {
//     paddingTop: '5px',
//     paddingLeft: '8px',
//     height: '20px',
//     width: '20px',
//     borderRadius: '50%',
//     float: 'right',
//     top: '-50px',
//     backgroundColor: colours.red[500],
// }

// export const divider = {
//     marginTop: 15,
//     marginBottom: 15
// }

// export const theme = Theme

// export const drawer = {
//     //padding: 5,//
//     fontSize: '10px',
//     width: '375px',
//     backgroundColor: 'green !important'
//     //opacity: 0.95,
//     //position: 'fixed',
//     //top: '100px'
// }

// export const scrollbar = {
//     backgroundColor: colours.red[500],
//     width: 10
// }

// export const tabs = {
//     height: '100%',
//     //backgroundColor: colours.red[500]
// }

// export const tab = {
//     height: '65px',
//     // backgroundColor: colours.red[500]
// }

// export const liPrimary = {
//     fontSize: '15px',
//     fontWeight: 'bold',
// }

// export const subPrimary = {
//     fontSize: '15px',
//     fontWeight: 'bold',
//     //backgroundColor:  colours.grey[400]
// }
// export const mmHeader = {
//     fontSize: '14px',
//     fontWeight: 'bold',
// }

// export const mmItem = {
//     fontSize: '12px',
//     //backgroundColor: 'gray',
// }

// export const liHeader = {
//     //fontSize: '15px',
//     ////backgroundColor: '#dcd6d7',
//     //borderBottom: '1px solid red'
// }

// export const li = {
//     //fontSize: '15px',
//     //borderBottom: '1px solid red'
// }

// export const menuIcon = {
//     //fontSize: 16,
//     /*     width: 10,
//         height: 10 */
// }

// export const icon = {
//     // color: colours.red[500],
//     // fontSize: '35px',
//     // marginTop: '5px'
// }

// export const icon_sm = {
//     color: colours.grey[500],
//     fontSize: '20px',
//     marginTop: '10px'
// }


  export const taskCounterNewTask = {
    display: 'flex',
    background: 'transparent', /* A light blue background */
    borderRadius: 'none',
    padding: '5px 5px 5px 5px',
    //border: '1px solid #FF8686', /* Light border color */
    width: '50%',
    //'-webkit-box-shadow': '#ff868666 0px 2px 14px 0px',
    //'-moz-box-shadow': '#ff868666 0px 2px 14px 0px',
    //boxShadow: '#ff868666 0px 2px 14px 0px',
  }

  export const taskCounterAssignedTask = {
    display: 'flex',
    background: 'transparent', /* A light blue background */
    borderRadius: 'none',
    padding: '5px 5px 5px 5px',
    //border: '1px solid #FAB400', /* Light border color */
    width: '50%',
    // '-webkit-box-shadow': '#fab4007a 0px 2px 14px 0px',
    // '-moz-box-shadow': '#fab4007a 0px 2px 14px 0px',
    // boxShadow: '#fab4007a 0px 2px 14px 0px',
  }

  export const taskCounterCompletedTask = {
    display: 'flex',
    background: 'transparent', /* A light blue background */
    borderRadius: 'none',
    padding: '5px 5px 5px 5px',
    border: '1px solid #AFDC99', /* Light border color */
    width: '50%',
    '-webkit-box-shadow': '#afdc999c 0px 2px 14px 0px',
    '-moz-box-shadow': '#afdc999c 0px 2px 14px 0px',
    boxShadow: '#afdc999c 0px 2px 14px 0px',
  }

  // export const taskCounterNewTask = {
  //   display: 'flex',
  //   background: 'transparent', /* A light blue background */
  //   borderRadius: 'none',
  //   padding: '5px 5px 5px 5px',
  //   //border: '1px solid #FF8686', /* Light border color */
  //   width: '50%',
  //   //'-webkit-box-shadow': '#ff868666 0px 2px 14px 0px',
  //   //'-moz-box-shadow': '#ff868666 0px 2px 14px 0px',
  //   //boxShadow: '#ff868666 0px 2px 14px 0px',
  // }

  // export const taskCounterAssignedTask = {
  //   display: 'flex',
  //   background: 'transparent', /* A light blue background */
  //   borderRadius: 'none',
  //   padding: '5px 5px 5px 5px',
  //   //border: '1px solid #FAB400', /* Light border color */
  //   width: '50%',
  //   // '-webkit-box-shadow': '#fab4007a 0px 2px 14px 0px',
  //   // '-moz-box-shadow': '#fab4007a 0px 2px 14px 0px',
  //   // boxShadow: '#fab4007a 0px 2px 14px 0px',
  // }

  // export const taskCounterCompletedTask = {
  //   display: 'flex',
  //   background: 'transparent', /* A light blue background */
  //   borderRadius: 'none',
  //   padding: '5px 5px 5px 5px',
  //   border: '1px solid #AFDC99', /* Light border color */
  //   width: '50%',
  //   '-webkit-box-shadow': '#afdc999c 0px 2px 14px 0px',
  //   '-moz-box-shadow': '#afdc999c 0px 2px 14px 0px',
  //   boxShadow: '#afdc999c 0px 2px 14px 0px',
  // }
  
  export const counterContainer = {
    display: 'flex',
    alignItems: 'center',
  }
  
  export const counterCircle = (isworkflow, inx) => {
  return isworkflow ? {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: ['#FF8686','#FAB400','#AFDC99'][inx], /* Blue background for the circle */
      borderRadius: '100%',
      padding: '5px 5px 5px 5px',
      border: '5px solid #FFFEFE',
      color: '#fff',
      //boxShadow: 'rgba(32, 40, 45, 39%) 0px 2px 14px 0px', 
      width: '60px', /* Circle size */
      height: '60px',
      marginRight: '5px'
  }
  :
  {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#2C3C8F', /* Blue background for the circle */
    borderRadius: '100%',
    padding: '5px 5px 5px 5px',
    border: '5px solid #FFFEFE',
    //boxShadow: 'rgba(32, 40, 45, 39%) 0px 2px 14px 0px', 
    width: '60px', /* Circle size */
    height: '60px',
    marginRight: '5px'
  }
  }
  
export const taskCounter = (isworkflow, inx, len) => {
  let size = (100/len) + '%'
  return isworkflow ? {
      display: 'flex',
      backgroundColor: ['#fff5f6','#fffbf2','#e5f9ed'][inx],
      //background: 'transparent', /* A light blue background */
      borderRadius: '20px',
      padding: '5px 5px 5px 30px',
      // border: '1px solid #2947AE', /* Light border color */
      width: size,
      margin: '5px'
      // '-webkit-box-shadow': 'rgba(32, 40, 45, 39%) 0px 2px 14px 0px',
      // '-moz-box-shadow': 'rgba(32, 40, 45, 39%) 0px 2px 14px 0px',
      // boxShadow: 'rgba(32, 40, 45, 39%) 0px 2px 14px 0px',
  }:{
    
    display: 'flex',
    backgroundColor: '#eaf6ff',
    //background: 'transparent', /* A light blue background */
    borderRadius: '20px',
    padding: '5px 5px 5px 30px',
    // border: '1px solid #2947AE', /* Light border color */
    width: size,
    margin: '5px'
    // '-webkit-box-shadow': 'rgba(32, 40, 45, 39%) 0px 2px 14px 0px',
    // '-moz-box-shadow': 'rgba(32, 40, 45, 39%) 0px 2px 14px 0px',
    // boxShadow: 'rgba(32, 40, 45, 39%) 0px 2px 14px 0px',
  }
}
  export const counterCircleRed = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#f83245 !important', /* Blue background for the circle */
    borderRadius: '100%',
    padding: '5px 5px 5px 5px',
    border: '5px solid #FFFEFE',
    boxShadow: 'rgba(32, 40, 45, 39%) 0px 2px 14px 0px', 
    width: '60px', /* Circle size */
    height: '60px',
    marginRight: '5px'
  }

  export const counterCircleNewTask = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#FF8686', /* Blue background for the circle */
    borderRadius: '100%',
    padding: '5px',
    // border: '5px solid #FFFEFE',
    boxShadow: '#ff868666 0px 0px 15px 0px', 
    width: '60px', /* Circle size */
    height: '60px',
    marginRight: '15px'
  }

  export const counterCircleAssignedTask = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#FAB400', /* Blue background for the circle */
    borderRadius: '100%',
    padding: '5px',
    border: '5px solid #FFFEFE',
    boxShadow: '#fab4007a 0px 0px 15px 0px', 
    width: '60px', /* Circle size */
    height: '60px',
    marginRight: '15px'
  }

  export const counterCircleCompletedTask = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#AFDC99', /* Blue background for the circle */
    borderRadius: '100%',
    padding: '5px',
    border: '5px solid #FFFEFE',
    boxShadow: '#afdc999c 0px 0px 15px 0px', 
    width: '60px', /* Circle size */
    height: '60px',
    marginRight: '15px'
  }

  export const kpiTextWrapper = {
    display: 'flex',
    flexDirection: 'column'
  }
  
  export const counterNumber = {
    color: '#2947AE', /* White text for the number */
    fontWeight: 100,
    fontSize: '20px', /* Larger font size for the number */
  }

  export const counterNumberNewTask = {
    color: '#FF8686', /* White text for the number */
    fontWeight: 100,
    fontSize: '20px', /* Larger font size for the number */
  }

  export const counterNumberAssignedTask = {
    color: '#FAB400', /* White text for the number */
    fontWeight: 100,
    fontSize: '20px', /* Larger font size for the number */
  }

  export const counterNumberCompletedTask = {
    color: '#AFDC99', /* White text for the number */
    fontWeight: 100,
    fontSize: '20px', /* Larger font size for the number */
  }
  
  export const counterText = {
    color: '#2947AE', /* Blue text matching the circle */
    fontWeight: 300,
    fontSize: '18px', /* Font size for the text */
  }

  export const counterTextNewTask = {
    color: '#FF8686', /* Blue text matching the circle */
    fontWeight: 300,
    fontSize: '18px', /* Font size for the text */
  }

  export const counterTextAssignedTask = {
    color: '#FAB400', /* Blue text matching the circle */
    fontWeight: 300,
    fontSize: '18px', /* Font size for the text */
  }

  export const counterTextCompletedTask = {
    color: '#AFDC99', /* Blue text matching the circle */
    fontWeight: 300,
    fontSize: '18px', /* Font size for the text */
  }