import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Tabs,Box, Tab, Button, List, ListItem, Tooltip, Dialog, TextField, IconButton, Checkbox } from '@mui/material';
import { Container, Row, Col } from 'react-grid-system'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import VisibilityIcon from '@mui/icons-material/Visibility';

import MultiSelect from '../../controls/multiselect'
import AutoComplete from '../../controls/autocomplete'
import DateControl from '../../controls/dateControl'
import Card from '../../controls/card'
import Fields from '../../controls/cardfields'

import Form from './form'
import * as actions from './actions'
import * as styles from './styles'

class CUSTOMERDERIVATIVECATEGORY extends Component {
    
    componentDidMount() {
        this.props.actions.getCategory()
    }

    getVehicleCategory() {
        return  <div className="text-centeroff">
            <div>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                            <Col xl={2}  key={'col_add'}>
                                <Button
                                    onClick={(event) => {
                                        event.preventDefault()
                                        this.props.actions.addBankCategory(this.props.state.customerderivative.original.customerderivative_id)
                                    }}
                                    className="btn-pill-off">
                                    <span className="btn-wrapper--label">Vehicle Category</span>
                                </Button>
                            </Col>
                            <Col xl={10} ></Col>
                            <Col xl={12}>
                            {
                                this.props.state.vehicle_category.length 
                                ? <Table className="table table-alternate-spaced mb-0" >
                                    <thead className="thead-light text-capitalize font-size-sm font-weight-bold">
                                        {/* <th className="text-left px-4" style={{width: "50px !important"}}></th> */}
                                        <th className="text-left"></th>
                                    </thead>
                                    <tbody style={{zIndex:1}}>
                                        {
                                                this.props.state.vehicle_category.map((x, inx) => {
                                                    
                                                    return <tr>
                                                        <td>
                                                        <Container style={{ margin: 0 }} className="custom-container">
                                                            {
                                                                x.editing
                                                                ? <Row>
                                                                    <Col xl={4}>
                                                                        <TextField
                                                                            variant="outlined"
                                                                            margin="normal"
                                                                            fullWidth={true}
                                                                            id={'txt_customerderivativecategory_' + inx}
                                                                            label={'Enter Category Name'}
                                                                            value={x.customerderivativecategory}
                                                                            style={{marginTop: 0}}
                                                                            onChange={(event, value) => {
                                                                                this.props.actions.setListValue({inx: inx, prop: 'customerderivativecategory', value: event.target.value})
                                                                            }}
                                                                        />
                                                                    </Col>
                                                                    <Col xl={4}>
                                                                        <TextField
                                                                            variant="outlined"
                                                                            margin="normal"
                                                                            fullWidth={true}
                                                                            id={'txt_customerderivativecategorydescription_' + inx}
                                                                            label={'Enter Category Description'}
                                                                            value={x.customerderivativecategorydescription}
                                                                            style={{marginTop: 0}}
                                                                            onChange={(event, value) => {
                                                                                this.props.actions.setListValue({inx: inx, prop: 'customerderivativecategorydescription', value: event.target.value})
                                                                            }}
                                                                        />
                                                                    </Col>
                                                                    <Col xl={3}>
                                                                        <AutoComplete
                                                                            name={'field_' + inx}
                                                                            title={'Select Category Type'}
                                                                            data={this.props.state.category_mode}
                                                                            value={x.categorymode}
                                                                            dataSourceConfig={{
                                                                                text: 'text',
                                                                                value: 'value'
                                                                            }}
                                                                            onSelect={(args) => {
                                                                                this.props.actions.setListValue({inx: inx, prop: 'categorymode', value: args.text})
                                                                            }}
                                                                            onClick={() => console.log('')}
                                                                            onFilter={(args) => console.log('')} 
                                                                            />
                                                                    </Col>
                                                                    <Col xl={1}>
                                                                        <IconButton 
                                                                            key={'command_remove_' + inx} style={styles.delButton} iconStyle={styles.action}
                                                                            onClick={
                                                                                () => {
                                                                                    //console.log('saveCategory x', x)
                                                                                    this.props.actions.saveCategory(x)
                                                                                    this.props.actions.setListValue({inx: inx, prop: 'editing', value: false})
                                                                                }
                                                                            }>
                                                                            <SaveIcon />
                                                                        </IconButton>
                                                                        &nbsp;&nbsp;
                                                                        <IconButton 
                                                                            key={'command_remove_' + inx} style={styles.delButton} iconStyle={styles.action}
                                                                            onClick={
                                                                                () => {
                                                                                    //console.log('saveCategory x', x)
                                                                                    this.props.actions.setListValue({inx: inx, prop: 'editing', value: false})
                                                                                }
                                                                            }>
                                                                            <CloseIcon />
                                                                        </IconButton>
                                                                    </Col>
                                                                </Row>
                                                                : <Row>
                                                                <Col xl={4}>
                                                                    {x.customerderivativecategory}
                                                                </Col>
                                                                <Col xl={4}>
                                                                    {x.customerderivativecategorydescription}
                                                                </Col>
                                                                <Col xl={3}>
                                                                    {x.categorymode}
                                                                </Col>
                                                                <Col xl={1}>
                                                                {
                                                                    x.config
                                                                    ? <div>
                                                                        <IconButton key={'command_close_' + x.inx} style={styles.delButton} iconStyle={styles.action}
                                                                            onClick={
                                                                                () => {
                                                                                    this.props.actions.toggleListValue({inx: inx, prop: 'vehicle_category', value: false})
                                                                                }
                                                                            }>
                                                                            <CloseIcon />
                                                                        </IconButton>
                                                                        &nbsp;&nbsp;
                                                                    </div>
                                                                    : <div>
                                                                        <IconButton key={'command_view_' + inx} style={styles.delButton} iconStyle={styles.action}
                                                                            onClick={
                                                                                () => {  
                                                                                    this.props.actions.toggleListValue({inx: inx, prop: 'vehicle_category', value: true})
                                                                                }
                                                                            }>
                                                                            <VisibilityIcon />
                                                                        </IconButton>
                                                                        &nbsp;&nbsp;
                                                                        <IconButton key={'command_edit_' + x.inx} style={styles.delButton} iconStyle={styles.action}
                                                                            onClick={
                                                                                () => {
                                                                                    this.props.actions.setListValue({inx: inx, prop: 'editing', value: true})
                                                                                }
                                                                            }>
                                                                            <EditIcon />
                                                                        </IconButton>
                                                                        &nbsp;&nbsp;
                                                                        <IconButton key={'command_delete_' + inx} style={styles.delButton} iconStyle={styles.action}
                                                                            onClick={
                                                                                () => {  
                                                                                    this.props.actions.delCategory(x)
                                                                                }
                                                                            }>
                                                                            <DeleteForeverIcon />
                                                                        </IconButton>
                                                                    </div>
                                                                }
                                                                </Col>
                                                                {
                                                                    x.config
                                                                    ? <Col xl={12}>
                                                                        <Form id={x.customerderivativecategory_id} />
                                                                    </Col>
                                                                    : ''
                                                                }
                                                            </Row>
                                                        }
                                                        </Container>
                                                        </td>
                                                    </tr>                               
                                            })
                                        }
                                    </tbody>
                                </Table>
                                : <h4></h4>
                            }
                            </Col>
                    </Row>
                </Container>
            </div>
        </div>
    }

    getLayout() {
        return <div>
                <Card title={window.t.en('Manage Category')} 
                subtitle={<div>{'Manage customer derivative categories'}</div>}
                content={this.getVehicleCategory()} />
            </div>
    }

    render() {
        return this.getLayout()
    }
}

const mapStateToProps = (state, ownProps) => {
    //console.log('mapStateToProps',ownProps)
    return {
        state: {
            ...state.customerDerivativeCategory,
            customerderivative: {
                ...state.components.customerderivative.selected[0]  //customerderivative.original.customerderivative_id
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CUSTOMERDERIVATIVECATEGORY)