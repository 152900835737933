export const ASSIGN_TASK = 'ASSIGN_TASK'
export const HANDLE_TASK = 'HANDLE_TASK'
export const UNASSIGN_TASK = 'UNASSIGN_TASK'
export const CANCEL_TASK = 'CANCEL_TASK'
export const REJECT_TASK = 'REJECT_TASK'
export const REASSIGN_TASK = 'REASSIGN_TASK'
export const CLEAR_WORKFLOW_QUEUE_TASKS = 'CLEAR_WORKFLOW_QUEUE_TASKS'
export const GET_WORKFLOW_QUEUE_TASK_TEAMS = 'GET_WORKFLOW_QUEUE_TASK_TEAMS'
export const SET_WORKFLOW_QUEUE_TASK_TEAMS = 'SET_WORKFLOW_QUEUE_TASK_TEAMS'
export const REFRESH_TEAM_DATA = 'REFRESH_TEAM_DATA'
export const SET_WORKFLOW_QUEUE_TASKS_SEARCH_VALUE = 'SET_WORKFLOW_QUEUE_TASKS_SEARCH_VALUE'
export const SET_WORKFLOW_QUEUE_TASKS_ACTIVE_TEAM = 'SET_WORKFLOW_QUEUE_TASKS_ACTIVE_TEAM'
export const SET_WORKFLOW_QUEUE_TASKS_DATE_SEARCH = 'SET_WORKFLOW_QUEUE_TASKS_DATE_SEARCH'
export const SEARCH_WORKFLOW_QUEUE_TASKS = 'SEARCH_WORKFLOW_QUEUE_TASKS'
export const SEARCH_WORKFLOW_QUEUE_TASKS_BOUNCE = 'SEARCH_WORKFLOW_QUEUE_TASKS_BOUNCE'
export const SET_WORKFLOW_QUEUE_TASKS_SEARCH_RESULTS = 'SET_WORKFLOW_QUEUE_TASKS_SEARCH_RESULTS'
export const SET_CURRENT_WORKFLOW_ACTIVE_TAB = 'SET_CURRENT_WORKFLOW_ACTIVE_TAB'
export const SET_CURRENT_WORKFLOW_TASKS_COLLAPSE = 'SET_CURRENT_WORKFLOW_TASKS_COLLAPSE'
export const SET_FILTER_DATE = 'SET_FILTER_DATE'

export const SET_COMPONENT_OPEN_CLOSE_OPTIONS = 'SET_COMPONENT_OPEN_CLOSE_OPTIONS'
export const SET_COMPONENT_OPEN_CLOSE_SEARCH = 'SET_COMPONENT_OPEN_CLOSE_SEARCH'
export const SET_COMPONENT_OPEN_CLOSE_SETTINGS = 'SET_COMPONENT_OPEN_CLOSE_SETTINGS'
export const SET_WORKFLOW_QUEUE_TASK_CURRENT_VIEW = 'SET_WORKFLOW_QUEUE_TASK_CURRENT_VIEW'
