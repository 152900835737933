import * as props from './props'
import * as types from './types'
import * as requestTypes from '../Request/types'

export default (state = props, action) => {
    switch (action.type) {
        case types.PROGRESS:
            return {
                ...state,
                display: action.payload
            }
        case requestTypes.GET:
            return {
                ...state,
                display: false
            }
        case requestTypes.POST:
            return {
                ...state,
                display: false
            }
        case requestTypes.REQUESTING:
            return {
                ...state,
                display: true
            }
        case requestTypes.CANCEL_REQUESTS:
            return {
                ...state,
                display: false
            }
        case requestTypes.REQUEST_SUCCEEDED:
            return {
                ...state,
                display: false
            }
        case requestTypes.REQUEST_FAILED:
            return {
                ...state,
                display: false
            }
        default:
            return state
    }
}