export const SET_BOOKING__INVOICE_COMPONENT_FIELD_INPUT_VALUE = 'SET_BOOKING__INVOICE_COMPONENT_FIELD_INPUT_VALUE'
export const CLEAR_DEALER_BOOKING_INVOCE_DOCUMENT_PREVIEW = 'CLEAR_DEALER_BOOKING_INVOCE_DOCUMENT_PREVIEW'
export const SET_DEALER_BOOKING_INVOCE_DOCUMENT_PREVIEW = 'SET_DEALER_BOOKING_INVOCE_DOCUMENT_PREVIEW'
export const UPLOAD_DEALER_BOOKING_INVOCE_DOCUMENT = 'UPLOAD_DEALER_BOOKING_INVOCE_DOCUMENT'

export const RESET_COMPONENT = 'RESET_COMPONENT'
export const GET_VEHICLE_LASTPOSITION = 'GET_VEHICLE_LASTPOSITION'
export const SET_VEHICLE_LASTPOSITION = 'SET_VEHICLE_LASTPOSITION'
export const DOWNLOAD_DOCUMENT_DISCOUNT_LETTER = 'DOWNLOAD_DOCUMENT_DISCOUNT_LETTER'
export const SET_DISCOUNT_LETTER_DOCUMENT_PREVIEW = 'SET_DISCOUNT_LETTER_DOCUMENT_PREVIEW'
export const SET_BOOKING_VEHICLE_SERVICE_PART_BASKET = 'SET_BOOKING_VEHICLE_SERVICE_PART_BASKET'
export const GET_BOOKING_VEHICLE_SERVICE_PART_BASKET = 'GET_BOOKING_VEHICLE_SERVICE_PART_BASKET'
export const GET_MAPO_VEHICLE_MMCODE_BY_VIN_NUMBER = 'GET_MAPO_VEHICLE_MMCODE_BY_VIN_NUMBER'
export const GET_BOOKING_VEHICLE_CONTRACT_DEREVATIVE = 'GET_BOOKING_VEHICLE_CONTRACT_DEREVATIVE'
export const SET_BOOKING_VEHICLE_CONTRACT_DEREVATIVE = 'SET_BOOKING_VEHICLE_CONTRACT_DEREVATIVE'

export const GET_COMPONENT_QUOTATION_SUPPLIERS_LIST = 'GET_COMPONENT_QUOTATION_SUPPLIERS_LIST'
export const SET_COMPONENT_QUOTATION_SUPPLIERS_LIST = 'SET_COMPONENT_QUOTATION_SUPPLIERS_LIST'
export const SET_COMPONENT_QUOTATION_SELECTED_SUPPLIER = 'SET_COMPONENT_QUOTATION_SELECTED_SUPPLIER'

export const SET_BOOKING__INVOICE_COMPONENT_FIELD_INPUT_VALIDATE = 'SET_BOOKING__INVOICE_COMPONENT_FIELD_INPUT_VALIDATE'
export const SET_COMPONENT_QUOTATION_SELECTED_SUPPLIER_NAME = 'SET_COMPONENT_QUOTATION_SELECTED_SUPPLIER_NAME'
export const TOGGLE_SUPPLIER_LIMIT_EXCEEDED_WIN = 'TOGGLE_SUPPLIER_LIMIT_EXCEEDED_WIN'

export const SET_COMPONENT_PO_QUOTE_FIELD_INPUT_VALUE = 'SET_COMPONENT_PO_QUOTE_FIELD_INPUT_VALUE'
export const GET_COMPONENT_BOOKING_DOCUMENTS_TYPES_LOOKUP_DATA = 'GET_COMPONENT_BOOKING_DOCUMENTS_TYPES_LOOKUP_DATA'