import React, { Component, useEffect  } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { useParams  } from "react-router-dom";
import * as styles from './styles'
import * as componentActions from '../../components/Components/actions'

    
const GeneralContentView = (props) => {   
    const _params = useParams(); 
    
    useEffect(() => {
        if(_params.component)
            props.actions.getComponent(_params.component)
    },[]);

   // render() {
        return (
            <div >
                {/* <div style={styles.spacer}></div> */}
                {
                    props.title 
                    ? <div style={styles.headerBackground(props.headerImage)}>
                    <div style={styles.headerContainer}>
                        <div className={'title_workflow_card'} style={styles.headerTitle}>{props.title}</div>
                    </div>
                </div>
                    : ''
                }
                
                <div style={styles.content(props.contentHeight)}>
                    <div className={'title_workflow_card_'}>
                         {props.content}
                    </div>
                    
                </div>
            </div>
        )
    //}
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.searchComponent
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...componentActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralContentView)