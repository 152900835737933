import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Calendar, Views, dateFnsLocalizer } from 'react-big-calendar';

import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';

import * as actions from './actions'
import * as styles from './styles'

let allViews = Object.keys(Views).map((k) => Views[k]);

const ColoredDateCellWrapper = ({ children }) =>
  React.cloneElement(React.Children.only(children), {
    style: {
      backgroundColor: '#eaf6ff'
    }
  });

const locales = {
  'en-US': require('date-fns/locale/en-US')
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales
});
class EventCalendar extends Component {
    getLayout() {
        return <>
          <Calendar
            localizer={localizer}
            views={allViews}
            step={60}
            showMultiDayTimes
            defaultDate={new Date()}
            components={{
              timeSlotWrapper: ColoredDateCellWrapper
            }}
            events={this.props.state.workqueue_calendar ? this.props.state.workqueue_calendar : []}
            startAccessor="start"
            endAccessor="end"
            style={{ minHeight: 650 }}
          />
        </>
    }

    render() {
        return this.getLayout()
    }
}

const mapStateToProps = (state) => {
    return {
        state: { 
            ...state.events
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EventCalendar)