import React, { Component } from 'react'

import WorkflowTaskAction from '../WorkflowTaskAction/component'
import WorkflowTaskApproval from '../WorkflowTaskApproval/component'
import WorkflowTaskInput from '../WorkflowTaskInput/component'

import GeneralContentView from '../../views/GeneralContentView/component'

class WorkflowTaskView extends Component {
    getWorkflowTaskTitle() {
        return this.props.match.path.indexOf('/input') > -1
            ? 'Workflow Task Input'
            : this.props.match.path.indexOf('/action') > -1
                ? 'Workflow Task Action'
                : this.props.match.path.indexOf('/approval') > -1
                    ? 'Workflow Task Approval'
                    : 'Unknown Workflow Task Type'
    }

    getWorkflowTaskView() {
        return <div>
            {
                this.props.match.path.indexOf('/input') > -1
                    ? <WorkflowTaskInput match={this.props.match} />
                    : this.props.match.path.indexOf('/action') > -1
                        ? <WorkflowTaskAction match={this.props.match} />
                        : this.props.match.path.indexOf('/approval') > -1
                            ? <WorkflowTaskApproval match={this.props.match} />
                            : <div />
            }

            <br />
            <br />
        </div>
    }

    render() {
        return (
            <GeneralContentView
                //title={this.getWorkflowTaskTitle()}
                headerImage={''}
                content={this.getWorkflowTaskView()}
            />
        )
    }
}

export default WorkflowTaskView