import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Loading from '../Loading/component'


import * as actions from './actions'
import * as readComponentActions from '../ReadComponent/actions'
import * as gridActions from '../Grid/actions'
import * as redirectActions from '../Redirect/actions'
import * as detailComponentViewActions from '../../views/DetailComponentView/actions'
import * as userOptionsActions from './UserOptions/actions'
import * as teamOptionActions from './TeamOptions/actions'

import {
    Card,
    CardHeader,
     CardContent,
    IconButton,
    TextField,
    Button,
} from '@mui/material'

import Icon from '../Icon/component'
import AutoComplete from '../../controls/autocomplete'

import * as styles from '../Grid/styles'
import * as teamStyles from './TeamOptions/styles'

import ReactTable from "react-table"

class UserGrid extends Component{

    constructor(){
        super();
        this.state = {
          expanded: {},
        };
    }
    componentWillMount(){

    }
    getCommands(row) {
        const id = row.original[this.component.component_field.filter(x => x.key)[0].name]
        let pkey = this.component.component_field.filter(x => x.key)[0].name
        let commands = []
        commands.push(
            <IconButton key={'command_view_' + this.component.name + '_' + id} style={styles.iconButton} iconStyle={styles.action}
                onClick={
                    () => {
                        this.props.actions.setReadComponentView('view', this.component.name)

                        this.props.actions.changeDetailComponentTab(0)
                        this.props.actions.clearDetailComponentVisualisations()

                        this.props.actions.route(
                            '/view/' + this.component.name + '/' + id
                        )
                    }
                }>
                <Icon istyle={{ fontSize: '22px', color: '#253053' }} iclass={'material-icons'} iname={'pageview'} />
            </IconButton>
        )
        commands.push(
            <IconButton key={'delete_forever_' + this.component.name + '_' + id} style={styles.iconButton} iconStyle={styles.action}
                onClick={
                    () => {
                        this.props.actions.confirmDelete({[pkey]: id})
                    }
                }>
                <Icon istyle={{ fontSize: '22px', color: '#253053' }} iclass={'material-icons'} iname={'delete_forever'} />
            </IconButton>
        )
        return commands
    }
    getValue(row,related){
        if(related){
            return row.value
        }
        else{
            const field = this.component.component_field.filter(x => x.name === row.column.meta)[0]
            switch (field.component_field_data_type.name){
                case 'boolean':
                    const toggle = field.toggle && field.toggle !== null ? field.toggle.split(',') : row.value

                    return row.value !== null
                        ? row.value
                            ? toggle[0]
                            : toggle[1]
                        : 'Not Set'
                default:
                    return row.value
            }
        }
    }
    getFormFieldInput(row) {
        let index = row.index
        let pkey = this.component.component_field.filter(x => x.key)[0].name
        const field = this.component.component_field.filter(x => x.name === row.column.meta)[0]
        return <TextField
                    key={field.name + '_' + index}
                    variant="filled"
                    size="small"
                    value={this.component.grid.data[index][field.name]} 
                    onChange={
                        (event) => {
                            this.props.actions.updComponentFieldInput(this.component,{[pkey]:row.original[pkey],[field.name]:event.target.value}, index, field.name, event.target.value)
                        }}
                    onBlur={
                        (event) => {
                            this.props.actions.updComponentFieldInput(this.component,{[pkey]:row.original[pkey],[field.name]:event.target.value}, index, field.name, event.target.value)
                        }}
                />
    
    }
    getFormFieldLabel(row, related) {
        return <div
        style={{
          width: '100%',
          marginTop: '4px',
          verticalAlign: 'baseline',
          fontSize: '13x',
          fontWeight: 'normal'
      }} >{this.getValue(row, related)}</div>
    }

    getColumns(surrogate) {
        const fields = this.component.component_field
            .filter(x => x.grid)
            .sort((x, y) => x.order - y.order)
            //.slice(0, 8)

        let columns =
            fields.map(x => {
                return {
                    meta: x.name,
                    Header: window.t.en(x.title),
                    accessor: x.related && !surrogate ? x.display : x.name,
                    filterable: !surrogate,
                    collapse: true,
                    resizeColumn: true,
                    disableResizing: true,
                    Filter: ({ filter, onChange }) => (
                        !x.related 
                            ? x.component_field_data_type.name !== 'boolean'
                                ? <input
                                    onChange={event => onChange(event.target.value)}
                                    value={filter ? filter.value : ''}
                                    style={{
                                        width: '100%',
                                        height: 25,
                                        border: '1px solid #E6E6E6',
                                        borderRadius: 25
                                    }}
                                />
                                : <select
                                    onChange={event => onChange(event.target.value)}
                                    style={{
                                        width: '100%',
                                        height: 25,
                                        border: '1px solid #E6E6E6',
                                        borderRadius: 25
                                    }}
                                    value={filter ? filter.value : ''}
                                >
                                    <option value={''}>Show All</option>
                                    {
                                        x.toggle.split(',').map((x, i) => {
                                            return <option value={i === 0 ? 'true' : 'false'}>{x}</option>
                                        })
                                    }
                                </select>
                            : <AutoComplete
                                name={x.component_reference.name}
                                title={x.component_reference.name}
                                component_reference={x.component_reference.name}
                                fieldvalue={x.component_reference.name + '_id'}
                                fieldtext={x.display.split('.')[0]}
                                displayfields={[x.display.split('.')[0]]}
                                data={this.props.state.components[x.component_reference.name].grid ? this.props.state.components[x.component_reference.name].grid.data : []}
                                value={this.props.state.components[x.component_reference.name].grid ? this.props.state.components[x.component_reference.name].grid.data : []}
                                onClick={() => { 
                                    //this.props.actions.loadComponent(x.component_reference.name)
                                    //this.props.actions.getComponentGridData(x.component_reference.name, null, null, null, null)
                                }}
                                onFilter={(args) => { 
                                    //this.props.actions.loadComponent(x.component_reference.name)
                                    //this.props.actions.getComponentGridData(x.component_reference.name, null, null, null, null)
                                }}
                            />  
                    ),
                    
                    width: x.width ? x.width : undefined,
                    headerStyle: {
                        fontSize: '13px',
                        fontWeight: 'bold', 
                        width: '100%'
                    },
                    style: {
                        verticalAlign: 'middle',
                        fontSize: 12,
                        padding: 1,
                        width: '100px !important',
                        letterSpacing: 0,
                        color: 'black', 
                        fontWeight: x.bold ? 'bold' : 'normal',
                        textAlign: x.component_field_display_type.name !== 'autoComplete' && (x.component_field_data_type.name === 'decimal' || x.component_field_data_type.name === 'integer' || x.component_field_data_type.name === 'bigInteger')
                            ? 'right'
                            : 'left'
                    },
                    Cell: (row) => (
                        x.edit ? this.getFormFieldInput(row)
                        : this.getFormFieldLabel(row, x.related)
                    )
                }
            })
            

        if (!this.props.task && !surrogate) {
            columns.unshift({
                //Header: '',
                accessor: 'options',
                filterable: false,
                maxWidth: 100,
                width: 100,
                Cell: (row) => {
                    return(this.getCommands(row))
                }
            })
        }
        if(this.props.name === 'user'){
            const expand = (row) =>{
                return(
                    <IconButton key={'command_edit_' + this.component.name + '_'} style={styles.iconButton} iconStyle={styles.action}
                        onClick={
                            () => {
                                this.props.actions.getUserTeam(row.original.user_id)
                            }
                        }>
                    <Icon istyle={{ fontSize: '22px', color: '#253053' }} ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'expand'} />
                </IconButton>
                )
            }
            const shrink = () =>{
                return(
                    <IconButton key={'command_edit_' + this.component.name + '_'} style={styles.iconButton} iconStyle={styles.action}
                        onClick={
                            () => {

                            }
                        }>
                    <Icon istyle={{ fontSize: '22px', color: '#253053' }} ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'close'} />
                    </IconButton>
                )
            }
          
            columns.unshift({
                expander: true,
                Expander: ({ isExpanded, ...rest }) => {
                    return (
                        <div>
                            {isExpanded ? shrink() : expand(rest)}
                        </div>
                    );
                }
            })
        }
        return columns
    }
    displayTeam(user_id){
        
        const teams = this.props.state.allUserTeam[user_id] ? this.props.state.allUserTeam[user_id] : null
        return(
            <Card >
                 <CardHeader
                    avatar={<Icon iclass={'custom-icons'} iname={'organization'} />}
                    title={window.t.en('Teams')}
                />
                <CardContent>
                    <ul>
                        {teams ? teams.map((team,i)=>{
                            return(
                                <div key={'users_team_' + team.team_id} style={teamStyles.table}>
                                <div style={teamStyles.cell}>
                                    {team.team.title}
                                </div>
                                <div style={teamStyles.right}>
                                    {team.team.title ?
                                     < Button secondary={true}
                                        label="Unassign"
                                        icon={<Icon iclass={'material-icons'} iname={'remove_circle'} />}
                                        onClick={(event) => {
                                            const data = {
                                                user_id :team.user_id,
                                                team_id : team.team_id
                                            }
                                            this.props.actions.unlinkUserTeam(data)
                                        }}
                                    ><Icon iclass={'material-icons'} iname={'remove_circle'} />Unassign </Button> : null}
                                </div>
                            </div>
                            )
                        }) : <Loading message={'Loading teams...'} /> }
                    </ul>
                </CardContent>
            </Card>
        )
    }
    getGrid() {
        window.glyco.log(this.component)
        //console.log("RRRRR",this.props.state.allUserTeam[3])
        return <div>
            
            <Button
                label="Excel Export"
                primary="color"
                onClick={(event) => {
                    
                    /// this.props.actions.showPopupDialog(true)
                    this.props.actions.getExcelData(this.component, this.props.link, null, this.props.filter, this.props.id)
                }}
                > {'Excel Export'}
            </Button>
            <br/><br/>
            <ReactTable
                key={'grid_' + this.component.name + '_' + this.component.component_id}
                data={this.component.grid.data}
                columns={this.getColumns()}
                defaultPageSize={this.component.grid.paging.pageSize}
                loading={this.component.grid.loading}
                manual
                onFetchData={
                    (state) => {
                        this.props.actions.getComponentGridData(this.component, this.props.link, state, this.props.filter, this.props.id)
                    }
                }
                getTrProps={(state, rowInfo) => {
                
                    return {
                        onClick: (event) => {
                            this.props.actions.selectRow(this.component.name, rowInfo)
                        },
                        style: {
                            background: this.component.selected && rowInfo
                                ? this.component.selected.filter(x => x.index === rowInfo.index)[0] ? '#FAFAFA' : 'white' //Theme.palette.primary1Color
                                : 'white',
                            color: this.component.selected && rowInfo
                                ? this.component.selected.filter(x => x.index === rowInfo.index)[0] ? 'white' : 'black'
                                : 'black'
                        }
                    }
                }}
                onResizedChange={(newResized, event) => {
                    this.props.actions.resizeColumn(this.component.name, newResized)
                }}
                minRows={1}
                pages={this.component.grid.paging.pageCount}
                showPageJump={true}
                showPaginationTop={false}
                style={{
                    boxSizing:' border-box',
                    position: 'relative',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    paddingLeft: '15px',
                    paddingRight: '15px',
                    width: '100%',
                    scrollX: 'scroll'
                }}
                onExpandedChange={(expanded,index, event) =>{
                    this.setState({ expanded })
                }}
                expanded={this.state.expanded}
                SubComponent = { (row) => {
                    return(
                     this.displayTeam(row.original.user_id)
                   )
                }}
            >
                {(state, render, instance) => {
                    return (
                        render()
                    )
                }}
            </ReactTable>
        </div>
    }
    render() {
        this.component = this.props.state.component
        return !this.component ? 'Unable to locate component definition...'
            : this.component.loading
                ? <Loading message={'Loading component...'} />
                : !this.props.surrogate
                    ? this.getGrid(this.component)
                    : null
    }
}
const mapStateToProps = (state,ownProps) => {
    window.glyco.log(ownProps)
    return {
        state: {
            ...state.teamOptions,
            ...state.userOptions,
            ...state.gridComponent,
            component: {
                ...state.components[
                ownProps.name
                    ? ownProps.name
                    : ownProps.component
                        ? ownProps.component.name
                        : undefined
                ]
            },
            components: {
                ...state.components
            }
        }
    }
}    

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({
            ...actions,
            ...gridActions,
            ...detailComponentViewActions,
            ...readComponentActions,
            ...redirectActions,
            ...userOptionsActions,
            ...teamOptionActions
        }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserGrid)