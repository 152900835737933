import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import { Container, Row, Col } from 'react-grid-system'
import Button from '@mui/material/Button'

import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'

import Dropzone from 'react-dropzone'
import Iframe from 'react-iframe'

import Drawer from '../../../controls/drawer'
import Card from '../../../controls/card'
import Icon from '../../Icon/component'
import Upload from '../../Upload/component'

import * as styles from './styles'
import * as actions from './actions'

class WorkflowDisposalInvoice extends Component {

    getViewForm() {     
         return <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={12}>
                           <Button variant='contained' className='global_button'
                                primary={true}
                                onClick={(event) => {
                                    event.preventDefault()
                                    this.props.actions.getInvoice(this.props.state.infosheet_inx)
                                   // this.props.actions.getChecksheeet(this.props.state.infosheet_inx)
                                }}>
                                {<Icon iclass={'material-icons'} iname={'cloud_download'} />} &nbsp; {window.t.en('Generate Invoice')}
                            </Button>
                        </Col>
                    </Row>
            </Container>
        }

        getView() {
            return <div> 
                <div className="text-center-off">
                    <div className="font-weight-bold font-size-lg mb-0 text-black">
                    Disposal Invoice
                    </div>
                    <p className="text-black-50">Generate disposal invoice</p>
                </div>
                {this.getViewForm()}
                <br />
                <div className="divider" />
            </div>
        }
            
        getLayout() {
            return  <Drawer 
                onClose={(event, value) => {
                    this.props.actions.toggleInfoSheet(null,'inforsheet_invoice')
                }}
                open={this.props.state.inforsheet_invoice}
                title={'Disposal  Invoice'}
                subtitle={'Generate disposal invoice'}
                content={this.getView()}
            />
            // return <Drawer
            //         width={800}
            //         anchor={'right'}
            //         openSecondary={true}
            //         open={this.props.state.inforsheet_decision}
            //         onRequestChange={(event) => {
            //             event.preventDefault()
            //             this.props.actions.toggleInfoSheet(null,'inforsheet_decision')
            //         }}>
            //         {this.getView()}
            //     </Drawer>
        }

        render() {
            this.data = this.props.state.yardlist[this.props.state.infosheet_inx]
            //this.disposal = this.data //.disposal.length ? this.data.disposal[0] : {}
            return this.getLayout()
        }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.workflowDisposal,
            user: {
                ...state.user
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowDisposalInvoice)