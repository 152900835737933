import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system'
import _ from 'lodash'
import moment from 'moment'

import Table from '@mui/material/Table'
import Card from '../../../controls/card'
import Fields from '../../../controls/cardfields'
import PdfViewer from '../../PDF/component'

import * as styles from './styles'
import * as actions from './actions'

class WorkflowSTRCreateConfirm extends Component {
    componentDidMount() {
        this.props.actions.setupWorkflowTaskInput(
            this.props.state, [], 'str_quotation', 'tsk_str_quotation_create_quote')
    }
    
    getQuotation() {
        return<Fields 
            fields={[
                {size:4, label: 'Customer:', value: this.props.state.customer},
                {size:4, label: 'RFQ Date :', value: this.props.state.rfqdate},
                {size:4, label: 'RFQ  Time:', value:  this.props.state.rfqtime},
                {size:4, label: 'Quote For', value: this.props.state.additional ? 'Replace Vehicle' : 'Additional Vehicle'},
                {size:4, label: 'Replace Vehicle', value: this.props.state.vehicle, dispay: this.props.state.additional},
                {size:4, label: 'Pick Up Address', value: this.props.state.collection_address.text},
                {size:4, label: 'Delivery Address', value: this.props.state.delivery_address.text},
                {size:4, label: 'Quotation Notes', value: this.props.state.quotationnotes ? this.props.state.quotationnotes.text : ''}
            ]}
        />
    }
          
    getContract() {
        return<Fields 
            fields={[
                {size: 4, label: 'Vehicle Category:', value: this.props.state.strratecard},
                {size: 4, label: 'Transmission:', value: this.props.state.transmission},
                {size: 4, label: 'Contact Period:', value: this.props.state.period},
                {size: 4, label: 'Monthly Inclusive:', value: this.props.state.distance},
                {size: 4, label: 'Start Date:', value: this.props.state.startdate},
                {size: 4, label: 'End Date:', value: this.props.state.enddate},
                {size: 4, label: ' Unit:', value: this.props.state.contractunit},
                {size: 4, label: 'Waivers:', value: this.props.state.waiver}
            ]}
        />
    } 

    getFinance() {
        return <Fields 
            fields={[
                {size: 4, label: 'Monthly Rental:', value: this.props.state.str_monthly_rental},
                {size: 4, label: 'Accessories Total:', value: this.props.state.accessory_tot_monthly},
                {size: 4, label: 'Waiver Total:', value: this.props.state.waiver_tot},
                {size: 4, label: 'Sub Total:', value: this.props.state.monthly_excl},
                {size: 4, label: 'VAT:', value: this.props.state.monthly_vat},
                {size: 4, label: 'Total Rental:', value: this.props.state.monthly_incl},
            ]}
        />
    } 
    
    getAccessory() {
        return <Table className="table table-hover text-nowrap mb-0">
            <thead>
                <tr>
                    <th>Accessory Type</th>
                    <th>Accessory Cost </th>
                    <th>Rate Per Month</th>
                </tr>
            </thead>
            <tbody>
                {
                    this.props.state.accessory_list.map(x => {
                    return <tr>
                        <td>{x.accessory_name}</td>
                        <td>{x.totalprice}</td>
                        <td>{x.ratepermonth}</td>
                        </tr>
                    })
                }
            </tbody>
        </Table>
    }
   
    getDocument() {
        return this.props.state.uploads.documents.length
            ? <PdfViewer
                key={'document_viewer_' + this.props.state.uploads.documents.length}
                documents={this.props.state.uploads.documents}
                preview={'preview'}
            />
            : 'Retrieving Documents'
    }

    getLayout() {
        //console.log('this.props.state uploads', this.props.state.uploads)
        //console.log('this.props.state length', this.props.state.uploads.length)
        return  <div>
            <Card
                title={window.t.en('Adhoc Rental Quotation Confirm')}
                subtitle={window.t.en('Ensure that all values are captured correctly before your proceed ')}
                content={this.getQuotation()}
            /> 
            <Card
                title={window.t.en('Contract Details')}
                subtitle={window.t.en('Contract parameters ')}
                content={this.getContract()}
            /> 
            <Card
                title={window.t.en('Accessories')}
                subtitle={window.t.en('Contract accessories list')}
                content={this.getAccessory()}
            />
            <Card
                title={window.t.en('Finance and Rental')}
                subtitle={window.t.en('Contract finance details ')}
                content={this.getFinance()}
            /> 
            <Card
                title={window.t.en('Documents')}
                subtitle={window.t.en('Attached documets')}
                content={this.getDocument()}
            />
        </div>
    }

    render() {
        return this.getLayout()
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.captureQuotation,
            user: {
                ...state.user
            },
            uploads: {
                ...state.upload
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowSTRCreateConfirm)