export const WORKFLOW_TIMELINE_GET_WORKFLOW_TIMELINE = 'WORKFLOW_TIMELINE_GET_WORKFLOW_TIMELINE'
export const WORKFLOW_TIMELINE_SET_WORKFLOW_TIMELINE = 'WORKFLOW_TIMELINE_SET_WORKFLOW_TIMELINE'
export const CLOSE_WORKFLOW_TIMELINE = 'CLOSE_WORKFLOW_TIMELINE'
export const TOGGLE_WORKFLOW_TIMELINE = 'TOGGLE_WORKFLOW_TIMELINE'
export const LOAD_WORKFLOW_TIMELINE = 'LOAD_WORKFLOW_TIMELINE'
export const SHOW_WORKFLOW_TIMELINE = 'SHOW_WORKFLOW_TIMELINE'
export const WORKFLOW_TIMELINE_ASSIGN_TASK_TO_ME = 'WORKFLOW_TIMELINE_ASSIGN_TASK_TO_ME'
export const WORKFLOW_TIMELINE_UNASSIGN_TASK = 'WORKFLOW_TIMELINE_UNASSIGN_TASK'
export const TOGGLE_WORKFLOW_TIMELINE_STATE_BY_TITLE = 'TOGGLE_WORKFLOW_TIMELINE_STATE_BY_TITLE'
export const TOGGLE_WORKFLOW_TIMELINE_TASK_BY_TASKNUMBER = 'TOGGLE_WORKFLOW_TIMELINE_TASK_BY_TASKNUMBER'
export const WORKFLOW_TIMELINE_SET_SELECTED_WORKFLOW_TIMELINE_QUEUE = 'WORKFLOW_TIMELINE_SET_SELECTED_WORKFLOW_TIMELINE_QUEUE'

export const GET_WORKFLOW_TASK_STEPS = 'GET_WORKFLOW_TASK_STEPS'
export const SET_WORKFLOW_TASK_STEPS = 'SET_WORKFLOW_TASK_STEPS'