
export const GET_CUSTOMER_STATEMENT_LIST = 'GET_CUSTOMER_STATEMENT_LIST'
export const GET_CUSTOMER_LIST = 'GET_CUSTOMER_LIST'
export const SET_ANY_DATA_LIST = 'SET_ANY_DATA_LIST'
export const SET_CUSTOMER_STATEMENT_LIST = 'SET_CUSTOMER_STATEMENT_LIST'
export const GEN_CUSTOMER_STATEMENT_PDF = 'GEN_CUSTOMER_STATEMENT_PDF'
export const SET_ANY_SELECTED_ITEM = 'SET_ANY_SELECTED_ITEM'
export const GET_SUPPLIER_LIST = 'GET_SUPPLIER_LIST'
export const GET_SUPPLIER_TRANSACTION_LIST = 'GET_SUPPLIER_TRANSACTION_LIST'
export const SET_SUPPLIER_TRANSACTION_LIST = 'SET_SUPPLIER_TRANSACTION_LIST'
export const GET_EXCEL_GRID_EXPORT = 'GET_EXCEL_GRID_EXPORT'