import moment from 'moment'
//import validator from '../validate'
import * as _ from 'lodash'

import * as appTypes from '../../App/types'
import * as types from './types'

let debitobj = { partialallocationvalue: 0, debit: 0, remaining: 0 }
let creditobj = { partialallocationvalue: 0, credit: 0, remaining: 0 }
let debitallocationaffected = false
let creditallocationaffected = false
let PartialCreditAllocation = []
let PartialDebitAllocation = []

export const setAllocationCreditorList = (payload) => {
    let data = []
    data = payload.data.map(x => {
        return {
            text: x.suppliername,
            value: x.supplier_id
        }
    })
    return {
        type: types.SET_ALLOCATION_CREDITORS_LIST,
        payload: {
            data: data
        }
    }
}
export const getAllocationCreditorData = (search) => {
    return {
        type: types.GET_ALLOCATION_CREDITORS_LIST,
        payload: {
            search_text: search
        }
    }
}
export const setSelectedLookup = (field, value, text) => {    
    debitobj = { partialallocationvalue: 0, debit: 0 }
    creditobj = { partialallocationvalue: 0, credit: 0 }
    PartialCreditAllocation = []
    PartialDebitAllocation = []
    return {
        type: types.SET_ALLOCATION_CREDITORS_LOOKUP,
        payload: {
            value: value,
            text: text,
            prop: field
        }
    }
}

export const getAllocationData = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_SUPPLIER_ALLOCATION_DATA,
            payload: {
                type: "GET_SUPPLIER_ALLOCATION_DATA",
                id: Number(id)
            }
        })
    }
}

export const setInputValue = (field, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_ALLOCATION_FIELD_INPUT_VALUE,
            payload: {
                prop: field,
                value: value
            }
        })
    }
}
export const saveSupplierPaymentAllocationData = (data, userdata) => {
    debitobj = { partialallocationvalue: 0, debit: 0 }
    creditobj = { partialallocationvalue: 0, credit: 0 }
    PartialCreditAllocation = []
    PartialDebitAllocation = []
    return {
        type: types.SAVE_ALLOCATION_DATA,
        payload: {
            data: data,
            user: userdata
        }
    }
}
export const savedSupplierPaymentAllocationData = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SAVED_PAYMENT_ALLOCATION_DATA
        })

        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Allocation Successful!',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}
export const setSupplierAllocationData = (payload) => {
    return (dispatch, getState) => {
        if (payload.data != null) {
            dispatch(setCalculatedData(payload.data))
        }
        dispatch({
            type: types.SET_SUPPLIER_ALLOCATION_DATA,
            payload: {
                data: payload.data
            }
        })
    }
}
export const setCalculatedData = (payload) => {

    var data = payload
    var unAllocated = 0, total = 0, credit = 0, debit = 0, current = 0, age30 = 0, age60 = 0, age90 = 0, age120plus = 0
    var allocateAll = false, canallocate = false
    var allocatedcredit = 0, allocateddebit = 0
    var partialdebit = 0, allocationbalance = 0, partialcredit = 0

    data.map((x, i) => {
        switch (Number(x.age)) {
            case 0:
                current += Number(x.credit) - Number(x.allocationamount) > 0 ? Number(x.credit) - Number(x.allocationamount) : 0
                break
            case 30:
                age30 += Number(x.credit) - Number(x.allocationamount) > 0 ? Number(x.credit) - Number(x.allocationamount) : 0
                break
            case 60:
                age60 += Number(x.credit) - Number(x.allocationamount) > 0 ? Number(x.credit) - Number(x.allocationamount) : 0
                break
            case 90:
                age90 += Number(x.credit) - Number(x.allocationamount) > 0 ? Number(x.credit) - Number(x.allocationamount) : 0
                break
            default:
                age120plus += Number(x.credit) - Number(x.allocationamount) > 0 ? Number(x.credit) - Number(x.allocationamount) : 0
                break
        }
        //credit
        if (x.credit > 0) {
            credit += Number(x.credit) - Number(x.allocationamount)
            allocatedcredit += x.valueupdated ? Number(x.allocationamount) : 0
        }

        //debit
        if (x.debit > 0) {
            debit += Number(x.debit) - Number(x.allocationamount)
            allocateddebit += x.valueupdated ? Number(x.allocationamount) : 0
            unAllocated += Number(x.allocationamount) == 0 ? Number(x.debit) : Number(x.allocationamount) > 0 ? Number(x.debit) - Number(x.allocationamount) : 0
        }

        //credit
        let _partialCreditAllocation = PartialCreditAllocation.filter(function (item, index) {
            return PartialCreditAllocation.indexOf(item) >= index;
        })

        //debit
        let _partialDebitAllocation = PartialDebitAllocation.filter(function (item, index) {
            return PartialDebitAllocation.indexOf(item) >= index;
        })

        //Credit
        PartialCreditAllocation = _partialCreditAllocation
        if (x.credit > 0 && x.valueupdated) {
            for (i of _partialCreditAllocation) {
                if (i.id == x.suppliertransaction_id && x.allocationamount > 0) {
                    allocatedcredit -= (i.value)
                }
            }
        }

        //Debit
        PartialDebitAllocation = _partialDebitAllocation
        if (x.debit > 0 && x.valueupdated) {
            for (i of _partialDebitAllocation) {
                if (i.id == x.suppliertransaction_id && x.allocationamount > 0) {
                    allocateddebit -= (i.value)
                }
            }
        }

        //credit
        if (x.credit > 0 && x.fullallocation == 'Partial' && x.valueupdated == false) {
            creditobj.partialallocationvalue = Number(x.allocationamount)
            let cred = {
                id: x.suppliertransaction_id,
                value: Number(x.allocationamount)
            }
            PartialCreditAllocation.push(cred);
            _partialCreditAllocation = PartialCreditAllocation.filter(function (item, index) {
                return PartialCreditAllocation.indexOf(item) >= index;
            })
            PartialCreditAllocation = _partialCreditAllocation
        }

        //debit
        if (x.debit > 0 && x.fullallocation == 'Partial' && x.valueupdated == false) {
            debitobj.partialallocationvalue = Number(x.allocationamount)
            let cred = {
                id: x.suppliertransaction_id,
                value: Number(x.allocationamount)
            }
            PartialDebitAllocation.push(cred);
            _partialDebitAllocation = PartialDebitAllocation.filter(function (item, index) {
                return PartialDebitAllocation.indexOf(item) >= index;
            })
            PartialDebitAllocation = _partialDebitAllocation
        }
    })

    total = age120plus + age90 + age60 + age30 + current
    credit = isNaN(credit) ? 0 : credit
    total = isNaN(total) ? 0 : total

    allocationbalance = (allocateddebit) - allocatedcredit

    var handledebit = (debit).toString().split('.')
    var handlecredit = (credit).toString().split('.')
    var handleallocateddebit = (allocateddebit).toString().split('.')
    var handleallocatedcredit = (allocatedcredit).toString().split('.')

    allocateAll = Number(handlecredit[0]) == Number(handledebit[0]) && (Number(handlecredit[0]) ? Number(handlecredit[0]) : Number(handledebit[0])) > 0 ? true : false
    canallocate = (Number(handleallocateddebit[0]) == Number(handleallocatedcredit[0])) && (allocatedcredit ? allocatedcredit : allocateddebit) > 0 ? true : false
    var calcFields = {
        allocationBalance: allocationbalance.toFixed(2).toString(),
        unAllocated: unAllocated.toFixed(2).toString(),
        total: total.toFixed(2).toString(),
        debit: debit.toFixed(2).toString(),
        age30: age30.toFixed(2).toString(),
        age60: age60.toFixed(2).toString(),
        age90: age90.toFixed(2).toString(),
        age120plus: age120plus.toFixed(2).toString(),
        current: current.toFixed(2).toString(),
        allocateAll: allocateAll,
        canallocate: canallocate
    }

    return (dispatch, getState) => {

        dispatch({
            type: types.SET_ANY_SUPPLIER_ALLOCATION_DATA,
            payload: {
                data: calcFields,
                component: 'supplierpaymentallocation'
            }
        })
    }
}
export const setFieldAllocation = (values, payload) => {
    var data = payload.supplierpaymentallocation_id.data.map((x, i) => {
        var full = 'Full'
        if (x.suppliertransaction_id === values.id) {
            if (Number(values.value) != (Number(x.credit) ? Number(x.credit) : Number(x.debit)) && Number(values.value) > 0) { full = 'Partial' }
            else
                if (Number(values.value) == (Number(x.credit) ? Number(x.credit) : Number(x.debit)) && Number(values.value) > 0) { full = 'Full' }
                else { full = 'None' }
        }
        return x.suppliertransaction_id === values.id && (values.field == 'allocationamount' || values.field == "allocated") ?
            {
                ...x,
                valueupdated: true,
                allocationamount: values.value,
                allocated: Number(values.value) > 0 ? true : false,
                fullallocation: full
            }
            : (x.suppliertransaction_id === values.id && values.field == 'allocationreference') ?
                {
                    ...x,
                    allocationreference: values.value,
                } : (x.suppliertransaction_id === values.id && values.field == 'allocationdescription') ?
                    {
                        ...x,
                        allocationdescription: values.value,
                    } : x
    })
    return (dispatch, getState) => {
        if (data != null) {
            dispatch(setCalculatedData(data))
            dispatch({
                type: types.SET_SUPPLIER_ALLOCATION_DATA,
                payload: {
                    data: data
                }
            })
        }

    }
}
export const allocateAll = (payload, userdata) => {
    debitobj = { partialallocationvalue: 0, debit: 0 }
    creditobj = { partialallocationvalue: 0, credit: 0 }
    var data = payload.map((x, i) => {
        return Number((x.debit ? x.debit : x.credit)) > 0 ?
            {
                ...x,
                valueupdated: true,
                allocationamount: (x.debit ? x.debit : x.credit),
                allocated: true
            }
            : x
    })
    return (dispatch, getState) => {
        dispatch({
            type: types.SAVE_ALLOCATION_DATA,
            payload: {
                data: data,
                user: userdata
            }
        })
        dispatch(setCalculatedData(data))
    }
}
export const unallocatedPaymentAllocationEpic = (data) => {
    return (dispatch, getState) => {
        //console.log('unallocatedPaymentAllocationEpic', data)
        dispatch({
            type: types.SAVE_UNALLOCATION_DATA,
            payload: {
                data: data,
            }
        })
    }
}
export const unallocatedPaymentAllocationEpicDone = () => {
    return (dispatch, getState) => {
        let supplier_id = getState().supplierpaymemtallocation.supplier_id
        dispatch(getAllocationData(supplier_id))
    }
}
export const excelExport = (component) => {
    return (dispatch, getState) => {
        const header = [{
            key: 'supplierpaymentallocationdate',
            name: 'Date',
            default: ' ',
        }, {
            key: 'age',
            name: 'Age',
            default: '0',
        }, {
            key: 'transactiontype',
            name: 'Type',
            default: ' ',
        }, {
            key: 'reference',
            name: 'Reference',
            default: ' ',
        }, {
            key: 'description',
            name: 'Description',
            default: ' ',
        }, {
            key: 'debit',
            name: 'Debit',
            default: '0',
        }, {
            key: 'credit',
            name: 'Credit',
            default: '0',
        }, {
            key: 'allocation',
            name: 'Allocation',
            default: 'None',
        }, {
            key: 'allocationamount',
            name: 'Allocation Amount',
            default: '0',
        }, {
            key: 'allocated',
            name: 'Allocated',
            default: false,
        }]


        dispatch({
            type: types.GET_EXCEL_GRID_EXPORT,
            payload: {
                type: 'GET_EXCEL_GRID_EXPORT',
                component: component.name,
                data: component.data.supplierpaymentallocation_id.data,
                header: header
            }
        })
    }
}