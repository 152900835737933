import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Grid, TextField, Button } from '@mui/material';
import * as actions from './actions'

import DateControl from '../../controls/dateControl'
import Autocomplete from '../../controls/autocomplete'
import Card from '../../controls/card'
import { Container, Row, Col } from 'react-grid-system'
import Header from '../WorkflowTaskInput/header'
import Loading from '../Loading/component'

class JSReport extends Component {

    componentDidMount() {
        this.props.actions.setJSReportContent(null,null,null)
        if (this.props.report_id) {
            if (!this.props.state.searchFilter)
                this.props.actions.getFilters(this.props.report_id)
        }

        this.props.actions.getJSReportContent(this.props.shortid, this.props.data, this.props.id, this.props.name)
    }

    // componentDidMount() {
    //     setJSReportContent(null,null,null)
    //     this.props.actions.getJSReportContent(this.props.shortid, this.props.data, this.props.id, this.props.name)
    // }
    
    getFilterControl(filter) {
        switch (filter.control_name) {
            case 'text':
                return <TextField
                    onChange={(e) => { this.props.actions.editFilters(e) }}
                    name={filter.field_name}
                    value={this.props.state.searchFilter[filter.field_name] ? this.props.state.searchFilter[filter.field_name] : ""}
                    id="outlined-basic"
                    label={filter.description}
                    variant="outlined"
                    margin="normal"
                />
            case 'date':
                return <Grid container spacing={2}>
                    <Grid item xs={2} sm={6}>
                        <DateControl
                            id={'dateFrom'}
                            key={'dateFrom'}
                            name={'dateFrom'}
                            // disabled={viewinvoice}


                            label={window.t.en(filter.description+' From')}
                            field={'dateFrom'}
                            value={this.props.state.dateFrom}
                            onSelect={(args, event) => {

                                this.props.actions.editDateFilter(null, args, 'dateFrom', filter.field_name)
                            }}
                            type={'date'}
                        />
                    </Grid>
                    <Grid item xs={2} sm={6}>
                        <DateControl
                            id={'dateTo'}
                            key={'dateTo'}
                            name={'dateTo'}
                            // disabled={viewinvoice}
                            label={window.t.en(filter.description + ' To')}
                            field={'dateTo'}
                            value={this.props.state.dateTo}
                            onSelect={(args, event) => {

                                this.props.actions.editDateFilter(null, args, 'dateTo', filter.field_name)
                            }}
                            type={'date'}
                        />
                    </Grid>
                </Grid>

            case 'autocomplete':
                return <div>autocomplete</div>
            default:
                return <div></div>
        }
    }

    getFilter() {

        const { filter, oqlisMenuReport } = this.props.state
        const { report_ } = oqlisMenuReport
        return (
            <Card
                title={'Report Filters'}
                subtitle={'Please select atleast one filter for a speedy report.'}
                content={<div>
                    <Row>
                        <Col xl={12}><Grid container spacing={2}>
                            {
                                filter.sort((firstItem, secondItem) => firstItem.order - secondItem.order).map((x, i) =>
                                    <Grid item xs={2} sm={x.control_name == 'date' ? 4 : 2}>
                                        {this.getFilterControl(x)}
                                    </Grid>
                                )
                            }</Grid>
                        </Col>
                    </Row>
                    <Row>
                    <Col xl={10}>
                           
                        </Col>
                        <Col xl={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={(e) => this.props.actions.submitFilter(e)}
                            >
                                Filter
                            </Button>
                        </Col>
                    </Row>
                </div>
                } />)

    }

    getReport() {
        return this.props.state[this.props.id] ? this.props.state[this.props.id].buffer
            ? <iframe src={this.props.state[this.props.id].url} style={{ height: '1000px', width: '100%', border: 'none' }}></iframe>
            : <div style={{ color: '#fff' }}>{'Loading...'}</div> : <div style={{ color: '#fff' }}>{'Loading...'}</div>
    }

    getLayout() {
        const { filter, oqlisMenuReport } = this.props.state
        const { report_ } = oqlisMenuReport
        return <div>
                {/* <br />
                {  report_ ? <Header title={report_.report_name} description={report_.report_desc} /> : <></> }
                <br />
                {this.props.state.filter && this.props.state.searchFilter ? this.getFilter() : null}
                <br /> */}
                {this.getReport()}
            </div>
    }

    render() {
        return (this.getLayout())
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.jsReport,
            ...state.navigationMenu
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(JSReport)