export const tree = []
export const clients = []
export const client = 2 

export const oqliskey = null
export const oqlis = []
export const data = []
export const hierarchy = []
export const init = []
export const allBusinesses = ''
export const allCustomers = ''
export const openlang = false
export const tabValue = 0
export const userDetails = {}