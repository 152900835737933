import 'date-fns';
import React from 'react';
import moment from 'moment';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';

import TextField from '@mui/material/TextField';

import { styled } from '@mui/material/styles';
const TextFieldx = styled(TextField)({
    "& .MuiFormLabel-root": {
        fontSize: '14px'
    },
    "& .MuiFormLabel-filled": {
        color: "black",
        fontWeight: 600,
        fontSize: '14px'
    },
    root: {
        "& .MuiFormLabel-filled": {
            color: "black",
            fontWeight: 600
        }
    }
});

export default function MaterialUIPickers(props) {
    const { label, hintText, error, helperText, type, value } = props

    //console.log('datePickerx dt', props)
    return (
        type == 'time'
            ? <div style={{ margin: '15px 0px' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                        size={"small"}
                        label={label ? label : hintText ? hintText : 'Select Time'}
                        slotProps={{
                            textField: {
                                size: 'small',
                                variant: 'outlined',
                                error: error,
                                helperText: helperText,
                            }
                        }}
                        //variant="outlined"
                        onChange={(event, selected) => {
                            // console.log('TimePicker', event)
                            if (event) {
                                const date = event?.$d ? moment(event?.$d)?.format('HH:mm') : ''
                                props.onSelect(date !== 'Invalid date' ? date : '', event)
                            }
                        }}
                        renderInput={(params) => <TextFieldx  {...params} size="small" />}
                    />
                </LocalizationProvider>
            </div>
            : <div style={{ margin: '25px 0px' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        size={"small"}
                        label={label ? label : hintText ? hintText : 'Select Date'}
                        slotProps={{
                            textField: {
                                size: 'small',
                                variant: 'outlined',
                                error: error,
                                fullWidth: true,
                                helperText: helperText,
                                fullWidth: true
                            }
                        }}
                        value={dayjs(value)}
                        fullWidth={true}
                        //variant="outlined"
                        format="YYYY-MM-DD"
                        inputFormat="YYYY-MM-DD"
                        onChange={(event, selected) => {
                            // console.log('DatePicker', event)
                            if (event) {
                                const date = event?.$d ? moment(event?.$d)?.format('YYYY-MM-DD HH:mm') : ''
                                props.onSelect(date !== 'Invalid date' ? date : '', event)
                            }
                        }}
                        renderInput={(params) => {
                            return <TextFieldx  {...params} size="small" />
                        }}
                    />
                </LocalizationProvider>
            </div>
    );
}
