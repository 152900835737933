import * as types from './types'
import moment from 'moment'


export const setupWorkflowTaskInput = (data, meta, component, step) => {
    return (dispatch, getState) => {

        const payload = {
            step: step,
            component: component,
            selected: data,
            meta: meta
        }

        dispatch({
            type: types.CONFIRM_WORKFLOW_CRAETE_FML_QUOTATION_HEADER_SELECTION,
            payload: payload
        })
    }
}

export const addVehicle = (field, form) => {
    return (dispatch, getState) => {
        field.fields.push(form)
        dispatch({
            type: types.ADD_COMPONENT_SUBFORM_FIELDS_DATA,
            payload: {
                prop: field.name,
                fields: field.fields
            }
        })
    }
}

export const removeVehicle = (field, inx) => {
    return (dispatch, getState) => {

        delete field.fields[inx]
        let fields = field.fields.filter(x => x)
        dispatch({
            type: types.ADD_COMPONENT_SUBFORM_FIELDS_DATA,
            payload: {
                prop: field.name,
                fields: fields
            }
        })
    }
}

export const setReplaceVehicleDetails = (payload) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_CONTRACT_REPLACE_VEHICLE_DATA,
            payload: payload.data[0],
        })
    }
}

export const getInputookupData = (field, search, inx) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_WORKFLOW_COMPONENT_INPUT_LOOKUP_DATA,
            payload: {
                inx: inx,
                field: field,
                search: search,
            }
        })
    }
}


export const setInputookupData = (field, payload) => {

    return (dispatch, getState) => {
        if (field.field.parent)
            dispatch({
                type: types.SET_WORKFLOW_FORM_COMPONENT_INPUT_LOOKUP_DATA,
                payload: {
                    inx: field.inx,
                    prop: field.field.name,
                    value: payload.data,
                    parent: field.field.parent,
                }
            })
        else
            dispatch({
                type: types.SET_WORKFLOW_COMPONENT_INPUT_LOOKUP_DATA,
                payload: {
                    inx: field.inx,
                    prop: field.field.name,
                    value: payload.data,
                }
            })
    }
}

export const setInputValue = (field, value, inx, text) => {
    return (dispatch, getState) => {
        //console.log('setInputValue field: ', field)
        if (field.parent) {
            dispatch({
                type: types.SET_WORKFLOW_FORM_INPUT_FIELD_VALUE,
                payload: {
                    inx: inx,
                    value: value,
                    prop: field.name,
                    parent: field.parent,
                }
            })
            if (text) {
                dispatch({
                    type: types.SET_WORKFLOW_FORM_INPUT_FIELD_TEXT,
                    payload: {
                        inx: inx,
                        value: text,
                        prop: field.name,
                        parent: field.parent,
                    }
                })
            }
        }
        else {
            dispatch({
                type: types.SET_WORKFLOW_COMPONENT_INPUT_FIELD_VALUE,
                payload: {
                    value: value,
                    prop: field.name,
                }
            })

            if (text) {
                dispatch({
                    type: types.SET_WORKFLOW_COMPONENT_INPUT_FIELD_TEXT,
                    payload: {
                        inx: inx,
                        value: text,
                        prop: field.name,
                    }
                })
            }
        }
        if (field.data) {
            dispatch({
                type: types.SET_WORKFLOW_COMPONENT_INPUT_LOOKUP_DATA,
                payload: {
                    prop: field.name,
                    value: field.data.filter(x => x[field.data_value] === value),
                }
            })
        }
        if (field.title === "Customer") {
            dispatch({
                type: types.SET_SELECTED_CUSTOMER_DATA,
                payload: field.data.filter(x => x[field.data_value] === value)[0]
            })

            dispatch({
                type: types.GET_CUSTOMER_DERIVATIVE_VALUES,
                payload: value
            })
        }
        if (field.title === "Replace Vehicle") {
            dispatch({
                type: types.SET_CONTRACT_REPLACE_VEHICLE_DATA,
                payload: field.data.filter(x => x[field.data_value] === value)[0]
            })
        }
        if (field.title === "Quote For") {
            dispatch({
                type: types.SET_SELECTED_QUOTE_FOR,
                payload: field.data.filter(x => x[field.data_value] === value)[0]
            })
        }
        if (field.title === "Vehicle Category") {
            dispatch({
                type: types.SET_SELECTED_VEHICLE_CATEGORY,
                payload: field.data.filter(x => x[field.data_value] === value)[0]
            })
            dispatch({
                type: types.GET_SELECTED_VEHICLE_CATEGORY_CHIP,
                payload: value
            })
        }
        if (field.title === "MM Code") {
            let mmcode = field.data.filter(x => x[field.data_value] === value)[0]
            //console.log(mmcode)
            dispatch({
                type: types.SET_SELECTED_VEHICLE_MMCODE_DATA,
                payload: mmcode
            })

            const _fields = getState().workflowCreateQuote.fields
            const _field = _fields.filter(x => x.name === field.parent)[0]

            for (let i of _field.populate) {
                let props = _field.fields[inx].filter(x => x.name === i)[0]
                dispatch(subInputValue(props, mmcode[i], inx))

                if (props.formula)
                    dispatch(doSubFormulaInputValue(_field.fields[inx], props, mmcode[i], inx))
            }
        }
    }
}

export const subInputValue = (field, value, inx) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_WORKFLOW_FORM_INPUT_FIELD_VALUE,
            payload: {
                inx: inx,
                value: value,
                prop: field.name,
                parent: field.parent,
            }
        })
    }
}

export const setCustomerDerivative = (payload) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_CUSTOMER_DERIVATIVE_VALUES,
            payload: payload.data[0]
        })
    }
}

export const setVehicleCategoryChip = (payload) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_SELECTED_VEHICLE_CATEGORY_CHIP,
            payload: payload.data
        })
    }
}

export const doFormulaInputValue = (field, formula, inx) => {
    //console.log('>>>>>>>>>>>>>>>>>>> doFormulaInputValue')
    return (dispatch, getState) => {
        //const field1 = getState().workflowCreateQuote.fields.filter(x => x.name === formula.field1)[0]
        //const field2 = getState().workflowCreateQuote.fields.filter(x => x.name === formula.field2)[0]

        const field1 = formula.sub
            ? formula.parent_field1
                ? getState().workflowCreateQuote.fields.filter(x => x.name === formula.parent_field1)[0]
                : getState().workflowCreateQuote.fields.filter(x => x.name === field.parent)[0].fields[inx].filter(x => x.name === field.formula.field1)[0]
            : getState().workflowCreateQuote.fields.filter(x => x.name === formula.field1)[0]

        const field2 = formula.sub
            ? getState().workflowCreateQuote.fields.filter(x => x.name === field.parent)[0].fields[inx].filter(x => x.name === field.formula.field2)[0]
            : getState().workflowCreateQuote.fields.filter(x => x.name === formula.field2)[0]

        //console.log('formula.operator: ', formula)
        //console.log('field1.value: ', field1.value)
        //console.log('field2.value: ', field2.value)
        let value = 0
        switch (formula.operator) {
            case 'm':
                value = (parseFloat(field1.value) * parseFloat(field2.value)).toFixed(2)
                break
            case 'mi':
                value = (parseFloat(field1.value) * parseFloat(value)).toFixed(0)
                break
            case 'rv':
                value = (parseFloat(field1.value) * (parseInt(field2.value) / 100)).toFixed(2)
                break
            case 's':
                value = (parseFloat(field1.value) - parseFloat(field2.value)).toFixed(2)
                break
            default:
                value = (parseFloat(field1.value) + parseFloat(field2.value)).toFixed(2)
                break
        }

        //console.log('value: ', value)

        if (field.parent) {
            dispatch({
                type: types.SET_WORKFLOW_FORM_INPUT_FIELD_VALUE,
                payload: {
                    inx: inx,
                    value: value,
                    prop: formula.prop,
                    parent: field.parent,
                }
            })
        } else {
            dispatch({
                type: types.SET_WORKFLOW_COMPONENT_INPUT_FIELD_VALUE,
                payload: {
                    prop: formula.prop,
                    value: value,
                }
            })
        }
    }
}

export const doSubFormulaInputValue = (fields, field, value, inx) => {
    return (dispatch, getState) => {
        const field1 = field.formula.parent_field1
            ? getState().workflowCreateQuote.fields.filter(x => x.name === field.formula.parent_field1)[0]
            : getState().workflowCreateQuote.fields.filter(x => x.name === field.parent)[0].fields[inx].filter(x => x.name === field.formula.field1)[0]
        //const field1 = field.formula.parent_field1 ? getState().workflowCreateQuote.fields.filter(x => x.name === field.formula.parent_field1)[0] : fields.filter(x => x.name === field.formula.field1)[0]

        // const _value = field.formula.operator === 'm' ? field1.value * value :
        //     field.formula.operator === doFormulaInputValue'rv' ? field1.value * (parseInt(value) / 100) : field1.value + value

        let _value = 0
        switch (field.formula.operator) {
            case 'm':
                value = (parseFloat(field1.value) * parseFloat(value)).toFixed(2)
                break
            case 'mi':
                value = (parseFloat(field1.value) * parseFloat(value)).toFixed(0)
                break
            case 'rv':
                value = (parseFloat(field1.value) * (parseInt(value) / 100)).toFixed(2)
                break
            case 's':
                value = (parseFloat(field1.value) - parseFloat(value)).toFixed(2)
                break
            default:
                value = (parseFloat(field1.value) + parseFloat(value)).toFixed(2)
                break
        }
        dispatch({
            type: types.SET_WORKFLOW_FORM_INPUT_FIELD_VALUE,
            payload: {
                inx: inx,
                value: _value,
                prop: field.formula.prop,
                parent: field.parent,
            }
        })
    }
}

export const setAcceptedDocuments = (field, files) => {
    let filename = 'RFQ_Document_' + moment().format('YYMMDDhhmmss')
    return (dispatch, getState) => {
        files.forEach(file => {
            const reader = new FileReader();
            filename = filename + '.' + file.type.split('/')[1]
            reader.readAsArrayBuffer(file);
            reader.onload = (e) => {
                let fileAsBinaryString = reader.result;
                dispatch({
                    type: types.UPLOAD_MULTIPLE_IMAGE_COMPONENT_UPLOADER,
                    payload: {
                        content: fileAsBinaryString,
                        filename: filename + file.type.replace('image/', '.'),
                        type: file.type,
                    }
                })
            };
        });

        dispatch({
            type: types.SET_WORKFLOW_COMPONENT_INPUT_FIELD_VALUE,
            payload: {
                prop: field.name,
                value: files[0].preview,
            }
        })

        dispatch({
            type: types.SET_WORKFLOW_COMPONENT_INPUT_FIELD_TEXT,
            payload: {
                prop: field.name,
                value: filename,
            }
        })
    }
}


export const getListLookupData = (field, search, inx) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_COMPONENT_LIST_LOOKUP_DATA,
            payload: {
                search: search,
                field: field,
                inx: inx
            }
        })
    }
}

export const setListLookupData = (field, payload) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_LIST_LOOKUP_DATA,
            payload: {
                prop: field.field.prop,
                data: payload.data
            }
        })
    }
}

export const setSelectedListLookupData = (field, value, inx) => {
    return (dispatch, getState) => {
        const list = getState().workflowCreateQuote[field.prop].filter(x => x[field.value] === value)[0]
        let props = []

        try {
            let _props = getState().workflowCreateQuote.fields.filter(x => x.name === field.parent)[0].fields[inx].filter(x => x.name === 'accessories')[0][field.prop]
            props = _props ? _props : []
        }
        catch (err) { }
        //console.log(props)
        props.push(list)
        //if(props)

        //console.log(props)
        dispatch({
            type: types.SET_COMPONENT_SELECTED_LIST_LOOKUP_DATA,
            payload: {
                inx: inx,
                prop: field,
                value: props
            }
        })
    }
}
export const setAccessoryValue = (field, props, value, inx, i) => {
    return (dispatch, getState) => {
        let data = getState().workflowCreateQuote.fields.filter(x => x.name === props.parent)[0].fields[inx].filter(x => x.name === 'accessories')[0][props.prop]
        data[i].costprice = value
        dispatch({
            type: types.SET_COMPONENT_SELECTED_LIST_LOOKUP_DATA,
            payload: {
                inx: inx,
                prop: props,
                value: data
            }
        })

    }
}
export const removeSelectedItemRow = (field, props, inx, i) => {
    return (dispatch, getState) => {
        let data = getState().workflowCreateQuote.fields.filter(x => x.name === props.parent)[0].fields[inx].filter(x => x.name === 'accessories')[0][props.prop]
        data = data.filter(x => x[props.value] !== field[props.value])
        dispatch({
            type: types.SET_COMPONENT_SELECTED_LIST_LOOKUP_DATA,
            payload: {
                inx: inx,
                prop: props,
                value: data
            }
        })
    }
}