import * as actions from './actions'
import * as progressBarActions from '../../ProgressBar/actions'
import * as requestActions from '../../Request/actions'
import * as routerActions from '../../Redirect/actions'
import * as types from './types'
import * as requestTypes from '../../Request/types'
import * as config from '../../../config'

export const approvePO = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.APPROVE_PO)
        //.debounceTime(500)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/disposaldecision/other',
                method: 'POST',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                },
                body: {
                    type: 'APPROVE_PO',
                    payload: payload.id,
                    invoicedate: payload.invoicedate,
                    invoicenumber: payload.invoicenumber,
                    invoiceamount: payload.invoiceamount,
                    completeddate: payload.completeddate,
                    user: payload.user
                }
            })
                .pipe(
            mergeMap((result) => [
                    actions.refreshbysupplier(payload.supplier_id),
                    requestActions.requestSucceeded(config.system.api + '')
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )

export const rejectPO = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.REJECT_PO)
        //.debounceTime(500)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/disposaldecision/other',
                method: 'POST',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                },
                body: {
                    type: 'REJECT_PO',
                    payload: payload
                }
            })
                .pipe(
            mergeMap((result) => [
                    actions.setRejectPO(result.response),
                    requestActions.requestSucceeded(config.system.api + '')
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )

export const uploadPODocuments = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.UPLOAD_PO_DOCUMENTS)
        //.debounceTime(500)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/disposaldecision/other',
                method: 'POST',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'Content-Type': 'application/json'
                },
                body: {
                    type: 'uploadPODocuments',
                    data: payload
                }
            })
                .pipe(
            mergeMap((result) => [
                    actions.refreshbysupplier(payload.payload.supplier_id),
                    requestActions.requestSucceeded(config.system.api + '')
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )

        
export const getposupplierlist = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
    types.GET_POSUPPLIERLIST,
)
    ,mergeMap(({ payload }) =>
        ajax$({
            //url: config.system.api + '/components/customertransaction/search?transactiontype=RC&reference='+ payload.search_text,
            url: config.system.api + '/components/supplier/searchor?suppliername=' + payload.search_text + '&accountnumber=' + payload.search_text,
            method: 'GET',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
                'take': 50,
                'order':'suppliername'
            }
        })
            .pipe(
            mergeMap((result) => [
                actions.setposupplierlist(result.response),
                requestActions.requestSucceeded(config.system.api + '')
            ])
            ,startWith(progressBarActions.progress(true))
            ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
            //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
    ))
    ,catchError((error) =>
        of$(routerActions.route('/support', false, error))
    ))
        
export const getApprovalInvoice = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
    types.GET_DISPOSAL_INVOICE,
)
,mergeMap(({ payload }) =>
ajax$({
    url: config.system.api + '/components/disposal/other',
    method: 'POST',
    headers: {
        'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
        'salt': store.value.cognito.authenticateUser.jwt,
        'datetime': store.value.cognito.authenticateUser.datetime,
    },
    body: payload
})
    .pipe(
            mergeMap((result) => [
        actions.setDisposalApprovalInvoice(result),
        requestActions.requestSucceeded(config.system.api + '')
    ])
    ,startWith(progressBarActions.progress(true))
    ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
    //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
))
,catchError((error) =>
of$(routerActions.route('/support', false, error))
))

