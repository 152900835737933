import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.SET_STR_ACTIVATION_COMPONENT_INPUT_FIELD_VALUE:
            return {
                ...state,
                [action.payload.prop]: action.payload.value
            }
        case types.STR_ACTIVATION_COMPONENT_RESET_FORM:
            return {
                registration: '',
                odometer: '',
                collectiondate: null,
                activationdate: null,
                startdate: null,
                terminationdate: null,
                comments: '',
                ponumber: '',
                quotefor: '',
                podate: '',
                send_marketing: false,
                marketingtype: '',
                marketingtype_id: null,
                customerdata: {},
                marketingtype_data: [],
                fields: props.fields
            }
        default:
            return state
    }
}

