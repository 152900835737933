export const calculators = []

export const modes = [{
    value: 1,
    text: 'Advance'
}, {
    value: 2,
    text: 'Arrears'
}]

export const solves = [{
    value: 1,
    text: 'Monthly Payment (PMT)'
}, {
    value: 2,
    text: 'Present Value (PV)'
}, {
    value: 3,
    text: 'Future Value (FV)'
}, {
    value: 4,
    text: 'Interest Rate Per Annum (I/YR)'
}]

export const errors = [{
    value: 'required',
    text: 'This field is required'
},
{
    value: 'invalidSymbol',
    text: 'You are tring to enter none number symbol'
},
{
    value: 'incompleteNumber',
    text: 'Number is incomplete'
},
{
    value: 'singleMinus',
    text: 'Minus can be use only for negativity'
},
{
    value: 'singleFloatingPoint',
    text: 'There is already a floating point'
},
{
    value: 'singleZero',
    text: 'Floating point is expected'
},
{
    value: 'required',
    text: 'This field is required'
},
{
    value: 'min',
    text: 'You are tring to enter number less than -10'
},
{
    value: 'max',
    text: 'You are tring to enter number greater than 12'
}]

export const calculator = {
    id: 0,
    mode: 2,
    solve: 1,

    search: '',
    vehicles: [],

    presentValue: '0.00',
    presentValueRaw: 0.0000,
    presentValueError: '',
    presentValueDisabled: false,

    futureValue: '0.00',
    futureValueRaw: 0.0000,
    futureValueError: '',
    futureValueDisabled: false,

    periodInMonths: '0.00',
    periodInMonthsRaw: 0.0000,
    periodInMonthsError: '',
    periodInMonthsDisabled: false,

    interestRatePerAnnum: '0.00',
    interestRatePerAnnumRaw: 0.0000,
    interestRatePerAnnumError: '',
    interestRatePerAnnumDisabled: false,

    residualValue: '30.00',
    residualValueRaw: 0.0000,
    residualValueError: '',
    residualValueDisabled: false,

    monthlyPayment: '0.00',
    monthlyPaymetRaw: 0.0000,
    monthlyPaymentError: '',
    monthlyPaymentDisabled: true,

    totalPayments: '0.00',
    totalPaymentsRaw: 0.0000,
    totalPaymentsError: '',
    totalPaymentsDisabled: true,

    totalInterest: '0.00',
    totalInterestRaw: 0.0000,
    totalInterestError: '',
    totalInterestDisabled: true
}

export const mode = 2
export const solve = 1

export const search = ''
export const vehicles = []

export const presentValue = '0.00'
export const presentValueError = ''
export const presentValueDisabled = false

export const futureValue = '0.00'
export const futureValueError = ''
export const futureValueDisabled = false

export const periodInMonths = '0.00'
export const periodInMonthsError = ''
export const periodInMonthsDisabled = false

export const interestRatePerAnnum = '0.00'
export const interestRatePerAnnumError = ''
export const interestRatePerAnnumDisabled = false

export const residualValue = '30.00'
export const residualValueError = ''
export const residualValueDisabled = false

export const monthlyPayment = '0.00'
export const monthlyPaymentError = ''
export const monthlyPaymentDisabled = true

export const totalPayments = '0.00'
export const totalPaymentsError = ''
export const totalPaymentsDisabled = true

export const totalInterest = '0.00'
export const totalInterestError = ''
export const totalInterestDisabled = true