export const REASSIGN_TASK_GET_TEAMS = 'REASSIGN_TASK_GET_TEAMS'
export const REASSIGN_TASK_SET_TEAMS = 'REASSIGN_TASK_SET_TEAMS'
export const REASSIGN_TASK_SELECT_TEAM = 'REASSIGN_TASK_SELECT_TEAM'
export const REASSIGN_TASK_SELECT_USER = 'REASSIGN_TASK_SELECT_USER'
export const REASSIGN_TASK_SHOW = 'REASSIGN_TASK_SHOW'
export const REASSIGN_TASK_HIDE = 'REASSIGN_TASK_HIDE'
export const REASSIGN_TASK_SUBMIT_REASSIGNMENT = 'REASSIGN_TASK_SUBMIT_REASSIGNMENT'
export const REASSIGNMENT_TASK_SET_SELECTION_COMMENT = 'REASSIGNMENT_TASK_SET_SELECTION_COMMENT'
export const REASSIGNMENT_TASK_SET_COMMENT = 'REASSIGNMENT_TASK_SET_COMMENT'
export const REASSIGN_TASK_GET_TASK = 'REASSIGN_TASK_GET_TASK'
export const REASSIGN_TASK_SET_TASK = 'REASSIGN_TASK_SET_TASK'
export const SET_SELECTED_WORKFLOW_REJECT_REASON = 'SET_SELECTED_WORKFLOW_REJECT_REASON'
export const SET_REJECT_DIALOG = 'SET_REJECT_DIALOG'