import * as types from './types'

export const getAdhocFieldInputData = (key, parent, parentFilters, child, childFilters, adhocFieldInput, extractChildData) => {
    const getQuery = (filters) => {
        return filters.reduce((str, x) => {
            str = str.length === 0
                ? '?' + x.field + '=' + x.value
                : '&' + x.field + '=' + x.value

            return str
        }, '')
    }

    return (dispatch, getState) => {
        dispatch({
            type: types.GET_ADHOC_FIELD_INPUT_DATA,
            payload: {
                key: key,
                parent: parent,
                parentFilters: parentFilters,
                query: getQuery(parentFilters),
                child: child,
                childFilters: childFilters,
                adhocFieldInput: adhocFieldInput,
                extractChildData: extractChildData
            }
        })
    }
}

export const setAdhocFieldInputData = (payload, data) => {
    const applyChildFilters = (filters, data) => {
        filters = Array.isArray(filters) ? filters : []

        return filters.length
            ? filters.reduce((results, filter) => results.filter(y => payload.extractChildData
                ? y[filter.field]
                : y[payload.child][filter.field] === filter.value), data)
            : data
    }

    const extractChildData = (data) => {
        return data.map(x => {
            return x[payload.child]
        })
    }

    const prepareData = (data) => {
        return data.map((x, i) => {
            return {
                ...x,
                id: i,
                selected: false,
                original: true,
                errorText: ''
            }
        })
    }

    return (dispatch, getState) => {
        dispatch({
            type: types.SET_ADHOC_FIELD_INPUT_DATA,
            payload: {
                key: payload.key,
                data: payload.childFilters
                    ? prepareData(applyChildFilters(payload.childFilters, payload.extractChildData ? extractChildData(data) : data))
                    : prepareData(data)
            }
        })
    }
}


export const addAdhocFieldInputData = (key, data) => {
    data.push(data[0])

    data = data.map((x, i) => {
        return i === data.length - 1
            ? Object.keys(x).reduce((obj, y) => {
                obj[y] = ''
                return obj
            }, {})
            : x
    })

    data = data.map((x, i) => {
        return i === data.length - 1
            ? {
                ...x,
                id: i,
                selected: false,
                original: false,
                errorText: ''
            }
            : x
    })

    return (dispatch, getState) => {
        dispatch({
            type: types.ADD_ADHOC_FIELD_INPUT_DATA,
            payload: {
                key: key,
                data: data
            }
        })
    }
}

export const chkAdhocComponentFieldInput = (key, data, field, value, index, selected) => {
    return (dispatch, getState) => {
        dispatch({
                type: types.SET_ADHOC_COMPONENT_FIELD_INPUT,
                payload: {
                    key: key,
                    data: data.map(x => {
                        return x.id === index
                            ? {
                                ...x,
                                selected: selected
                            }
                            : x
                    })
                }
            })
    }
}

export const setAdhocComponentFieldInput = (key, data, field, value, index, selected) => {
    return (dispatch, getState) => {
        selected && !field
            ? dispatch({
                type: types.SET_ADHOC_COMPONENT_FIELD_INPUT,
                payload: {
                    key: key,
                    data: data.map(x => {
                        return x.id === index
                            ? {
                                ...x,
                                selected: selected
                            }
                            : x
                    })
                }
            })
            : dispatch({
                type: types.SET_ADHOC_COMPONENT_FIELD_INPUT,
                payload: {
                    key: key,
                    data: data.map(x => {
                        return x.id === index
                            ? {
                                ...x,
                                [field.name]: value
                            }
                            : x
                    })
                }
            })
    }
}

export const validateAdhocFieldInputData = (component, relationship, query) => {
    return {
        type: types.VALIDATE_ADHOC_FIELD_INPUT_DATA,
        payload: ''
    }
}

export const removeAdhocFieldInputData = (component, relationship, query) => {
    return {
        type: types.REMOVE_ADHOC_FIELD_INPUT_DATA,
        payload: ''
    }
}
