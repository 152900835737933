import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Container, Row, Col } from 'react-grid-system'
import { Table, Button } from '@mui/material'
import Link from '@mui/material/Link';

import Card from '../../controls/card'
import Icon from '../Icon/component'

import * as actions from './actions'
import * as styles from './styles'

class Xero extends Component {
    componentDidMount() {
        this.props.actions.getXeroTransactions()
    }

    getLayout() {
        return <Container style={{ margin: 0 }} className="custom-container">     
                <Row>
                    <Col xl={4}>
                        < Button className={"global_button"}
                            secondary={true}
                            icon={<Icon iclass={'material-icons'} iname={'file_upload'} />}
                            onClick={(event) => {
                                this.props.actions.getConsentUrl()
                            }}
                        ><Icon iclass={'material-icons'} iname={'file_upload'} /> Get Consent URL</Button>
                    </Col>
                    <Col xl={4}>
                        < Button className={"global_button"}
                            secondary={true}
                            icon={<Icon iclass={'material-icons'} iname={'file_upload'} />}
                            onClick={(event) => {
                                this.props.actions.getXeroTransactions()
                            }}
                        ><Icon iclass={'material-icons'} iname={'file_upload'} /> Get Xero Transaction</Button>
                    </Col>
                    {/* <Col xl={4}>
                        < Button className={"global_button"}
                            secondary={true}
                            icon={<Icon iclass={'material-icons'} iname={'file_upload'} />}
                            onClick={(event) => {
                                this.props.actions.getContacts()
                            }}
                        ><Icon iclass={'material-icons'} iname={'file_upload'} /> Post Contacts</Button>
                    </Col>
                    <Col xl={4}>
                        < Button className={"global_button"}
                            secondary={true}
                            icon={<Icon iclass={'material-icons'} iname={'file_upload'} />}
                            onClick={(event) => {
                                this.props.actions.getInvoice()
                            }}
                        ><Icon iclass={'material-icons'} iname={'file_upload'} /> Post Invoice</Button>
                    </Col> */}
                </Row>
                <Row>
                    <Col xl={12}>
                        <br />
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        {
                        this.props.state.consentUrl
                            ? <Link href={this.props.state.consentUrl}  target="_blank">
                                {window.t.en('Go To Xero Consent')}
                            </Link>
                            : ''
                        }
                    </Col>
                </Row>
                
                <Row>
                        <Col xl={12}>
                            <div>
                            {
                                this.props.state.transactions
                                ? <Table className="table table-hover text-nowrap mb-0">
                                        <thead>
                                        <tr>
                                            <th>Xero Id</th>
                                            <th>Type</th>
                                            <th>Contact</th>
                                            <th>Inv No</th>
                                            <th>Date</th>
                                            <th>Total</th>
                                            <th>Error</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.props.state.transactions
                                                ? this.props.state.transactions.map(x => {
                                                    return <tr  style={{fontWeight: 'normal',fontSize: '14px'}}>
                                                        <td>{x.xero_id}</td>
                                                        <td>{x.type}</td>
                                                        <td>{x.contactname}</td>
                                                        <td>{x.invoiceNumber}</td>
                                                        <td>{x.date}</td>
                                                        <td>{x.total}</td>
                                                        <td>{x.error}</td>
                                                    </tr>
                                                })
                                                : ''
                                            }
                                        </tbody>
                                    </Table>
                                    : ''
                            }
                            </div>
                        </Col>
                    </Row>  
            </Container>
    }

    getView() {
        return <Card
            title={'XERO acounting'}
            subtitle={'Get concent url'}
            content={this.getLayout()}
        />
    }

    render() {
        return (
            this.getView()
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.xero,
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Xero)