import * as props from './props'
import * as types from './types'
import * as _ from 'lodash'

export default (state = props, action) => {
    switch (action.type) {
        case types.TOGGLE_TEAM_FORM:
            return {
                ...state,
                team: true,
                edit: !state.edit,
                assign: false
            }
        case types.TOGGLE_ASSIGNMENT_FORM:
            return {
                ...state,
                assign: true,
                team: false,
                edit: true,
                view: action.payload
            }
        case types.CHANGE_SELECTED_TEAM:
            return {
                ...state,
                changed: false,
                selectedTeam: action.payload
            }
        case types.SET_TEAM_USERS:
            return {
                ...state,
                users: action.payload.map(x => {
                    return x.user
                })
            }
        case types.SET_TEAM_COMPONENTS:
            console.log('SET_TEAM_COMPONENTSx all', action.payload)
            console.log('SET_TEAM_COMPONENTSx distinct', _.uniqBy(action.payload,'component_id'))
            let components = _.uniqBy(action.payload,'component_id')
            components = _.orderBy(components, 'title')
            return {
                ...state,
                components: components
            }
        case types.SET_TEAM_ACCOUNTS:
            return {
                ...state,
                //accounts: action.payload.accounts,
                accountBusinesses: action.payload.accountBusinesses,
                accountBusinessCustomers: action.payload.accountBusinessCustomers
            }
        case types.GET_ALL_USERS:
            return {
                ...state,
                allUsersLoading: true
            }
        case types.SET_ALL_USERS:
            return {
                ...state,
                allUsersLoading: false,
                allUsers: action.payload
            }
        case types.GET_ALL_ACCOUNTS:
            return {
                ...state,
                allAccountsLoading: true
            }
        case types.SET_ALL_ACCOUNTS:
            return {
                ...state,
                allAccountsLoading: false,
                allAccounts: action.payload
            }
        case types.GET_ALL_TEAMS:
            return {
                ...state,
                allTeamsLoading: true
            }
        case types.SET_ALL_TEAMS:
            return {
                ...state,
                allTeamsLoading: false,
                allTeams: action.payload
            }
        case types.GET_ALL_COMPONENTS:
            return {
                ...state,
                allComponentsLoading: true
            }
        case types.SET_ALL_COMPONENTS:
            return {
                ...state,
                allComponentsLoading: false,
                allComponents: action.payload
            }
        case types.SET_ALL_EVENTS:
            return {
                ...state,
                allEventsLoading: false,
                allEvents: action.payload
            }
        case types.SET_ALL_CUSTOMERS:
            return {
                ...state,
                allCustomersLoading: false,
                allCustomers: action.payload
            }
        case types.GET_ALL_REPORTS:
            return{
                ...state,
                allReportsLoading : false,
            }
        case types.SET_ALL_REPORTS:
            return{
                ...state,
                allReportsLoading : false,
                allReports : action.payload.data
            }
        case types.ASSIGN_REPORT_TO_TEAM:
            return{
                ...state,
                changed : true,
                reports : action.payload
            }
        case types.UNASSIGN_REPORT_FROM_TEAM:
            return{
                ...state,
                changed : true,
                reports : action.payload
            }
        case types.SET_WORKQUEUE_USER_AND_TEAM_LINK:
            return{
                ...state,
                event_users : action.payload.users,
                event_teams : action.payload.teams
            }
        case types.SET_WORKQUEUE_LINKED_USER:
            return{
                ...state,
                changed: true,
                event_users : action.payload,
            }
        case types.SET_WORKQUEUE_LINKED_TEAM:
            return{
                ...state,
                changed: true,
                event_teams : action.payload
            }
        case types.CHANGE_SELECTED_REPORT:
            return {
                ...state,
                selectedReport: action.payload
            }
        case types.SET_WORKQUEUE_SELECTED_WORKQUEUE_ID:
            return {
                ...state,
                workqueue_id: action.payload
            }
        case types.SET_TEAM_REPORTS:
            return{
                ...state,
                reports: action.payload.map(x => {
                    return x.report
                })
            }
        case types.ASSIGN_USER_TO_TEAM:
            return {
                ...state,
                changed: true,
                users: action.payload
            }
        case types.UNASSIGN_USER_FROM_TEAM:
            return {
                ...state,
                changed: true,
                users: action.payload
            }
        case types.CHANGE_SELECTED_USER:
            return {
                ...state,
                selectedUser: action.payload
            }
        case types.ASSIGN_COMPONENT_TO_TEAM:
            return {
                ...state,
                changed: true,
                components: action.payload
            }
        case types.UNASSIGN_COMPONENT_FROM_TEAM:
            return {
                ...state,
                changed: true,
                components: action.payload
            }
        case types.CHANGE_SELECTED_COMPONENT:
            return {
                ...state,
                selectedComponent: action.payload
            }

        case types.ASSIGN_ACCOUNT_TO_TEAM:
            return {
                ...state,
                changed: true,
                accounts: action.payload
            }
        case types.UNASSIGN_ACCOUNT_FROM_TEAM:
            return {
                ...state,
                changed: true,
                accounts: action.payload
            }
        case types.CHANGE_SELECTED_ACCOUNT:
            return {
                ...state,
                selectedAccount: action.payload
            }
        case types.CHANGE_SELECTED_BUSINESS:
            return {
                ...state,
                selectedBusiness: action.payload
            }
        case types.CHANGE_SELECTED_CUSTOMER:
            return {
                ...state,
                selectedCustomer: action.payload
            }
        case types.SAVED_TEAM_ASSIGNMENT_CHANGES:
            return {
                ...state,
                changed: false
            }
        case types.SAVED_TEAM_MANAGEMENT_CHANGES:
            return {
                ...state,
                changed: false
            }
        case types.TOGGLE_TEAM_ACTIVATION:
            return {
                ...state,
                changed: true,
                allTeams: state.allTeams.map(x => {
                    if (x.team_id === action.payload) {
                        return {
                            ...x,
                            active: !x.active
                        }
                    }
                    else {
                        return { ...x }
                    }
                })
            }
        case types.TOGGLE_BUSINESS_MODE:
            return {
                ...state,
                businessMode: !state.businessMode
            }
        case types.SET_ACCOUNT_BUSINESSES:
            return {
                ...state,
                allBusinesses: action.payload
            }
        case types.SET_ALL_USER_CUSTOMERS:
            return {
                ...state,
                allUserCustomers: action.payload
            }
        case types.SET_CUSTOMER_TO_LINK:
            return {
                ...state,
                toLinkCustomer: action.payload
            }
        case types.SET_USER_TO_LINK:
            return {
                ...state,
                toLinkUser: action.payload
            }
        case types.SET_ACCOUNT_BUSINESS_CUSTOMERS:
            return {
                ...state,
                allCustomers: action.payload
            }
        case types.ASSIGN_ACCOUNT_BUSINESS_TO_TEAM:
            return {
                ...state,
                changed: true,
                accountBusinesses: action.payload
            }
        case types.ASSIGN_ACCOUNT_BUSINESS_CUSTOMER_TO_TEAM:
            return {
                ...state,
                changed: true,
                accountBusinessCustomers: action.payload
            }
        case types.UNASSIGN_ACCOUNT_BUSINESS_FROM_TEAM:
            return {
                ...state,
                changed: true,
                accountBusinesses: action.payload
            }
        case types.UNASSIGN_ACCOUNT_BUSINESS_CUSTOMER_FROM_TEAM:
            return {
                ...state,
                changed: true,
                accountBusinessCustomers: action.payload
            }
        case types.ADD_NEW_TEAM_STRUCTURE:
            return {
                ...state,
                changed: true
            }
        case types.SET_SELECTED_TEAM_OPTION_STATE:
            return {
                ...state,
                [ action.payload.name]: action.payload.data,
            }
        default:
            return state
    }
}