import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import { Container, Row, Col } from 'react-grid-system'
import Button from '@mui/material/Button'

import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'

import Dropzone from 'react-dropzone'
import Iframe from 'react-iframe'

import Drawer from '../../../controls/drawer'
import Card from '../../../controls/card'
import Icon from '../../Icon/component'
import Upload from '../../Upload/component'

import * as styles from './styles'
import * as actions from './actions'

class WorkflowDisposalChecksheet extends Component {

    getViewForm() {     
         return <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={12}>
                           <Button variant='contained' className='global_button'
                                primary={true}
                                onClick={(event) => {
                                    event.preventDefault()
                                    this.props.actions.getChecksheeet(this.props.state.infosheet_inx)
                                }}>
                                {<Icon iclass={'material-icons'} iname={'cloud_download'} />} &nbsp; {window.t.en('Generate Checksheet')}
                            </Button>
                        </Col>
                    </Row>
            </Container>
        }

    getEditForm() {     
         return <Container style={{ margin: 0 }} className="custom-container">
                    {/* <Row>
                        <Col xl={12}>
                            <Upload />
                        </Col>
                    </Row> */}
                    <Row>
                        <Col xl={12}>
                            {
                                this.props.state.checksheet_preview
                                    ? <Iframe url={this.props.state[this.props.state.infosheet_inx + '_checksheet_preview']}
                                        width="90%"
                                        height="200px"
                                        id={'receipt_checksheet_preview'}
                                        className="myClassname"
                                        display="initial"
                                        position="relative"
                                        allowFullScreen />
                                    : <Dropzone
                                        accept={'.doc,.docx,.jpeg,.jpg,.pdf,.png,.xls,.xlsx,.msg'}
                                        style={styles.dropZone}
                                        hintText={'Upload'}
                                        onDrop={(files) => {
                                            //component.actions.toggleDropzoneVisibility()
                                            let handleDropImages;
                                            //console.log('acceptedFiles.length', files)
                                        }}
                                        onDropAccepted={(files) => {
                                            this.props.actions.acceptedChecksheeetDocuments(files, this.props.state.infosheet_inx)
                                        }}
                                        onDropRejected={(files) => {
                                            this.props.actions.resetChecksheeetDocuments(this.props.state.infosheet_inx)
                                        }}
                                    >
                                        <p style={styles.watermark}>
                                            Drag and drop file here, or click to select file to upload....
                            </p>
                                    </Dropzone>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <br/>
                           <Button variant='contained' className='global_button'
                                onClick={(event) => {
                                    event.preventDefault()
                                    this.props.actions.resetChecksheeetDocuments(this.props.state.infosheet_inx)
                                }}>
                                    {window.t.en('Reset')}
                            </Button>
                        </Col>
                    </Row>
                </Container>
        }

        getView() {
            return <div> 
                <div className="text-center-off">
                    <div className="font-weight-bold font-size-lg mb-0 text-black">
                    Disposal Checksheet
                    </div>
                    <p className="text-black-50">Download disposal checksheet</p>
                </div>
                {this.getViewForm()}
                <br />
                <div className="divider" />
                <br />
                <div className="text-center-off">
                    <div className="font-weight-bold font-size-lg mb-0 text-black">
                   Upload Completed Checksheet
                    </div>
                    <p className="text-black-50">Upload completed disposal checksheet</p>
                </div>
                {this.getEditForm()}
            </div>
        }
            
        getLayout() {
            return  <Drawer 
                onClose={(event, value) => {
                    this.props.actions.toggleInfoSheet(null,'inforsheet_checksheet')
                }}
                open={this.props.state.inforsheet_checksheet}
                title={'Checksheet'}
                subtitle={'Disposal checksheet'}
                content={this.getView()}
            />
            // return <Drawer
            //         width={800}
            //         anchor={'right'}
            //         openSecondary={true}
            //         open={this.props.state.inforsheet_decision}
            //         onRequestChange={(event) => {
            //             event.preventDefault()
            //             this.props.actions.toggleInfoSheet(null,'inforsheet_decision')
            //         }}>
            //         {this.getView()}
            //     </Drawer>
        }

        render() {
            this.data = this.props.state.yardlist[this.props.state.infosheet_inx]
            this.disposal = this.data.disposal.length ? this.data.disposal[0] : {}
            return this.getLayout()
        }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.workflowDisposal,
            user: {
                ...state.user
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowDisposalChecksheet)