export const SET_XERO_CONSENT_URL = 'SET_XERO_CONSENT_URL'
export const GET_XERO_CONSENT_URL = 'GET_XERO_CONSENT_URL'

export const GET_XERO_CONTACTS_LIST = 'GET_XERO_CONTACTS_LIST'
export const SET_XERO_CONTACTS_LIST = 'SET_XERO_CONTACTS_LIST'

export const XERO_API_INVOICE_PUSH = 'XERO_API_INVOICE_PUSH'
export const SET_XERO_API_INVOICE_PUSH = 'SET_XERO_API_INVOICE_PUSH'

export const GET_XERO_TRANSACTIONS = 'GET_XERO_TRANSACTIONS'
export const SET_XERO_TRANSACTIONS = 'SET_XERO_TRANSACTIONS'