import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import { Container, Row, Col } from 'react-grid-system'

import { Table, Button, Dialog } from '@mui/material'

import Card from '../../../controls/card'
import MultiSelect from '../../../controls/multiselect'
import AutoComplete from '../../../controls/autocomplete'
import ViewVehicle from '../../../controls/view_vehicle'
import ViewSupplier from '../../../controls/view_supplier'
import SupplierSpend from '../../../controls/view_supplier_spend'

import * as workflowTaskInputAction from '../../WorkflowTaskInput/actions'
import * as styles from './styles'
import * as actions from './actions'
import * as config from '../../../config'

class POHeader extends Component {

    componentDidMount() {
        this.props.actions.resetComponent()
        this.props.actions.getPreAuthNumber()
        this.props.actions.setValidationState('poHeader')
        this.props.actions.getSupplierTypes()
        this.props.actions.setTicketNumber()
        //console.log('setSupplierTypes onload')
    }

    // componentWillUnmount() {
    //     this.props.actions.setValidationState('')
    // }

    getDuplicateData(data) {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>
                    <div>
                        <Table className="table table-hover text-nowrap mb-0">
                            <thead>
                                <tr>
                                    <th>Auth No</th>
                                    <th>Type</th>
                                    <th>Description</th>
                                    <th>Odometer</th>
                                    <th>Task No</th>
                                    <th>Booked By</th>
                                    <th>Booked Date</th>
                                    <th>Supplier</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data.map(x => {
                                        return <tr style={{ fontWeight: 'normal', fontSize: '14px' }}>
                                            <td>{x.authnumber ? x.authnumber : x.preauthnumber}</td>
                                            <td>{x.maintdescription}</td>
                                            <td>{x.comments}</td>
                                            <td>{x.odometer}</td>
                                            <td>{x.tasknumber}</td>
                                            <td>{x.bookedby}</td>
                                            <td>{moment(x.bookeddate).format('YYYY-MM-DD hh:mm')}</td>
                                            <td>{x.suppliername}</td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </Row>
        </Container>
    }

    getForm() {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={6}>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={12}>
                                <AutoComplete
                                    key={'dll_vehicle_id'}
                                    name={'dropdown_po_type_select'}
                                    title={window.t.en('Select vehicle registration')}
                                    info={{
                                        show: false
                                    }}
                                    fullWidth={true}
                                    fieldvalue={'vehicle_id'}
                                    label={'Select Vehicle Registration'}
                                    displayfields={['licenseplateno', 'makemodelyear']}
                                    displaywidth={['20', '80']}
                                    data={this.props.state.vehicles}
                                    value={this.props.state.fields.selectedVehicle.data ? this.props.state.fields.selectedVehicle.data.text : ''}
                                    onSelect={(args) => {
                                        this.props.actions.setVehicleAutoCompleteSelected(args)
                                        //console.log('setVehicleAutoCompleteSelected',args)
                                    }}
                                    onClick={() => {
                                        //console.log('this.props.state.purchaseOrderType',this.props.state.purchaseOrderTypes)
                                        this.props.actions.getVehicleListAutoCompleteMenuItems('')
                                    }}
                                    onFilter={(search) => {
                                        if (search) {
                                            this.props.actions.getVehicleListAutoCompleteMenuItems(search)
                                        } else {
                                            this.props.actions.getVehicleListAutoCompleteMenuItems('')
                                            this.props.actions.setVehicleAutoCompleteSelected({ text: '', value: null })
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <div style={{ marginTop: '0px' }}>
                                    <AutoComplete
                                        name={'dropdown_po_type_select'}
                                        title={window.t.en('Select maintenance type')}
                                        info={{
                                            show: false
                                        }}
                                        fullWidth={true}
                                        fieldvalue={'value'}
                                        fieldtext={'text'}
                                        label={'Select Maintenance Type'}
                                        data={this.props.state.purchaseOrderTypes}
                                        value={this.props.state.fields.selectedPurchaseOrderType.data ? this.props.state.fields.selectedPurchaseOrderType.data.text : ''}
                                        onSelect={(args) => {
                                            //console.log('onSelect',args)

                                            ////console.log('this.props.state.purchaseOrderType',this.props.state.purchaseOrderTypes)
                                            if (!this.props.state.fields.selectedVehicle.data)
                                                this.props.actions.winSelectMaintenanceType(true)
                                            else {

                                                this.props.actions.checkDuplicate(args,
                                                    this.props.state.fields.maintenanceclass.data,
                                                    this.props.state.fields.selectedVehicle.data)

                                                this.props.actions.selectMaintenanceClass({ text: null, value: null })
                                                this.props.actions.selectPurchaseOrderType(args)
                                            }
                                        }}
                                        onClick={() => {
                                            if (!this.props.state.fields.selectedVehicle.data) this.props.actions.winSelectMaintenanceType(true)
                                        }}
                                        onFilter={(search) => {
                                            if (!this.props.state.fields.selectedVehicle.data) {
                                                this.props.actions.winSelectMaintenanceType(true)
                                            }
                                            else {
                                                this.props.actions.selectMaintenanceClass({ text: null, value: null })
                                                if (search) {
                                                    const purchaseOrderTypes = this.props.state.purchaseOrderTypes;
                                                    const selectedPurchaseOrderType = purchaseOrderTypes.find(p => p.text === search)
                                                    if (selectedPurchaseOrderType?.value) {
                                                        this.props.actions.selectPurchaseOrderType({ text: selectedPurchaseOrderType.text, value: selectedPurchaseOrderType.value })
                                                    }
                                                } else {
                                                    this.props.actions.selectPurchaseOrderType({ text: '', value: null })
                                                }
                                            }
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                        {
                            (this.props.state.fields.selectedPurchaseOrderType?.data?.text === 'Unscheduled Maintenance' || this.props.state.fields.selectedPurchaseOrderType?.data?.text === 'Scheduled Maintenance') &&
                            <Row>
                                <Col xl={12} >
                                    <div style={{ marginTop: '0px' }}>
                                        <AutoComplete
                                            name={'dropdown_po_type_class'}
                                            title={window.t.en('Select maintenance class')}
                                            info={{
                                                show: false
                                            }}
                                            fullWidth={true}
                                            fieldvalue={'maintenanceclass_id'}
                                            displayfields={['maintenanceclass']}
                                            fieldtext={'maintenanceclass'}
                                            label={'Select Maintenance Class'}
                                            data={this.props.state.maintenanceclass}
                                            value={this.props.state.fields.maintenanceclass.data ? this.props.state.fields.maintenanceclass.data.text : ''}
                                            onSelect={(args) => {
                                                this.props.actions.selectMaintenanceClass(args)
                                            }}
                                            onClick={() => {
                                                this.props.actions.getMaintenanceClass('')
                                            }}
                                            onFilter={(search) => {
                                                if (search) {
                                                    this.props.actions.getMaintenanceClass(search)
                                                    this.props.actions.selectMaintenanceClass({ text: search, value: null })
                                                } else {
                                                    this.props.actions.getMaintenanceClass('')
                                                    this.props.actions.selectMaintenanceClass({ text: '', value: null })
                                                }
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        }
                        {
                            this.props.state.fields.selectedVehicle.data?.length && this.props.state.vehicles.length
                                ? <Row>
                                    <Col xl={12} >
                                        <div style={{ marginTop: '50px' }}>
                                            <MultiSelect
                                                name={'autocomplete_customer_contacts'}
                                                title={window.t.en('Select customer contact')}
                                                info={{
                                                    show: false
                                                }}
                                                fullWidth={true}
                                                fieldvalue={'contact_id'}
                                                fieldtext={'email'}
                                                label={'Select Customer Contacts'}
                                                displayfields={['firstname', 'email']}
                                                displaywidth={['75', '25']}
                                                data={this.props.state.customercontacts}
                                                //value={this.props.state.fields.selectedSupplier.data ? this.props.state.fields.selectedSupplier.data.text : ''}
                                                onSelect={(args) => {
                                                    //console.log('setSupplierAutoCompleteSelected',args)
                                                    this.props.actions.setSelectedCustomerContactList(args)
                                                    //this.props.actions.getSelectedSupplierDetails(args.value)
                                                    //this.props.actions.getSupplierMonthlyUsage(args.value)
                                                }}
                                                onClick={() =>
                                                    this.props.actions.getCustomerContactsAutoCompleteItems(this.props.state.fields.selectedVehicle.data.value)
                                                }
                                                onFilter={(args) => {
                                                    ///this.props.actions.getCustomerContactsAutoCompleteItems({text: args, value: null})
                                                    this.props.actions.getCustomerContactsAutoCompleteItems(this.props.state.fields.selectedVehicle.data.value)
                                                }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                : <div></div>
                        }
                        <Row>
                            <Col xl={12}>
                                <div style={{ marginTop: '20px' }}>
                                    <MultiSelect
                                        name={'autocomplete_purchase_order_copy_user'}
                                        title={window.t.en('Select copy user')}
                                        info={{
                                            show: false
                                        }}
                                        label={'Copy User'}
                                        fullWidth={true}
                                        data={this.props.state.users}
                                        value={this.props.state.fields.selectedUser.data ? this.props.state.fields.selectedUser.data.text : ''}
                                        onSelect={(args) => {
                                            this.props.actions.setUserAutoCompleteSelected(args)
                                        }}
                                        onClick={() =>
                                            this.props.actions.getUserAutoCompleteMenuItems('')
                                        }
                                        onFilter={(args) => {
                                            this.props.actions.setUserAutoCompleteSelected({ text: args, value: null })
                                            this.props.actions.getUserAutoCompleteMenuItems(args)
                                        }} />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                {this.props.state.fields.selectedPurchaseOrderType.data ?
                                    <AutoComplete
                                        name={'autocomplete_purchase_order_'}
                                        title={window.t.en('Select supplier')}
                                        info={{
                                            show: false
                                        }}
                                        label={'Select Supplier'}
                                        fullWidth={true}
                                        fieldvalue={'supplier_id'}
                                        displayfields={['suppliername', 'accountnumber', 'suppliertype']}
                                        displaywidth={['100', '50', '50']}
                                        data={this.props.state.suppliers}
                                        value={this.props.state.fields.selectedSupplier.data ? this.props.state.fields.selectedSupplier.data.text : ''}
                                        onSelect={(args) => {
                                            //console.log('setSupplierAutoCompleteSelected',args)
                                            this.props.actions.setSupplierAutoCompleteSelected(args)
                                            this.props.actions.getSelectedSupplierDetails(args.value)
                                        }}
                                        onClick={() => {
                                            if (this.props.state.fields.selectedPurchaseOrderType.data) {
                                                this.props.actions.getSupplierAutoCompleteMenuItems('', '', this.props.state.fields.selectedPurchaseOrderType.data)
                                            }
                                        }}
                                        onFilter={(search) => {
                                            if (this.props.state.fields.selectedPurchaseOrderType.data && search) {
                                                this.props.actions.getSupplierAutoCompleteMenuItems(search, '', this.props.state.fields.selectedPurchaseOrderType.data)
                                                const suppliers = this.props.state.suppliers;
                                                const selectedSupplier = suppliers.find(s => s.suppliername === search);
                                                if (selectedSupplier) {
                                                    this.props.actions.setSupplierAutoCompleteSelected({ text: selectedSupplier.suppliername, value: selectedSupplier.supplier_id })
                                                }
                                            } else {
                                                this.props.actions.getSupplierAutoCompleteMenuItems('', '', this.props.state.fields.selectedPurchaseOrderType.data)
                                                this.props.actions.setSupplierAutoCompleteSelected({ text: '', value: null })
                                            }
                                        }}
                                    /> : null
                                }
                            </Col>
                        </Row>
                        {
                            this.props.state.fields.selectedSupplier.data
                                ? <Row>
                                    <Col xl={12}>
                                        <MultiSelect
                                            name={'autocomplete_supplier_contacts'}
                                            title={window.t.en('Select customer contact')}
                                            info={{
                                                show: false
                                            }}
                                            label={'Select Supplier Contacts'}
                                            fullWidth={true}
                                            fieldvalue={'contact_id'}
                                            fieldtext={'email'}
                                            displayfields={['firstname', 'email']}
                                            displaywidth={['75', '25']}
                                            data={this.props.state.suppliercontacts}
                                            //value={this.props.state.fields.selectedSupplier.data ? this.props.state.fields.selectedSupplier.data.text : ''}
                                            onSelect={(args) => {
                                                //console.log('setSupplierAutoCompleteSelected',args)
                                                this.props.actions.setSelectedSupplierContactList(args)
                                                //this.props.actions.setSupplierAutoCompleteSelected(args)
                                                //this.props.actions.getSelectedSupplierDetails(args.value)
                                                //this.props.actions.getSupplierMonthlyUsage(args.value)
                                            }}
                                            onClick={() =>
                                                this.props.actions.getSupplierContactsAutoCompleteItems(this.props.state.fields.selectedSupplier.data.value)
                                            }
                                            onFilter={(search) => {
                                                ///this.props.actions.getCustomerContactsAutoCompleteItems({text: args, value: null})
                                                if (!search) {
                                                    this.props.actions.setSelectedSupplierContactList({ text: '', value: null })
                                                }
                                                this.props.actions.getSupplierContactsAutoCompleteItems(this.props.state.fields.selectedSupplier.data.value)
                                            }}
                                        />

                                    </Col>
                                </Row>
                                : <div></div>
                        }
                    </Container>
                </Col>
                <Col xl={6}>
                    {
                        this.props.state.fields.selectedVehicle.data
                            ? <div>
                                <iframe src={`${config.oq.uri}8d4bef71ce0d3e19ca39742aa7f7630ff22b8327b30ab5ee978b3594a7e982b441a18effc62363bd698932d7c5c68b86776ff093555885cd76a3c9c402f6b7d8&filters=registration=%27${this.props.state.fields.selectedVehicle.data.text}%27#`}
                                    height="380px"
                                    width="100%"
                                    style={{
                                        border: "none",
                                        margin: 0,
                                        padding: '-25px',
                                        alignSelf: "center",
                                    }} />
                            </div>
                            : <div></div>
                    }

                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                </Col>
            </Row>
        </Container>
    }


    getPOLayout() {
        return <div>
            <Card
                title={'Create Booking Authorisation'}
                subtitle={'Booking Pre Authorisation No: ' + this.props.state.preauth + ' Ticket No: ' + this.props.state.ticketnumber}
                content={this.getForm()}
            />
            {
                this.props.state.fields.selectedSupplier.data
                    ? <SupplierSpend key={'SupplierSpend'}
                        id={this.props.state.fields.selectedSupplier.data.value}
                    />
                    : ''
            }
            {
                this.props.state.fields.selectedVehicle.data
                    ? <ViewVehicle
                        key={this.props.state.fields.selectedVehicle.data.value}
                        id={this.props.state.fields.selectedVehicle.data.value}
                    />
                    : ''
            }
            {
                this.props.state.fields.selectedSupplier.data
                    ? <ViewSupplier
                        key={this.props.state.fields.selectedSupplier.data.value}
                        id={this.props.state.fields.selectedSupplier.data.value}
                    />
                    : ''
            }

            <Dialog
                open={this.props.state.suspended_win}
                onClose={(event) => {
                    event.preventDefault()
                    this.props.actions.toggleSupplierSuspended(false)
                }}
            >
                <div className="text-center p-5">
                    <h4 className="font-weight-bold mt-4">
                        SUPPLIER SUSPENDED
                    </h4>
                    <p className="mb-0 font-size-lg text-muted">
                        {this.props.state.selectedSupplierSuspended ? this.props.state.selectedSupplierSuspended.suspendedreason : 'Supplier is suspended please select another supplier.'}
                    </p>
                    <div className="pt-4">
                        <Button
                            onClick={(event) => {
                                event.preventDefault()
                                this.props.actions.toggleSupplierSuspended(false)
                            }}
                            className=" btn-pill mx-1">
                            <span className="btn-wrapper--label">Ok</span>
                        </Button>
                    </div>
                </div>
            </Dialog>


            <Dialog
                open={this.props.state.selectmaintenance_win}
                onClose={(event) => {
                    event.preventDefault()
                    this.props.actions.winSelectMaintenanceType(false)
                }}
            >
                <div className="text-center p-5">
                    <h4 className="font-weight-bold mt-4">
                        Vehicle Missing
                    </h4>
                    <p className="mb-0 font-size-lg text-muted">
                        {'Please select vehicle registration type before you can select maintenance type.'}
                    </p>
                    <div className="pt-4">
                        <Button
                            onClick={(event) => {
                                event.preventDefault()
                                this.props.actions.winSelectMaintenanceType(false)
                            }}
                            className=" btn-pill mx-1">
                            <span className="btn-wrapper--label">Ok</span>
                        </Button>
                    </div>
                </div>
            </Dialog>


            <Dialog
                open={this.props.state.upcoming.upcoming}
                onClose={(event) => {
                    event.preventDefault()
                    this.props.actions.toggleUpcomingSupplierSuspended(false)
                }}
            >
                <div className="text-center p-5">
                    <h4 className="font-weight-bold mt-4">
                        UPCOMING SUPPLIER SUSPENSION
                    </h4>
                    <p className="mb-0 font-size-lg text-muted">
                        {this.props.state.upcoming.bee ? 'Supplier BEE certitifcate will expire with in 30 days.' : ''}
                    </p>
                    <p className="mb-0 font-size-lg text-muted">
                        {this.props.state.upcoming.tax ? 'Supplier Tax certitifcate will expire with in 30 days.' : ''}
                    </p>
                    <p className="mb-0 font-size-lg text-muted">
                        {this.props.state.upcoming.sla ? 'Supplier SLA agreement will expire with in 30 days.' : ''}
                    </p>
                    <div className="pt-4">
                        <Button
                            onClick={(event) => {
                                event.preventDefault()
                                this.props.actions.toggleUpcomingSupplierSuspended(false)
                            }}
                            className="btn-pill mx-1">
                            <span className="btn-wrapper--label">Ok</span>
                        </Button>
                    </div>
                </div>
            </Dialog>

            <Dialog
                open={this.props.state.duplicatemaintenance_win}
                onClose={(event) => {
                    event.preventDefault()
                    this.props.actions.winDuplicateMaintenance(false)
                }}
                style={{ width: '80% !important', maxWidth: '80% !important' }}
                fullWidth={true}
                maxWidth="xl"
            >
                <div className="text-center p-5">
                    <h4 className="font-weight-bold mt-4">
                        Duplicate {this.props.state.fields.selectedPurchaseOrderType?.data?.text} Transaction
                    </h4>
                    <p className="mb-0 font-size-lg text-muted">
                        {'The following' + ' ' + (this.props.state.fields.selectedPurchaseOrderType?.data?.text) + ' ' + 'are currently open of this vehicle with same type:'}
                    </p>
                    <p className="mb-0 font-size-lg text-muted">
                        {
                            this.getDuplicateData(this.props.state.duplicate_data)
                        }
                    </p>
                    <hr />
                    <p className="mb-0 font-size-lg text-danger">
                        {'The open transaction demands action;it must either be completed or the operation should be cancelled'}
                    </p>
                    <div className="pt-4">
                        <Button
                            onClick={(event) => {
                                event.preventDefault()
                                this.props.actions.winDuplicateMaintenance(false)
                            }}
                            className=" btn-pill mx-1">
                            <span className="btn-wrapper--label">Ok</span>
                        </Button>
                    </div>
                </div>
            </Dialog>
        </div>
    }

    getErrorLayout() {
        return <Card
            title={'Error'}
            subtitle={'Connection did not respond  '}
            content={<div>
                <h4>Please check following:</h4>
                <ul>
                    <li>Please ensure that the engine is connected</li>
                    <li>Please ensure that you do not log in twice</li>
                </ul>
                <i>Alternatively, you can contact the system administrator for further assistance.</i>
            </div>}
        />
    }

    render() {
        return (this.props.state.preauth ? this.getPOLayout() : this.getErrorLayout())
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.poHeader
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...workflowTaskInputAction }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(POHeader)
