import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.SET_COMPONENT_FML_QUOTATION_ITEMS_INPUT_VALUE:
         return {
             ...state,
             [action.payload.prop]: action.payload.value
         } 
        case types.SET_SELECTED_COMPONENT_NAME_FROM_SELECTION:
         return {
             ...state,
             components_selected: action.payload
         } 
        case types.SET_COMPONENT_FML_QUOTATION_CHIP_DATA_LIST:
         return {
             ...state,
             chip_accessory: action.payload
         } 
        //  case types.SET_QUOTE_ITEM_TYPES:
        //  return {
        //      ...state,
        //      itemtype: action.payload
        //  }
        //  case types.SET_SELECTED_QUOTE_ITEM_TYPES:
        //  return {
        //      ...state,
        //      component_id: action.payload.value,
        //      componentname: action.payload.text
        //  }
        //  case types.LOAD_QUOTE_TYPE_ITEMS_LIST:
        //  return {
        //      ...state,
        //      [action.payload.componentname]: action.payload.componentname
             
        //  }
        //  case types.SET_QUOTE_TYPE_ITEMS_LIST:
        //  return {
        //      ...state,
        //      itemlist: action.payload
        //  }
        //  case types.SET_SELECTED_QUOTE_ITEM_LIST:
        //  return {
        //      ...state,
        //      item_id: action.payload
        //  }
        //  case types.SET_SELECTED_ITEM_LIST:
        //  return {
        //      ...state,
        //      selected: action.payload
        //  }
        default:
            return state
    }
}