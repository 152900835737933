import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import get from 'lodash/get'
import moment from 'moment'

import * as config from '../../config'
import { Table } from "@mui/material";
import { Container, Row, Col } from 'react-grid-system'
import ViewQuotation from '../../controls/view_quotation'
import ViewCustomer from '../../controls/view_customer'

import TextField from '@mui/material/TextField'
import Fields from '../../controls/cardfields'
import Card from '../../controls/card'
import PdfViewer from '../PDF/component'
import Loading from '../Loading/component'

import * as styles from './styles'
import * as actions from './actions'

class WorkflowQuoteApprove extends Component {
    

    componentDidMount() {
   
    }
    getVehicles(data) {
        return <Card 
                title={window.t.en('Vehicle List')}
                subtitle={window.t.en('Selected vehicle for quote')}
                content={
                    <Table className="table" >
                        <thead className="thead-light text-capitalize font-size-sm font-weight-bold">
                            <th className="text-left "></th>
                            <th className="text-left ">Vehicle Description</th>
                            <th className="text-left">Vehicle Pricing</th>
                            <th className="text-left">Residual Values</th>
                            <th className="text-right">Vehicle Component </th>
                            <th className="text-right">Rental</th>
                        </thead>
                        <tbody style={{zIndex: 1, fontWeight: 'normal'}}>
                        {
                            data.map((x, i) => {
                            return  <tr key={'vehicle_inx_' + i}>
                                    <td xl={3}>
                                    <img style={styles.vehicle} src={config.system.cdn + '/' + (x.worksheet.mmcode.imagefilename !== null ? x.worksheet.mmcode.imagefilename : 'unknown.png')} />
                                    </td>
                                    <td xl={3}>
                                        MM: {x.worksheet.mmcode.mmcode} <br />
                                        {x.worksheet.mmcode.make}<br />
                                        {x.worksheet.mmcode.cdescription}
                                    </td>
                                    <td xl={3}>
                                        RP: {parseFloat(x.worksheet.retailprice)}<br />
                                        DS: {parseFloat(x.worksheet.discount)}<br />
                                        VP: {parseFloat(x.worksheet.vehicleprice)}
                                    </td>
                                    <td xl={1} style={styles.numericFieldValues}>
                                        RV %: {x.worksheet.rv}<br />
                                        RV: {x.worksheet.rv_amount}<br />
                                        {x.worksheet.deliveryperiod + ' Days'}
                                    </td>
                                    <td xl={1} style={styles.numericFieldValues}>
                                        Maint: {x.worksheet.maintenance}<br />
                                        Tyres: {x.worksheet.tyres}<br />
                                        Service: {x.worksheet.service}
                                    </td>
                                    <td xl={1} style={styles.numericFieldValues}>
                                        {x.worksheet.monthly_excl}<br />
                                        {x.worksheet.monthly_vat}<br />
                                        {x.worksheet.monthly_incl}
                                    </td>
                                </tr>
                            })
                        }
                        </tbody>
                    </Table>
                }
            />
    }

    getContract(data) {
        return <Card 
                title={window.t.en('Quote Confirmation')}
                subtitle={window.t.en('Please ensure all details are captured correctly before you proceed')}
                content={
                    <Fields nocard={true}
                        fields={[
                        {size: 4, label: 'Contract Type:', value: data.contractname},
                        //{size: 6, label: 'Vehicle Category:', value: data.ratecard},
                        {size: 4, label: 'Vehicle Category:', value: data.customerderivativecategory},
                        {size: 4, label: ' Monthly Distance:', value: data.distancepermonth},
                        {size: 4, label: 'Contract Period:', value: data.contractperiod},
                        {size: 4, label: 'Quote For:', value: data.quotefor},
                        {size: 4, label: 'RFQ Date:', value: data.quotedate},
                        {size: 4, label: 'Vehicle Color:', value: data.color},
                        {size: 4, label: 'Vehicle Location:', value: data.location},
                        {size: 4, label: 'Special  Vehicle:', value: data.specialistvehicle},
                        {size: 4, label: 'Vehicle Group:', value: data.vehiclegroup},
                        {size: 4, label: ' Operating Terrain:', value: data.terrain},
                        {size: 4, label: 'Vehicle Usage:', value: data.usage},
                        {size: 4, label: 'Comments:', value: data.comments},
                    ]}
                />
                }
            />
    }

    
    getCustomer(data) {
        //console.log('getCustomer ds',data)
        let contact = data.customercontact ? data.customercontact.length ? data.customercontact[0].contact : {} : {}
        
        
        let oq_key = `a45cc3095246cdd6895faaedad652e8744feaefedbfaa7d974e7d68acf726bf2fefb622b7d2cb01713a91717bfc76a655559783b7792c8621832b675fef5c693&filters=customer_id=${data.customer_id}`
        return <Card 
                title={window.t.en('Customer Details')}
                subtitle={window.t.en('Customer quote created for')}
                content={
                    <div>
                    <ViewCustomer id={data.customer_id} nocard={true} min={true} />
                    {/* <Fields nocard={true}
                        fields={[
                            {size: 4, label: 'Customer Name:', value: data.customername},
                            {size: 4, label: 'Cost Centre:', value: data.costcentre},
                            {size: 4, label: 'Account Number:', value: data.accountnumber},
                            {size: 4, label: 'Contact Person Name:', value: contact.fullname},
                            {size: 4, label: 'Contact Number:', value: contact.mobile},
                            {size: 4, label: 'Contact Email:', value: contact.email},
                        ]}
                    /> */}
                    <br/><br/>
                    <iframe src={`${config.oq.uri}${oq_key}`} 
                     height="550px"
                     width="100%"
                     style={{
                         border: "none",
                         margin: 0,
                         padding: '-25px',
                         alignSelf: "center",
                        //  boxShadow: "0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0), -4px -4px 30px 19px rgba(0, 0, 0, 0.1)" 
                     }}/>
                    </div>
                }
            />
    }

    getRFQDocument(documents) {
        return <Card 
                title={window.t.en('Quotations Documents')}
                subtitle={window.t.en('Customer Quotations Document ')}
                content={
                    documents.length
                    ? <PdfViewer
                        key={'quotation_viewer_'}
                        documents={documents}
                        multiple={true}
                        height={500}
                        edit={false}
                    />
                    : <Loading message={'Retrieving Purchase Order document...'} />
                }
            />
    }
    
    getComments() {
        return <Card 
                title={window.t.en('Quotation Notes')}
                subtitle={window.t.en('Enter Approval Comments')}
                content={
                    <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                    <Col xl={12}>
                    {this.props.workflow_queue_data.workflow_queue_id && <ViewQuotation
                     key={this.props.workflow_queue_data.workflow_queue_id}
                    id={this.props.workflow_queue_data.workflow_queue_id}
                     />
                    }</Col>
                       <Col xl={12}>
                    <TextField
                        variant="outlined"
                        fullWidth={true}
                        margin="normal"
                        multiline={true}
                        rows={5}
                        id={'txt_laodfactor'}
                        hintText={'Enter Load Factor %'}
                        value={this.props.state.comments}
                        onChange={(event, value) => {
                            this.props.actions.setInputValue({ prop: 'comments', value: event.target.value })
                        }}
                    />
                      </Col>
                       </Row>
                     </Container>
                }
            />
    }

    getView(data) {
        let documents = data.documents
        data.quotations.map(x => { 
            x.documents.map(doc => { 
                documents.push(doc) 
            })
        })
        return <div>
            {this.getCustomer(data.quotations[0].report_data.customer)}
            {this.getContract(data.quotations[0].report_data)}
            {this.getVehicles(data.quotations)}
            {this.getRFQDocument(documents)}
            {this.getComments()}
        </div>
    }

    render() {
        //console.log('WorkflowQuoteApprove this.props.state',this.props.workflow_queue_data.context_data.data.data.data)
        let data = this.props.workflow_queue_data.context_data.data.data.data
        return (
            data.length ? this.getView(data[0]) : 'Leading...'
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.workflowQuoteApprove
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowQuoteApprove)