import * as actions from './actions'
import * as progressBarActions from '../ProgressBar/actions'
import * as requestActions from '../Request/actions'
import * as routerActions from '../Redirect/actions'
import * as types from './types'
import * as config from '../../config'


export const getLookupData = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.SET_FML_QUOTATION_ACCESSORY_LOOKUP_DATA),
    delay(0)
    ,mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + '/components/' + payload.component + '/search?suppliername=' + payload.search,
            method: 'GET',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
            },
        })
            .pipe(
            mergeMap((result) => [
                actions.setLookupData(result.response, payload),
                progressBarActions.progress(false),
                requestActions.requestSucceeded(config.system.api + '/components/' + payload.component + '/search')
            ])
            ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/' + payload.component + '/search', error)))
    )
    ),
    catchError((error) =>
        of$(routerActions.route('/support', false, error))
    )
)