export const GET_COMPLETED_POS = 'GET_COMPLETED_POS'
export const APPROVE_PO = 'APPROVE_PO'
export const REJECT_PO = 'REJECT_PO'
export const UPLOAD_PO_DOCUMENTS = 'UPLOAD_PO_DOCUMENTS'
export const GET_POSUPPLIERLIST = 'GET_POSUPPLIERLIST'
export const SET_ANY_DATA_LIST = 'SET_ANY_DATA_LIST'
export const REFRESH_INVAMT = 'REFRESH_INVAMT'
export const REFRESH_INVNO = 'REFRESH_INVNO'
export const UPDATE_VALUE = 'UPDATE_VALUE'
export const TOGGLE_ITEM = 'TOGGLE_ITEM'
export const GET_BUSINESSLIST = 'GET_BUSINESSLIST'
export const SET_BUSINESSLIST = 'SET_BUSINESSLIST'
export const SET_SELECTED_ITEM = 'SET_SELECTED_ITEM'
export const GET_PAYMENT_DOCUMENT = 'GET_PAYMENT_DOCUMENT'
export const GET_EXCEL_GRID_EXPORT = 'GET_EXCEL_GRID_EXPORT'
export const ADD_BLANK = 'ADD_BLANK'
export const UPDATE_ITEM_VALUE = 'UPDATE_ITEM_VALUE'
export const APPROVE_ITEM = 'APPROVE_ITEM'

