import React, { useEffect, useState } from 'react';

function SVGLoader({ svgFileName, styleName, isKPI }){
  const [svgContent, setSvgContent] = useState(null);
  const counter = 0;

  const loadSvg = async () => {
    //console.log('loadSvg', svgFileName);
    setSvgContent(null);
    try {

        const svgModule = await import(`!!raw-loader!./assets/img/svg/icons/${isKPI ? 'kpi/' : ''}${svgFileName}.svg`);
        
        const svg = svgModule.default;

        return svg;
    } catch (error) {
      console.error('Error loading SVG:', error);
    }
  };

  useEffect(() => {
    setSvgContent(null);
    loadSvg().then((result) => {
      setSvgContent(result);
    }).catch((err) => {
      console.error('Error loading SVG:', err)
    });
  }, [svgFileName]);

  return (
    <div className={styleName}>
      {svgContent ? (
        <div key={`${svgFileName}_${counter+1}`} dangerouslySetInnerHTML={{ __html: svgContent }} />
      ) : (
        <span>Icon</span>
      )}
    </div>
  );
};

export default SVGLoader;

