import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'
import _ from 'lodash'

import IconButton from '@mui/material/IconButton'

import ReactTable from "react-table"
import "react-table/react-table.css"
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'

import { Container, Row, Col } from 'react-grid-system'

import Drawer from '../../../controls/drawer'
import Card from '../../../controls/card'
import Icon from '../../Icon/component'
import Loading from '../../Loading/component'

import * as actions from './actions'
import * as styles from './styles'

class WorkflowDisposalOffers extends Component {

    getCommands(row) {
        let field = this.props.state.offers_headers.filter(x => x.key === true)[0]
        const id = row.original[field.name]
        let i = row.index
        let commands = []

        commands.push(
            <IconButton key={'command_accept_' + field.name + '_' + id} style={styles.iconButton} iconStyle={styles.actionIcon(row.original.accepted)}
                onClick={
                    () => {
                        this.props.actions.acceptVehicleOffer(id,this.props.state.user.user_id)
                    }
                }>
                <Icon istyle={styles.gridOptionsIcon} ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={row.original.accepted ? 'done_all' : 'check'} />
            </IconButton>
        )

        return commands
    }

    getFormFieldInput(row) {
        let field = this.props.state.fields.filter(x => x.name === row.column.meta)[0]
        let index = row.index

        switch (field.type) {
            case 'date':
            case 'dateTime':
            case 'time':
            case 'timestamp':
                return <div>
                    <TextField
                        key={field.name + '_' + index}
                        container='inline'
                        mode='landscape'
                        value={this.data[index][field.name] ? new Date(this.data[index][field.name]) : null}
                    />
                </div>
            case 'boolean':
                return <div>
                    <Checkbox
                        key={field.name + '_' + index}
                        //label={field.hint}
                        labelPosition='right'
                        //defaultToggled={false}
                        style={styles.toggle}
                        trackStyle={{
                            backgroundColor: 'gray'
                        }}
                        trackSwitchedStyle={{
                            backgroundColor: 'green'
                        }}
                        toggled={this.data[index][field.name] === true}
                    />
                </div>

            default:
                return <TextField
                key={field.name + '_' + index}
                value={this.data[index][field.name]} 
            />
        }
          
    }

    getValue(row) {
        let field = this.props.state.offers_headers.filter(x => x.name === row.column.meta)[0]
            if(!row.value) return row.value
            switch (field.type) {
                case 'date':
                    return moment(row.value).format('YYYY-MM-DD')
                case 'dateTime':
                    return moment(row.value).format('YYYY-MM-DD HH:mm ZZ')
                case 'time':
                    return moment(row.value).format('HH:mm ZZ')
                case 'timestamp':
                    return moment(row.value).format('YYYY-MM-DD HH:mm ZZ')
                case 'boolean':
                    const toggle = field.toggle && field.toggle !== null ? field.toggle.split(',') : row.value

                    return row.value !== null
                        ? row.value
                            ? toggle[0]
                            : toggle[1]
                        : 'Not Set'
                case 'decimal':
                    return !isNaN(row.value)
                        ? Number(row.value).toFixed(2).toString()
                        : row.value
                default:
                    return row.value
            }
    }

    getFormFieldLabel(row) {
          return <div
          style={{
            width: '100%',
            marginTop: '3px',
            verticalAlign: 'baseline',
            fontSize: '15px'
        }} >{this.getValue(row)}</div>
    }

    getColumns() {
        const fields = this.props.state.offers_headers.filter(x => x.key === false)
        let columns =
            fields.map(x => {
                return {
                    meta: x.name,
                    Header: x.title,
                    accessor: x.display,
                    filterable: true,
                    Filter: ({ filter, onChange }) => (
                        x.type !== 'boolean'
                            ? <input
                                onChange={event => onChange(event.target.value)}
                                value={filter ? filter.value : ''}
                                style={{
                                    width: '100%',
                                    height: 25,
                                    border: '1px solid #E6E6E6',
                                    borderRadius: 25
                                }}
                            />
                            : <select
                                onChange={event => onChange(event.target.value)}
                                style={{
                                    width: '100%',
                                    height: 25,
                                    border: '1px solid #E6E6E6',
                                    borderRadius: 25
                                }}
                                value={filter ? filter.value : ''}
                            >
                                <option value={''}>Show All</option>
                                {
                                    x.toggle.split(',').map((x, i) => {
                                        return <option value={i === 0 ? 'true' : 'false'}>{x}</option>
                                    })
                                }
                            </select>
                    ),
                    width: x.width ? x.width : undefined,
                    headerStyle: {
                        fontSize: '13px',
                        fontWeight: 'bold',
                    },
                    style: {
                        verticalAlign: 'middle',
                        fontSize: 12,
                        letterSpacing: 0,
                        color: 'black',
                        fontWeight: x.bold ? 'bold' : 'normal',
                        textAlign: x.right
                            ? 'right'
                            : 'left'
                    },
                    Cell: (row) => (
                        this.getFormFieldLabel(row)
                    )
                }
            })

            columns.unshift({
                //Header: '',
                accessor: 'options',
                filterable: false,
                maxWidth: 80,
                width: 80,
                Cell: (row) => (
                    this.getCommands(row)
                )
            })
        return columns
    }

    getGrid() {
        return <div>
        
            <ReactTable
                data={this.data.disposalbidder}
                columns={this.getColumns()}
                manual
                loading={this.loading}
                minRows={1}
                showPageJump={true}
                showPaginationTop={false}
                style={{
                    height: '100%'
                }}
            >
                {(state, render, instance) => {
                    return (
                        render()
                    )
                }}
            </ReactTable>
        </div>
    }

    getInfo() {     
        return <Container style={{ margin: 0 }} className="custom-container">
                   <Row>
                       <Col xl={12}>
                           <div style={styles.fieldContainer}>
                               <b>{'Retail Value:'}</b>
                               <div style={styles.fieldContent}>
                                   <TextField
                                        variant="outlined" 
                                       id={'lblretailval'}
                                       hintText={'Retail Value'}
                                       fullWidth={true}
                                       value={this.data.retailvalue}
                                       disabled={true}
                                   />
                               </div>
                           </div>
                       </Col>
                   </Row>
                   <Row>
                       <Col xl={12}>
                           <div style={styles.fieldContainer}>
                               <b>{'Trade Value:'}</b>
                               <div style={styles.fieldContent}>
                                   <TextField
                                        variant="outlined" 
                                       id={'lbltradevaue'}
                                       hintText={'Trade Value'}
                                       fullWidth={true}
                                       value={this.data.tradevalue}
                                       disabled={true}
                                   />
                               </div>
                           </div>
                       </Col>
                   </Row>
                   <Row>
                       <Col xl={12}>
                           <div style={styles.fieldContainer}>
                               <b>{'Market Value:'}</b>
                               <div style={styles.fieldContent}>
                                   <TextField
                                        variant="outlined" 
                                       id={'lblmarketvaue'}
                                       hintText={'Market Value'}
                                       fullWidth={true}
                                       value={this.data.marketvalue}
                                       disabled={true}
                                   />
                               </div>
                           </div>
                       </Col>
                   </Row>
               </Container>
       }

    getForm() {     
        return <Container style={{ margin: 0 }} className="custom-container">
                   <Row>
                       <Col xl={12}>
                               <b>{'Bidder Name:'}</b>
                                   <TextField
                                        variant="outlined" 
                                       id={'txtbidder'}
                                       hintText={'Enter Bidder Name'}
                                       fullWidth={true}
                                       value={this.props.state.biddername}
                                       onChange={(event, value) => {
                                           this.props.actions.setSetinputField({prop: 'biddername', value: event.target.value})
                                       }}
                                   />
                       </Col>
                   </Row>
                   <Row>
                       <Col xl={12}>
                               <b>{'Offer Amount With Repairs:'}</b>
                                   <TextField
                                        variant="outlined" 
                                       id={'txtrepairoffer'}
                                       hintText={'Enter Offer Amount With Repairs'}
                                       fullWidth={true}
                                       value={this.props.state.repairoffer}
                                       onChange={(event, value) => {
                                        this.props.actions.setSetinputField({prop: 'repairoffer', value: event.target.value})
                                       }}
                                   />
                       </Col>
                   </Row>
                   <Row>
                       <Col xl={12}>
                               <b>{'Offer Amount With No Repairs:'}</b>
                                   <TextField
                                        variant="outlined" 
                                       id={'txtnonrepairoffer'}
                                       hintText={'Enter Offer Amount With No Repairs'}
                                       fullWidth={true}
                                       value={this.props.state.nonrepairoffer}
                                       onChange={(event, value) => {
                                        this.props.actions.setSetinputField({prop: 'nonrepairoffer', value: event.target.value})
                                       }}
                                   />
                       </Col>
                   </Row>
                   <Row>
                       <Col xl={12}>
                               <b>{'Percentage Of Trade:'}</b>
                                   <TextField
                                        variant="outlined" 
                                       id={'txtoftrade'}
                                       hintText={'Enter Percentage Of Trade'}
                                       fullWidth={true}
                                       value={this.props.state.oftrade}
                                       disabled={true}
                                   />
                       </Col>
                   </Row>
                   <Row>
                       <Col xl={12}>
                               <b>{'Percentage Achieved of Market:'}</b>
                                   <TextField
                                        variant="outlined" 
                                       id={'txtofmarket'}
                                       hintText={'Enter Percentage Achieved of Market'}
                                       fullWidth={true}
                                       value={this.props.state.ofmarket}
                                       disabled={true}
                                   />
                       </Col>
                   </Row>
                   <Row>
                       <Col xl={12}>
                               <b>{''}</b>
                               <Button variant='contained' className='global_button'
                                    label="Add Offer"
                                    primary={true}
                                    disabled={this.data.vehicle_id ? false : true}
                                    onClick={(event) => {
                                        event.preventDefault()
                                        this.props.actions.saveOffer(this.data.vehicle_id,this.data.licenseplateno)
                                    }}
                                />
                       </Col>
                   </Row>               
               </Container>
       }


    getViews() {
        return this.props.state.addoffer
                ? this.data.disposalbidder
                    ? this.getInfo()
                    : <Loading message={'Loading component...'} />
                : this.getForm()
    }


    // gridLayout() {
    //     return <Card  expandable={false}>
    //         <CardHeader
                
    //             actAsExpander={true}
    //             showExpandableButton={true}
    //             title={'Offers List'}
    //             subtitle={'Vehicle offers details'}
    //             avatar={
    //                 <Icon iclass={'custom-icons'} iname={'workflow'} />
    //             }
    //         />
    //         <CardContent expandable={false}>
    //             {this.getView()}
    //         </CardContent>
    //     </Card>
    // }
    getEditForm() {
        return <div> 
            <div className="text-center">
                <div className="font-weight-bold font-size-lg mb-0 text-black">
                Vehicle Details
                </div>
                <p className="text-black-50">Vehicle disposal details</p>
            </div>
            {this.getInfo()}
            <div className="text-center">
                <div className="font-weight-bold font-size-lg mb-0 text-black">
                Vehicle Price
                </div>
                <p className="text-black-50">Capture vehicle pricing details</p>
            </div>
            {this.getViews()}
        </div>
    }

    getLayout() {
        return <Drawer 
                onClose={(event, value) => {
                    this.props.actions.toggleInfoSheet(null,'inforsheet_offers')
                }}
                width={'950px'}
                open={this.props.state.inforsheet_offers}
                title={'Bidders Offer'}
                subtitle={'Vehicle offer from bidders'}
                content={this.props.state.addoffer ? this.getEditForm() : this.getGrid()}
                // buttons={<div> 
                //         <Button
                //             label="Add Offer"
                //             primary={true}
                //             onClick={(event) => {
                //                 event.preventDefault()
                //                 this.props.actions.toggleAddOffer(this.props.state.addoffer)
                //             }}
                //         >
                //             {"Add Offer"}
                //         </Button>
                //     </div>
                //     }
            />
        // return <Drawer
        //         width={800}
        //         anchor={'right'}
        //         openSecondary={true}
        //         open={this.props.state.inforsheet_offers}
        //         onRequestChange={(event) => {
        //             event.preventDefault()
        //             this.props.actions.toggleInfoSheet(null,'inforsheet_offers')
        //         }}>
        //         {/* <br /> <br /> 
        //         <Button 
        //             className={"global_button"} 
        //             variant="contained"
        //             className=""
        //             style={{ width: '120px'}}
        //             onClick={(event) => {
        //                 event.preventDefault()
        //                 this.props.actions.toggleInfoSheet(null,'inforsheet_offers')
        //             }}>
        //                 {<Icon iclass={'material-icons'} iname={'close'} />} &nbsp; {window.t.en('Close')}
        //         </Button><br /> <br />
        //         <Button
        //             label="Add Offer"
        //             primary={true}
        //             onClick={(event) => {
        //                 event.preventDefault()
        //                 this.props.actions.toggleAddOffer(this.props.state.addoffer)
        //             }}
        //             >{"Add Offer"}</Button>
        //         <Container style={{ margin: 0 }} className="custom-container">
        //             <Row>
        //                <Col xl={12}>
        //                 { this.getInfo() }
        //             </Col>   </Row>
        //             <Row>
        //                <Col xl={12}>
        //                 { this.getViews() }
        //                 </Col>   </Row>
        //         </Container> */}
                
        //     </Drawer>
    }

    render() {
        this.data = this.props.state.yardlist[this.props.state.infosheet_inx]
        return this.getLayout()
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.workflowDisposal,
            user: {
                ...state.user
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowDisposalOffers)