import * as types from './types'
import * as _ from 'lodash'

export const getTeamsList = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.WORKFLOWS_GET_TEAMS_LIST
        })
    }
}

export const setTeamsList = (payload) => {
    const workflows = payload.reduce((obj, x) => {
        obj[x.name] = { ...x }

        return obj
    }, {})

    return (dispatch, getState) => {
        dispatch({
            type: types.WORKFLOWS_SET_TEAMS_LIST,
            payload: workflows
        })

        /*         let tree = []
        
                Object.keys(getState().teams).forEach(x => {
                    const result = getState().teams[x].businessteam
        
                    if (result.length) {
                        result.forEach(y => {
                            _.set(tree, y.business_fktext + '.' + y.customer_fktext, {
                                ...y.customer,
                                business_id: y.business_id
                            })
                        })
                    }
                })
        
                const recurse = (obj) => {
                    return Object.keys(obj).map(x => {
                        if (!obj[x].customer_id) {
                            return {
                                label: x,
                                value: {
                                    business_id: obj[x].business_id
                                },
                                children: recurse(obj[x])
                            }
                        }
                        else {
                            return {
                                label: obj[x].customerbusinessunit_fktext !== 'null' ? obj[x].customerbusinessunit_fktext + ' (' + obj[x].customername + ')' : obj[x].customername,
                                value: {
                                    business_id: obj[x].business_id,
                                    customer_id: obj[x].customer_id
                                }
                            }
                        }
                    })
                }
        
                //customername, costcentre, customerbusinessunit_fktext, registeredname, hierarchyname
        
                const final = Object.keys(tree).map(x => {
                    return {
                        label: x,
                        value: {
                            business_id: tree[x].business_id
                        },
                        children: recurse(tree[x])
                    }
                })
        
                dispatch({
                    type: types.WORKFLOWS_SET_TEAMS_HIERARCHY,
                    payload: final
                }) */
    }
}