import * as types from './types'
import moment from 'moment'

export const addBlankUpload = (i) => {
    return (dispatch, getState) => {
        let images = getState().imageUploader.images
        images.push({
            fileinx: moment(new Date()).format('YYYYMMDDhhmmssSSS'),
            filename: '',
            preview: '',
            inx: i ? i : 1
        })
        dispatch({
            type: types.UPLOAD_IMAGES_ADD_ROW,
            payload: images
        })
    }
}

export const resetAllDocuments = () => {
    return (dispatch, getState) => {

        dispatch({
            type: types.UPLOAD_IMAGES_ADD_ROW,
            payload: []
        })
    }
}

export const resetDocuments = (inx) => {
    return (dispatch, getState) => {
        let images = getState().imageUploader.images
        images = images.filter(x => x.fileinx !== inx)

        dispatch({
            type: types.UPLOAD_IMAGES_ADD_ROW,
            payload: images
        })
    }
}

// export const viewDocument = (data) => {
//     let link = ''

//     const filekey = data.data.document.key.replace('quotes/', '')
//     const saveByteArray = (function () {
//         let a = document.createElement('a')
//         document.body.appendChild(a)
//         a.style = 'display: none'

//         return (data, name) => {
//             const blob = new Blob(data, { type: 'application/pdf' })
//             const url = window.URL.createObjectURL(blob)
//             link = url
//         }
//     }())

//     saveByteArray([data.data.content.Body], data.data.document.key)

//     return (dispatch, getState) => {
//         const grid = getState().components['purchaseorder'].grid.data

//         const payload = grid.map(x => {
//             return filekey.indexOf('Receipt') < 0
//                 ? x.filename === filekey
//                     ? {
//                         ...x,
//                         preview: link
//                     }
//                     : x
//                 : x.po_filename === filekey
//                     ? {
//                         ...x,
//                         po: link
//                     }
//                     : x
//         })

//         dispatch({
//             type: types.SET_ACCESSORY_PO_DOCUMENTS_PREVIEW,
//             payload: {
//                 component: 'purchaseorder',
//                 data: payload
//             }
//         })
//     }
// }

export const setAcceptedDocuments = (documents, id, prefix) => {
    let filename = prefix + '_' + moment(new Date()).format('YYMMDDhhmmss')
    return (dispatch, getState) => {
        documents.forEach(file => {
            const reader = new FileReader();
            filename =  filename + file.type.replace('image/','.') 
            reader.readAsArrayBuffer(file);
            reader.onload = (e) => {
                let fileAsBinaryString = reader.result;
                dispatch({
                    type: types.UPLOAD_MULTIPLE_IMAGE_COMPONENT_UPLOADER,
                    payload: {
                        content: fileAsBinaryString,
                        filename: filename +file.type.replace('image/','.')  ,
                        type: file.type,
                    }
                })
            };
        });

        dispatch({
            type: types.SET_UPLOAD_IMAGE_PREVIEW,
            payload: {
                preview: documents[0].preview,
                filename: filename,
                id: id,
            }
        })
    }
}

