export const interest_rate = '0.00'
export const effective_date = null
export const primarate = []
export const contracts = []
export const client_data = []

export const fields = { 
    effective_date: {
        errorText: 'Please select Effective Date',
        valid:false,
        state: 'getState().interestRateCreate?.effective_date'
    },
    interest_rate: {
        errorText: 'Please enter Interest Rate',
        valid:false,
        state: 'getState().interestRateCreate?.interest_rate',
        validations: [
            {
              'schema': 'decimal',
              'required': true,
              'maxLength': null,
              'errorText': 'Please input a valid Interest Rate'
            }
          ]
    }
}