import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Table, Card, CardHeader, CardContent ,Button} from '@mui/material'
import { Container, Row, Col } from 'react-grid-system'
import moment from 'moment'
import IconButton from '@mui/material/IconButton';

import Icon from '../../Icon/component'
import Loading from '../../Loading/component'
import ReassignTask from '../ReassignTask/component'
import WorkflowTimeline from '../WorkflowTimeline/component'

import * as workflowTimelineActions from '../WorkflowTimeline/actions'
import * as workflowQueueTaskActions from '../actions'
import * as workflowTaskInputActions from '../../WorkflowTaskInput/actions'
import * as reassignTaskActions from '../ReassignTask/actions'


import {ReactComponent as WrenchAltIcon} from '../../../assets/img/svg/icons/wrench-alt.svg'
import {ReactComponent as ArrowSmallRightIcon} from '../../../assets/img/svg/icons/arrow-small-right.svg'
import {ReactComponent as DeleteRightIcon} from '../../../assets/img/svg/icons/delete-right.svg'

import * as actions from './actions'
import * as styles from './styles'

class AssignedTasks extends Component {
    componentDidMount() {
        this.props.actions.getAssignedTasks(this.props.team_id, this.props.user_id, this.props.state.search.value ? this.props.state.search.value : '')
    }

    getExpandedStatus() {
        return this.props.state['tasks_' + this.props.team_id].data.length ? true : false
    }

    getTasks() {
       return <div className="p-4">
          <Table className="table">
               {/*<thead>
            <tr>
                <th scope="col" className="text-center">
                  #
                </th>
                <th style={{ width: '400px' }} scope="col">
                  Product
                </th>
                <th scope="col">Client</th>
                <th scope="col">Amount</th>
                <th scope="col">VAT</th>
                <th scope="col"></th>
              </tr>
            </thead> */}
            <tbody>
             {
                this.props.state['tasks_' + this.props.team_id].data.length
                    ? this.props.state.reassignTask.visible && this.props.state.reassignTask.team_id === this.props.team_id && (this.props.state.reassignTask.mode === 'reassign_task' || this.props.state.reassignTask.mode === 'unassign_task' || this.props.state.reassignTask.mode === 'reject_task')
                        ? <ReassignTask key={'assigned_tasks_reassign_task_' + this.props.team_id + '_' + this.props.user_id} team_id={this.props.team_id} user_id={this.props.user_id} data={this.props.state['tasks_' + this.props.team_id].data} />
                        : this.props.state['tasks_' + this.props.team_id].data
                        .sort((x, y) => y['workflow_queue_task#created_at'] - x['workflow_queue_task#created_at'])
                            .map((x, i) => {
                                return <tr>
                        <td className="text-center  text-warning">
                        <span>{'#' + x['workflow_queue_task#workflow_queue_id'] + '/' + (x['workflow_task#order'] ? x['workflow_task#order'] : '?')}</span>
                        </td>
                        <td>
                        <span>{moment(x['workflow_queue_task#created_at']).format('YYYY-MM-DD HH:mm')}</span>
                        </td>
                        <td>
                        <b>{x['workflow#title']}</b>
                        <span className="d-block text-black-50 font-size-sm">
                        {x['workflow_task#title']}
                        </span>
                        </td>
                        <td>
                        <small>{
                                    x['ref_user#firstname']
                                        ? <div style={styles.column(140, false)}>
                                            {x['ref_user#firstname'] + ' ' + x['ref_user#lastname']}
                                        </div>
                                        : <div style={styles.column(140, false)}>
                                            System
                                    </div>
                                }</small>
                        </td>
                        <td>
                        <div style={styles.column(500, false)}>
                                    {x['workflow_queue#meta_data'] ? x['workflow_queue#meta_data'] : ''}
                        </div>
                        </td>
                        {/* <td className="text-warning">
                        <span>21%</span>
                        </td> */}
                        <td className="text-right">
                        <IconButton
                                        tooltip={'Handle'}
                                        onClick={(event) => {
                                            event.preventDefault()
                                            
                                            this.props.actions.handleTask(this.props.team_id, this.props.user_id, x)
                                        }}
                                    >
                                        <WrenchAltIcon style={styles.actionIcon('')} />
                                        {/* <Icon istyle={{ fontSize: '22px', color: '#253053' }} iclass={'material-icons'} iname={'build'} /> */}
                                    </IconButton>

                                    <IconButton
                                        tooltip={'Reassign'}
                                        onClick={(event) => {
                                            event.preventDefault()
                                            this.props.actions.showReassignTask('reassign_task', this.props.team_id, this.props.user_id, x)
                                        }}
                                    >
                                    <ArrowSmallRightIcon style={styles.actionIcon('')} />
                                        {/* <Icon istyle={{ fontSize: '22px', color: '#253053'}} iclass={'material-icons'} iname={'forward'} /> */}
                                    </IconButton>

                                    <IconButton
                                        tooltip={'Unassign'}
                                        onClick={(event) => {
                                            event.preventDefault()
                                            this.props.actions.showReassignTask('unassign_task', this.props.team_id, this.props.user_id, x)
                                        }}
                                    >
                                    <DeleteRightIcon style={styles.actionIcon('')} />
                                        {/* <Icon istyle={{ fontSize: '22px', color: '#253053' }} iclass={'material-icons'} iname={'backspace'} /> */}
                                    </IconButton>

                                    {/* <IconButton
                                        tooltip={'Timeline'}
                                        onClick={(event) => {
                                            event.preventDefault()
                                            this.props.actions.toggleWorkflowTimeline('workflow_queue_timeline_' + x['workflow_queue_task#workflow_queue_id'] + '_' + x['workflow_queue_task#workflow_queue_task_id'])
                                        }}
                                    >
                                        <Icon istyle={{ fontSize: '22px', color: '#253053' }} iclass={'material-icons'} iname={'timeline'} />
                                    </IconButton> */}
                        </td>
                    </tr>
                            })
                    : <Row style={styles.row(0, false)}>
                        <Col xl={12}>
                            {this.props.state['tasks_' + this.props.team_id].searching ? 'No tasks found.' : 'There are currently no tasks assigned to you.'}
                        </Col>
                    </Row>
            }
            </tbody>
          </Table>
      </div>
    }

    getPagingControls() {
        return !this.props.state.reassignTask.visible
            ? <Row>
                <Col xl={12} style={styles.pagingControls}>
                    <IconButton
                        tooltip={'Previous'}
                        onClick={(event) => {
                            (this.props.state['tasks_' + this.props.team_id].paging.page - 1 === -1)
                                ? event.preventDefault()
                                : this.props.actions.getAssignedTasks(
                                    this.props.team_id,
                                    this.props.user_id,
                                    this.props.state.search.value ? this.props.state.search.value : '',
                                    {
                                        ...this.props.state['tasks_' + this.props.team_id].paging,
                                        page: this.props.state['tasks_' + this.props.team_id].paging.page - 1
                                    }
                                )
                        }}
                    >
                        <Icon istyle={{ fontSize: '22px' }} iclass={'material-icons'} iname={'navigate_before'} />
                    </IconButton>

                    <IconButton
                        tooltip={'Next'}
                        onClick={(event) => {
                            (this.props.state['tasks_' + this.props.team_id].paging.page + 1) === this.props.state['tasks_' + this.props.team_id].paging.pages
                                ? event.preventDefault()
                                : this.props.actions.getAssignedTasks(
                                    this.props.team_id,
                                    this.props.user_id,
                                    this.props.state.search.value ? this.props.state.search.value : '',
                                    {
                                        ...this.props.state['tasks_' + this.props.team_id].paging,
                                        page: this.props.state['tasks_' + this.props.team_id].paging.page + 1
                                    }
                                )
                        }}
                    >
                        <Icon istyle={{ fontSize: '22px' }} iclass={'material-icons'} iname={'navigate_next'} />
                    </IconButton>
                </Col>
            </Row>
            : <div />
    }

    render() {
        return !this.props.state['tasks_' + this.props.team_id] || this.props.state['tasks_' + this.props.team_id].loading
            ? <Loading message={this.props.state.search.value.length ? 'Searching...' : 'Retrieving your assigned tasks...'} />
            : <div style={styles.tasksCard}>
                {/* <CardHeader
                    actAsExpander={false}
                    showExpandableButton={false}
                    title={window.t.en('Assigned Tasks'}
                    subtitle={
                        this.props.state['tasks_' + this.props.team_id].searching
                            ? '(' + this.props.state['tasks_' + this.props.team_id].paging.count + ') tasks found matching "' + this.props.state['tasks_' + this.props.team_id].searched + '". Viewing page ' + (this.props.state['tasks_' + this.props.team_id].paging.page + 1) + ' of ' + this.props.state['tasks_' + this.props.team_id].paging.pages + '.'
                            : 'There are (' + this.props.state['tasks_' + this.props.team_id].paging.count + ') tasks assigned to you. Viewing page ' + (this.props.state['tasks_' + this.props.team_id].paging.page + 1) + ' of ' + (this.props.state['tasks_' + this.props.team_id].paging.pages ? this.props.state['tasks_' + this.props.team_id].paging.pages : '1') + '.'
                    }
                    avatar={<Icon istyle={{ color: '#FF9800' }} iclass={'material-icons'} iname={'build'} />}
                /> */}
                {
                        this.props.state['tasks_' + this.props.team_id].searching
                            ? '(' + this.props.state['tasks_' + this.props.team_id].paging.count + ') tasks found matching "' + this.props.state['tasks_' + this.props.team_id].searched + '". Viewing page ' + (this.props.state['tasks_' + this.props.team_id].paging.page + 1) + ' of ' + this.props.state['tasks_' + this.props.team_id].paging.pages + '.'
                            : 'There are (' + this.props.state['tasks_' + this.props.team_id].paging.count + ') tasks assigned to you. Viewing page ' + (this.props.state['tasks_' + this.props.team_id].paging.page + 1) + ' of ' + (this.props.state['tasks_' + this.props.team_id].paging.pages ? this.props.state['tasks_' + this.props.team_id].paging.pages : '1') + '.'
                    }
                    <div style={{ height: '850px', overflowY: 'auto', overflowX: 'hidden' }}>
                        {this.getPagingControls()}
                        {this.getTasks()}
                    </div>
            </div>
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.assignedTasks,
            timeline: {
                ...state.workflowTimeline
            },
            reassignTask: {
                ...state.reassignTask
            },
            search: {
                search: state.workflowQueueTasks.search,
                value: state.workflowQueueTasks.value
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({
            ...actions,
            ...workflowQueueTaskActions,
            ...workflowTaskInputActions,
            ...reassignTaskActions,
            ...workflowTimelineActions
        }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignedTasks)