import * as types from './types'
import * as props from './props'

export default (state = props, action) => {
    switch (action.type) {
        case types.SET_WORKFLOW_TASKS:
            return {
                ...state,
                data: action.payload.data
            }
        case types.SET_UPDATE_WORKFLOW_TASK_USER:
            return {
                ...state,
                data: action.payload.data
            }
        default:
            return state
    }
}