import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { Container, Row, Col } from 'react-grid-system'
import { Table } from '@mui/material'

import * as actions from './actions'
import * as styles from './styles'

import { LicenseInfo } from '@mui/x-license-pro';
LicenseInfo.setLicenseKey('0dc2c32f83edb803ade140958edb564aTz04MjQzNSxFPTE3MzcxOTI2NzEwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');

class CASHFLOW_RPT extends Component {
    componentDidMount() {
       this.props.actions.getCashflowData(moment().format('YYYYMM'),moment().format('YYYYMM'))
    }
 
    getDatePicket() {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col> <label style={{paddingRight: '10px',paddingTop: '10px', fontSize: '16px', fontWeight: 'bold'}}>Select Period: </label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateRangePicker
                            localeText={{
                                start: 'Start Month',
                                end: 'End Month',
                            }}
                            slotProps={{ 
                                width: '300px',
                                textField: { 
                                    size: 'small',
                                    width: '300px',
                                    variant: 'outlined',
                                }
                            }}
                            sx={{width: '300px'}}
                            width={'350px'}
                            selectedSections={'month'}
                            slots={{ field: SingleInputDateRangeField }}
                            name="allowedRange"
                            onChange={(event, args) => {
                                if(event[0] && event[1]) {
                                    this.props.actions.getCashflowData(moment(event[0].$d).format('YYYYMM'), moment(event[1].$d).format('YYYYMM'))
                                }
                            }}
                        />
                    </LocalizationProvider>
                </Col>
            </Row>
            <Row>
                <Col>
                <hr />
                </Col>
            </Row>
        </Container> 
    }

    getLayout() {
        let accounttypes = _.uniq(_.map(this.props.state.report_data, 'accounttype'));
        //let months = _.uniq(_.map(this.props.state.report_data, 'customertransactionmonth'));// _.uniqBy(this.props.state.report_data, 'accounttype').map(x => { return x.accounttype})
        
        ////console.log('rowsx months', months)
        //console.log('rowsx accounttypes', accounttypes)
        return <Table size="small" aria-label="a dense table">
        <thead>
            <tr>
                {
                    this.props.state.columns.map((col, inx) => {
                        return <th className="text-uppercase bg-secondary">{inx === 0 ? '' : col}</th>
                    })
                }
            </tr> 
            <tr>
                <th className="text-uppercase bg-secondary" colSpan={this.props.state.columns.length}><hr/></th>
            </tr>
        </thead>
        <tbody>
            {
                accounttypes.map(accounttype => {
                    let rows = []
                    rows.push(<tr>
                        <td><strong>{accounttype}</strong></td>
                   
                        <td></td>
                    </tr>)
                    for(let x of this.props.state.report_data.filter(x => x.accounttype === accounttype)) { 
                        rows.push(<tr>
                            {
                                this.props.state.columns.map((col, inx) => {
                                    return <td className="text-uppercase bg-secondary">
                                    {x[col]}
                                </td>
                                })
                            }
                        </tr>) 
                    }
                    //console.log('rowsx', rows)
                    return rows
                })
              
            }


        </tbody>
    </Table>
    }
    
    render() {
        return [
            this.getDatePicket(),this.getLayout()
        ]
    }
}

const mapStateToProps = (state) => {
    return {
        state: { ...state.cashflow_rpt }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CASHFLOW_RPT)