import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.RESET_COMPONENT:
            return {
                ...state,
                comments: '',
                fields: {
                    comments: {
                        errorText: 'Enter comments',
                        value: '',
                        valid: false,
                        empty: true,
                    },
                    completeddate: {
                        errorText: 'Enter completed date',
                        value: null,
                        valid: false,
                        empty: true,
                    },
                    isinvoice_uploaded: {
                        errorText: 'Enter quote date',
                        value: false,
                        valid: true,
                        empty: true,
                    }
                }
            }
        case types.PO_SUBCOMPONENT_ACTION:
            return {
                ...state
            }
        case types.SET_CLOSE_BOOKING_COMPONENT_FIELD_INPUT_VALUE:
            return {
                ...state,
                [action.payload.prop]: action.payload.value
            }
        case types.SET_CLOSE_BOOKING_COMPONENT_FIELD_INPUT_VALIDATE:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    [action.payload.prop]: {
                        ...state[action.payload.prop],
                        value: action.payload.value,
                        valid: action.payload.valid
                    }
                }
            }
        default:
            return state
    }
}