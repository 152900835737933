import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import get from 'lodash/get'
import moment from 'moment'

import { Container, Row, Col } from 'react-grid-system'

import { Card, CardHeader, CardContent } from '@mui/material'

import * as helper from '../../helpers/components'
import Icon from '../Icon/component'
import SurrogateView from '../SurrogateView/component'

import * as actions from '../Form/actions'
import * as styles from './styles'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

class WorkflowPOGRVComponent extends Component {
    componentDidMount() {
        //this.props.actions.loadComponent(this.props.name, this.props.id)
    }

    getView() {
        return <Card key={'card_workflowPOGRVComponent'} initiallyExpanded={true}> 
            <CardHeader
                title={window.t.en('Purchase Order')}
                subtitle={window.t.en('Ensure that all document has been verified correctly')}
                actAsExpander={false}
                showExpandableButton={false}
                style={styles.row}
            />
            <CardContent expandable={false}>
                {'Loading View...'}
            </CardContent>
        </Card>
    }

    render() {
        this.component = this.props.state
        this.data = this.props.data

        return (
            this.props.data
                ? this.getView()
                : 'Loading...'
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: { ...state.components[ownProps.name] }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowPOGRVComponent)