import * as _ from 'lodash'
import moment  from 'moment'
import * as types from './types'
import * as appTypes from '../App/types'

import * as cognitoTypes from '../Cognito/types'
import { cognitoUser } from '../../cognito'
import * as cognitoActions from '../Cognito/actions'
import * as redirectActions from '../Redirect/actions'
import * as componentActions from '../Components/actions'
import * as workflowActions from '../Workflows/actions'
import * as messengerActions from '../Messenger/actions'
import * as navigationMenuActions from '../NavigationMenu/actions'

export const clearAccountSelection = () => {
    return (dispatch) => {
        dispatch({
            type: types.CLEAR_ACCOUNT_SELECTION
        })
    }
}

export const setSelectedSetting = (data) => {
    return (dispatch) => {
        dispatch({
            type: types.SET_USER_ACCOUNT_DEFAULT_SETTING_INPUT,
            payload: data
        })
    }
}

export const updateUserAccountDefaultSetting = (data) => {
    return (dispatch) => {
        dispatch({
            type: types.UPDATE_USER_ACCOUNT_DEFAULT_SETTINGS,
            payload: data
        })
    }
}
export const updateOrganisationSetting = (data) =>{
    return (dispatch) => {
        //console.log('UPDATE_ORGANISATION_SETTINGS', data)
        dispatch({
            type: types.UPDATE_ORGANISATION_SETTINGS,
            payload: data
        })
    }
}

export const updateUserAccountDefaultSettingDone = () => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Profile details successfully updated',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}
export const setAppSignin = (_clients) => {
    return (dispatch, getState) => {
    
        let defaultaccount = getState().accounts.defaultaccount ? getState().accounts.defaultaccount : getState().user.defaultaccount
        //console.log("YYYYYY",defaultaccount,"SSSS",getState())
        let clients = Object.keys(getState().clients).map(key => getState().clients[key])
        clients = clients ? clients : _clients
        //console.log("clients",clients)
        dispatch({
            type: types.SET_SELECTED_ACCOUNT,
            payload: {
                defaultaccount: defaultaccount ? defaultaccount : 2,
                client: clients.filter(x => x.client_id === defaultaccount ? defaultaccount : 2)[0]
            }
        })
        dispatch({
            type: cognitoTypes.ACCOUNT_USER_AUTHENTICATION_SUCCEEDED
        })
        let _page = getState().user.defaultlandingpage ? getState().user.defaultlandingpage : 'tasks'
        //console.log('defaultlandingpage', _page)
        dispatch(redirectActions.route('/' +  _page  ))
        //dispatch(messengerActions.connectToMessenger())
        dispatch(componentActions.getComponentNames())
        dispatch(workflowActions.getWorkflowNames())

        
            // dispatch(componentActions.getComponentNames())
            // dispatch(workflowActions.getWorkflowNames()) //todo
                //this.props.actions.getComponentNames()
                //this.props.actions.getWorkflowNames()


        // if (process.env.NODE_ENV === 'production' && window.Raygun) {   ///
        //     window.Raygun.init('oHyuAzztxKulwy40QIagOFF')
        //     window.Raygun.setUser({
        //         identifier: getState().user.cognito_key,
        //         isAnonymous: false,
        //         email: getState().user.email,
        //         firstName: getState().user.firstname,
        //         fullName: getState().user.lastname,
        //     });
        // }
    }
}

export const setSelectedAccount = (account) => {
    return (dispatch) => {
        dispatch({
            type: types.SET_SELECTED_ACCOUNT,
            payload: account
        })

        dispatch(cognitoActions.getCurrentCognitoUser())
    }
}

export const setDefaultAccount = (account) => {
    return (dispatch) => {
        dispatch({
            type: types.SET_DEFAULT_ACCOUNT,
            payload: account
        })

        dispatch(cognitoActions.getCurrentCognitoUser())
    }
}

export const selectAccount = () => {
    return (dispatch, getState) => {
        let clients = Object.keys(getState().clients).map(key => getState().clients[key])
        dispatch({
            type: types.SELECT_ACCOUNT,
            payload: {
                defaultaccount: getState().accounts.selectedAccount,
                client: clients.filter(x => x.client_id === getState().accounts.selectedAccount ? getState().accounts.selectedAccount : 2)[0]
            }
        })
    }
}

export const getUserAccounts = (username) => {
    return (dispatch) => {
        window.glyco.log('setUserAccounts get',cognitoUser.username)
        dispatch({
            type: types.GET_USER_ACCOUNTS,
            payload: username
        })
    }
}

export const getLocaleList = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.CLIENTS_GET_LOCALE_LIST
        })
    }
}

export const setLocaleList = (response) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.CLIENTS_SET_LOCALE_LIST,
            payload: response
        })
    }
}

export const setUserAccounts = (data) => {
    return (dispatch) => {
        window.glyco.log('setUserAccounts',data)

        let teams = []
        let accounts = []

        data.forEach(x => {
            teams = x.user_team.map(y => {
                return {
                    team_id: y.team.team_id,
                    title: y.team.title
                }
            })
        })

        data.forEach(x => {
            x.user_team.forEach(y => {
                if (y.team.team_client_business_customer.length) {
                    y.team.team_client_business_customer.map(z => {
                        if (!_.find(accounts, { client_id: z.client.client_id })) {
                            accounts.push({
                                client_id: z.client.client_id,
                                title: z.client.title,
                                selected: false
                            })
                        }
                    })
                }
            })
        })

        teams = _.uniq(teams)
        accounts = _.uniq(accounts)

        accounts = accounts.map((x, i) => {
            return i === 0
                ? { ...x, selected: true }
                : { ...x, selected: false }
        })

        dispatch({
            type: types.SET_USER_ACCOUNTS,
            payload: {
                teams: teams,
                accounts: accounts
            }
        })

        if (accounts.length === 1) {
            dispatch(setDefaultAccount(accounts[0]))
        }

        dispatch(navigationMenuActions.getNavigationMenu())
    }
}
export const setSelectedUserAccount = (data, account) => {
    return (dispatch,getState) => {
        let clients = Object.keys(getState().clients).map(key => getState().clients[key])
        dispatch({
            type : types.USER_SELECTED_ACCOUNT,
            payload : { 
                data : data,
                defaultaccount : account, 
                selectedAccount : account,
                client: clients.filter(x => x.client_id === account ? account : 2)[0]
            }
        })
    }
}
