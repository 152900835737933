import * as _ from 'lodash'
import moment from 'moment'


import * as types from './types'
import * as appTypes from '../../App/types'

import * as formActions from '../../Form/actions'
import * as componentActions from '../../Components/actions'
import { dispatch } from 'rxjs/internal/observable/pairs'

export const toggleUserForm = () => {
    return {
        type: types.TOGGLE_USER_FORM
    }
}

export const toggleManagementForm = () => {
    return {
        type: types.TOGGLE_MANAGEMENT_FORM
    }
}

export const toggleUserActivation = (user_id) => {
    return {
        type: types.TOGGLE_USER_ACTIVATION,
        payload: user_id
    }
}

export const toggleUserAdmin = (user_id) => {
    return {
        type: types.TOGGLE_USER_ADMIN,
        payload: user_id
    }
}


export const setToggledUserActivation = (user_id) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_TOGGLED_USER_ADMIN,
        })
    }
}

export const resetUserPassword = (cognito_key) => {
    return {
        type: types.RESET_USER_PASSWORD,
        payload: {
            type: types.RESET_USER_PASSWORD,
            cognito_key: cognito_key
        }
    }
}

export const userPasswordReset = (cognitoKey) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.USER_PASSWORD_RESET,
        })

        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Password reset successful!',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const saveUserData = () => {
    const getData = (component) => {
        return component.component_field.reduce((obj, x) => {
            if (component.data[x.name]) {
                if (x.key) {
                    if (component.data[x.name] && component.data[x.name].input) {
                        obj[x.name] = component.data[x.name].input
                    }
                }
                else {
                    obj[x.name] = component.data[x.name] ? component.data[x.name].input : null
                }
            }

            return obj
        }, {})
    }

    return (dispatch, getState) => {
        dispatch({
            type: types.SAVE_USER_DATA
        })
        let data = getData(getState().components.user)

        //console.log('SAVE_USER_COMPONENT_INPUT_DATA',data)
        dispatch({
            type : types.SAVE_USER_COMPONENT_INPUT_DATA,
            payload : {
                data: data,
                type: 'SAVE_USER_COMPONENT_INPUT_DATA'
            }
        })

        //dispatch(formActions.saveComponentData (getState().components.user))

        dispatch(componentActions.clearComponentData('user'))

        dispatch(toggleUserForm())

        dispatch(getAllUsers())
    }
}

export const saveNewUserChanges = () => {
   
    return (dispatch, getState) => {
        dispatch({
            type: types.SAVE_USER_DATA
        })

        dispatch({
            type : types.SAVE_USER_COMPONENT_INPUT_DATA,
            payload : {
                data: getState().userstructure,
                type: 'SAVE_USER_COMPONENT_INPUT_DATA'
            }
        })

        //dispatch(formActions.saveComponentData (getState().components.user))

        //dispatch(componentActions.clearComponentData('user'))

        dispatch(toggleUserForm())

        dispatch(getAllUsers())
    }
}
export const saveUserDataDone = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'User successfully saved',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const getAllUsers = () => {
    return {
        type: types.GET_ALL_USERS
    }
}

export const setAllUsers = (data) => {
    return {
        type: types.SET_ALL_USERS,
        payload: data
    }
}

export const getUserTeam = (user_id) =>{

    return {
        type : types.GET_USER_TEAM,
        payload : user_id
    }
}

export const setUserTeam = (data) =>{
    return{
        type : types.SET_USER_TEAM,
        payload : {
            data : data.data,
            prop : 'userTeam'
        }
    }
}

export const setAllUserTeam =(user_id)=>{
    return (dispatch,getState) =>{
        dispatch({
            type : types.SET_ALL_USER_TEAM,
            payload : {
                data : {
                    ...getState().userOptions.allUserTeam,
                    [user_id] : getState().userOptions.userTeam
                },
                prop : 'allUserTeam'
            }
        })
    }
}

export const getComponentListLookupData = (field, search) => {
    //console.log('getComponentListLookupData',{...field, search: search})
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_USER_COMPONENT_LIST_LOOKUP_DATA,
            payload: {...field, search: search}
        })
    }
}

export const setComponentListLookupData = (response, payload) => {
    return (dispatch, getState) => {
        let data = response.data.map(x => {
            return {
                ...x,
                value: x[payload.display.split('.')[0] + '_id'],
                text: x[payload.display.split('.')[1]]
            }
        })
        dispatch({
            type: types.SET_USER_COMPONENT_LIST_LOOKUP_DATA,
            payload: {
                component: payload.component.name,
                data: data,
                field: payload.name
            }
        })
    }
}

export const saveUserManagementChanges = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SAVE_USER_ASSIGNMENT_CHANGES,
            payload: {
                type: types.SAVE_USER_ASSIGNMENT_CHANGES,
                users: getState().userOptions.allUsers
            }
        })
    }
}

export const savedUserManagementChanges = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SAVED_USER_ASSIGNMENT_CHANGES
        })

        dispatch(getAllUsers())

        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'User Management Actions Completed!',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const unlinkUserTeam = (data) =>{
    return {
        type : types.UNLINK_USER_TO_TEAM,
        payload : {
            type : types.UNLINK_USER_TO_TEAM,
            user_id : data.user_id,
            team_id : data.team_id
        }
    }
}


export const setComponentFieldInput = (field, input) => {
    //console.log('setComponentFieldInput',field, input)
    const format = (field, input) => {
        switch (field.component_field_display_type.name) {
            case 'datePicker':
                input = input.toString().trim()
                const now = moment(new Date())
                return new Date(moment(input).set({ 'hour': now.get('hour'), 'minute': now.get('minute'), 'second': now.get('second') }).format('YYYY-MM-DD HH:mm:ss'))
            case 'timePicker':
                input = input.toString().trim()
                return new Date(moment(input).format('YYYY-MM-DD HH:mm:ss'))
            default:
                return input
        }
    }

    return (dispatch, getState) => {
        const component = getState().components[field.component.name]
        const value = input ? format(field, input) : ''

        dispatch({
            type: types.SET_USER_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                component: component.name,
                field: field.name,
                input: value,
                validation: true // validate(component.schema, field, value)
            }
        })
    }
}


export const setComponentFieldInputAndText = (field, input, text) => {
    const format = (field, input) => {
        switch (field.component_field_display_type.name) {
            case 'datePicker':
                input = input.toString().trim()
                const now = moment(new Date())
                return new Date(moment(input).set({ 'hour': now.get('hour'), 'minute': now.get('minute'), 'second': now.get('second') }).format('YYYY-MM-DD HH:mm:ss'))
            case 'timePicker':
                input = input.toString().trim()
                return new Date(moment(input).format('YYYY-MM-DD HH:mm:ss'))
            default:
                return input
        }
    }

    return (dispatch, getState) => {
        const component = getState().components[field.component.name]
        const value = format(field, input)
        dispatch({
            type: types.SET_USER_COMPONENT_FIELD_INPUT_AND_TEXT_VALUE,
            payload: {
                component: component.name,
                field: field.name,
                input: value,
                text: text,
                validation: validate(component.schema, field, value)
            }
        })
    }
}

const validate = (schema, field, input) => {
    let result = []
    if(!input && field.required){
        return {
            valid: false,
            field: field.title,
            message: field.title + ' is required',
        }
    }
    return {
        valid: true,
        field: field.title,
        message: '',
    }
}
