export const status = {
    logOnFailureReason: '',
    logOnToken: '',
    message: ''
}
export const schedule_win = false
export const schedulename = ''
export const scheduletime = null
export const email = false
export const excel = false
export const mon = false
export const tue = false
export const wed = false
export const thu = false
export const fri = false
export const sat = false
export const sun = false
export const dashboard_view = null
export const report_view = null

export const report_key = {
    'oq_dashboard_key': false,
    'oq_report_key': false
}
export const report_data = null
export const report = null

export const enddate = null
export const startdate = null

export const category = []
export const schedule_list = []

export const dataState = {'skip': 0, }
export const new_report = null

