import * as colours from '@mui/material/colors'
import Theme from '../../theme'

export const kpi_block = {
    width: 250,
    height: 250,
    //opacity: 0.85
}

export const selected = {
    backgroundColor: colours.red[100]
}

export const kpi_header = {
    width: 200,
    height: 75,
    //opacity: 0.85
}

export const biDashboard = {
    //width: '100%',
    height: 768,
}

export const primary = {
    marginTop: 90,
    //height: 2198,
    margin: 'auto',
    width: '50%',

   
    border: '3px solid green',
    padding: '10px',
}



// .teamMate:after {
//     content: ".";
//     display: block;
//     height: 0;
//     line-height: 0;
//     clear: both;
//     visibility: hidden;
// }
// .teamMate h2 {
//     font-size: 1.3em;
//     font-weight: normal;
//     padding-top: 17px;
//     margin: 0;
// }
// .teamMate p {
//     margin: 0;
//     font-size: .8em;
// }
// .teamMate img {
//     display: inline-block;
//     vertical-align: top;
//     width: 50px;
//     height: 50px;
//     margin: 10px;
//     border: 1px solid #ccc;
//     border-radius: 50%;
// }
// .mate-info {
//     display: inline-block;
//     vertical-align: top;
// }
export const panelbar_header = {
    fontSize: '20px',
    paddingTop: '17px',
    margin: 0,
    fontWeight: 'bold'
}
export const panelbar_items = {
    paddingRight: '20px',
}
export const panelbar_wrapper = {
    maxWidth: '100%',
    margin: '0 auto',
}
export const fieldHeader = {
    fontWeight: 'bold',
    fontSize: '16px',
    padding: '5px',
    backgroundColor: '#D1E0E6'
}

export const fieldTitle = {
    width: 300,
    display: 'table-cell',
    verticalAlign: 'middle',
    fontSize: '16px',
    textAlign: 'right',
    paddingRight: 10
}

export const fieldContainer = {
    display: 'table',
    padding: '5px',
}
export const fieldContent = {
    display: 'table-cell',
    width: '80%'
}

export const formContent = {
    display: 'table-cell',
    width: '50%'
}

export const textBox = {
    width: '100%'
}