import * as types from './types'
import * as userActions from '../User/actions'
import * as cognitoActions from '../Cognito/actions'
import { Amplify, Auth } from 'aws-amplify';

export const setAmplifyState = (auth) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_AMPLIFY_AUTH_STATE,
            payload: auth
        })
    }
}


export const setAmplifySession = (session) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_AMPLIFY_AUTH_SESSION,
            payload: session
        })
    }
}

export const signup = () => {
    //console.log('>>>>>>> signup')
    return (dispatch, getState) => {
        dispatch({
            type: types.CREATE_SINGUP_USER_ENTRY,
        })
    }
}


export const login = (user) => {
    return (dispatch, getState) => {
        ///dispatch(userActions.getUser('loading', true))
        
        // dispatch(userActions.getUser('loading', true))
        console.log('amplifyx login', user)
        dispatch(cognitoActions.setCognitoUserAuthenticationDetails(user))
        
        // Auth.signOut()
        // .then(data => {
        //     //this.props.actions.signoutCognitoUser()
        //     //console.log('login Amplify user',sub)
        //     dispatch(userActions.getUser(sub))
        // })
        // .catch(err => {
        //     //console.log('handleSingout Err', err)
        // });

        // dispatch({
        //     type: types.SET_LOGIN_INPUT,
        //     payload: props
        // })
    }
}