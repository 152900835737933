import moment from 'moment'
import * as _ from 'lodash'

import * as appTypes from '../../App/types'
import * as types from './types'

export const getAllPayments = (search) => {
    return {
        type: types.GET_SUPPLIER_PAYMENT_LIST,
        payload: {
            search_text: search
        }
    }
}

export const setPaymentList = (payload) => {
    let data = []

    data = payload.data.map(x => {
        return {
            text: x.supplier_fktext + ' | ' + x.reference,
            value: x.reference,
            supplier_id: x.supplier_id,
            suppliername: x.suppliername,
            suppliertransactiondate: x.suppliertransactiondate,
            suppliertransactionmonth: x.suppliertransactionmonth,
            supplierreference: x.reference,
            description: x.description,
            business_id: x.business_id,
            businessname: x.businessname,
            accountingitem_id: x.accountingitem_id,
            itemname: x.itemname,
            debit: x.debit,
        }
    })

    return {
        type: types.SET_SUPPLIER_PAYMENT_LIST,
        payload: {
            data: data,
            component: 'supplierpayment',
            prop: 'reference'
        }
    }
}

export const setCreditorList = (payload) => {
    let data = []

    data = payload.data.map(x => {
        return {
            text: x.suppliername + ' | ' + x.accountnumber,
            value: x.supplier_id
        }
    })

    return {
        type: types.SET_PAY_CREDITORS_LIST,
        payload: {
            data: data
        }
    }
}

export const getCreditorData = (search) => {
    return {
        type: types.GET_PAY_CREDITORS_LIST,
        payload: {
            search_text: search
        }
    }
}

export const setSelectedLookup = (field, value, text) => {
    return {
        type: types.SET_PAY_CREDITORS_LOOKUP,
        payload: {
            value: value,
            text: text,
            prop: field
        }
    }
}

export const setInputValue = (field, value) => {
    return (dispatch, getState) => {
        const format = (field, input) => {
            const definition = getState().components.supplierpayment.component_field.filter(x => x.name === field)[0]

            if (definition) {
                switch (definition.component_field_display_type.name) {
                    case 'datePicker':
                        input = input.toString().trim()
                        const now = moment(new Date())
                        return new Date(moment(input).set({ 'hour': now.get('hour'), 'minute': now.get('minute'), 'second': now.get('second') }).format('YYYY-MM-DD HH:mm:ss'))
                    case 'timePicker':
                        input = input.toString().trim()
                        return new Date(moment(input).format('YYYY-MM-DD HH:mm:ss'))
                    default:
                        return input
                }
            }
            else {
                return input
            }
        }

        dispatch({
            type: types.SET_PAY_FIELD_INPUT_VALUE,
            payload: {
                prop: field,
                value: format(field, value)
            }
        })
    }
}

export const getBusinessListData = (search) => {
    return {
        type: types.GET_PAY_BUSINESS_LIST,
        payload: {
            search_text: search
        }
    }
}

export const setBusinessList = (payload) => {
    let data = []
    data = payload.data.map(x => {
        return {
            text: x.businessname,
            value: x.business_id
        }
    })
    return {
        type: types.SET_PAY_BUSINESS_LIST,
        payload: {
            data: data
        }
    }
}

export const getContraAccountListData = (search) => {
    return {
        type: types.GET_PAY_ACCOUNT_LIST,
        payload: {
            search_text: search
        }
    }
}

export const setContraAccountList = (payload) => {
    let data = []
    data = payload.data.map(x => {
        return {
            text: x.itemdescription,
            value: x.accountingitem_id
        }
    })
    return {
        type: types.SET_PAY_ACCOUNT_LIST,
        payload: {
            data: data
        }
    }
}

export const getDiscountAccountListData = (search) => {
    return {
        type: types.GET_PAY_DISCOUNT_LIST,
        payload: {
            search_text: search
        }
    }
}

export const setDiscountAccountList = (payload) => {
    let data = []
    data = payload.data.map(x => {
        return {
            text: x.itemdescription,
            value: x.accountingitem_id
        }
    })
    return {
        type: types.SET_PAY_DISCOUNT_LIST,
        payload: {
            data: data
        }
    }
}

export const getSupplierPaymentAccount = (id) => {

    return (dispatch, getState) => {
        dispatch({
            type: types.GET_SUPPLIER_PAYMENT_ACCOUNT,
            payload: {
                type: "GET_SUPPLIER_PAYMENT_ACCOUNT",
                id: id
            }
        })
    }
}

export const setSupplierPaymentAccount = (payload) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_SUPPLIER_PAYMENT_ACCOUNT,
            payload: {
                data: payload.data[0]
            }
        })
    }
}

export const saveSupplierPaymentData = (payload, validatefields) => {
    var valid = true
    var text = ''
    for (var i = 0; i < validatefields.length; i++) {
        if (!validatefields[i].value) { valid = false; text += validatefields[i].field + " " }
    }
    if (!valid) {
        return (dispatch, getState) => {
            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    title: 'System Notification',
                    additionalText: 'You missed a couple of fields [' + text + ']...',
                    overflowText: getState().login.fields.email.input,
                    autoHide: 10000,
                    timestamp: moment().format('h:mm A')
                }
            })
        }
    }
    else {
        return {
            type: types.SAVE_SUPPLIER_PAYMENT_DATA,
            payload: {
                data: payload
            }
        }
    }
}

export const savedSupplierPaymentData = (data) => {
    window.glyco.log("SAVED_SUPPLIER_PAYMENT_DATA")
    window.glyco.log(data)
    return (dispatch, getState) => {
        dispatch({
            type: types.SAVED_SUPPLIER_PAYMENT_DATA
        })

        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Payment Capturing Successful!',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const setLookupValue = (field, value, text) => {
    return {
        type: types.SET_PAY_SELECTED_ITEM,
        payload: {
            value: value,
            text: text,
            prop: field
        }
    }
}

export const setBusinessValue = (field, value, text) => {
    return {
        type: types.SET_PAY_SELECTED_ITEM,
        payload: {
            value: value,
            text: text,
            prop: field
        }
    }
}

export const setDiscountValue = (field, value, text) => {
    return {
        type: types.SET_PAY_SELECTED_ITEM,
        payload: {
            value: value,
            text: text,
            prop: field
        }
    }
}

export const setBankAccountValue = (field, value, text) => {
    return {
        type: types.SET_PAY_SELECTED_ITEM,
        payload: {
            value: value,
            text: text,
            prop: field
        }
    }
}

export const invalidEntry = () => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Please capture Creditor, Reference, Payment and Bank Account...',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const setDuplicateReferenceCheck = (payload) => {
    if (payload.data.length > 0) {
        return (dispatch, getState) => {
            dispatch({
                type: types.SUPPLIER_PAYMENT_DUPLICATE_FOUND,
                payload: {
                    component: 'supplierpayment'
                }
            })

            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    title: 'System Notification',
                    additionalText: 'The Reference Number already Exist!',
                    overflowText: getState().login.fields.email.input,
                    autoHide: 10000,
                    timestamp: moment().format('h:mm A')
                }
            })
        }
    }
    else {
        return (dispatch, getState) => {
            dispatch({
                type: types.SUPPLIER_PAYMENT_NO_DUPLICATE_FOUND,
                payload: {
                    component: 'supplierpayment'
                }
            })
        }
    }
}

export const getDuplicateReferenceCheck = (reference) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_SUPPLIER_PAYMENT_DUPLICATE_REFERENCE_CHECK,
            payload: {
                type: "GET_SUPPLIER_PAYMENT_DUPLICATE_REFERENCE_CHECK",
                reference: reference
            }
        })
    }
}

export const foundDuplicateReference = (reference) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'The Reference Number already Exist!',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
} 