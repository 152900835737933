import * as types from './types'

export const setupWorkflowTaskInput = (data, meta, component, step) => {
    return (dispatch, getState) => {
        
        const payload = {
            step: step,
            component: component,
            selected: data,
            meta: meta
        }

        dispatch({
            type: types.CONFIRM_GENERATE_CHECKSHEET,
            payload: payload
        })
    }
}



export const generateChecksheet = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GENERATE_DEFLEET_CHECKSHEET,
            payload: {
                type: 'GENERATE_DEFLEET_CHECKSHEET',
                data: data
            }
        })
    }
}

export const setDefleetChecksheet = (data) => {
    
    //console.log("DEFLEET CHECK SHEET")
    //console.log(data)
    let link = ''

    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'

       /*  return (data, name) => {
            const blob = new Blob(data, { type: 'application/pdf' })
            const url = window.URL.createObjectURL(blob)
            link = url
        } */
        
        return (data, name) => {
            const blob = new Blob(data, { type: 'octet/stream' })
            const url = window.URL.createObjectURL(blob)
            link = url
            a.href = url
            a.download = name
            a.click()

            window.URL.revokeObjectURL(url)
        } 
    }())

    saveByteArray([data.data.pdf.Body], 'Defleet Checksheet.pdf')

    return (dispatch, getState) => {
        dispatch({
            type: types.SET_DEFLEET_CHECKSHEET,
            payload: {
                preview: link,
                filename: data.data.key.replace('quotes/', '')
            }
        })
    }
}

export const resetComponent = (type) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.RESET_THIS_COMPONENT,
            payload: null
        })
    }
}