import * as actions from './actions'
import * as types from './types'
import * as progressBarActions from '../../ProgressBar/actions'
import * as requestActions from '../../Request/actions'
import * as routerActions from '../../Redirect/actions'
import * as requestTypes from '../../Request/types'
import * as config from '../../../config'

export const getTeamUsersEpic = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
        types.GET_TEAM_USERS,
    )
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/user_team/query?team_id=' + payload,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'related': 'user'
                }
            })
                .pipe(
            mergeMap((result) => [
                    actions.setTeamData(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/userteam/query?team_id=' + payload)
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/userteam/query?team_id=' + payload, error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )

export const getAllTeamsEpic = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
        types.GET_ALL_TEAMS,
    )
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/team/query',
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'order': 'title'
                }
            })
                .pipe(
            mergeMap((result) => [
                    actions.setAllTeams(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/team/query')
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/team/query', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )

export const getAllUsersEpic = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
        types.GET_ALL_USERS,
    )
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/user/query',
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'order': 'firstname'
                }
            })
                .pipe(
            mergeMap((result) => [
                    actions.setAllUsers(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/user/query')
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/user/query', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )

export const getAllComponentsEpic = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
        types.GET_ALL_COMPONENTS,
    )
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/component/query',
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                }
            })
                .pipe(
            mergeMap((result) => [
                    actions.setAllComponents(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/component/query')
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/component/query', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )



export const getAllReportsEpic = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
        types.GET_ALL_REPORTS,
    )
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/report/query',
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                }
            })
                .pipe(
            mergeMap((result) => [
                    actions.setAllReports(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/report/query')
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/report/query', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )


export const saveTeamManagementChangesEpic = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
        types.SAVE_TEAM_MANAGEMENT_CHANGES,
    )
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/team/other',
                method: 'POST',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'Content-Type': 'application/json'
                },
                body: payload
            })
                .pipe(
            mergeMap((result) => [
                    actions.savedTeamManagementChanges(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/user/other')
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/user/other', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )

export const getTeamComponentsEpic = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
        types.GET_TEAM_COMPONENTS,
    )
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/team_component/query?team_id=' + payload,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'related': 'component'
                }
            })
                .pipe(
            mergeMap((result) => [
                    actions.setTeamData(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/team_component/query?team_id=' + payload)
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/team_component/query?team_id=' + payload, error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )



export const getTeamReportsEpic = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
        types.GET_TEAM_REPORTS,
    )
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/team_report/query?team_id=' + payload,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'related': 'report'
                }
            })
                .pipe(
            mergeMap((result) => [
                    actions.setTeamData(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/team_report/query?team_id=' + payload)
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/team_report/query?team_id=' + payload, error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )


export const getEventsEpic = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
        types.GET_ALL_EVENTS,
    )
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/workqueue/query',
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime
                }
            })
                .pipe(
            mergeMap((result) => [
                    actions.setEventData(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/team_report/query?team_id=' + payload)
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/team_report/query?team_id=' + payload, error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )

export const getAllAccountsEpic = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
        types.GET_ALL_ACCOUNTS,
    )
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/client/query',
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'order': 'title'
                }
            })
                .pipe(
            mergeMap((result) => [
                    actions.setAllAccounts(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/user/query')
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/user/query', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )

export const getAllCustomersEpic = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
        types.GET_ALL_CUSTOMERS,
    )
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/customer/query',
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'order': 'customername'
                }
            })
                .pipe(
            mergeMap((result) => [
                    actions.setAllCustomers(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/customer/query')
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/customer/query', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )

export const getAllUserCustomersEpic = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
        types.GET_ALL_USER_CUSTOMERS,
    )
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/user_customer/query?user_id='+payload,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'related': 'customer'
                }
            })
                .pipe(
                mergeMap((result) => [
                    actions.setAllUserCustomers(result.response, payload),
                    requestActions.requestSucceeded(config.system.api + '/components/customer/query')
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/customer/query', error)))
            )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )

export const unassignUserToCustomerEpics = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
        types.UNASSIGN_USER_TO_CUSTOMERS,
    )
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/user_customer',
                method: 'DELETE',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                },
                body: {user_customer_id: payload.user_customer_id}
            })
                .pipe(
            mergeMap((result) => [
                    actions.unassignUserToCustomerDone(payload.user_id),
                    requestActions.requestSucceeded(config.system.api + '/components/customer/query')
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/customer/query', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )

export const assignUserToCustomerEpics = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
        types.ASSIGN_USER_TO_CUSTOMERS,
    )
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/user_customer',
                method: 'POST',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'Content-Type': 'application/json'
                },
                body: payload
            })
                .pipe(
            mergeMap((result) => [
                    actions.assignUserToCustomerDone(payload.user_id),
                    requestActions.requestSucceeded(config.system.api + '/components/customer/query')
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/customer/query', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )

export const getAccountBusinessesEpic = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
        types.CHANGE_SELECTED_ACCOUNT,
    )
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/business/query?client_id=' + payload,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'related': 'client'
                }
            })
                .pipe(
            mergeMap((result) => [
                    actions.setAccountBusinesses(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/business/query?client_id=' + payload)
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/business/query?client_id=' + payload, error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )

export const getTeamAccountsEpic = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
        types.GET_TEAM_ACCOUNTS,
    )
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/team_client_business_customer/query?team_id=' + payload,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'related': 'client,business,customer'
                }
            })
                .pipe(
            mergeMap((result) => [
                    actions.setTeamData(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/team_component/query?team_id=' + payload)
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/team_component/query?team_id=' + payload, error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )

export const getTeamAccountBusinessesEpic = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
        types.GET_TEAM_ACCOUNT_BUSINESSES,
    )
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/team_client_business_customer/query?team_id=' + payload.team_id + '&client_id=' + payload.client_id + '&customer_id=null',
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'related': 'client,business,customer'
                }
            })
                .pipe(
            mergeMap((result) => [
                    actions.setTeamData(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/team_component/query?team_id=' + payload)
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/team_component/query?team_id=' + payload, error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )

export const getTeamAccountBusinessCustomersEpic = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
        types.GET_TEAM_ACCOUNT_BUSINESS_CUSTOMERS,
    )
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/team_client_business_customer/query?team_id=' + payload.team_id + '&client_id=' + payload.client_id + '&business_id=' + payload.business_id,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'related': 'client,business,customer'
                }
            })
                .pipe(
            mergeMap((result) => [
                    actions.setTeamData(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/team_component/query?team_id=' + payload)
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/team_component/query?team_id=' + payload, error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )

export const getAccountBusinessCustomersEpic = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
        types.CHANGE_SELECTED_BUSINESS,
    )
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/customer/query?business_id=' + payload,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'related': 'business'
                }
            })
                .pipe(
            mergeMap((result) => [
                    actions.setAccountBusinessCustomers(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/team_component/query?team_id=' + payload)
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/team_component/query?team_id=' + payload, error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )

export const saveTeamAssignmentChangesEpic = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
        types.SAVE_TEAM_ASSIGNMENT_CHANGES,
    )
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/' + (payload.view === 'users' ? 'user_team' : payload.view === 'components' ? 'team_component' : payload.view === 'reports' ? 'team_report' : 'team_client_business_customer') + '/other',
                method: 'POST',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'Content-Type': 'application/json'
                },
                body: payload
            })
                .pipe(
            mergeMap((result) => [
                    actions.savedTeamAssignmentChanges(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/user/query')
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/user/query', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )


export const getLinkedTeamAndUser = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(types.GET_WORKQUEUE_USER_AND_TEAM_LINK)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/workqueue/query?workqueue_id=' + payload,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'related': 'workqueue_user.user,workqueue_team.team'
                }
            })
                .pipe(
            mergeMap((result) => [
                    actions.setLinkedTeamAndUser(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/user/query')
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/user/query', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )

        
export const saveTeamAndUserChangesEpics = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(types.SAVED_TEAM_AND_USER_CHANGES)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/workqueue/other',
                method: 'POST',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'Content-Type': 'application/json'
                },
                body: payload
            })
                .pipe(
            mergeMap((result) => [
                    actions.saveTeamAndUserChangesDone(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/user/query')
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/user/query', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )