export const UPDATE_EVENT_MANAGEMENT_ACK_STATUS = 'UPDATE_EVENT_MANAGEMENT_ACK_STATUS'
export const SET_WORKQUEUE_COMPONENT_INPUT_VALUE = 'SET_WORKQUEUE_COMPONENT_INPUT_VALUE'
export const SET_WORKQUEUE_COMPONENT_GRID_FILTER = 'SET_WORKQUEUE_COMPONENT_GRID_FILTER'
export const SEND_EVENTS_MANAGMENT_EMAIL = 'SEND_EVENTS_MANAGMENT_EMAIL'
export const SET_WORKQUEUE_PROP_INPUT_VALUE = 'SET_WORKQUEUE_PROP_INPUT_VALUE'

export const SET_WORKQUEUE_DATA = 'SET_WORKQUEUE_DATA'
export const GET_WORKQUEUE_FORM_DATA = 'GET_WORKQUEUE_FORM_DATA'
export const SET_WORKQUEUE_FORM_DATA = 'SET_WORKQUEUE_FORM_DATA'
export const GET_WORKQUEUE_ITEM_DATA = 'GET_WORKQUEUE_ITEM_DATA'
export const SET_WORKQUEUE_ITEM_DATA = 'SET_WORKQUEUE_ITEM_DATA'

export const GET_WORKQUEUE_USER_LIST = 'GET_WORKQUEUE_USER_LIST'
export const SET_WORKQUEUE_USER_LIST = 'SET_WORKQUEUE_USER_LIST'