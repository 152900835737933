import * as types from './types'
import * as appTypes from '../../App/types'
import moment from 'moment'
import _ from 'lodash'
import * as formulas from 'formulajs'

export const resetForm = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.STR_BOOKOUT_COMPONENT_RESET_FORM,
        })
    }
}

export const setInputValue = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_STR_BOOKOUT_COMPONENT_INPUT_FIELD_VALUE,
            payload: data
        })
    }
}

export const setListValue = (data) => {
    return (dispatch, getState) => {
        //console.log('setListValue', data)
        let list = getState().strQuotationBookout[data.prop].map(x => ({
            ...x,
            checked: x.value === data.value ? !x.checked : x.checked
        }))
        //console.log('setListValue list2', list)
        dispatch(setInputValue({prop: data.prop, value: list}))
    }
}

export const getLookupValueData = (table, field, search, related = '') => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_STR_BOOKOUT_COMPONENT_DATA_FIELD_INPUT_LIST,
            payload: {
                table: table,
                field: field,
                search: search,
                related: related
            }
        })
    }
}

export const getSupplierRateCardData = (table, field, search, related = '') => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_STR_BOOKOUT_COMPONENT_SUPPLIER_RATE_CARD,
            payload: {
                table: table,
                field: field,
                search: search,
                related: related
            }
        })
    }
}
export const setLookupValueData = (response, payload) => {
    return (dispatch, getState) => {
        //console.log('setLookupValueData response', response)
        //console.log('setLookupValueData payload', payload)
        let data = response.data.map(x => {
            return {
                ...x,
                text: x[payload.field],
                value: x[payload.table + '_id']
            }
        })

        //console.log('setLookupValueData data', data)
        
        dispatch(setInputValue({prop: payload.table + '_data', value: data}))
        // dispatch({
        //     type: types.SET_STR_COMPONENT_INPUT_FIELD_VALUE,
        //     payload: {
        //         value: data,
        //         prop: payload.table + '_data'
        //     }
        // })
    }
}

export const getYardlist = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_STR_BOOKOUT_COMPONENT_YARDLIST_VEHICLES,
            payload: data
        })
    }
}