import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'


import { Container, Row, Col } from 'react-grid-system'
import { Card, CardHeader, CardContent } from '@mui/material'

import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'


import Loading from '../../Loading/component'
import PdfViewer from '../../PDF/component'
import ImageUploader from '../../ImageUploader/component'
import Icon from '../../Icon/component'
import Upload from '../../Upload/component'

import * as styles from './styles'
import * as actions from './actions'

import AdhocComponentFieldInput from '../../Custom/AdhocComponentFieldInput/component'

class AcceptSupplierSTRQuote extends Component {

    componentDidMount() {
        this.props.actions.resetComponent()
    }

    ContractDetails(data) {
        return <div>
            <Card>
                <CardHeader
                    actAsExpander={false}
                    showExpandableButton={false}
                    title={window.t.en('Contract Summary')}
                    subtitle={window.t.en('Contract details.')}
                    icon={<Icon iclass={'custom-icons'} iname={'vehicle_category'} />}
                    style={styles.row}
                >
                </CardHeader>
                <CardContent>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Contract Category:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.contractvehiclecategory}</Col>
                            <Col xl={3} style={styles.fieldLabel}>{'Vehicle Supplier Name:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.supplier}</Col>
                        </Row>
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Contract Start Date:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{moment(data.startdate).format('DD/MM/YYYY')}</Col>
                            <Col xl={3} style={styles.fieldLabel}>{'Contract End Date:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{moment(data.enddate).format('DD/MM/YYYY')}</Col>
                        </Row>
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Contract Number Of Days:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.days}</Col>
                            <Col xl={3} style={styles.fieldLabel}>{'Contract Number Of Months:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.months}</Col>
                        </Row>
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Distance Per Month:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.distancepermonth}</Col>
                            <Col xl={3} style={styles.fieldLabel}>{'Contract Distance:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.contractdistance}</Col>
                        </Row>
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Access KMs:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.accesskm}</Col>
                            <Col xl={3} style={styles.fieldLabel}>{'Quote Valid For:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.quotevalidfor}</Col>
                        </Row>
                    </Container>
                </CardContent>
            </Card>
        </div>
    }

    PaymentDetails(data) {
        return <div>
            <Card>
                <CardHeader
                    actAsExpander={false}
                    showExpandableButton={false}
                    title={window.t.en('Contract Payment Summary')}
                    subtitle={window.t.en('Contract payment details.')}
                    icon={<Icon iclass={'custom-icons'} iname={'vehicle_category'} />}
                    style={styles.row}
                >
                </CardHeader>
                <CardContent>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Total Rental Amount:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{parseFloat(data.monthly_excl).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                            <Col xl={3} style={styles.fieldLabel}>{'Rental Vat Amount:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{parseFloat(data.monthly_vat).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                        </Row>
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Rental Amount:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{parseFloat(data.monthly_incl).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                            <Col xl={3} style={styles.fieldLabel}>{'STR Rental Amount:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{parseFloat(data.accessory_tot).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                        </Row>
                    </Container>
                </CardContent>
            </Card>
        </div>
    }


    uploadInvoice() {
        return <div>
            <Card>
                <CardHeader
                    actAsExpander={false}
                    showExpandableButton={false}
                    title={window.t.en('Upload Supplier Invoice')}
                    subtitle={window.t.en('Upload supplier invoice together with other supporting documents')}
                    icon={<Icon iclass={'custom-icons'} iname={'vehicle_category'} />}
                    style={styles.row}
                >
                </CardHeader>
                <CardContent>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={12}>
                                <Upload
                                    id={'upload_ctl'}
                                    prefix={'inv'}
                                    header={false}
                                />
                            </Col>
                        </Row>
                    </Container>
                </CardContent>
            </Card>
        </div>
    }

    commentsView() {
        return <div>
            <Card>
                <CardHeader
                    actAsExpander={false}
                    showExpandableButton={false}
                    title={window.t.en('Capture Invoice')}
                    subtitle={window.t.en('Capture invoice details')}
                    style={styles.row}
                >
                </CardHeader>
                <CardContent>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={12}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Recieved Date:'}</div>
                                    <div style={styles.fieldContent}>
                                       <TextField
                                                type="date"
                                            id={'txtinvdate'}
                                            hintText='Select recieved date'
                                            container='inline'
                                            mode='landscape'
                                            maxDate={new Date()}
                                            value={this.props.state.recieveddate}
                                            defaultDate={this.props.state.recieveddate}
                                            onChange={(event, value) => {
                                                this.props.actions.setValue('recieveddate', value)
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Comments:'}</div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            id={'Comments'}
                                            hintText={'Enter comments'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={this.props.state.comments}
                                            onChange={(event, value) => {
                                                this.props.actions.setValue('comments', value)
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </CardContent>
            </Card>
        </div>
    }

    renderView(workflow_queue_data) {
        const data = workflow_queue_data.step.data[0].context_data.data.data.data[0]
        const documents = data.documents
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>
                    {
                        documents
                            ? <PdfViewer
                                key={'document_viewer_approval'}
                                name={'booking'}
                                workflow={'fml_customer_quote'}
                                task={'tsk_purchase_order_review_and_send'}
                                documents={documents}
                                edit={false}
                                default_document={'Rebill'}
                                workflow_queue_data={this.props.workflow_queue_data}
                            />
                            : <Loading message={'Retrieving Booking Document...'} />
                    }
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    {this.ContractDetails(data.contract)}
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    {this.PaymentDetails(data.contract)}
                </Col>
            </Row>

            <Row>
                <Col xl={12}>
                    {this.uploadInvoice()}
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    {this.commentsView()}
                </Col>
            </Row>
        </Container>
    }

    render() {
        let workflow_queue_data = null
        window.glyco.log(this.props.workflow_queue_data)
        try {
            workflow_queue_data = this.props.workflow_queue_data.context_data.data.data.data[0]
            workflow_queue_data = workflow_queue_data.step.data[0].context_data.data.data.data[0].step.data[0].context_data.data.data.data[0]
        } catch (error) {

        }
        window.glyco.log('workflow_queue_data')
        window.glyco.log(workflow_queue_data)

        return workflow_queue_data
            ? this.renderView(workflow_queue_data)
            : 'Select vehicle...'
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.acceptSupplierSTRQuote
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AcceptSupplierSTRQuote)