export const READ_COMPONENT = 'READ_COMPONENT'
export const READ_COMPONENT_SET_RELATIONSHIPS = 'READ_COMPONENT_SET_RELATIONSHIPS'
export const READ_COMPONENT_SET_TITLE = 'READ_COMPONENT_SET_TITLE'
export const READ_COMPONENT_SET_DESCRIPTION = 'READ_COMPONENT_SET_DESCRIPTION'
export const READ_COMPONENT_SET_IMAGES = 'READ_COMPONENT_SET_IMAGES'
export const READ_COMPONENT_SET_DOCUMENTS = 'READ_COMPONENT_SET_DOCUMENTS'
export const READ_COMPOMENT_SET_RELATED_COMPONENTS_LIST = 'READ_COMPOMENT_SET_RELATED_COMPONENTS_LIST'
export const READ_COMPONENT_SET_VIEW = 'READ_COMPONENT_SET_VIEW'
export const READ_COMPONENT_GET_RELATED_COMPONENT_DATA = 'READ_COMPONENT_GET_RELATED_COMPONENT_DATA'
export const READ_COMPONENT_SET_RELATED_COMPONENT_DATA = 'READ_COMPONENT_SET_RELATED_COMPONENT_DATA'
export const READ_COMPONENT_TOGGLE_RELATED_COMPONENTS_MENU = 'READ_COMPONENT_TOGGLE_RELATED_COMPONENTS_MENU'
export const READ_COMPONENT_CLEAR_COMPONENT_DATA = 'READ_COMPONENT_CLEAR_COMPONENT_DATA'
export const CLEAR_COMPONENT_RELATIONSHIPS = 'CLEAR_COMPONENT_RELATIONSHIPS'