import * as colours from '@mui/material/colors'
import Theme from '../../../theme'

export const theme = Theme

export const icon = {
    color: colours.red[500]
}

export const related = {
    //fontSize: '10px'
}

export const fieldContainer = {
    display: 'table',
    padding: 5
}

export const fieldTitle = {
    width: 400,
    display: 'table-cell',
    verticalAlign: 'middle',
    fontSize: '16px',
    textAlign: 'right',
    paddingRight: 10
}

export const fieldContent = {
    display: 'table-cell',
    width: '70%'
}

export const headerButton = {
    width: 250
}

export const right = {
    float: 'right'
}

export const toggle = {
    width: '100%'
}

export const textBox = {
    width: '100%'
}

export const row = {
    fontSize: 16,
    textAlign: 'left',
}

export const card = {
    height: '100%'
}

export const hidden = {
    display: 'none'
}

export const rightpanel = {
    background: '#e9ecef',
    border: '2px solid #e9ecef'
}

export const whitediv = {
    backgroundColor: '#fff',
    padding: 2,
    verticalAlign: 'left'
}

export const tableheader = {
    fontWeight: 'bold',
    fontSize: '16px',
    borderBottom: '2px solid grey',    
    marginLeft: '13px',
    marginRight: '13px'
}

export const buttonRight = {    
    verticalAlign: 'right'
}

export const numericFields = {
    textAlign: 'right',
}