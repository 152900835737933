export const SET_CURRENT_GRID_COMPONENT = 'SET_CURRENT_GRID_COMPONENT'
export const GET_COMPONENT_GRID_DATA = 'GET_COMPONENT_GRID_DATA'
export const SET_COMPONENT_GRID_DATA = 'SET_COMPONENT_GRID_DATA'
export const SET_COMPONENT_GRID_PAGE = 'SET_COMPONENT_GRID_PAGE'
export const SELECT_ROW = 'SELECT_ROW'
export const REMOVE_SINGLE_ROW = 'REMOVE_SINGLE_ROW'
export const SELECT_MULTIPLE_ROWS = 'SELECT_MULTIPLE_ROWS'
export const SET_COMPONENT_FIELD_INPUT = 'SET_COMPONENT_FIELD_INPUT'
export const LOAD_QUOTE_ITEM_TYPES = 'LOAD_QUOTE_ITEM_TYPES'
export const SET_QUOTE_ITEM_TYPES = 'SET_QUOTE_ITEM_TYPES'
export const SET_SELECTED_QUOTE_ITEM_TYPES = 'SET_SELECTED_QUOTE_ITEM_TYPES'
export const LOAD_QUOTE_TYPE_ITEMS_LIST = 'LOAD_QUOTE_TYPE_ITEMS_LIST'
export const SET_QUOTE_TYPE_ITEMS_LIST = 'SET_QUOTE_TYPE_ITEMS_LIST'
export const SET_SELECTED_QUOTE_ITEM_LIST = 'SET_SELECTED_QUOTE_ITEM_LIST'
export const SET_SELECTED_ITEM_LIST = 'SET_SELECTED_ITEM_LIST'
export const UPDATE_QUOTE_GRID_ITEMS = 'UPDATE_QUOTE_GRID_ITEMS'
export const QUOTE_GRID_ADD_BLANK_ROW = 'QUOTE_GRID_ADD_BLANK_ROW'
export const REMOVE_QUOTE_GRID_ITEM = 'REMOVE_QUOTE_GRID_ITEM'
export const SET_QUOTE_GRID_FIELD_INPUT_VALUE = 'SET_QUOTE_GRID_FIELD_INPUT_VALUE'
export const SET_COMPONENT_FIELD_DATA = 'SET_COMPONENT_FIELD_DATA'
export const SET_SELECTED_QUOTE_ITEM_TYPES_INPUT = 'SET_SELECTED_QUOTE_ITEM_TYPES_INPUT'
export const SET_SELECTED_QUOTE_ITEM_TYPES_TEXT = 'SET_SELECTED_QUOTE_ITEM_TYPES_TEXT'

export const COMPONENT_GRID_LOADED = 'COMPONENT_GRID_LOADED'
export const ADD_QUOTE_ITEM_BLANK_ROW = 'ADD_QUOTE_ITEM_BLANK_ROW'
export const GET_COMPONENT_GRID_PO_ITEMS_LOOKUP_FIELD_DATA = 'GET_COMPONENT_GRID_PO_ITEMS_LOOKUP_FIELD_DATA'
export const SET_COMPONENT_GRID_PO_ITEMS_LOOKUP_FIELD_DATA = 'SET_COMPONENT_GRID_PO_ITEMS_LOOKUP_FIELD_DATA'
export const SET_COMPONENT_GRID_LOOKUP_FIELD_DATA = 'SET_COMPONENT_GRID_LOOKUP_FIELD_DATA'
export const SET_COMPONENT_INPUT_GRID_LOOKUP_VALUE = 'SET_COMPONENT_INPUT_GRID_LOOKUP_VALUE'
export const SET_COMPONENT_SELECTED_GRID_LOOKUP_VALUE = 'SET_COMPONENT_SELECTED_GRID_LOOKUP_VALUE'

export const RESET_COMPONENT = 'RESET_COMPONENT'
export const OPEN_QUOTE_ITEM_HISTORY = 'OPEN_QUOTE_ITEM_HISTORY'
export const CLOSE_QUOTE_ITEM_HISTORY = 'CLOSE_QUOTE_ITEM_HISTORY'
export const OPEN_VEHICLE_SERVICE_HISTORY_LIST = 'OPEN_VEHICLE_SERVICE_HISTORY_LIST'
export const CLOSE_VEHICLE_SERVICE_HISTORY_LIST = 'CLOSE_VEHICLE_SERVICE_HISTORY_LIST'
export const SET_SELECTED_ROW_VALUES = 'SET_SELECTED_ROW_VALUES'

export const GET_SELECTED_ROW_RELATED_ITEMS_VALUES_MAX = 'GET_SELECTED_ROW_RELATED_ITEMS_VALUES_MAX'
export const GET_SELECTED_ROW_RELATED_ITEMS_VALUES = 'GET_SELECTED_ROW_RELATED_ITEMS_VALUES'
export const SET_SELECTED_ROW_RELATED_ITEMS_VALUES = 'SET_SELECTED_ROW_RELATED_ITEMS_VALUES'
export const TOGGLE_VEHICLE_TYRES_HISTORY_LIST = 'TOGGLE_VEHICLE_TYRES_HISTORY_LIST'
export const TOGGLE_DRIVER_BEHAVIOUR = 'TOGGLE_DRIVER_BEHAVIOUR'
export const GET_MAINTENANCE_ITEM_VALUE_VATTABLE = 'GET_MAINTENANCE_ITEM_VALUE_VATTABLE'