import moment from 'moment'
//import validator from '../validate'
import * as _ from 'lodash'

import * as appTypes from '../../App/types'
import * as gridTypes from '../AdhocGrid/types'
import * as types from './types'


export const getPOs = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_COMPLETED_POS,

        })
    }
}
export const refresh_invno = (val) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.REFRESH_INVNO,
            payload: val
        })

    }
}

export const updateValue = (prop, val) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.UPDATE_VALUE,
            payload: val,
            prop: prop
        })

    }
}

export const toggleItem = (data) => {
    //console.log("<<<<<<<<<< data >>>>>>>>>>>")
    //console.log(data)
    return (dispatch, getState) => {
        dispatch({
            type: types.TOGGLE_ITEM_MANAGEMENT,
            payload: data,
        })
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'Action',
                additionalText: 'Executing, please wait...',
                overflowText: '',
                autoHide: 100000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const getDocumentCheck = (check, data) => {
    //console.log("<<<<<<<<<< data >>>>>>>>>>>")
    //console.log(data)
    return (dispatch, getState) => {
        dispatch({
            type: types.DOCUMENT_CHECK,
            payload: data,
        })

    }
}

export const toggleDontPayItem = (data) => {
    //console.log("<<<<<<<<<< data >>>>>>>>>>>")
    //console.log(data)
    return (dispatch, getState) => {
        dispatch({
            type: types.TOGGLE_DONTPAY_ITEM,
            payload: data
        })

    }
}

export const toggleShortPayItem = (data) => {
    //console.log("<<<<<<<<<< data >>>>>>>>>>>")
    //console.log(data)
    return (dispatch, getState) => {
        dispatch({
            type: types.TOGGLE_SHORTPAY_ITEM,
            payload: data
        })

    }
}
export const toggleCreditItem = (data) => {
    //console.log("<<<<<<<<<< data >>>>>>>>>>>")
    //console.log(data)
    return (dispatch, getState) => {
        dispatch({
            type: types.TOGGLE_CREDIT_ITEM,
            payload: data
        })

    }
}
export const toggleItemAdmin = (data) => {
    //console.log("<<<<<<<<<< data >>>>>>>>>>>")
    //console.log(data)
    return (dispatch, getState) => {
        dispatch({
            type: types.TOGGLE_ITEM_ADMIN,
            payload: data
        })

    }
}

export const approveItem = (data) => {
    //console.log("<<<<<<<<<< data >>>>>>>>>>>")
    //console.log(data)
    return (dispatch, getState) => {
        dispatch({
            type: types.APPROVE_ITEM,
            payload: data
        })

    }
}

export const approveItemAdmin = (data) => {
    //console.log("<<<<<<<<<< data >>>>>>>>>>>")
    //console.log(data)
    return (dispatch, getState) => {
        dispatch({
            type: types.APPROVE_ITEM_ADMIN,
            payload: data
        })

    }
}

export const updateItem = (data) => {
    //console.log("<<<<<<<<<< data >>>>>>>>>>>")
    //console.log(data)
    return (dispatch, getState) => {
        dispatch({
            type: types.UPDATE_ITEM,
            payload: data
        })

    }
}

export const updateItemAdmin = (data) => {
    //console.log("<<<<<<<<<< data >>>>>>>>>>>")
    //console.log(data)
    return (dispatch, getState) => {
        dispatch({
            type: types.UPDATE_ITEM_ADMIN,
            payload: data
        })

    }
}

export const processPayment = (data) => {
    //console.log("<<<<<<<<<< processPayment >>>>>>>>>>>")
    //console.log(data)
    return (dispatch, getState) => {
        dispatch({
            type: types.PROCESS_PAYMENT,
            payload: data
        })

    }
}
export const refresh_invamt = (val) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.REFRESH_INVAMT,
            payload: val
        })

    }
}

export const getposupplierlist = (search) => {
    //console.log('>>>>>>>>>>>>> action : getposupplierlist <<<<<<<<<<<<<')
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_POSUPPLIERLIST,
            payload: {
                search_text: search
            }
        })
    }

}
export const setposupplierlist = (payload) => {
    let data = []
    data = payload.data.map(x => {
        return {
            text: x.suppliername,
            value: x.supplier_id
        }
    })

    return (dispatch, getState) => {
        dispatch({
            type: types.SET_ANY_DATA_LIST,
            payload: {
                data: data,
                component: 'purchaseorder',
                field: 'purchaseorder_id'
            }
        })
    }
}

export const setPOs = (payload) => {
    window.glyco.log('setPOs')
    window.glyco.log(payload)
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_COMPLETED_POS,

        })
    }
}

export const uploadPODocument = (id, supplier_id) => {
    window.glyco.log('setPOs')
    window.glyco.log(id)
    return (dispatch, getState) => {
        const data = {
            id: id,
            documents: getState().upload.documents,
            supplier_id: supplier_id
        }
        window.glyco.log(' uploadPODocument data')
        window.glyco.log(data)
        dispatch({
            type: types.UPLOAD_PO_DOCUMENTS,
            payload: JSON.stringify(data),
        })
    }
}

export const processedSupplier = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: gridTypes.ADHOC_GRID_GET_DATA,
            payload: {
                name: 'payment_grid',
                uri: '/components/supplierpaymentmanagement/other',

                body: {
                    type: 'get_completed_pos',
                    user_id: getState().user,
                    paging: {
                        page: 0,
                        pageSize: 10,
                        pages: 0,
                        count: 0
                    },
                    state: {
                        page: 1,
                        pageSize: 10,
                        sorted: undefined,
                        filtered: undefined
                    },
                    search: ''
                }
            }
        })
        dispatch({
            type: types.GENERATE_PAYMENT_NOTIFICATION,
            payload: {
                type: types.GENERATE_PAYMENT_NOTIFICATION,
                data: id
            }
        })
    }
}
export const refreshbysupplier = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: gridTypes.ADHOC_GRID_GET_DATA,
            payload: {
                name: 'payment_grid',
                uri: '/components/supplierpaymentmanagement/other',

                body: {
                    type: 'get_completed_pos',
                    user_id: getState().user,
                    paging: {
                        page: 0,
                        pageSize: 10,
                        pages: 0,
                        count: 0
                    },
                    state: {
                        page: 1,
                        pageSize: 10,
                        sorted: undefined,
                        filtered: undefined
                    },
                    search: ''
                }
            }
        })
    }
}
export const refreshbysupplierAdmin = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: gridTypes.ADHOC_GRID_GET_DATA,
            payload: {
                name: 'payment_grid_admin',
                uri: '/components/supplierpaymentmanagement/other',

                body: {
                    type: 'get_admin_transactions',
                    user_id: getState().user,
                    paging: {
                        page: 0,
                        pageSize: 10,
                        pages: 0,
                        count: 0
                    },
                    state: {
                        page: 1,
                        pageSize: 10,
                        sorted: undefined,
                        filtered: undefined
                    },
                    search: ''
                }
            }
        })
    }
}
export const setApprovePO = (payload) => {
    window.glyco.log('setApprovePO')
    window.glyco.log(payload)
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'Approved',
                additionalText: 'Purchase order has been successfully approved',
                overflowText: '',
                autoHide: 7500,
                timestamp: moment().format('h:mm A')
            }
        })

        dispatch({
            type: gridTypes.ADHOC_GRID_GET_DATA,
            payload: {
                name: 'purchaseorder',
                uri: '/components/purchaseorder/other',
                body: {
                    type: 'get_completed_pos',
                    paging: {
                        page: 0,
                        pageSize: 10,
                        pages: 0,
                        count: 0
                    },
                    state: {
                        page: 1,
                        pageSize: 10,
                        sorted: undefined,
                        filtered: undefined
                    },
                    search: ''
                }
            }
        })
    }
}

export const approvePO = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.APPROVE_PO,
            payload: data
        })
        dispatch({
            type: gridTypes.ADHOC_GRID_GET_DATA,
            payload: {
                name: 'purchaseorder',
                uri: '/components/purchaseorder/other',
                body: {
                    type: 'get_completed_pos',
                    paging: {
                        page: 0,
                        pageSize: 10,
                        pages: 0,
                        count: 0
                    },
                    state: {
                        page: 1,
                        pageSize: 10,
                        sorted: undefined,
                        filtered: undefined
                    },
                    search: ''
                }
            }
        })
    }
}

export const rejectsPO = (id) => {
    window.glyco.log('setPOs')
    window.glyco.log(id)
    return (dispatch, getState) => {
        dispatch({
            type: types.REJECT_PO,
            payload: id
        })
    }
}

export const setRejectPO = (payload) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'Rejected',
                additionalText: 'Purchase order has been successfully rejected',
                overflowText: '',
                autoHide: 7500,
                timestamp: moment().format('h:mm A')
            }
        })

        dispatch({
            type: gridTypes.ADHOC_GRID_GET_DATA,
            payload: {
                name: 'purchaseorder',
                uri: '/components/purchaseorder/other',
                body: {
                    type: 'get_completed_pos',
                    paging: {
                        page: 0,
                        pageSize: 10,
                        pages: 0,
                        count: 0
                    },
                    state: {
                        page: 1,
                        pageSize: 10,
                        sorted: undefined,
                        filtered: undefined
                    },
                    search: ''
                }
            }
        })
    }
}

export const setUploadPO = (payload) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'Uploaded',
                additionalText: 'Purchase order document successfully uploaded',
                overflowText: '',
                autoHide: 7500,
                timestamp: moment().format('h:mm A')
            }
        })

        dispatch({
            type: gridTypes.ADHOC_GRID_GET_DATA,
            payload: {
                name: 'purchaseorder',
                uri: '/components/purchaseorder/other',
                body: {
                    type: 'get_completed_pos',
                    paging: {
                        page: 0,
                        pageSize: 10,
                        pages: 0,
                        count: 0
                    },
                    state: {
                        page: 1,
                        pageSize: 10,
                        sorted: undefined,
                        filtered: undefined
                    },
                    search: ''
                }
            }
        })
    }
}

export const alert = (msg) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'Approval',
                additionalText: msg,
                overflowText: '',
                autoHide: 7500,
                timestamp: moment().format('h:mm A')
            }
        })
        dispatch({
            type: gridTypes.ADHOC_GRID_GET_DATA,
            payload: {
                name: 'purchaseorder',
                uri: '/components/purchaseorder/other',
                body: {
                    type: 'get_completed_pos',
                    paging: {
                        page: 0,
                        pageSize: 10,
                        pages: 0,
                        count: 0
                    },
                    state: {
                        page: 1,
                        pageSize: 10,
                        sorted: undefined,
                        filtered: undefined
                    },
                    search: ''
                }
            }
        })
    }
}


export const getBusinessList = (search) => {
    //console.log('>>>>>>>>>>>>> action : getBusinessList <<<<<<<<<<<<<')
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_BUSINESSLIST,
            payload: {
                search_text: search
            }
        })
    }

}

export const setBusinessList = (payload) => {
    //console.log('>>>>>>>>>>>>> action : getBusinessList <<<<<<<<<<<<<')
    //console.log(payload)
    let data = []
    data = payload.data.map(x => {
        return {
            text: x.title,
            value: x.client_id
        }
    })
    //console.log(data)
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_BUSINESSLIST,
            payload: data
        })
    }

}

export const setLookupValue = (prop, value, text) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_PAYMENT_SELECTED_ITEM,
            payload: {
                prop: prop,
                values: {
                    value: value,
                    text: text
                }
            }
        })
    }

}

export const getDocument = (data) => {
    //console.log(">>>>>>>>>>>>>>> get" + data.document + " <<<<<<<<<<<")
    //console.log(data)
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Generating ' + data.document + '...',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })

        dispatch({
            type: types.GET_PAYMENT_DOCUMENT,
            payload: {
                type: types.GET_PAYMENT_DOCUMENT,
                data: data
            }
        })
    }
}

export const validateAccountSelection = () => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Please select business account...',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}
export const setPaymentNotification = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'Payment Loaded',
                additionalText: 'Payment processed for approval.',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}


export const getSupplierTransactionDocument = (filename) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Generating Document...',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })

        dispatch({
            type: types.GET_SUPPLIER_TRANSACTION_DOCUMENT,
            payload: {
                type: "GET_SUPPLIER_TRANSACTION_DOCUMENT",
                filename: filename
            }
        })
    }
}

export const setPaymentDocumentPDF = (data) => {
    //console.log("Does it hit")
    //console.log(data)
    return (dispatch, getState) => {

        switch (data.data.document ? data.data.document : data.data.key) {
            case 'Remittance': {
                //console.log(">>>>>>>>>>>>>>> setRemittancePDF <<<<<<<<<<<")
                dispatch(setRemittancePDF(data))
            }
                break;
            case 'Recon': {
                //console.log(">>>>>>>>>>>>>>> setReconPDF <<<<<<<<<<<")
                dispatch(setDocumentPDF(data))
            }
                break;
            case 'Requisition': {
                //console.log(">>>>>>>>>>>>>>> setRequisitionPDF <<<<<<<<<<<")
                dispatch(setDocumentPDF(data))
            }
                break;
        }
    }
}

export const setDownloadedPDF = (data) => {
    //console.log("Does it hit")
    //console.log(data)
    return (dispatch, getState) => {
        dispatch(setDocumentPDF(data))
    }
}

export const setDocumentPDF = (data) => {
    let link = ''

    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'

        return (data, name) => {
            const blob = new Blob(data, { type: 'octet/stream' })
            const url = window.URL.createObjectURL(blob)

            a.href = url
            a.download = name
            a.click()

            window.URL.revokeObjectURL(url)
        }
    }())

    saveByteArray([data.data.pdf.Body], data.data.key)
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Document Generated!',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const setRemittancePDF = (data) => {

    return (dispatch, getState) => {
        const header = [
            { key: 'date', name: 'Date', default: '' },
            { key: 'reference', name: 'Invoice Number', default: '' },
            { key: 'description', name: 'Description', default: '' },
            { key: 'credit', name: 'Credit', default: '' },
            { key: 'maintenancefund', name: 'Maintenance Fund', default: '' },
            { key: 'rebill', name: 'Rebill', default: '' },
            { key: 'shortpaid', name: 'Short Payment', default: '' },
            { key: 'invoiceamount', name: 'Invoice Total', default: '' },
            { key: 'payable', name: 'Amount Payable', default: '' },
        ]

        dispatch({
            type: types.GET_EXCEL_GRID_EXPORT,
            payload: {
                type: 'GET_EXCEL_GRID_EXPORT',
                component: data.data.document,
                data: data.data.excel,
                header: header
            }
        })
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Document Generated!',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const addBlankRow = (type, id) => {
    //console.log("Blank ID")
    //console.log(id)

    return (dispatch, getState) => {
        let obj = []
        let prop = type
        switch (type) {
            case 'payments':
                {
                    obj = getState().supplierpaymentmanagement[type]
                    obj.push(
                        {
                            id: id,
                            date: '',
                            eft: 0.00,
                            amount: 0.00,
                            discount: 0.00,
                            total: 0.00
                        })
                } break;
            default:
                {
                    obj = getState().supplierpaymentmanagement[type]
                    obj.push(
                        {
                            id: id,
                            date: '',
                            invoicenumber: '',
                            amount: 0.00,
                            reason: '',
                            total: 0.00
                        })
                }
        }


        dispatch({
            type: types.ADD_BLANK,
            payload: obj,
            prop: prop
        })
    }
}

export const setUpdateItem = (type, id, prop, value) => {
    return (dispatch, getState) => {
        let obj = getState().supplierpaymentmanagement[type]
        var data = obj.map((x, i) => {
            return (x.id === id && (prop === 'date')) ?
                {
                    ...x,
                    date: value
                } : (x.id === id && (prop === 'eft')) ?
                    {
                        ...x,
                        eft: (value),
                        amount: (value),
                        total: parseFloat(value) + parseFloat(x.discount)
                    } : (x.id === id && (prop === 'amount')) ?
                        {
                            ...x,
                            amount: (value),
                            total: (value)
                        } : (x.id === id && (prop === 'discount')) ?
                            {
                                ...x,
                                discount: value,
                                total: parseFloat(value) + parseFloat(x.amount)
                            }
                            : (x.id === id && (prop === 'reason')) ?
                                {
                                    ...x,
                                    reason: value,
                                } : (x.id === id && (prop === 'invoicenumber')) ?
                                    {
                                        ...x,
                                        invoicenumber: value,
                                    } : x
        })
        dispatch({
            type: types.UPDATE_ITEM_VALUE,
            payload: {
                data: data,
                prop: type
            }
        })
    }
}

export const setCommitData = (data) => {
    return (dispatch, getState) => {
        let obj = getState().supplierpaymentmanagement[data.type]
        dispatch({
            type: types.COMMIT_PAYMENT,
            payload: {
                data: obj
            }
        })
    }
}

export const removeRow = (type, id) => {
    return (dispatch, getState) => {
        let obj = getState().supplierpaymentmanagement[type].filter(x => x.id !== id)
        dispatch({
            type: types.UPDATE_ITEM_VALUE,
            payload: {
                data: obj,
                prop: type
            }
        })
    }
}
export const getPaymentList = (search_parameters) => {
    //console.log("search_parameters")
    //console.log(search_parameters)
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_PAYMENT_DATA_LIST,
            payload: {
                type: 'get_payment_list',
                search: search_parameters
            }
        })
        /* dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'Action',
                additionalText: 'Loading data, please wait...',
                overflowText: '',
                autoHide: 100000,
                timestamp: moment().format('h:mm A')
            }
        }) */
    }
}

export const getPaymentListAdmin = (search_parameters) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_PAYMENT_DATA_LIST_ADMIN,
            payload: {
                type: 'get_payment_list_admin',
                search: search_parameters
            }
        })
        /* dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'Action',
                additionalText: 'Loading admin data, please wait...',
                overflowText: '',
                autoHide: 100000,
                timestamp: moment().format('h:mm A')
            }
        }) */
    }
}

export const getMainPaymentList = (input) => {
    return (dispatch, getState) => {
        let item_data = getState().supplierpaymentmanagement.main_payment_list
        dispatch({
            type: types.SET_PAYMENT_DATA_LIST_ADMIN,
            payload: item_data
        })
    }
}

export const getUpdatePaymentList = (input) => {
    //console.log("getUpdatePaymentList")
    //console.log(input)
    return (dispatch, getState) => {
        dispatch(setValidationValue('saveChanges', null))
        dispatch(setValue('previewDocuments', null))
        let item_data = getState().supplierpaymentmanagement.payment_list
        let id = input.id
        let notpaying = 0.00, payable = 0.00, payment = 0.00, shortpay = 0.00, discount = 0.00, discount_perc = 0.00

        //effecting the change per field
        if (item_data.length) {
            item_data[0].items = item_data[0].items.map(x => {
                //id: i.suppliertransaction_id
                return (x.suppliertransaction_id === id) ? {
                    ...x,

                    dontpay: input.column == 'dontpay' ? (x.dontpay ? false : true) :
                        input.column == 'accept' ? (x.selected ? x.dontpay : false) : x.dontpay,

                    selected: input.column == 'accept' ? (x.selected ? false : true) :
                        input.column == 'dontpay' ? (!x.dontpay ? false : x.selected) : x.selected,
                } : x
            })

            //Managing the totalpayment fiel seperate
            item_data[0].items = item_data[0].items.map(x => {
                //id: i.suppliertransaction_id
                return (x.suppliertransaction_id === id) ? {
                    ...x,
                    totalpayment: x.dontpay ? -(Number(x.payment) - Number(x.credit) - Number(x.shortpay)) :
                        x.selected ? Number(x.payment) - Number(x.credit) - Number(x.shortpay) : 0.00
                } : x
            })

            for (let x of item_data[0].items) {
                notpaying += x.dontpay ? x.totalpayment : 0.00
                payable += x.selected ? Number(x.payment) - Number(x.credit) - Number(x.shortpay) : 0.00
                payment += x.selected ? Number(x.payment) : 0.00
                shortpay += x.selected ? Number(x.shortpay) : 0.00
            }
            item_data[0].dontpay = (Math.abs(notpaying)).toFixed(2).toString()
            item_data[0].payments = (payable - Math.abs(notpaying)).toFixed(2).toString()
            item_data[0].payment = (payment).toFixed(2).toString()

            discount_perc = getState().supplierpaymentmanagement.discount_perc
            discount = discount_perc && payment ? ((Number(payment) * Number(discount_perc)) / 100).toFixed(2).toString() : 0.00
            payable = payable - discount

            dispatch(setValue('short_pay', (shortpay).toFixed(2).toString()))
            dispatch(setValue('not_paying', (notpaying).toFixed(2).toString()))
            dispatch(setValue('total_payable', (payable).toFixed(2).toString()))
            dispatch(setValue('selected_invoices', (payment).toFixed(2).toString()))
            dispatch(setValue('discount', discount))
        }
        dispatch({
            type: types.SET_PAYMENT_DATA_LIST,
            payload: item_data
        })
        /* if (item_data.length) {
            dispatch(setReconData(item_data[0]))
            dispatch(setRequisitionData(item_data[0]))
            dispatch(setRemittanceData(item_data[0]))
            dispatch(setValue('refresh_documents', true))
        } */
    }
}

export const getPaymentListMaint = (search_parameters) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_PAYMENT_DATA_LIST_MAINT,
            payload: {
                type: 'get_payment_list_maint',
                search: search_parameters
            }
        })
        /* dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'Action',
                additionalText: 'Loading maintenance data, please wait...',
                overflowText: '',
                autoHide: 100000,
                timestamp: moment().format('h:mm A')
            }
        }) */
    }
}

export const getPaymentListTyres = (search_parameters) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_PAYMENT_DATA_LIST_TYRES,
            payload: {
                type: 'get_payment_list_tyres',
                search: search_parameters
            }
        })
        /*  dispatch({
             type: appTypes.SHOW_NOTIFICATION,
             payload: {
                 title: 'Action',
                 additionalText: 'Loading tyres data, please wait...',
                 overflowText: '',
                 autoHide: 100000,
                 timestamp: moment().format('h:mm A')
             }
         }) */
    }
}



export const setPaymentList = (response) => {
    //console.log("response", response)
    return (dispatch, getState) => {
        let data = response.map(x => {
            return {
                ...x,
                showdetails: false,
                showcomments: false
            }
        })

        dispatch(setValue("technical_payment", data.length && data[0].items && data[0].items.filter(x => Number(x.maintenancetransaction_id ? x.maintenancetransaction_id : 0) > 0).length ? false : true))

        dispatch(setValue('refresh_documents', false))
        dispatch(clearReport())

        let item_data = data
        let notpaying = 0.00, payable = 0.00, payment = 0.00, shortpay = 0.00, discount = 0.00, discount_perc = 0.00
        //Managing the totalpayment fiel seperate
        if (item_data.length) {
            item_data[0].items = item_data[0].items.map(x => {
                //id: i.suppliertransaction_id
                return {
                    ...x,
                    totalpayment: x.dontpay ? -(Number(x.payment) - Number(x.credit) - Number(x.shortpay)) :
                        x.selected ? Number(x.payment) - Number(x.credit) - Number(x.shortpay) : 0.00
                }
            })

            for (let x of item_data[0].items) {
                notpaying += x.dontpay ? x.totalpayment : 0.00
                payable += x.selected ? Number(x.payment) - Number(x.credit) - Number(x.shortpay) : 0.00
                payment += x.selected ? Number(x.payment) : 0.00
                shortpay += x.selected ? Number(x.shortpay) : 0.00
            }
            item_data[0].dontpay = (Math.abs(notpaying)).toFixed(2).toString()
            item_data[0].payments = (payable - Math.abs(notpaying)).toFixed(2).toString()
            item_data[0].payment = (payment).toFixed(2).toString()


            discount_perc = Number(item_data[0].discount_perc ? item_data[0].discount_perc : 0)
            discount = discount_perc && payment ? ((Number(payment) * Number(discount_perc)) / 100).toFixed(2).toString() : 0.00
            payable = payable - discount

            //Focusing on the Supplier in action
            //let data = state.payment_list
            // data.push(item_data)
            //console.log('discountdiscountdiscountdiscountdiscount')
            //console.log(discount)
            dispatch(setValue('short_pay', (shortpay ? shortpay : 0).toFixed(2).toString()))
            dispatch(setValue('not_paying', (notpaying ? notpaying : 0).toFixed(2).toString()))
            dispatch(setValue('total_payable', (payable ? payable : 0).toFixed(2).toString()))
            dispatch(setValue('selected_invoices', (payment ? payment : 0).toFixed(2).toString()))
            dispatch(setValue('statement_amount', (item_data[0].statement_amount ? item_data[0].statement_amount : 0).toFixed(2).toString()))
            dispatch(setValue('discount', discount))
        }
        dispatch({
            type: types.SET_PAYMENT_DATA_LIST,
            payload: item_data
        })

        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'Action',
                additionalText: 'Data loaded!',
                overflowText: '',
                autoHide: 1000000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const setPaymentListAdmin = (response) => {
    //console.log("response", response)
    return (dispatch, getState) => {
        let data = response.map(x => {
            return {
                ...x,
                showdetails: false,
                showcomments: false
            }
        })
        dispatch({
            type: types.SET_PAYMENT_DATA_LIST_ADMIN,
            payload: data
        })
        dispatch({
            type: types.SET_PAYMENT_MAIN_DATA,
            payload: data
        })
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'Action',
                additionalText: 'Admin data loaded!',
                overflowText: '',
                autoHide: 100000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const setPaymentListMaint = (response) => {
    //console.log("response", response)
    return (dispatch, getState) => {
        let data = response.map(x => {
            return {
                ...x,
                showdetails: false,
                showcomments: false
            }
        })
        dispatch({
            type: types.SET_PAYMENT_DATA_LIST_MAINT,
            payload: data
        })
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'Action',
                additionalText: 'Maintenance data loaded!',
                overflowText: '',
                autoHide: 100000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const setPaymentListTyres = (response) => {
    //console.log("response", response)
    return (dispatch, getState) => {
        let data = response.map(x => {
            return {
                ...x,
                showdetails: false,
                showcomments: false
            }
        })
        dispatch({
            type: types.SET_PAYMENT_DATA_LIST_TYRES,
            payload: data
        })
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'Action',
                additionalText: 'Tyres data loaded!',
                overflowText: '',
                autoHide: 100000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const toggleItemProps = (inx) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_PAYMENT_PROP_INDEX,
            payload: getState().supplierpaymentmanagement.prop_inx ? null : inx
        })
    }
}

export const toggleItemDetails = (inx) => {

    return (dispatch, getState) => {
        dispatch({
            type: types.SET_PROP_DRAWER_INDEX,
            payload: getState().supplierpaymentmanagement.prop_drawer ? null : inx
        })
    }
}

export const setSupplierList = (payload) => {
    //console.log("setSupplierList")
    //console.log(payload)
    let data = payload.data
    /* data = payload.data.map(x => {
        return {
            text: x.suppliername,
            value: x.supplier_id
        }
    }) */
    return {
        type: types.SET_AUTOMATED_SUPPLIER_PAYMENT_LIST,
        payload: payload.data
    }
}

export const getSupplierList = (search) => {
    return {
        type: types.GET_AUTOMATED_SUPPLIER_PAYMENT_LIST,
        payload: {
            search_text: search
        }
    }
}

export const linkSupplierDocument = (data) => {

    return (dispatch, getState) => {
        let documents = getState().upload.documents
        if (documents.length) {
            dispatch({
                type: types.LINK_SUPPLIER_DOCUMENT,
                payload: {
                    type: 'link_documents',
                    data: data,
                    documents: documents
                }
            })
            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    title: 'Action',
                    additionalText: 'Linking Documents...',
                    overflowText: '',
                    autoHide: 100000,
                    timestamp: moment().format('h:mm A')
                }
            })
        } else {
            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    title: 'Action',
                    additionalText: 'No Documents Attached...',
                    overflowText: '',
                    autoHide: 10000,
                    timestamp: moment().format('h:mm A')
                }
            })
        }
    }
}

export const resetComponent = (type) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.RESET_PAYMENT_COMPOMENT_STATE
        })
    }
}

export const getCommitData = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.COMMIT_PAYMENT,
            payload: data
        })
    }
}

export const getUpdatePaymentListHeader = () => {
    return (dispatch, getState) => {

        dispatch(setValidationValue('saveChanges', null))
        dispatch(setValue('previewDocuments', null))
        dispatch(clearReport())

        let item_data = getState().supplierpaymentmanagement.payment_list
        let notpaying = 0.00, payable = 0.00, payment = 0.00, shortpay = 0.00, discount = 0.00, discount_perc = 0.00
        if (item_data.length) {
            for (let x of item_data[0].items) {
                notpaying += x.dontpay ? x.totalpayment : 0.00
                payable += x.selected ? Number(x.payment) - Number(x.credit) - Number(x.shortpay) : 0.00
                payment += x.selected ? Number(x.payment) : 0.00
                shortpay += x.selected ? Number(x.shortpay) : 0.00
            }
            item_data[0].dontpay = (Math.abs(notpaying)).toFixed(2).toString()
            item_data[0].payments = (payable - Math.abs(notpaying)).toFixed(2).toString()
            item_data[0].payment = (payment).toFixed(2).toString()

            discount_perc = Number(getState().supplierpaymentmanagement.discount_perc ? getState().supplierpaymentmanagement.discount_perc : 0)
            discount = discount_perc && payment ? ((Number(payment) * Number(discount_perc)) / 100).toFixed(2).toString() : 0.00
            payable = payable - discount

            dispatch(setValue('short_pay', (shortpay ? shortpay : 0).toFixed(2).toString()))
            dispatch(setValue('not_paying', (notpaying ? notpaying : 0).toFixed(2).toString()))
            dispatch(setValue('total_payable', (payable ? payable : 0).toFixed(2).toString()))
            dispatch(setValue('selected_invoices', (payment ? payment : 0).toFixed(2).toString()))
            dispatch(setValue('discount', discount))
        }
        /* if (item_data.length) {
            dispatch(setReconData(item_data[0]))
            dispatch(setRequisitionData(item_data[0]))
            dispatch(setRemittanceData(item_data[0]))
            dispatch(setValue('refresh_documents', true))
        } */
    }
}



export const setValue = (prop, value) => {
    return (dispatch, getState) => {
        if (prop.indexOf('date') >= 0) {
            value = value.toString().trim()
            const now = moment(new Date())
            let date = new Date(moment(value).set({ 'hour': now.get('hour'), 'minute': now.get('minute'), 'second': now.get('second') }).format('YYYY-MM-DD HH:mm:ss'))
            dispatch({
                type: types.SET_PAYMENT_FIELD_INPUT_VALUE,
                payload: {
                    prop: prop,
                    value: date
                }
            })
        }
        else {
            dispatch({
                type: types.SET_PAYMENT_FIELD_INPUT_VALUE,
                payload: {
                    prop: prop,
                    value: value
                }
            })
        }

    }
}

export const setValidationValue = (prop, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_PAYMENT_VALIDATION_VALUE,
            payload: {
                prop: prop,
                value: value
            }
        })
    }
}

export const getUpdateAllPaymentList = () => {
    return (dispatch, getState) => {
        dispatch(setValue('refresh_documents', false))
        dispatch(clearReport())

        let state = getState().supplierpaymentmanagement
        let item_data = state.payment_list
        let notpaying = 0.00, payable = 0.00, payment = 0.00, shortpay = 0.00, discount = 0.00, discount_perc = 0.00
        //effecting the change per field
        if (item_data.length) {
            item_data[0].items = item_data[0].items.map(x => {
                //id: i.suppliertransaction_id
                return {
                    ...x,
                    dontpay: state.accept_all ? false : x.dontpay,
                    selected: state.accept_all
                }
            })

            //Managing the totalpayment fiel seperate
            item_data[0].items = item_data[0].items.map(x => {
                //id: i.suppliertransaction_id
                return {
                    ...x,
                    totalpayment: x.dontpay ? -(Number(x.payment) - Number(x.credit) - Number(x.shortpay)) :
                        x.selected ? Number(x.payment) - Number(x.credit) - Number(x.shortpay) : 0.00
                }
            })

            for (let x of item_data[0].items) {
                notpaying += x.dontpay ? x.totalpayment : 0.00
                payable += x.selected ? Number(x.payment) - Number(x.credit) - Number(x.shortpay) : 0.00
                payment += x.selected ? Number(x.payment) : 0.00
                shortpay += x.selected ? Number(x.shortpay) : 0.00
            }
            item_data[0].dontpay = (Math.abs(notpaying)).toFixed(2).toString()
            item_data[0].payments = (payable - Math.abs(notpaying)).toFixed(2).toString()
            item_data[0].payment = (payment).toFixed(2).toString()


            discount_perc = Number(state.discount_perc ? state.discount_perc : 0)
            discount = discount_perc && payment ? ((Number(payment) * Number(discount_perc)) / 100).toFixed(2).toString() : 0.00
            payable = payable - discount

            //Focusing on the Supplier in action
            //let data = state.payment_list
            // data.push(item_data)
            //console.log('discountdiscountdiscountdiscountdiscount')
            //console.log(discount)
            dispatch(setValue('short_pay', (shortpay ? shortpay : 0).toFixed(2).toString()))
            dispatch(setValue('not_paying', (notpaying ? notpaying : 0).toFixed(2).toString()))
            dispatch(setValue('total_payable', (payable ? payable : 0).toFixed(2).toString()))
            dispatch(setValue('selected_invoices', (payment ? payment : 0).toFixed(2).toString()))
            dispatch(setValue('discount', discount))
        }
        dispatch({
            type: types.SET_PAYMENT_DATA_LIST,
            payload: item_data
        })

        /* if (item_data.length) {
            dispatch(setReconData(item_data[0]))
            dispatch(setRequisitionData(item_data[0]))
            dispatch(setRemittanceData(item_data[0]))
            dispatch(setValue('refresh_documents', true))
        } */
    }
}

export const setReconData = (data) => {
    return (dispatch, getState) => {
        let state = getState().supplierpaymentmanagement
        let report_data =
        {
            "recon":
            {
                "accountnumber": data.accountnumber,
                "creditor": data.supplier,
                "statementdate": moment(state.statement_date).format('YYYY-MM-DD'),
                "statementamount": Number(state.statement_amount),
                "paymentamount": Number(state.total_payable),
                "creditamount": "0.00",
                "discountperc": Number(state.discount_perc),
                "discountamount": Number(state.discount),
                "amountpayable": Number(state.selected_invoices),
                "shortpay": Number(state.short_pay),
                "preparedby": {
                    "name": getState().user.firstname + ' ' + getState().user.lastname,
                    "date": moment().format('YYYY-MM-DD HH:mm')
                },
                "reviewedby": {
                    "name": "",
                    "date": ""
                },
                "payments": state.payments,
                "credits": state.credits,
                "other":  Number(state.short_pay) ? [{
                    "date":"",
                    "invoicenumber":"",
                    "amount":"",
                    "reason":"Penalties",
                    "total": Number(state.short_pay)
                }]  : state.others
            }
        }
        dispatch(setValue('recon_data', report_data))
    }
}

export const setRequisitionData = (data) => {

    return (dispatch, getState) => {
        let state = getState().supplierpaymentmanagement
        let items = data.items.filter(x => x.selected == true)
        let items_distinct = []
        let trans_acc = []
        if (items.length > 0) {
            items.map(i => {
                if (trans_acc.indexOf(i.accountno) === -1) {
                    trans_acc.push(i.accountno)
                }
            });
        }

        for (let acc of trans_acc) {
            let amountexcl = 0, vat = 0, totalincl = 0
            let account = ''
            items.map(i => {
                if (i.accountno === acc) {
                    account = i.account
                    amountexcl += parseFloat(i.amountexcl)
                    vat += parseFloat(Number(i.totalincl)-Number(i.amountexcl))
                    totalincl += parseFloat(i.totalincl)
                }
            })
            items_distinct.push({
                "accountno": acc,
                "account": account,
                "amountexcl": Number(amountexcl.toFixed(2)),
                "vat": Number(vat.toFixed(2)),
                "totalincl": Number(totalincl.toFixed(2))
            })
        }

        let report_data = {
            "req": {
                "clientname": state.selectedBusinessUnit.text,
                "accountnumber": data.accountnumber,
                "creditor": data.supplier,
                "statementdate": state.statement_date ? moment(state.statement_date).format('YYYY-MM-DD') : '',
                "datestamp": moment().format('YYYY-MM-DD HH:mm'),
                "statementamount": Number(state.statement_amount),
                "shortpay": Number(state.short_pay),
                "paymentamount": Number(state.total_payable),
                "discountperc": Number(state.discount_perc),
                "discountamount": Number(state.discount),
                "discountgranted": Number(state.discount) ? true : false,
                "amountpayable": Number(state.selected_invoices),
                "totalexcl": _.sumBy(items, (d) => { return parseFloat(d.amountexcl) }).toFixed(2),
                "totalvat": _.sumBy(items, (d) => { return parseFloat(d.vatted) }).toFixed(2),
                "totalincl": _.sumBy(items, (d) => { return parseFloat(d.totalincl) }).toFixed(2),
                "payableexcl": 0.00,
                "payablevat": 0.00,
                "payableincl": 0.00,
                "paymentdetails": "",
                "paymentrebilldetails": "",
                "compliancestatus": data.supp_payment.compliance,
                "comments": {
                    "comments": state.comments,
                    "managercomments": "",
                    "gmcomments": "",
                    "financecomments": "",
                    "ceocomments": ""
                },
                "transactions": items_distinct,
                "d_transactions": items_distinct,
                "requestedby": {
                    "name": "",
                    "date": "",
                    "time": ""
                },
                "preparedby": {
                    "name": getState().user.firstname + ' ' + getState().user.lastname,
                    "date": moment().format('YYYY-MM-DD'),
                    "time": moment().format('HH:mm')
                },
                "reviewedby": {
                    "name": "",
                    "date": "",
                    "time": ""
                },
                "financereviewedby": {
                    "name": "",
                    "date": "",
                    "time": ""
                },
                "approvedby": {
                    "name": "",
                    "date": "",
                    "time": ""
                },
                "loadedby": {
                    "name": "",
                    "date": "",
                    "time": ""
                },
                "authorisedby": {
                    "name": "",
                    "date": "",
                    "time": ""
                },
                "expensecategories": {
                    "maintenance": data.suppliertype.indexOf("Maintenance") >= 0 ? true : false,
                    "subhire": data.suppliertype.indexOf("Canopies") >= 0 ? true : false,
                    "panelbeaters": data.suppliertype.indexOf("Panel") >= 0 ? true : false,
                    "administrative": data.suppliertype.indexOf("Admin") >= 0 ? true : false,
                    "legalfees": data.suppliertype.indexOf("Legal") >= 0 ? true : false,
                    "tyres": data.suppliertype.indexOf("Tyres") >= 0 ? true : false,
                    "accessories": data.suppliertype.indexOf("Accessories") >= 0 ? true : false,
                    "tracking": data.suppliertype.indexOf("Tracking") >= 0 ? true : false,
                    "utilities": data.suppliertype.indexOf("Dealership") >= 0 ? true : false,
                    "other": data.suppliertype.indexOf("Other") >= 0 ? true : false
                },
                "bankingdetails": {
                    "bank": data.supplierdetails.bankname,
                    "accountno": data.supplierdetails.bankaccountnumber,
                    "branchcode": data.supplierdetails.bankbranchnumber,
                    "accounttype": data.supplierdetails.bankaccountname
                }
            }
        }
        dispatch(setValue('requisition_data', report_data))
    }
}

export const setRemittanceData = (data) => {
    var items = data.items.filter(x => x.selected == true)

    return (dispatch, getState) => {
        let state = getState().supplierpaymentmanagement
        let report_data = {
            "accountnumber": data.accountnumber,
            "supplier": data.supplier,
            "businessunit": state.selectedBusinessUnit.text,
            "items": items.map(function (x) {
                return {
                    date: x.date ? moment(x.date).format('YYYY-MM-DD') : '',
                    type: x.account,
                    reference: x.reference,
                    description: x.description,
                    credit: x.credit ? parseFloat(x.credit.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0.00,
                    invoiceamount: x.totalincl ? parseFloat(x.totalincl.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0.00,
                    maintenancefund: x.rebill ? 0.00 : x.totalincl ? parseFloat(x.totalincl.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0.00,
                    rebill: x.rebill ? x.totalincl ? parseFloat(x.totalincl.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0.00 : 0.00,
                    shortpaid: x.shortpay ? parseFloat(x.shortpay.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0.00,
                    payable: x.totalincl ? parseFloat(x.totalincl.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0.00
                }
            }),
            "subtotal": {
                "date": "",
                "type": "",
                "reference": "",
                "description": "Sub-Total",
                "credit": parseFloat(_.sumBy(items, (d) => { return parseFloat(d.credit ? d.credit : 0.00) }).toFixed(2).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                "invoiceamount": parseFloat(_.sumBy(items, (d) => { return parseFloat(d.totalincl ? d.totalincl : 0.00) }).toFixed(2).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                "maintenancefund": parseFloat(_.sumBy(items, (d) => { return parseFloat(d.rebill ? 0.00 : d.totalincl ? d.totalincl : 0.00) }).toFixed(2).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                "rebill": parseFloat(_.sumBy(items, (d) => { return parseFloat(d.rebill ? d.totalincl : 0.00) }).toFixed(2).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                "shortpaid": parseFloat(_.sumBy(items, (d) => { return parseFloat(d.shortpay ? d.shortpay : 0.00) }).toFixed(2).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                "payable": parseFloat(_.sumBy(items, (d) => { return parseFloat(d.totalincl ? d.totalincl : 0.00) }).toFixed(2).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            },
            "discount": {
                "date": "",
                "type": "",
                "reference": "",
                "description": "Sub-Total",
                "credit": 0.00,
                "invoiceamount": "Less " + Number(state.discount_perc ? state.discount_perc : 0.00) + "%",
                "maintenancefund": 0.00,
                "rebill": 0.00,
                "shortpaid": 0.00,
                "payable": state.discount ? parseFloat(state.discount.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0.00
            },
            "total": {
                "date": "",
                "type": "",
                "reference": "",
                "description": "Sub-Total",
                "credit": 0.00,
                "invoiceamount": 0.00,
                "maintenancefund": 0.00,
                "rebill": 0.00,
                "shortpaid": 0.00,
                "payable": state.selected_invoices ? parseFloat((state.selected_invoices - state.discount).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0.00
            },
            "penalties": {
                "date": "",
                "type": "",
                "reference": "",
                "description": "Sub-Total",
                "credit": 0.00,
                "invoiceamount": "Less Penalties",
                "maintenancefund": 0.00,
                "rebill": 0.00,
                "shortpaid": 0.00,
                "payable": state.short_pay ? parseFloat(state.short_pay.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0.00
            },
            /* "disputes": {
                "date": "",
                "type": "",
                "reference": "",
                "description": "Sub-Total",
                "credit": 0,
                "invoiceamount": 0,
                "maintenancefund": 0,
                "rebill": 0,
                "shortpaid": 0,
                "payable": 11111
            }, */
            "payable": {
                "date": "",
                "type": "",
                "reference": "",
                "description": "Sub-Total",
                "credit": 0.00,
                "invoiceamount": "Total Payable",
                "maintenancefund": 0.00,
                "rebill": 0.00,
                "shortpaid": 0.00,
                "payable": state.total_payable ? parseFloat(state.total_payable.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0.00
            }
        }
        dispatch(setValue('remittance_data', report_data))
    }
}

export const clearReport = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.CLEAR_REPORT,
        })

    }
}
export const refreshReports = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.CLEAR_REPORT,
        })

        dispatch(setReconData(getState().supplierpaymentmanagement.payment_list[0]))
        dispatch(setRequisitionData(getState().supplierpaymentmanagement.payment_list[0]))
        dispatch(setRemittanceData(getState().supplierpaymentmanagement.payment_list[0]))
        dispatch(setValue('previewDocuments', true))
    }
}

export const EditLineItem = (type, item) => {
    return (dispatch, getState) => {
        let obj = []
        let prop = type
        switch (type) {
            case 'payments':
                {
                    obj = getState().supplierpaymentmanagement[type].filter(x => x.id != item.id)
                    obj.push(item)
                } break;
            default:
                {
                    obj = getState().supplierpaymentmanagement[type].filter(x => x.id != item.id)
                    obj.push(item)
                }
        }


        dispatch({
            type: types.ADD_BLANK,
            payload: obj,
            prop: prop
        })
    }
}