export const selectedDefleetType =  {
    value: null,
    text: null,
    key: null,
    types: []
}

export const selectedDefleetReason =  {
    value: null,
    text: null,
    key: null,
    types: []
}

export const defleetTypes = []

export const defleetReason = [
    {
        value: 1,
        text: 'Poor Service',
        key: 'poor_service',
        types: []
    },
    {
        value: 2,
        text: 'Settle Debt to Purchase Funds Available',
        key: 'settle_debt',
        types: []
    },
    {
        value: 3,
        text: 'Client Wishes to Purchase New Vehicle Replacement with Bidvest Bank',
        key: 'new_vehicle',
        types: []
    },
    {
        value: 4,
        text: 'Other',
        key: 'other',
    }
]


export const selectedVehicleDetails = {
    errorText: 'Please select vehicle from the list',
    data: [],
    valid: false,
    empty: true,
}


export const selectedVehicleContractAccessories = {
    errorText: 'Please select vehicle from the list',
    data: [],
    valid: false,
    empty: true,
}

export const selectedSupplierDetails = {
    errorText: 'Please select supplier from the list',
    data: [],
    valid: false,
    empty: true,
}

export const selectedSupplierSuspended = {
    errorText: 'Selected supplier is suspended please select another supplier',
    data: [],
    valid: false,
    empty: true,
}
export const overthelimit = {
    errorText: 'Selected supplier is over the limit, please another supplier',
    data: [],
    valid: true,
    empty: true,
}

export const vehicles = []
export const supplierage = []
export const bookinginfo = []
export const selectedVehicle =  {
    errorText: 'Please select vehicle from the list',
    value: null,
    valid: false,
    empty: true,
}
export const defleetNum = null
export const market_value = 0.00
export const salvage_value = 0.00
export const settle_value = 0.00
export const nbv_value = 0.00
export const salvage_percentage = 0
export const sub_total = 0.00
export const accessories_total = 0.00
export const amount = 0.00
export const latest_odo = 0
export const taskNum = null
export const geoSuppliers = null
export const terminationdate = null
export const funder = ""
export const comments = ""
export const maintenance_type = ""
export const maintenance_description = ""
export const net_book_value = ""
export const retail_value = ""
export const trade_value = ""
export const vehicleserviceinfo = []
export const recent_auth = []

export const supplierAutoCompleteAmount = 1

export const supplierAutoCompletes = [
    {
        id: 0,
        data: [],
        selected: []
    }
]


export const accessories = [
    {
        id: 0,
        accessoryname: '',
        amount: 0.00
    }
]
export const defleetrequestdate = null
export const defleetrequesttime = null

export const fields = {   
    defleetRequestDate: {
        errorText: 'De-fleet Request Date is Required!',
        state: 'getState()?.workflowDefleetView?.defleetrequestdate',
        data: null,
        valid: false,
        empty: true
    },
    selectedVehicle: {
        errorText: 'Please select vehicle from the list',
        state: 'getState()?.workflowDefleetView?.selectedVehicle?.text',
        data: null,
        valid: false,
        empty: true
    },
    selectedDefleetType: {
        errorText: 'Please select defleet type from the list',
       state: 'getState()?.workflowDefleetView?.selectedDefleetType?.text',
        data: null,
        valid: false,
        empty: true
    },
    selectedDefleetReason: {
        errorText: 'Please select defleet reason from the list',
        state: 'getState()?.workflowDefleetView?.selectedDefleetReason?.text',
        data: null,
        valid: false,
        empty: true
    },
    comments: {
        errorText: 'Please provide comment on more details required for de-fleet reason',
        state: 'getState()?.workflowDefleetView?.comments',
        data: '',
        valid: false,
        empty: true,
    },
    vehicle_return_form: {
        errorText: 'Please confirm vehicle return form',
        data: '',
        valid: false,
        empty: true,
        state: "getState()?.workflowDefleetView?.selectedDefleetType?.text !== 'EOC Return'",
        states: "getState()?.workflowDefleetView?.vehicle_return_form"
    },
    make_good_cost_invoice: {
        errorText: 'Please confirm make good cost invoice',
        data: '',
        valid: false,
        empty: true,
        state: "getState()?.workflowDefleetView?.selectedDefleetType?.text !== 'EOC Return'",
        states: "getState()?.workflowDefleetView?.make_good_cost_invoice"
    },
    make_good_cost_invoice_document: {
        errorText: 'Please upload documents',
        data: '',
        valid: false,
        empty: true,
        state: "getState()?.workflowDefleetView?.selectedDefleetType?.text !== 'EOC Return'",
        states: "getState()?.upload?.documents?.length"
    },
    breach_of_contract: {
        errorText: 'Please upload documents',
        data: '',
        valid: false,
        empty: true,
        state: "getState()?.workflowDefleetView?.selectedDefleetType?.text !== 'Breach of Contract'",
        states: "getState()?.upload.documents?.length"
    },
    sight_of_payment: {
        errorText: 'Please confirm sight of payment',
        data: '',
        valid: false,
        empty: true,
        //state: "!(getState().workflowDefleetView.selectedDefleetType.text === 'EOC termination' || getState().workflowDefleetView.selectedDefleetType.text === 'EOC purchase' || getState().workflowDefleetView.selectedDefleetType.text === 'Early purchase' || getState().workflowDefleetView.selectedDefleetType.text === 'Early Return')",
        state: "!(getState()?.workflowDefleetView?.selectedDefleetType?.text === 'EOC purchase' || getState()?.workflowDefleetView?.selectedDefleetType?.text === 'Early purchase' || getState()?.workflowDefleetView?.selectedDefleetType?.text === 'Early Return')",
        states: "getState()?.workflowDefleetView?.sight_of_payment"
    },
    sight_of_payment_document: {
        errorText: 'Please upload document',
        data: '',
        valid: false,
        empty: true,
        state: "!(getState()?.workflowDefleetView?.selectedDefleetType?.text === 'EOC termination' || getState()?.workflowDefleetView?.selectedDefleetType?.text === 'EOC purchase' || getState()?.workflowDefleetView?.selectedDefleetType?.text === 'Early purchase' || getState()?.workflowDefleetView?.selectedDefleetType?.text === 'Early Return')",
        states: "getState()?.upload.documents?.length"
    },
    net_book_value: {
        errorText: 'Please enter net book value',
        data: '',
        valid: false,
        empty: true,
        state: "!(getState()?.workflowDefleetView?.selectedDefleetType?.text === 'Early settlement' || getState()?.workflowDefleetView?.selectedDefleetType?.text === 'Early Purchase')",
        states: "getState()?.workflowDefleetView?.net_book_value",
        validations : [{
            'schema': 'decimal',
            'required': true,
            'maxLength': null,
            'errorText': 'Please input a valid net book value'
        }]
    },
    retail_value: {
        errorText: 'Please enter retail value',
        data: '',
        valid: false,
        empty: true,
        state: "!(getState()?.workflowDefleetView?.selectedDefleetType?.text === 'Early settlement' || getState()?.workflowDefleetView?.selectedDefleetType?.text === 'Early Purchase')",
        states: "getState()?.workflowDefleetView?.retail_value",
        validations : [{
            'schema': 'decimal',
            'required': true,
            'maxLength': null,
            'errorText': 'Please input a valid retail value'
        }]
    },
    trade_value: {
        errorText: 'Please enter trade value',
        data: '',
        valid: false,
        empty: true,
        state: "!(getState()?.workflowDefleetView?.selectedDefleetType?.text === 'Early settlement' || getState()?.workflowDefleetView?.selectedDefleetType?.text === 'Early Purchase')",
        states: "getState()?.workflowDefleetView?.trade_value",
        validations : [{
            'schema': 'decimal',
            'required': true,
            'maxLength': null,
            'errorText': 'Please input a valid trade value'
        }]
    }
}

