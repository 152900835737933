import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.CREATE_CALCULATOR_STATE:
            return {
                ...state,
                calculators: state.calculators.concat({
                    ...state.calculator,
                    id: action.payload
                })
            }
        case types.REMOVE_CALCULATOR_STATE:
            return {
                ...state,
                calculators: state.calculators.filter(x => x.id !== action.payload)
            }
        case types.GET_VEHICLES:
            return {
                ...state,
                calculators: state.calculators.map(x => {
                    if (x.id === action.payload.id) {
                        x.search = action.payload.search
                    }

                    return x
                })
            }
        case types.SET_VEHICLES:
            return {
                ...state,
                calculators: state.calculators.map(x => {
                    if (x.id === action.payload.id) {
                        x.vehicles = action.payload.data
                    }

                    return x
                })
            }
        case types.RESET_FINANCIAL_CALCULATOR:
            return {
                ...state,
                calculators: state.calculators.map(x => {
                    if (x.id === action.payload) {
                        x.mode = 2
                        x.solve = 1
                        x.presentValue = '0.00'
                        x.futureValue = '0.00'
                        x.periodInMonths = '0.00'
                        x.interestRatePerAnnum = '0.00'
                        x.residualValue = '30.00'
                        x.monthlyPayment = '0.00'
                        x.totalPayments = '0.00'
                        x.totalInterest = '0.00'
                        x.monthlyPaymentDisabled = true
                        x.presentValueDisabled = false
                        x.futureValueDisabled = false
                        x.interestRatePerAnnumDisabled = false
                        x.residualValueDisabled = false
                        x.totalPaymentsDisabled = true
                        x.totalInterestDisabled = true
                    }

                    return x
                })
            }
        case types.CALCULATE:
            return {
                ...state,
                calculators: state.calculators.map(x => {
                    if (x.id === action.payload.id) {
                        x.futureValue = action.payload.futureValue
                        x.presentValue = action.payload.presentValue
                        x.monthlyPayment = action.payload.monthlyPayment
                        x.totalPayments = action.payload.totalPayments
                        x.totalInterest = action.payload.totalInterest
                    }

                    return x
                })
            }
        case types.CHANGE_SELECT:
            return {
                ...state,
                calculators: state.calculators.map(x => {
                    if (x.id === action.payload.id) {
                        x[action.payload.field] = action.payload.value
                        x.monthlyPaymentDisabled = action.payload.disabled.monthlyPayment
                        x.presentValueDisabled = action.payload.disabled.presentValue
                        x.futureValueDisabled = action.payload.disabled.futureValue
                        x.residualValueDisabled = action.payload.disabled.residualValue
                        x.interestRatePerAnnumDisabled = action.payload.disabled.interestRatePerAnnum
                        x.totalPaymentsDisabled = true
                        x.totalInterestDisabled = true
                    }

                    return x
                })
            }
        case types.SET_VALID:
            return {
                ...state,
                calculators: state.calculators.map(x => {
                    if (x.id === action.payload.id) {
                        x[action.payload.field] = action.payload.value
                    }

                    return x
                })
            }
        case types.SET_ERROR:
            return {
                ...state,
                calculators: state.calculators.map(x => {
                    if (x.id === action.payload.id) {
                        x[action.payload.field + 'Error'] = action.payload.value
                    }

                    return x
                })
            }
        case types.SET_FIELD_VALUE:
            return {
                ...state,
                calculators: state.calculators.map(x => {
                    if (x.id === action.payload.id) {
                        x[action.payload.field] = action.payload.value
                        x[action.payload.field + 'Error'] = ''
                    }

                    return x
                })
            }
        default:
            return state
    }
}