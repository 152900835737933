import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.SET_FORM_VALIDATION_RESULT:
            return {
                ...state,
                valid: action.payload
            }
        default:
            return state
    }
}