import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'
import _ from 'lodash'

import IconButton from '@mui/material/IconButton'
import ButtonGroup from '@mui/material/ButtonGroup'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import ReactTable from "react-table"
import "react-table/react-table.css"

import Icon from '../Icon/component'
import Loading from '../Loading/component'

import * as actions from './actions'
import * as styles from './styles'

class CardTransaction extends Component {

    getCommands(row) {
        let field = this.props.state.transaction_header.filter(x => x.key === true)[0]
        const id = row.original[field.name]
        let i = row.index
        let commands = []

        commands.push(
            <IconButton key={'command_view_' + field.name + '_' + id} style={styles.iconButton} iconStyle={styles.actionIcon}
                tooltip={'Vehicle details'}>
                <Icon tooltip={'Manage card details'} istyle={styles.gridOptionsIcon} ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'edit'} />
            </IconButton>
        )
        
        commands.push(
            <IconButton key={'command_decision_' + field.name + '_' + id} style={styles.iconButton} iconStyle={styles.actionIcon}
            tooltip={'Manage card'}>
                <Icon istyle={styles.gridOptionsIcon} ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'notifications'} />
            </IconButton>
        )

        return commands
    }

    getValue(row) {       
        let field = this.props.state.transaction_header.filter(x => x.name === row.column.meta)[0]
            switch (field.type) {
                case 'month':
                    return moment(row.value).format('MM')
                    case 'date':
                        return moment(row.value).format('YYYY-MM-DD')
                case 'dateTime':
                    return moment(row.value).format('YYYY-MM-DD HH:mm ZZ')
                case 'time':
                    return moment(row.value).format('HH:mm ZZ')
                case 'timestamp':
                    return moment(row.value).format('YYYY-MM-DD HH:mm ZZ')
                case 'boolean':
                    const toggle = field.toggle && field.toggle !== null ? field.toggle.split(',') : row.value

                    return row.value !== null
                        ? row.value
                            ? toggle[0]
                            : toggle[1]
                        : 'Not Set'
                case 'decimal':
                    return !isNaN(row.value)
                        ? Number(row.value).toFixed(2).toString()
                        : row.value
                default:
                    return row.value
            }
    }

    getFormFieldLabel(row) {
          return <div
          style={{
            width: '100%',
            marginTop: '3px',
            verticalAlign: 'baseline',
            fontSize: '15px'
        }} >{this.getValue(row)}</div>
    }

    getColumns() {
        const fields = this.props.state.transaction_header.filter(x => x.key === false)
        let columns =
            fields.map(x => {
                return {
                    meta: x.name,
                    Header: x.title,
                    accessor: x.display,
                    filterable: true,
                    Filter: ({ filter, onChange }) => (
                        x.type !== 'boolean'
                            ? <input
                                onChange={event => onChange(event.target.value)}
                                value={filter ? filter.value : ''}
                                style={{
                                    width: '100%',
                                    height: 25,
                                    border: '1px solid #E6E6E6',
                                    borderRadius: 25
                                }}
                            />
                            : <select
                                onChange={event => onChange(event.target.value)}
                                style={{
                                    width: '100%',
                                    height: 25,
                                    border: '1px solid #E6E6E6',
                                    borderRadius: 25
                                }}
                                value={filter ? filter.value : ''}
                            >
                                <option value={''}>Show All</option>
                                {
                                    x.toggle.split(',').map((x, i) => {
                                        return <option value={i === 0 ? 'true' : 'false'}>{x}</option>
                                    })
                                }
                            </select>
                    ),
                    //width: x.width ? x.width : undefined,
                    headerStyle: {
                        fontSize: '13px',
                        fontWeight: 'bold',
                    },
                    style: {
                        verticalAlign: 'middle',
                        fontSize: 12,
                        letterSpacing: 0,
                        color: 'black',
                        //fontWeight: x.bold ? 'bold' : 'normal',
                        textAlign: x.right
                            ? 'right'
                            : 'left'
                    },
                    Cell: (row) => (
                          this.getFormFieldLabel(row)
                    )
                }
            })

            // columns.unshift({
            //     //Header: '',
            //     accessor: 'options',
            //     filterable: false,
            //     maxWidth: 290,
            //     width: 290,
            //     Cell: (row) => (
            //         this.getCommands(row)
            //     )
            // })
        return columns
    }

    getGrid() {
        return <div>
            <Typography variant="h4" component="h4">
Card Transactions
</Typography>
<br/>
        <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
  <Button>All</Button>
  <Button>Fuel</Button>
  <Button>Oil</Button>
  <Button>Toll</Button>
  <Button>Maintenance</Button>
  <Button>License Cost</Button>
  <Button>Other</Button>
</ButtonGroup>
            <ReactTable
                data={this.props.state.card.cardtransaction}
                columns={this.getColumns()}
                manual
                loading={this.loading}
                minRows={1}
                showPageJump={true}
                showPaginationTop={false}
                style={{
                    height: '100%'
                }}
            >
                {(state, render, instance) => {
                    return (
                        render()
                    )
                }}
            </ReactTable>
        </div>
    }

    render() {

        return this.props.state.cards.length
                ? this.getGrid()
                : <Loading message={'Grid Loading component...'} />
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.cardManagement,
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CardTransaction)