import * as props from './props'

import * as types from './types'
import * as controlTypes from '../../../controls/types'

export default (state = props, action) => {
    switch (action.type) {
        case types.EMPTY_TYPE:
            return {
                ...state
            }
        case controlTypes.SET_CONTROL_VIEW_INPUT_DATA:
            return {
                ...state,
                [action.payload.prop]: action.payload.data

            }
        case types.SET_INTEREST_RATE_COMPONENT_INPUT_FIELD_VALUE:
            return {
                ...state,
                [action.payload.prop]: action.payload.value

            }
        case types.RESET_COMPONENT_INTERESTCHANGE_FORM:
                return {
                    ...state,
                    interest_rate:'',
                    effective_date:''
                }
        default:
            return state
    }
}