import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { Card, CardHeader, CardContent } from '@mui/material'
import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import { Col, Container, Row } from 'react-grid-system'
import Iframe from 'react-iframe'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Icon from '../Icon/component'
import WorkflowPOSupplier from '../WorkflowPOSupplier/component'
import * as workflowTaskInputActions from '../WorkflowTaskInput/actions'
import WorkflowViewFMLQuoteSummary from '../WorkflowViewFMLQuoteSummary/component'
import * as actions from './actions'
import POHeader from './POHeader/component'
import * as styles from './styles'






class WorkflowPOHeader extends Component {
    // componentDidMount() {
    //     this.props.actions.removeCachedGridRow(this.props.state.component)
    //     this.props.actions.loadContractTypes(this.props.state.component, this.props.step)
    //     this.props.actions.setStartdate()
    //     // this.props.mode === 'edit'
    //     //     ? this.props.actions.setupWorkflowTaskInput(
    //     //         this.props.state.workflow.name,
    //     //         this.props.state.workflow_task.name
    //     //     )
    //     //     : ''

    //     //if (!this.props.state.component.grid.data.length)
    //     //   this.props.actions.laodComponentStepData(this.props.step, this.props.state.component)

    //     // const mode = this.props.state.contracttype.data.contracttype_id.input
    //     // if (mode === '21') {
    //     //this.props.actions.strBlankRow(this.props.state.component)
    //     // }
    // }

    getCorporateVehicle(component, ordertype) {
        return <div>
            <Card style={styles.card}>
                <CardHeader
                    actAsExpander={false}
                    showExpandableButton={false}
                    title={window.t.en(ordertype)}
                    subtitle={window.t.en('Complete RFQ details for ' + ordertype.toLowerCase())}
                >
                </CardHeader>
                <CardContent>
                    <Container style={{ margin: 0 }} className="custom-container">
                        {
                            this.props.state.document.data.content.text
                                ? <Row>
                                    <Col xl={4}>{'Upload RFQ Document'}:</Col>
                                    <Col xl={7}>
                                        {
                                            this.props.state.document.data.content.text
                                                ? <Iframe url={this.props.state.document.data.content.text}
                                                    width="100%"
                                                    height="100%"
                                                    id="myId"
                                                    className="myClassname"
                                                    display="initial"
                                                    position="relative"
                                                    allowFullScreen />
                                                : 'No File..'
                                        }
                                    </Col>
                                    <Col xl={1}>
                                       < Button className={"global_button"} variant="contained"
                                            primary={true}
                                            label="Clear"
                                            onClick={(event) => {
                                                this.props.actions.clearRFQDocument(component)
                                            }}
                                            >{window.t.en('Clear')}</Button></Col>
                                </Row>
                                : <Row>
                                    <Col xl={4}>{'Select RFQ Document'}:</Col>
                                    <Col xl={8}>
                                        <Dropzone
                                            ////accept={'/*'}
                                            style={styles.dropZone}
                                            onDrop={(files) => {
                                                //component.actions.toggleDropzoneVisibility()
                                            }}
                                            onDropAccepted={(files) => {
                                                this.props.actions.setAcceptedDocuments(this.props.state.document, files)
                                            }}
                                            onDropRejected={(files) => {
                                                this.props.actions.setRejectedDocuments(this.props.state.document, files)
                                            }}
                                        >
                                                                <p style={styles.watermark}>
                                                                    Drag and drop file here, or click to select file to upload.
                                                                </p>
                                        </Dropzone>
                                    </Col>
                                </Row>
                        }
                        <Row>
                            <Col xl={12}>
                                <br />
                            </Col>
                        </Row>
                    </Container>
                </CardContent>
            </Card>
        </div>
    }

    getVehicleService(component, ordertype) {
        return <div>
            <Card style={styles.card}>
                <CardHeader
                    actAsExpander={false}
                    showExpandableButton={false}
                    title={window.t.en(ordertype)}
                    subtitle={window.t.en('Complete RFQ details for ' + ordertype.toLowerCase())}
                >
                </CardHeader>
                <CardContent>
                    <Container style={{ margin: 0 }} className="custom-container">
                        {
                            this.props.state.document.data.content.text
                                ? <Row>
                                    <Col xl={4}>{'Upload RFQ Document'}:</Col>
                                    <Col xl={7}>
                                        {
                                            this.props.state.document.data.content.text
                                                ? <Iframe url={this.props.state.document.data.content.text}
                                                    width="100%"
                                                    height="100%"
                                                    id="myId"
                                                    className="myClassname"
                                                    display="initial"
                                                    position="relative"
                                                    allowFullScreen />
                                                : 'No File..'
                                        }
                                    </Col>
                                    <Col xl={1}>
                                       < Button className={"global_button"} variant="contained"
                                            primary={true}
                                            label="Clear"
                                            onClick={(event) => {
                                                this.props.actions.clearRFQDocument(component)
                                            }}
                                            >{window.t.en('Clear')}</Button></Col>
                                </Row>
                                : <Row>
                                    <Col xl={4}>{'Select RFQ Document'}:</Col>
                                    <Col xl={8}>
                                        <Dropzone
                                            ////accept={'/*'}
                                            style={styles.dropZone}
                                            onDrop={(files) => {
                                                //component.actions.toggleDropzoneVisibility()
                                            }}
                                            onDropAccepted={(files) => {
                                                this.props.actions.setAcceptedDocuments(this.props.state.document, files)
                                            }}
                                            onDropRejected={(files) => {
                                                this.props.actions.setRejectedDocuments(this.props.state.document, files)
                                            }}
                                        >
                                                                <p style={styles.watermark}>
                                                                    Drag and drop file here, or click to select file to upload.
                                                                </p>
                                        </Dropzone>
                                    </Col>
                                </Row>
                        }
                        <Row>
                            <Col xl={12}>
                                <br />
                            </Col>
                        </Row>
                    </Container>
                </CardContent>
            </Card>
        </div>
    }

    getTowing(component, ordertype) {
        return <div>
            <Card style={styles.card}>
                <CardHeader
                    actAsExpander={false}
                    showExpandableButton={false}
                    title={window.t.en(ordertype)}
                    subtitle={window.t.en('Complete RFQ details for ' + ordertype.toLowerCase())}
                >
                </CardHeader>
                <CardContent>
                    <Container style={{ margin: 0 }} className="custom-container">
                        {
                            this.props.state.document.data.content.text
                                ? <Row>
                                    <Col xl={4}>{'Upload RFQ Document'}:</Col>
                                    <Col xl={7}>
                                        {
                                            this.props.state.document.data.content.text
                                                ? <Iframe url={this.props.state.document.data.content.text}
                                                    width="100%"
                                                    height="100%"
                                                    id="myId"
                                                    className="myClassname"
                                                    display="initial"
                                                    position="relative"
                                                    allowFullScreen />
                                                : 'No File..'
                                        }
                                    </Col>
                                    <Col xl={1}>
                                       < Button className={"global_button"} variant="contained"
                                            primary={true}
                                            label="Clear"
                                            onClick={(event) => {
                                                this.props.actions.clearRFQDocument(component)
                                            }}
                                            >{window.t.en('Clear')}</Button></Col>
                                </Row>
                                : <Row>
                                    <Col xl={4}>{'Select RFQ Document'}:</Col>
                                    <Col xl={8}>
                                        <Dropzone
                                            ////accept={'/*'}
                                            style={styles.dropZone}
                                            onDrop={(files) => {
                                                //component.actions.toggleDropzoneVisibility()
                                            }}
                                            onDropAccepted={(files) => {
                                                this.props.actions.setAcceptedDocuments(this.props.state.document, files)
                                            }}
                                            onDropRejected={(files) => {
                                                this.props.actions.setRejectedDocuments(this.props.state.document, files)
                                            }}
                                        >
                                                                <p style={styles.watermark}>
                                                                    Drag and drop file here, or click to select file to upload.
                                                                </p>
                                        </Dropzone>
                                    </Col>
                                </Row>
                        }
                        <Row>
                            <Col xl={12}>
                                <br />
                            </Col>
                        </Row>
                    </Container>
                </CardContent>
            </Card>
        </div>
    }

    getFMLTransnet(component, ordertype) {
        return <div>
            <Card style={styles.card}>
                <CardHeader
                    actAsExpander={false}
                    showExpandableButton={false}
                    title={window.t.en(ordertype)}
                    subtitle={window.t.en('Complete RFQ details for ' + ordertype.toLowerCase())}
                >
                </CardHeader>
                <CardContent>
                    <Container style={{ margin: 0 }} className="custom-container">
                        {
                            this.props.state.document.data.content.text
                                ? <Row>
                                    <Col xl={4}>{'Upload RFQ Document'}:</Col>
                                    <Col xl={7}>
                                        {
                                            this.props.state.document.data.content.text
                                                ? <Iframe url={this.props.state.document.data.content.text}
                                                    width="100%"
                                                    height="100%"
                                                    id="myId"
                                                    className="myClassname"
                                                    display="initial"
                                                    position="relative"
                                                    allowFullScreen />
                                                : 'No File..'
                                        }
                                    </Col>
                                    <Col xl={1}>
                                       < Button className={"global_button"} variant="contained"
                                            primary={true}
                                            label="Clear"
                                            onClick={(event) => {
                                                this.props.actions.clearRFQDocument(component)
                                            }}
                                            >{window.t.en('Clear')}</Button></Col>
                                </Row>
                                : <Row>
                                    <Col xl={4}>{'Select RFQ Document'}:</Col>
                                    <Col xl={8}>
                                        <Dropzone
                                            ////accept={'/*'}
                                            style={styles.dropZone}
                                            onDrop={(files) => {
                                                //component.actions.toggleDropzoneVisibility()
                                            }}
                                            onDropAccepted={(files) => {
                                                this.props.actions.setAcceptedDocuments(this.props.state.document, files)
                                            }}
                                            onDropRejected={(files) => {
                                                this.props.actions.setRejectedDocuments(this.props.state.document, files)
                                            }}
                                        >
                                                                <p style={styles.watermark}>
                                                                    Drag and drop file here, or click to select file to upload.
                                                                </p>
                                        </Dropzone>
                                    </Col>
                                </Row>
                        }
                        <Row>
                            <Col xl={12}>
                                <br />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={4}>{this.field.quotenumber.title}</Col>
                            <Col>
                                <TextField
                                    id={this.field.quotenumber.name}
                                    value={component.data.quotenumber.input}
                                    fullWidth={true}
                                    onChange={(event, value) => {
                                        this.props.actions.getQuoteDetails(this.field.quotenumber, value)
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <br />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={4}>{'Comments to supplier'}</Col>
                            <Col>
                                <TextField
                                    id={this.field.comments.name}
                                    value={component.data.comments.input}
                                    fullWidth={true}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue(this.field.comments, value)
                                    }}
                                />
                            </Col>
                        </Row>
                    </Container>
                </CardContent>
            </Card>
            {
                this.props.state.quote.grid.data.length
                    ?
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={12}>
                                <br />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <WorkflowViewFMLQuoteSummary
                                    id={'strvehicle_0_' + this.field.surrogateheader_id}
                                    data={this.props.state.quote.grid.data}
                                    name={this.props.name} />

                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <br />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={4}>
                               < Button className={"global_button"} variant="contained"
                                    primary={true}
                                    label="Add Supplier"
                                    icon={<Icon iclass={'material-icons'} iname={'plus_one'} />}
                                    disabled={!component.grid.data.length > 1}
                                    onClick={(event) => {
                                        this.props.actions.addSupplierRow(component)
                                    }}
                                    ><Icon iclass={'material-icons'} iname={'plus_one'} /> Add Supplier</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <br />
                            </Col>
                        </Row>
                        <Row>
                            {
                                component.grid.data.map((x, i) => {
                                    return <Col xl={12 / component.grid.data.length}>
                                        <WorkflowPOSupplier
                                            id={'workflowPOSupplier_' + i}
                                            inx={i}
                                            name={this.props.name} />
                                    </Col>
                                })
                            }
                        </Row>
                    </Container>
                    : ''
            }
        </div>
    }

    getVehicleRepairs(component, ordertype) {
        return <div>
            <Card style={styles.card}>
                <CardHeader
                    actAsExpander={false}
                    showExpandableButton={false}
                    title={window.t.en(ordertype)}
                    subtitle={window.t.en('Complete RFQ details for ' + ordertype.toLowerCase())}
                >
                </CardHeader>
                <CardContent>
                    <Container style={{ margin: 0 }} className="custom-container">
                        {
                            this.props.state.document.data.content.text
                                ? <Row>
                                    <Col xl={4}>{'Upload RFQ Document'}:</Col>
                                    <Col xl={7}>
                                        {
                                            this.props.state.document.data.content.text
                                                ? <Iframe url={this.props.state.document.data.content.text}
                                                    width="100%"
                                                    height="100%"
                                                    id="myId"
                                                    className="myClassname"
                                                    display="initial"
                                                    position="relative"
                                                    allowFullScreen />
                                                : 'No File..'
                                        }
                                    </Col>
                                    <Col xl={1}>
                                       < Button className={"global_button"} variant="contained"
                                            primary={true}
                                            label="Clear"
                                            onClick={(event) => {
                                                this.props.actions.clearRFQDocument(component)
                                            }}
                                            >{window.t.en('Clear')}</Button></Col>
                                </Row>
                                : <Row>
                                    <Col xl={4}>{'Select RFQ Document'}:</Col>
                                    <Col xl={8}>
                                        <Dropzone
                                            ////accept={'/*'}
                                            style={styles.dropZone}
                                            onDrop={(files) => {
                                                //component.actions.toggleDropzoneVisibility()
                                            }}
                                            onDropAccepted={(files) => {
                                                this.props.actions.setAcceptedDocuments(this.props.state.document, files)
                                            }}
                                            onDropRejected={(files) => {
                                                this.props.actions.setRejectedDocuments(this.props.state.document, files)
                                            }}
                                        >
                                                                <p style={styles.watermark}>
                                                                    Drag and drop file here, or click to select file to upload.
                                                                </p>
                                        </Dropzone>
                                    </Col>
                                </Row>
                        }
                        <Row>
                            <Col xl={12}>
                                <br />
                            </Col>
                        </Row>
                    </Container>
                </CardContent>
            </Card>
        </div>
    }

    getVehiclePanelBeating(component, ordertype) {
        return <div>
            <Card style={styles.card}>
                <CardHeader
                    actAsExpander={false}
                    showExpandableButton={false}
                    title={window.t.en(ordertype)}
                    subtitle={window.t.en('Complete RFQ details for ' + ordertype.toLowerCase())}
                >
                </CardHeader>
                <CardContent>
                    <Container style={{ margin: 0 }} className="custom-container">
                        {
                            this.props.state.document.data.content.text
                                ? <Row>
                                    <Col xl={4}>{'Upload RFQ Document'}:</Col>
                                    <Col xl={7}>
                                        {
                                            this.props.state.document.data.content.text
                                                ? <Iframe url={this.props.state.document.data.content.text}
                                                    width="100%"
                                                    height="100%"
                                                    id="myId"
                                                    className="myClassname"
                                                    display="initial"
                                                    position="relative"
                                                    allowFullScreen />
                                                : 'No File..'
                                        }
                                    </Col>
                                    <Col xl={1}>
                                       < Button className={"global_button"} variant="contained"
                                            primary={true}
                                            label="Clear"
                                            onClick={(event) => {
                                                this.props.actions.clearRFQDocument(component)
                                            }}
                                            >{window.t.en('Clear')}</Button></Col>
                                </Row>
                                : <Row>
                                    <Col xl={4}>{'Select RFQ Document'}:</Col>
                                    <Col xl={8}>
                                        <Dropzone
                                            ////accept={'/*'}
                                            style={styles.dropZone}
                                            onDrop={(files) => {
                                                //component.actions.toggleDropzoneVisibility()
                                            }}
                                            onDropAccepted={(files) => {
                                                this.props.actions.setAcceptedDocuments(this.props.state.document, files)
                                            }}
                                            onDropRejected={(files) => {
                                                this.props.actions.setRejectedDocuments(this.props.state.document, files)
                                            }}
                                        >
                                                                <p style={styles.watermark}>
                                                                    Drag and drop file here, or click to select file to upload.
                                                                </p>
                                        </Dropzone>
                                    </Col>
                                </Row>
                        }
                        <Row>
                            <Col xl={12}>
                                <br />
                            </Col>
                        </Row>
                    </Container>
                </CardContent>
            </Card>
        </div>
    }

    getAccessor(component, ordertype) {
        return <div>
            <Card style={styles.card}>
                <CardHeader
                    actAsExpander={false}
                    showExpandableButton={false}
                    title={window.t.en(ordertype)}
                    subtitle={window.t.en('Complete RFQ details for ' + ordertype.toLowerCase())}
                >
                </CardHeader>
                <CardContent>
                    <Container style={{ margin: 0 }} className="custom-container">
                        {
                            this.props.state.document.data.content.text
                                ? <Row>
                                    <Col xl={4}>{'Upload RFQ Document'}:</Col>
                                    <Col xl={7}>
                                        {
                                            this.props.state.document.data.content.text
                                                ? <Iframe url={this.props.state.document.data.content.text}
                                                    width="100%"
                                                    height="100%"
                                                    id="myId"
                                                    className="myClassname"
                                                    display="initial"
                                                    position="relative"
                                                    allowFullScreen />
                                                : 'No File..'
                                        }
                                    </Col>
                                    <Col xl={1}>
                                       < Button className={"global_button"} variant="contained"
                                            primary={true}
                                            label="Clear"
                                            onClick={(event) => {
                                                this.props.actions.clearRFQDocument(component)
                                            }}
                                            >{window.t.en('Clear')}</Button></Col>
                                </Row>
                                : <Row>
                                    <Col xl={4}>{'Select RFQ Document'}:</Col>
                                    <Col xl={8}>
                                        <Dropzone
                                            ////accept={'/*'}
                                            style={styles.dropZone}
                                            onDrop={(files) => {
                                                //component.actions.toggleDropzoneVisibility()
                                            }}
                                            onDropAccepted={(files) => {
                                                this.props.actions.setAcceptedDocuments(this.props.state.document, files)
                                            }}
                                            onDropRejected={(files) => {
                                                this.props.actions.setRejectedDocuments(this.props.state.document, files)
                                            }}
                                        >
                                                                <p style={styles.watermark}>
                                                                    Drag and drop file here, or click to select file to upload.
                                                                </p>
                                        </Dropzone>
                                    </Col>
                                </Row>
                        }
                        <Row>
                            <Col xl={12}>
                                <br />
                            </Col>
                        </Row>
                    </Container>
                </CardContent>
            </Card>
        </div>
    }

    getOfficeAdmin(component, ordertype) {
        return <div>
            <Card style={styles.card}>
                <CardHeader
                    actAsExpander={false}
                    showExpandableButton={false}
                    title={window.t.en(ordertype)}
                    subtitle={window.t.en('Complete RFQ details for ' + ordertype.toLowerCase())}
                >
                </CardHeader>
                <CardContent>
                    <Container style={{ margin: 0 }} className="custom-container">
                        {
                            this.props.state.document.data.content.text
                                ? <Row>
                                    <Col xl={4}>{'Upload RFQ Document'}:</Col>
                                    <Col xl={7}>
                                        {
                                            this.props.state.document.data.content.text
                                                ? <Iframe url={this.props.state.document.data.content.text}
                                                    width="100%"
                                                    height="100%"
                                                    id="myId"
                                                    className="myClassname"
                                                    display="initial"
                                                    position="relative"
                                                    allowFullScreen />
                                                : 'No File..'
                                        }
                                    </Col>
                                    <Col xl={1}>
                                       < Button className={"global_button"} variant="contained"
                                            primary={true}
                                            label="Clear"
                                            onClick={(event) => {
                                                this.props.actions.clearRFQDocument(component)
                                            }}
                                            >{window.t.en('Clear')}</Button></Col>
                                </Row>
                                : <Row>
                                    <Col xl={4}>{'Select RFQ Document'}:</Col>
                                    <Col xl={8}>
                                        <Dropzone
                                            ////accept={'/*'}
                                            style={styles.dropZone}
                                            onDrop={(files) => {
                                                //component.actions.toggleDropzoneVisibility()
                                            }}
                                            onDropAccepted={(files) => {
                                                this.props.actions.setAcceptedDocuments(this.props.state.document, files)
                                            }}
                                            onDropRejected={(files) => {
                                                this.props.actions.setRejectedDocuments(this.props.state.document, files)
                                            }}
                                        >
                                                                <p style={styles.watermark}>
                                                                    Drag and drop file here, or click to select file to upload.
                                                                </p>
                                        </Dropzone>
                                    </Col>
                                </Row>
                        }
                        <Row>
                            <Col xl={12}>
                                <br />
                            </Col>
                        </Row>
                    </Container>
                </CardContent>
            </Card>
        </div>
    }

    getTyres(component, ordertype) {
        return <div>
            <Card style={styles.card}>
                <CardHeader
                    actAsExpander={false}
                    showExpandableButton={false}
                    title={window.t.en(ordertype)}
                    subtitle={window.t.en('Complete RFQ details for ' + ordertype.toLowerCase())}
                >
                </CardHeader>
                <CardContent>
                    <Container style={{ margin: 0 }} className="custom-container">
                        {
                            this.props.state.document.data.content.text
                                ? <Row>
                                    <Col xl={4}>{'Upload RFQ Document'}:</Col>
                                    <Col xl={7}>
                                        {
                                            this.props.state.document.data.content.text
                                                ? <Iframe url={this.props.state.document.data.content.text}
                                                    width="100%"
                                                    height="100%"
                                                    id="myId"
                                                    className="myClassname"
                                                    display="initial"
                                                    position="relative"
                                                    allowFullScreen />
                                                : 'No File..'
                                        }
                                    </Col>
                                    <Col xl={1}>
                                       < Button className={"global_button"} variant="contained"
                                            primary={true}
                                            label="Clear"
                                            onClick={(event) => {
                                                this.props.actions.clearRFQDocument(component)
                                            }}
                                            >{window.t.en('Clear')}</Button></Col>
                                </Row>
                                : <Row>
                                    <Col xl={4}>{'Select RFQ Document'}:</Col>
                                    <Col xl={8}>
                                        <Dropzone
                                            ////accept={'/*'}
                                            style={styles.dropZone}
                                            onDrop={(files) => {
                                                //component.actions.toggleDropzoneVisibility()
                                            }}
                                            onDropAccepted={(files) => {
                                                this.props.actions.setAcceptedDocuments(this.props.state.document, files)
                                            }}
                                            onDropRejected={(files) => {
                                                this.props.actions.setRejectedDocuments(this.props.state.document, files)
                                            }}
                                        >
                                                                <p style={styles.watermark}>
                                                                    Drag and drop file here, or click to select file to upload.
                                                                </p>
                                        </Dropzone>
                                    </Col>
                                </Row>
                        }
                        <Row>
                            <Col xl={12}>
                                <br />
                            </Col>
                        </Row>
                    </Container>
                </CardContent>
            </Card>
        </div>
    }

    getSubscriptions(component, ordertype) {
        return <div>
            <Card style={styles.card}>
                <CardHeader
                    actAsExpander={false}
                    showExpandableButton={false}
                    title={window.t.en(ordertype)}
                    subtitle={window.t.en('Complete RFQ details for ' + ordertype.toLowerCase())}
                >
                </CardHeader>
                <CardContent>
                    <Container style={{ margin: 0 }} className="custom-container">
                        {
                            this.props.state.document.data.content.text
                                ? <Row>
                                    <Col xl={4}>{'Upload RFQ Document'}:</Col>
                                    <Col xl={7}>
                                        {
                                            this.props.state.document.data.content.text
                                                ? <Iframe url={this.props.state.document.data.content.text}
                                                    width="100%"
                                                    height="100%"
                                                    id="myId"
                                                    className="myClassname"
                                                    display="initial"
                                                    position="relative"
                                                    allowFullScreen />
                                                : 'No File..'
                                        }
                                    </Col>
                                    <Col xl={1}>
                                       < Button className={"global_button"} variant="contained"
                                            primary={true}
                                            label="Clear"
                                            onClick={(event) => {
                                                this.props.actions.clearRFQDocument(component)
                                            }}
                                            >{window.t.en('Clear')}</Button></Col>
                                </Row>
                                : <Row>
                                    <Col xl={4}>{'Select RFQ Document'}:</Col>
                                    <Col xl={8}>
                                        <Dropzone
                                            ////accept={'/*'}
                                            style={styles.dropZone}
                                            onDrop={(files) => {
                                                //component.actions.toggleDropzoneVisibility()
                                            }}
                                            onDropAccepted={(files) => {
                                                this.props.actions.setAcceptedDocuments(this.props.state.document, files)
                                            }}
                                            onDropRejected={(files) => {
                                                this.props.actions.setRejectedDocuments(this.props.state.document, files)
                                            }}
                                        >
                                                                <p style={styles.watermark}>
                                                                    Drag and drop file here, or click to select file to upload.
                                                                </p>
                                        </Dropzone>
                                    </Col>
                                </Row>
                        }
                        <Row>
                            <Col xl={12}>
                                <br />
                            </Col>
                        </Row>
                    </Container>
                </CardContent>
            </Card>
        </div>
    }

    setWorkflowQuote(component) {
        const ordertype_component = this.props.state.ordertype
        const ordertype = 'FML' //ordertype_component.selected[0].original.ordertype

        this.field = component.component_field.reduce((obj, x) => {
            obj[x.name] = x
            return obj
        }, {})

        switch (ordertype) {
            case 'Corporate Vehicle':
                return this.getCorporateVehicle(component, ordertype)
                break;
            case 'Vehicle Service':
                return this.getVehicleService(component, ordertype)
                break;
            case 'Towing':
                return this.getTowing(component, ordertype)
                break;
            case 'FML':
                return this.getFMLTransnet(component, ordertype)
                break;
            case 'Vehicle Repairs':
                return this.getVehicleRepairs(component, ordertype)
                break;
            case 'Vehicle Panel Beating':
                return this.getVehiclePanelBeating(component, ordertype)
                break;
            case 'Accessor':
                return this.getAccessor(component, ordertype)
                break;
            case 'Office Admin':
                return this.getOfficeAdmin(component, ordertype)
                break;
            case 'Tyres':
                return this.getTyres(component, ordertype)
                break;
            case 'Subscriptions':
                return this.getSubscriptions(component, ordertype)
                break;
            default:
                break;
        }
    }

    render() {
        const component = this.props.state.component

        this.data = this.props.state.step.task.workflow_task_step
            ? this.props.state.step.task.workflow_task_step
                .filter(x =>
                    x.name === this.props.step &&
                    x.workflow_task_step_component.filter(y => y.component.name === this.props.name).length
                )
                .reduce((arr, x) => {
                    if (x.workflow_task_step_component.filter(y => y.component.name === this.props.name && y.data.length).length) {
                        arr.push(x.workflow_task_step_component.filter(y => y.component.name === this.props.name && y.data.length)[0].data)
                    }

                    return arr
                }, [])
            : []


        return (
            <div>
                <POHeader />
            </div>

            /*             this.data.length
                        ? (
                            <div>
                                <Container style={{ margin: 0 }} className="custom-container">
                                    <Row>
                                        <Col xl={12}>
                                            <WorkflowViewFMLQuoteConfirm
                                                id={'strvehicle_0_' + this.field.surrogateheader_id}
                                                data={{
                                                    quote: this.props.state.quote.grid.data,
                                                    suppliers: this.props.state.component.grid.data
                                                }}
                                                name={this.props.name} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12}>
                                            <br />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12}>
                                           < Button className={"global_button"} variant="contained"
                                                label={'Change'}
                                                primary={true}
                                                style={styles.right}
                                                disabled={!component.grid.data.length}
                                                onClick={(event) => {
                                                    this.props.actions.resetSelection(this.props.step, this.props.name)
                                                }}
                                           >{window.t.en('xxxxx')}</Button>
                                        </Col>
                                    </Row>
                                </Container>
            
                                <br />
                            </div>
                        )
                        : (<Container style={{ margin: 0 }} className="custom-container">
                            <Row>
                                <Col xl={12}>
                                    {this.setWorkflowQuote(component)}
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={12}>
                                    <br />
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={12} style={styles.confirmSelection}>
                                   < Button className={"global_button"} variant="contained"
                                        label={'Done'}
                                        primary={true}
                                        disabled={!component.grid.data.length}
                                        onClick={(event) => {
                                            this.props.actions.confirmSelection(this.props.step, component)
                                        }}
                                   >{window.t.en('xxxxx')}</Button>
                                </Col>
                            </Row>
                        </Container>
                        ) */
        )
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.workflowQueueTasks,
            step: {
                ...state.workflowTaskInput
            },
            component: {
                ...state.components[ownProps.name]
            },
            ordertype: {
                ...state.components['ordertype']
            },
            document: {
                ...state.components['document']
            },
            quote: {
                ...state.components['customerquote']
            },
            supplier: {
                ...state.components['supplier']
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...workflowTaskInputActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowPOHeader)