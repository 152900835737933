import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.SET_COMPONENT_STR_DEFAULT_ACCESSORY_LIST:
            return {
                ...state,
                grid: action.payload
            }
        case types.SET_COMPONENT_STR_DEFAULT_ACCESSORY_TOTAL:
            return {
                ...state,
                accessory_tot: action.payload
            }
        case types.SET_COMPONENT_STR_INPUT_FIELD_VALUE:
            return {
                ...state,
                grid: state.grid.map((x, i) =>
                    i === action.payload.inx
                        ? {
                            ...x,
                            [action.payload.field]: action.payload.value
                        }
                        : x
                )
            }
        default:
            return state
    }
}