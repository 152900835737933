import * as types from './types'

export const setupWorkflowTaskInput = (data, meta, component, step) => {
    return (dispatch, getState) => {
        
        const payload = {
            step: step,
            component: component,
            selected: data,
            meta: meta
        }

        dispatch({
            type: types.CONFIRM_VEHICLE_BOOKING_HEADER_SELECTION,
            payload: payload
        })
    }
}