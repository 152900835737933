import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import FolderIcon from '@mui/icons-material/Folder';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import { Collapse, Popover, MenuItem, Menu, ListItemIcon, ListItemText, AppBar, Toolbar, Typography, List, ListItem, ListSubheader } from '@mui/material'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField'
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Table from '@mui/material/Table'

import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import MoreIcon from '@mui/icons-material/MoreVert';
import HomeIcon from '@mui/icons-material/Home';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ExpandNotificationsMore from '@mui/icons-material/ExpandMore';
import ExpandNotificationsLess from '@mui/icons-material/ExpandLess';

import PieChartIcon from '@mui/icons-material/PieChart';
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';
import ViewModuleIcon from '@mui/icons-material/ViewModule';

import { Container, Row, Col } from 'react-grid-system'
import AutoComplete from '../../controls/autocomplete'
import Card from '../../controls/card'

import logo from '../../assets/img/visa_card.jpeg'
import Loading from '../Loading/component'
import Icon from '../Icon/component'


import Tile from './tile'
import Grid from './grid'
import Create from './create'
import Transactions from './transaction'

import * as actions from './actions'
import * as styles from './styles'

class Cards extends Component {

    componentDidMount() {
        this.props.actions.setCardData({ prop: 'cardsettingswin', data: false })
        this.props.actions.loadCardsData()
    }

    getNotificationPopover() {
        return <div className="d-flex align-items-center popover-header-wrapper">
            <span className="pr-2">

                <Popover
                    open={this.props.state.cardnotificationwin}
                    anchorEl={this.props.state.cardnotificationwinel}
                    onClose={(event, value) => {
                        event.preventDefault();
                        this.props.actions.toggleCardNotificationWin(false, null)
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                    classes={{
                        paper: 'app-header-dots'
                    }}>
                    <div className="popover-custom-lg overflow-hidden">
                        <div className="bg-composed-wrapper bg-midnight-bloom border-0 text-center rounded-sm">
                            <div className="bg-composed-img-3 bg-composed-wrapper--image-off" />
                            <div className="bg-composed-wrapper--content ">
                                <h4 className="font-size-xl font-weight-bold mb-2 ">
                                    Notifications
                                </h4>
                                {/* <p className="opacity-8 mb-0 font-size-l font-weight-bold">
                                You have <b className="text-success">{this.props.state.notifications_items ? this.props.state.notifications_items.length : 0}</b> new notifications
                         </p> */}
                            </div>
                        </div>
                        <div className="height-280">
                            <List >
                                {
                                    this.props.state.cardnotifications
                                        ? this.props.state.cardnotifications.map(x => {
                                            return <List >
                                                <ListItem button
                                                    key={'user_workflow_notification_' + x.title}
                                                    style={styles.liPrimary}
                                                    onClick={() => {
                                                        this.props.actions.toggleNotificationsList(x.title);
                                                    }}>
                                                    <ListItemText
                                                        primary={<div>{x.title}</div>}
                                                    />
                                                    {this.props.state.cardnotificationtitle === x.title ? <ExpandNotificationsLess /> : <ExpandNotificationsMore />}
                                                </ListItem>
                                                <Collapse in={this.props.state.cardnotificationtitle === x.title} timeout="auto" unmountOnExit>
                                                    <List component="div" disablePadding >
                                                        {
                                                            x.items.map((itm) => {
                                                                return <ListItem button style={styles.cardDropdown}>
                                                                    <ListItemText

                                                                        key={'card_notification_item_' + itm.title}
                                                                        onClick={(event) => {
                                                                            event.preventDefault()
                                                                        }}
                                                                        primary={<span style={{ color: 'black' }}>{itm.title}</span>}
                                                                        secondary={<><span style={{ color: 'gray' }}>{itm.description}</span>
                                                                            <Badge badgeContent={itm.count} color="primary" ></Badge></>}
                                                                    />
                                                                </ListItem>
                                                            })
                                                        }
                                                    </List>
                                                </Collapse>
                                            </List>
                                        })
                                        : ''
                                }
                            </List>
                        </div>
                    </div>
                </Popover>
            </span>
        </div>

    }

    getView() {
        return this.props.state.tile_view
            ? <Tile />
            : <Grid />
    }
    getToolbar() {
        return <div> <AppBar position="static"
            style={{ backgroundColor: 'red' }}
        ><Toolbar style={{ backgroundColor: '#D1E0E6' }}>

                <IconButton edge="start" color="primary" aria-label="menu" onClick={(event) => {
                    event.preventDefault()
                    this.props.actions.setCardData({ prop: 'card', data: null })
                }}>
                    <HomeIcon color="primary" />
                </IconButton>

                {/* <Typography  variant="h6"  text=" Card Status"></Typography> */}
                {/* <Select value={this.props.state.card.cardstatus} onChange={(event) => {
                                    event.preventDefault()
                                    ////console.log(event.target.innerText)
                                    this.props.actions.updateCardStatus(this.props.state.card, event.target.innerText, true)
                                }}>
                <MenuItem value={'Active'} primaryText="Active" />
                <MenuItem value={'Lost'} primaryText="Lost" />
                <MenuItem value={'Cancel'} primaryText="Cancel" />
                <MenuItem value={'Damaged'} primaryText="Damaged" />
              </Select> */}
                <Typography text="" style={{ color: 'white' }}>Card Management</Typography>
                {/* <FontIcon className="muidocs-icon-custom-sort" /> */}
                {/* <Button label="Back Home" primary={true} onClick={(event) => {
                                    event.preventDefault()
                                    this.props.actions.setCardData({prop: 'card', data: null})
                                }}  >{"Back Home"}</Button>
              <Button label="Update Changes" primary={true} >{"Update Changes"}</Button> */}
                {/* <IconMenu
                iconButtonElement={
                  <IconButton touch={true}>
                    <NavigationExpandMoreIcon />
                  </IconButton>
                }
              >
                <MenuItem primaryText="Download Transactions Report" />
                <MenuItem primaryText="Download Exception Report" />
              </IconMenu> */}

                <div style={{ marginLeft: 'auto' }}>

                    <IconButton aria-label="card status" color="primary" onClick={(event) => {
                        event.preventDefault()
                        this.props.actions.toggleCardStausWin(true, event.currentTarget)
                    }}>
                        <CreditCardIcon color="primary" />
                    </IconButton>
                    <IconButton aria-label="card notifications" color="primary" onClick={(event) => {
                        event.preventDefault()
                        this.props.actions.toggleCardNotificationWin(true, event.currentTarget)
                    }}>
                        <Badge badgeContent={1} color="primary">
                            <NotificationsIcon color="primary" />
                        </Badge>
                    </IconButton>
                    <IconButton
                        edge="end"
                        aria-label="account of current user"
                        aria-controls={'primary-search-account-menu'}
                        aria-haspopup="true"
                        //onClick={handleProfileMenuOpen}
                        color="primary" onClick={(event) => {
                            event.preventDefault()
                            this.props.actions.toggleTransactionView(this.props.state.cardtransactionview)
                        }}>
                        <PieChartIcon color="primary" />
                    </IconButton>
                    <IconButton
                        aria-label="show more"
                        //aria-controls={mobileMenuId}
                        aria-haspopup="true"
                        //onClick={handleMobileMenuOpen}
                        color="primary" onClick={(event) => {
                            event.preventDefault()
                            this.props.actions.toggleCardMoreWin(true, event.currentTarget)
                        }}>
                        <MoreIcon color="primary" />
                    </IconButton>

                    <Menu
                        anchorEl={this.props.state.cardmorewinel}
                        //anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        id={'primary-search-account-menu'}
                        keepMounted
                        //transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                        open={this.props.state.cardmorewin}
                        onClose={(event) => {
                            event.preventDefault()
                            this.props.actions.toggleCardMoreWin(false, null)
                        }}
                    >
                        <MenuItem onClick={(event) => {
                            event.preventDefault()
                            this.props.actions.getCardReportExcel('Card Transactions')
                        }}>Download Card Transactions</MenuItem>
                        <MenuItem onClick={(event) => {
                            event.preventDefault()
                            this.props.actions.getCardReportExcel('Card Exception Report')
                        }}>Download Card Exception Report</MenuItem>
                        <MenuItem onClick={(event) => {
                            event.preventDefault()
                            this.props.actions.getCardReportExcel('Card Statements')
                        }}>Download Statements</MenuItem>
                    </Menu>


                    <Menu
                        anchorEl={this.props.state.cardstatuswinel}
                        //anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        id={'primary-search-account-menu'}
                        keepMounted
                        //transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                        open={this.props.state.cardstatuswin}
                        onClose={(event) => {
                            event.preventDefault()
                            this.props.actions.toggleCardStausWin(false, null)
                        }}
                    >
                        <MenuItem onClick={(event) => {
                            event.preventDefault()
                            this.props.actions.updateCardStatus('Active', true)
                        }}>Activate Card</MenuItem>
                        <MenuItem onClick={(event) => {
                            event.preventDefault()
                            this.props.actions.updateCardStatus('Cancel', true)
                        }}>Cancel Card</MenuItem>
                        <MenuItem onClick={(event) => {
                            event.preventDefault()
                            this.props.actions.updateCardStatus('Damaged', true)
                        }}>Card Damaged</MenuItem>
                        <MenuItem onClick={(event) => {
                            event.preventDefault()
                            this.props.actions.updateCardStatus('Lost', true)
                        }}>Card Lost</MenuItem>
                    </Menu>
                    {this.getNotificationPopover()}
                </div>
            </Toolbar>
        </AppBar>

        </div>
    }

    getParamtersForm() {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={6}>
                    <div style={styles.fieldContainer}>
                        <div style={styles.fieldTitle}>{'Fuel Volume Limit:'}</div>
                        <div style={styles.fieldContent}>
                            <TextField
                                id={'txtlimit'}
                                hintText={'Enter Fuel Volume Limit'}
                                fullWidth={true}
                            />
                        </div>
                    </div>
                </Col>
                <Col xl={6}>
                    <div style={styles.fieldContainer}>
                        <div style={styles.fieldTitle}>{'Facility LImit:'}</div>
                        <div style={styles.fieldContent}>
                            <TextField
                                id={'txttradevalue'}
                                hintText={'Enter Facility LImit'}
                                fullWidth={true}
                            />
                        </div>
                    </div>
                </Col>
                <Col xl={6}>
                    <div style={styles.fieldContainer}>
                        <div style={styles.fieldTitle}>{'Card Limit:'}</div>
                        <div style={styles.fieldContent}>
                            <TextField
                                id={'txtmarketvalue'}
                                hintText={'Enter Card Limit'}
                                fullWidth={true}
                            />
                        </div>
                    </div>
                </Col>
                <Col xl={6}>
                    <div style={styles.fieldContainer}>
                        <div style={styles.fieldTitle}>{'Daily Limit:'}</div>
                        <div style={styles.fieldContent}>
                            <TextField
                                id={'txtmarketvalue'}
                                hintText={'Enter Daily Limit'}
                                fullWidth={true}
                            />
                        </div>
                    </div>
                </Col>
            </Row>               <Row>
                <Col xl={12}>
                    <div style={styles.fieldContainer}>
                        <div style={styles.fieldTitle}>{''}</div>
                        <div style={styles.fieldContent}>
                            <Button
                                label="Update Details"
                                primary={true}
                                onClick={(event) => {
                                    event.preventDefault()
                                    this.props.actions.updateCardLimits(this.props.state.card)
                                }}
                            >{"Update Details"}</Button>

                        </div>
                    </div>
                    <br /> <br />
                </Col>
            </Row>
        </Container>
    }


    getCardStatusForm() {
        return <Card 
        title={'Card ' + this.props.state.update_card_status}
        subtitle={'You are about to change card status.'}
            content={<Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={12}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{'Reason:'}</div>
                                <div style={styles.fieldContent}>
                                    <TextField
                                        id={'txtReason'}
                                        hintText={'Enter Reason'}
                                        fullWidth={true}
                                        value={this.props.state.status_reason}
                                        onChange={
                                            (event, value) => {
                                                this.props.actions.setCardData({ prop: 'status_reason', data: event.target.value })
                                            }
                                        }
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col xl={12}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{'Card ' + this.props.state.update_card_status + ' Date:'}</div>
                                <div style={styles.fieldContent}>
                                    <TextField
                                        id={'txttradevalue'}
                                        hintText={'Enter Facility LImit'}
                                        fullWidth={true}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col xl={12}>
                            <br></br>
                        </Col>
                    </Row>               <Row>
                        <Col xl={6}></Col>
                        <Col xl={6}>
                            <Button
                                label="Update Card Status"
                                primary={true}
                                onClick={(event) => {
                                    event.preventDefault()
                                    this.props.actions.changeCardStatus(this.props.state.card, this.props.state.update_card_status, this.props.state.status_reason)
                                    this.props.actions.updateCardStatus(this.props.state.card, '', false)
                                    this.props.actions.refreshCardData(this.props.state.card.card_id)
                                }}
                            >{"Update Card Status"}</Button>
                            &nbsp;&nbsp;
                            <Button
                                label="Cancel"
                                primary={true}
                                onClick={(event) => {
                                    event.preventDefault()
                                    this.props.actions.updateCardStatus(this.props.state.card, '', false)
                                }}
                            >{"Cancel"}</Button>
                            <br /> <br />
                        </Col>
                    </Row>
                </Container>
            }
        />

    }

    getMandatesChip(data, model, display_id, display, component, cat_id) {
        return <div style={{
            display: 'flex',
            flexWrap: 'wrap',
        }}>
            {
                data.length
                    ? data.map((x, i) => {
                        return cat_id
                            ? x[model].mandatecategory_id === cat_id
                                ? <Chip
                                    clickable={true}
                                    style={{ backgroundColor: '#D1E0E6', color: '#000' }}
                                    key={'chip_cat_' + i}
                                    onClick={(event) => {
                                        event.preventDefault()
                                        //console.log('chip_cat_')
                                        //console.log(this.props.state.card.card_id, x[model][display_id], display_id, component, false)
                                        this.props.actions.postCardData(this.props.state.card.card_id, x[model][display_id], display_id, component, false)
                                        this.props.actions.refreshCardData(this.props.state.card.card_id)
                                        //this.props.actions.toggleCreateView(false)
                                    }}
                                    //style={{margin: 4}}
                                    label={x?.[model]?.[display]}
                                />
                                : ''
                            : <Chip
                                key={'chip_mondate_' + i}
                                clickable={true}
                                style={{ backgroundColor: '#D1E0E6', color: '#000' }}
                                onClick={(event) => {
                                    event.preventDefault()
                                    //console.log('chip_mondate_')
                                    //console.log(this.props.state.card.card_id, x[model][display_id], display_id, component, false)
                                    this.props.actions.postCardData(this.props.state.card.card_id, x[model][display_id], display_id, component, false)
                                    this.props.actions.refreshCardData(this.props.state.card.card_id)
                                    //this.props.actions.toggleCreateView(false)
                                }}
                                // style={{margin: 4}}
                                label={x?.[model]?.[display]}
                            />
                    })
                    : ''
            }
        </div>
    }

    getMondateSubItemsOff(data, display, secondary, display_id, component, model, cat_id) {
        return <ListItem
            //primaryText="Inbox"
            // leftIcon={<ContentInbox />}
            style={styles.mondateheader}
            nestedListStyle={styles.mondatesubheader}
            initiallyOpen={false}
            primaryTogglesNestedList={true}
            primaryText={
                this.props.state.card[component].length
                    ? <p style={{ width: '100%' }}>
                        {this.getMandatesChip(this.props.state.card[component], model, display_id, display, component, cat_id)}
                    </p>
                    : <p style={{ width: '100%', color: 'white' }}>
                        <strong>No Items</strong>
                    </p>
            }
            nestedItems={
                data.map(x => {
                    return <ListItem
                        leftCheckbox={<Checkbox
                            checked={this.props.state.card[component].filter(f => f[display_id] === x[display_id]).length ? true : false}
                            onCheck={
                                (event, checked) => {
                                    this.props.actions.postCardData(this.props.state.card.card_id, x[display_id], display_id, component, checked)
                                    this.props.actions.refreshCardData(this.props.state.card.card_id)
                                }
                            } />}
                        primaryText={x[display]}
                        secondaryText={x[secondary]}
                    />
                })
            }
        />
    }

    getMondateSubItems(data, display, secondary, display_id, component, model, mandatecategory_id) {
        return <div className="table-responsive-md" style={{border: '2px solid #e9ecef'}}>
        <Table className="table table-hover text-nowrap mb-0">
            <thead>
                <tr>
                    <th>{'Add ' + model + ' mandate to card'}</th>
                </tr>
                </thead>
                <tbody  style={{ backgroundColor: '#fff', color: '#000' }}>
                    <tr  style={{ backgroundColor: '#fff', color: '#000' }}>
                        <td>
                            
                        <AutoComplete
                            name={'autocomplete_purchase_order_copy_user'}
                            title={'Select ' + model}
                            info={{
                                show: false
                            }}
                            multiple={false}
                            //disableCloseOnSelect={true}
                            fullWidth={true}
                            data={this.props.state[model] ? this.props.state[model] : []}
                            //value={this.props.state.input[display]}
                            checkbox={true}
                            fieldvalue={display_id}
                            fieldtext={display}
                            selected={component}
                            displayfields={[display, secondary]}
                            displaywidth={['75', '25']}
                            onSelect={(args) => {
                                //console.log('onSelect', args.value)
                                this.props.actions.postCardData(this.props.state.card.card_id, args.value, display_id, component, true)
                                this.props.actions.refreshCardData(this.props.state.card.card_id)
                                //if(this.props.state.input[display])
                                //this.props.actions.getCardLookupData(model, component, display, this.props.state.input[display], mandatecategory_id)
                            }}
                            onClick={() => {
                                //console.log('this.props.state.input[display]',this.props.state.input[display])
                                //this.props.actions.getCardLookupData(model, component, display, this.props.state.input[display] ? this.props.state.input[display] : '',mandatecategory_id)
                                this.props.actions.getCardLookupData(model, component, display, '',mandatecategory_id)
                            }}
                            onFilter={(args) => {
                                //console.log('onFilter',args)
                                //console.log('this.props.state.input[display]',this.props.state.input[display])
                                this.props.actions.getCardLookupData(model, component, display, args, mandatecategory_id)
                            }}
                        />
                        </td>
                    </tr>
                </tbody>
                </Table>
                </div>
    }

    getMondatesForm() {
        return this.props.state.cardtransactionview
            ? <Transactions />
            : <div>
                <List
                //   component="nav"
                //   aria-labelledby="nested-list-subheader"
                //   subheader={
                //     <ListSubheader style={styles.list_subheader}>{'Card Limit'}</ListSubheader>
                //   }
                >

                    <ListItem button
                        onClick={(event) => {
                            event.preventDefault()
                            this.props.actions.toggleMandateTab('limits')
                        }}>
                        <ListItemIcon>
                            <FolderIcon />
                        </ListItemIcon>
                        <ListItemText primary="Card Limit" />
                        {this.props.state.mandatetab === 'limits' ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    {this.props.state.mandatetab === 'limits' && <div style={{ backgroundColor: '#fff', color: '#000' }}>{this.getParamtersForm()}</div>}
                </List>
                <List>
                    <ListItem ListItem button
                        onClick={(event) => {
                            event.preventDefault()
                            this.props.actions.toggleMandateTab('cardcostcentre')
                        }}>
                        <ListItemIcon>
                            <FolderIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Cost Center Linked To This Card'} />
                        {this.props.state.mandatetab === 'cardcostcentre' ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    {this.props.state.mandatetab === 'cardcostcentre' &&
                        this.getMondateSubItems(this.props.state.costcentre, 'customername', 'costcentre', 'customer_id', 'cardcostcentre', 'customer', null)}
                    {
                        this.props.state.card['cardcostcentre'].length
                            ? <ListItem style={{ backgroundColor: '#fff', color: '#fff' }}>
                                {
                                    this.props.state.card['cardcostcentre'].length
                                        ? <p style={{ width: '100%' }}>
                                            {this.getMandatesChip(this.props.state.card['cardcostcentre'], 'customer', 'customer_id', 'customername', 'cardcostcentre', null)}
                                        </p>
                                        : <p style={{ width: '100%', color: 'white' }}>
                                            <strong>No Items</strong>
                                        </p>
                                }
                            </ListItem>
                            : <div />
                    }

                    <ListItem ListItem button
                        onClick={(event) => {
                            event.preventDefault()
                            this.props.actions.toggleMandateTab('cardvehicle')
                        }}>
                        <ListItemIcon>
                            <FolderIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Vehicles Linked To This Card'} />
                        {this.props.state.mandatetab === 'cardvehicle' ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    {this.props.state.mandatetab === 'cardvehicle' &&
                        this.getMondateSubItems(this.props.state.vehicles, 'licenseplateno', 'makemodelyear', 'vehicle_id', 'cardvehicle', 'vehicle', null)}
                    {
                        this.props.state.card['cardvehicle'].length
                            ? <ListItem style={{ backgroundColor: '#fff', color: '#fff' }}>
                                {
                                    this.props.state.card['cardvehicle'].length
                                        ? <p style={{ width: '100%' }}>
                                            {this.getMandatesChip(this.props.state.card['cardvehicle'], 'vehicle', 'vehicle_id', 'licenseplateno', 'cardvehicle', null)}
                                        </p>
                                        : <p style={{ width: '100%', color: 'white' }}>
                                            <strong>No Items</strong>
                                        </p>
                                }
                            </ListItem>
                            : <div />
                    }

                    <ListItem ListItem button
                        onClick={(event) => {
                            event.preventDefault()
                            this.props.actions.toggleMandateTab('carddriver')
                        }}>
                        <ListItemIcon>
                            <FolderIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Drivers Linked To This Card'} />
                        {this.props.state.mandatetab === 'carddriver' ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    {this.props.state.mandatetab === 'carddriver' &&
                        this.getMondateSubItems(this.props.state.drivers, 'fullname', 'employee_number', 'person_id', 'carddriver', 'person', null)}
                    {
                        this.props.state.card['carddriver'].length
                            ? <ListItem style={{ backgroundColor: '#fff', color: '#fff' }}>
                                {
                                    this.props.state.card['carddriver'].length
                                        ? <p style={{ width: '100%' }}>
                                            {this.getMandatesChip(this.props.state.card['carddriver'], 'person', 'person_id', 'fullname', 'carddriver', null)}
                                        </p>
                                        : <div />
                                }
                            </ListItem>
                            : <div />
                    }
                    {
                        this.props.state.others.map(x => {
                            return <div>
                                <ListItem ListItem button
                                    onClick={(event) => {
                                        event.preventDefault()
                                        this.props.actions.toggleMandateTab(x.title)
                                    }}>
                                    <ListItemIcon>
                                        <FolderIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={x.title} />
                                    {this.props.state.mandatetab === x.title ? <ExpandLess /> : <ExpandMore />}
                                </ListItem>
                                {this.props.state.mandatetab === x.title &&
                                    this.getMondateSubItems(x.mandateitem, 'title', 'description', 'mandateitem_id', 'carditem', 'mandateitem', x.mandatecategory_id)}
                                {
                                    this.props.state.card['carditem'].filter(f => f.mandateitem.mandatecategory_id === x.mandatecategory_id).length ?
                                        <ListItem style={{ backgroundColor: '#fff', color: '#000' }}>
                                            {
                                                this.props.state.card['carditem'].length
                                                && <p style={{ width: '100%' }}>
                                                    {this.getMandatesChip(this.props.state.card['carditem'], 'mandateitem', 'mandateitem_id', 'title', 'carditem', x.mandatecategory_id, x.title)}
                                                </p>
                                            }
                                        </ListItem>
                                        : <div />
                                }
                            </div>
                        })
                    }
                </List>
            </div>
    }
    getCardManagement() {
        return <div>
            <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={12}>
                        {this.getToolbar()}
                    </Col>
                </Row>
                <Row style={{ minHeight: '900px' }}>
                    <Col xl={3} style={{ borderRight: "2px solid grey" }}>
                        <div style={styles.cardnumber}>{this.props.state.card.cardnumber}</div>
                        <div style={styles.cardexpiry}>{this.props.state.card.expirymonth}/{this.props.state.card.expiryyear}</div>
                        <div style={styles.cardname}>{this.props.state.card.cardname}</div>
                        <div style={styles.cardtype}>{this.props.state.card.cardtype}</div>
                        <img style={styles.image} src={logo} alt='Card' />
                        <br />
                        <br />
                        <table style={{ width: '100%' }}>
                            <tr>
                                <th colspan="2"><strong>Card Details</strong></th>
                            </tr>
                            <tr>
                                <td>Card Expiry Date:</td>
                                <td style={{ fontWeight: 'bold' }}> {this.props.state.card.expirymonth}/{this.props.state.card.expiryyear}</td>
                            </tr>
                            <tr>
                                <td>Card Name:</td>
                                <td style={{ fontWeight: 'bold' }}> {this.props.state.card.cardname}</td>
                            </tr>
                            <tr>
                                <td>Card Status:</td>
                                <td style={{ color: 'green', fontWeight: 'bold' }}> {this.props.state.card.cardstatus}</td>
                            </tr>
                            <tr>
                                <th colspan="2"><hr /></th>
                            </tr>
                            <tr>
                                <th colspan="2"><strong>Card Limit</strong></th>
                            </tr>
                            <tr>
                                <td>Card Limit:</td>
                                <td style={styles.cardamount}>{this.props.state.card.cardlimit}</td>
                            </tr>
                            <tr>
                                <td>Fillup Limit:</td>
                                <td style={styles.cardamount}>{this.props.state.card.fuellimit}</td>
                            </tr>
                            <tr>
                                <td>Facility Limit:</td>
                                <td style={styles.cardamount}>{this.props.state.card.facilitylimit}</td>
                            </tr>
                            <tr>
                                <td>Limit Daily:</td>
                                <td style={styles.cardamount}>{this.props.state.card.dailylimit}</td>
                            </tr>
                            <tr>
                                <th colspan="2"><hr /></th>
                            </tr>
                            <tr>
                                <th colspan="2"><strong>Card Spend</strong></th>
                            </tr>
                            <tr>
                                <td>Current Spend:</td>
                                <td style={styles.cardamount}>0.00</td>
                            </tr>
                            <tr>
                                <td>Current Fillup:</td>
                                <td style={styles.cardamount}>0.00</td>
                            </tr>
                            <tr>
                                <td>Current Facility Limit:</td>
                                <td style={styles.cardamount}>0.00</td>
                            </tr>

                        </table>
                    </Col>
                    <Col xl={9}>
                        {
                            this.props.state.toggle_card_status
                                ? this.getCardStatusForm()
                                : this.getMondatesForm()
                        }
                    </Col>
                </Row>
                <Row>
                    <Col>
                    </Col>
                </Row>
            </Container>
        </div>
    }

    render() {
        return (this.getView())
    }
    
    renderOff() {
        return 1 !== 1
            ? <Loading message={'Loading....'} />
            : <>
            {/* <Card 
                title={'Card Management'}
                subtitle={'manage card.'}
                content={<>
                    {this.getCardManagement()}
                    
                    <div style={styles.table}>
                                <div style={styles.cell}>
                                    <Button
                                        label={'Manage Cards'}
                                        primary={true}
                                        onClick={(event) => {
                                            event.preventDefault()
                                            this.props.actions.toggleCreateView(false)
                                            this.props.actions.loadCardsData()
                                        }}
                                    >{'Manage Cards'}</Button>

                                    &nbsp;&nbsp;&nbsp;

                                    <Button
                                        label={'Request Card'}
                                        primary={true}
                                        onClick={(event) => {
                                            event.preventDefault()
                                            this.props.actions.toggleCreateView(true)
                                        }}
                                    >{'Request Card'}</Button>

                                    &nbsp;&nbsp;&nbsp;

                                </div>

                            </div>
                </>
                }
                /> */}
                          
                                        <Card 
                                        title={'Cards List '}
                                        subtitle={'Card Management'}
                                        content={this.getView()}
                                        />
                                        <Create />
                                        </>
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.cardManagement,
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Cards)