export const filepreview = ''
export const filename = ''
export const comments = ''
export const completeddate = null
export const documents = []

export const invoicedate = ''
export const invoice_number = ''
export const invoice_amount = ''
export const isinvoice_uploaded = false

export const completedby = ''
export const odometer = ''