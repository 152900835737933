import * as actions from './actions'
import * as types from './types'

import * as config from '../../config'
import * as progressBarActions from '../ProgressBar/actions'
import * as requestActions from '../Request/actions'
import * as routerActions from '../Redirect/actions'
import * as requestTypes from '../Request/types'


export const getReportFilter = (action$, store, { ajax$, of$, startWith, map, mergeMap, delay, catchError, ofType, takeUntil }) => action$.pipe(
    ofType(types.GET_JSREPORT_FILTER),
    mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + '/components/report/other',
            method: 'POST',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
                'Content-Type': 'application/json'
            },
            body: payload
        }).pipe(
            mergeMap((result) => [
                actions.setFilters(result.response),
                progressBarActions.progress(false)
            ]),
            // startWith(progressBarActions.progress(true)),
            //takeUntil(action$.ofType(requestTypes.CANCEL_REQUESTS)),
            catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/component?client_id=2&menu=true', error)))
        )
    ),
    catchError((error) =>
        of$(routerActions.route('/support', false, error))
    )
)



export const submitFilter = (action$, store, { ajax$, of$, startWith, map, mergeMap, delay, catchError, ofType, takeUntil }) => action$.pipe(
    ofType(types.GET_JSREPORT_FILTER_DATA),
    mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + '/components/report/other',
            method: 'POST',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
                'Content-Type': 'application/json'
            },
            body: payload
        }).pipe(
            mergeMap((result) => [
                actions.setJSReportData(result.response),
                progressBarActions.progress(false)
            ]),
            // startWith(progressBarActions.progress(true)),
            //takeUntil(action$.ofType(requestTypes.CANCEL_REQUESTS)),
            catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/component?client_id=2&menu=true', error)))
        )
    ),
    catchError((error) =>
        of$(routerActions.route('/support', false, error))
    )
)
   
export const getJSReportData = (action$, store, { ajax$, of$, startWith, map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.GET_JSREPORT_DATA)
    , mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + '/components/report/other',
            method: 'POST',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
                'Content-Type': 'application/json'
            },
            body: payload
        }).pipe(
            mergeMap((result) => [
                actions.setJSReportData(result.response),
                progressBarActions.progress(false)
            ])
            , startWith(progressBarActions.progress(true))
            , catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/component?client_id=2&menu=true', error)))
            //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
    )
    , catchError((error) =>
        of$(routerActions.route('/support', false, error))
    )
)