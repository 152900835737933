export const loading = true
export const report_title = null
export const selected_report_type = null
export const report_type = [
    {
        value: 1,
        text: 'Report',
        key: 'reports',
    },
    {
        value: 2,
        text: 'Dashboard',
        key: 'dashboard',
    },

]
export const sub_category = null
export const subcategory_id = null
export const key = null
export const selected_cartegory = null

