import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import moment from 'moment'
import { Container, Row, Col } from 'react-grid-system'

import AdhocComponentFieldInput from '../../Custom/AdhocComponentFieldInput/component'
import Icon from '../../Icon/component'

import ViewVehicle from '../../../controls/view_vehicle'
import ViewSupplier from '../../../controls/view_supplier'
import ViewMaintenance from '../../../controls/view_maintenance'
import Card from '../../../controls/card'

import * as styles from './styles'
import * as actions from './actions'

class POBookingConfirm extends Component {

    componentDidMount() {
        const data = this.props.state
        //console.log('componentWillMount', data)
        const meta = [
            {
                header: true,
                title: 'Vehicle',
                description: 'Vehicle',
                meta_data: data.header.fields.selectedVehicle.data.text
            },
            {
                title: 'Booking Type',
                description: 'Booking Type',
                meta_data: data.header.fields.selectedPurchaseOrderType.data.text
            },
            {
                title: 'Infleet Internal Pre-Auth',
                description: 'Infleet Internal Pre-Auth',
                meta_data: data.header.preAuth
            }
        ]

        this.props.actions.setupWorkflowTaskInput(data, meta, 'booking', 'tsk_purchase_order_request_for_quote')
    }
   

    ServiceDealer(data) {
        return <div>
                    <Container style={{ margin: 0 }} className="custom-container">
                        {
                            data.map(x => {
                                return <div>
                                    
                                    {/* <Col xl={6}>
                                        {this.getLabel('xxxxxxx:', x.suppliername)}
                                    </Col>
                                    <Col xl={6}>
                                        {this.getLabel('xxxxxxx:', x.suppliername)}
                                    </Col> */}
                                    <Row>
                                        <Col xl={2} style={styles.fieldLabel}>{'Supplier Name:'}</Col>
                                        <Col xl={2} style={styles.selectedFieldName}>{x.suppliername}</Col>
                                        <Col xl={2} style={styles.fieldLabel}>{'BEE Level:'}</Col>
                                        <Col xl={2} style={styles.selectedFieldName}>{x.beelevel}</Col>
                                        <Col xl={2} style={styles.fieldLabel}>{'Supplier Registration:'}</Col>
                                        <Col xl={2} style={styles.selectedFieldName}>{x.registrationnumber}</Col>
                                        <Col xl={2} style={styles.fieldLabel}>{'BBE Score:'}</Col>
                                        <Col xl={2} style={styles.selectedFieldName}>{x.beescore}</Col>
                                        <Col xl={2} style={styles.fieldLabel}>{'VAT Number:'}</Col>
                                        <Col xl={2} style={styles.selectedFieldName}>{x.vatnumber}</Col>
                                        <Col xl={2} style={styles.fieldLabel}>{'BBE Type Number:'}</Col>
                                        <Col xl={2} style={styles.selectedFieldName}>{x.beetype}</Col>
                                        <Col xl={2} style={styles.fieldLabel}>{'BBE Certificate Received:'}</Col>
                                        <Col xl={2} style={styles.selectedFieldName}>{x.beecertificatereceived ? x.beecertificatereceived : 'Not Set'}</Col>
                                        <Col xl={2} style={styles.fieldLabel}>{'BEE Certificate Expry Date:'}</Col>
                                        <Col xl={2} style={styles.selectedFieldName}>{x.beecertificateexpdate ? x.beecertificateexpdate.split('T')[0] : 'Not Set'}</Col>
                                    </Row>
                                </div>
                            })
                        }
                    </Container>
        </div>
    }

    BookingForm(data) {
        return <div>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={2} style={styles.fieldLabel}>{'Service Date:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{moment(this.data.servicedate).format('DD/MM/YYYY')}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Vehicle Current Odometer:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{this.data.millage}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Service Description:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{this.data.servicedescription}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Service Comments:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{this.data.comments}</Col>
                        </Row>
                    </Container>
        </div>
    }

    ServiceForm(data) {
        return <div>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={2} style={styles.fieldLabel}>{'Service Date:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{moment(this.data.servicedate.value).format('DD/MM/YYYY')}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Vehicle Current Odometer:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{this.data.millage.value}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Service Description:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{this.data.servicedescription.value}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Service Comments:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{this.data.comments.value}</Col>
                        </Row>
                    </Container>
        </div>
    }


    RepairsForm(data) {
        return <div>
            
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={2} style={styles.fieldLabel}>{'Repair Date:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{moment(this.data.servicedate.value).format('DD/MM/YYYY')}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Vehicle Current Odometer:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{this.data.millage.value}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Driver Name:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{this.data.drivername.value}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Repair Description:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{this.data.servicedescription.value}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Comments:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{this.data.comments.value}</Col>
                        </Row>
                    </Container>
        </div>
    }

    TowingForm(data) {
        return <div>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={2} style={styles.fieldLabel}>{'Towing Date:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{moment(this.data.servicedate.value).format('DD/MM/YYYY')}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Vehicle Current Odometer:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{this.data.millage.value}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Driver Name:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{this.data.drivername.value}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Reason for Towing:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{this.data.servicedescription.value}</Col> 
                            <Col xl={2} style={styles.fieldLabel}>{'Comments:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{this.data.comments.value}</Col>
                        </Row>
                    </Container>
        </div>
    }

    TyresForm() {
        return <div>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={2} style={styles.fieldLabel}>{'Pre Auth Number:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{this.props.state.header.preAuth}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Copy User:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{this.props.state.header.fields.selectedUser.data ? this.props.state.header.fields.selectedUser.data.text : ''}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Supplier:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{this.props.state.header.fields.selectedSupplier.data.text}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Vehicle:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{this.props.state.header.fields.selectedVehicle.data.text}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Fitment Date:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{moment(this.data.servicedate.value).format('DD/MM/YYYY')}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Vehicle Current Odometer:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{this.data.millage.value}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Driver Name:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{this.data.drivername.value}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Number of Tyres:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{this.data.tyrescount.value}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Reason For New Tyres:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{this.data.servicedescription.value}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Comments:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{this.data.comments.value}</Col>
                        </Row>
                    </Container>
        </div>
    }

    PanelBeatersForm() {
        return <div>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={2} style={styles.fieldLabel}>{'Date:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{moment(this.data.servicedate.value).format('DD/MM/YYYY')}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Vehicle Current Odometer:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{this.data.millage.value}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Description:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{this.data.servicedescription.value}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Comments:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{this.data.comments.value}</Col>
                        </Row>
                    </Container>
        </div>
    }

    AssessorForm(data) {
        return <div>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={4} style={styles.fieldLabel}>{'Assessment Date:'}</Col>
                            <Col xl={4} style={styles.selectedFieldName}>{moment(this.data.servicedate.value).format('DD/MM/YYYY')}</Col>
                            <Col xl={4} style={styles.fieldLabel}>{'Vehicle Current Odometer:'}</Col>
                            <Col xl={4} style={styles.selectedFieldName}>{this.data.millage.value}</Col>
                            <Col xl={4} style={styles.fieldLabel}>{'Reason For Assessing:'}</Col>
                            <Col xl={4} style={styles.selectedFieldName}>{this.data.servicedescription.value}</Col>
                            <Col xl={4} style={styles.fieldLabel}>{'Comments:'}</Col>
                            <Col xl={4} style={styles.selectedFieldName}>{this.data.comments.value}</Col>
                        </Row>
                    </Container>
        </div>
    }

    PurchaseOrderType(type) {
        switch (type) {
            case 1:
                return this.AssessorForm()
            case 2:
                return '<POCorporateVehicle />'
            case 3:
                return '<POFMLTransnet />'
            case 4:
                return '<POOfficeAdmin />'
            case 5:
                return this.PanelBeatersForm()
            case 6:
                return this.RepairsForm()
            case 7:
                return this.ServiceForm()
            case 8:
                return '<POSTRTransnetCorporate />'
            case 9:
                return '<POSubscriptions />'
            case 10:
                return this.TowingForm()
            case 11:
                return this.TyresForm()
            default:
                return ''
        }
    }

    renderView(po) {
        return <div>
            {
                this.props.state.header.fields.selectedVehicle && <ViewVehicle 
                    key={this.props.state.header.fields.selectedVehicle.data ? this.props.state.header.fields.selectedVehicle.data.value : null} 
                    id={this.props.state.header.fields.selectedVehicle.data ? this.props.state.header.fields.selectedVehicle.data.value : null} 
                    min={true}
                />
            }
            {
                this.props.state.header.fields.selectedSupplier && <ViewSupplier
                    key={this.props.state.header.fields.selectedSupplier.data ? this.props.state.header.fields.selectedSupplier.data.value : null} 
                    id={this.props.state.header.fields.selectedSupplier.data ? this.props.state.header.fields.selectedSupplier.data.value : null}  
                    min={true}
                />
            }
            <Card
            title={po.text}
            subtitle={'Please review the details and proceed'}
            content={this.PurchaseOrderType(po.value)}
        />
        </div>
    }

    render() {
        const selectedPurchaseOrder = this.props.state.header.fields.selectedPurchaseOrderType.data
        this.data = this.props.state.booking.fields
        return this.renderView(selectedPurchaseOrder)
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            header: {
                ...state.poHeader,
            },
            booking: {
                ...state.poBooking,
            },
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(POBookingConfirm)