import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system'
import _ from 'lodash'
import moment from 'moment'

import { Card, CardHeader, CardContent } from '@mui/material'

import Icon from '../../Icon/component'
import Loading from '../../Loading/component'
import PdfViewer from '../../PDF/component'

import * as styles from './styles'
import * as actions from './actions'

class CaptureSTRConfirm extends Component {

    componentDidMount() {
        const formatDate = (value) => {
            const now = moment(new Date())
            return new Date(moment(value).set({ 'hour': now.get('hour'), 'minute': now.get('minute'), 'second': now.get('second') }).format('YYYY-MM-DD HH:mm:ss'))
        }
        
        const data = {
            ...this.props.state,
            enddate: formatDate(this.props.state.enddate.value),
            startdate: formatDate(this.props.state.startdate.value)
        }

        this.props.actions.setupWorkflowTaskInput(data, 'customerquote', 'tsk_str_quote_create_customer_quote')
    }

    ContractDetails() {
        const data = this.props.state
        return <div>
            <Card>
                <CardHeader
                    actAsExpander={false}
                    showExpandableButton={false}
                    title={window.t.en('Contract Summary')}
                    subtitle={window.t.en('Contract details.')}
                    icon={<Icon iclass={'custom-icons'} iname={'vehicle_category'} />}
                    style={styles.row}
                >
                </CardHeader>
                <CardContent>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Contract Category:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.contractvehiclecategory.value}</Col>
                            <Col xl={3} style={styles.fieldLabel}>{'Vehicle Supplier Name:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.supplier.value}</Col>
                        </Row>
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Contract Start Date:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{moment(data.startdate.value).format('DD/MM/YYYY')}</Col>
                            <Col xl={3} style={styles.fieldLabel}>{'Contract End Date:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{moment(data.enddate.value).format('DD/MM/YYYY')}</Col>
                        </Row>
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Contract Number Of Days:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.days.value}</Col>
                            <Col xl={3} style={styles.fieldLabel}>{'Contract Number Of Months:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.months.value}</Col>
                        </Row>
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Distance Per Month:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.distancepermonth.value}</Col>
                            <Col xl={3} style={styles.fieldLabel}>{'Contract Distance:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.contractdistance.value}</Col>
                        </Row>
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Access KMs:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.accesskm.value}</Col>
                            <Col xl={3} style={styles.fieldLabel}>{'Quote Valid For:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.quotevalidfor.value}</Col>
                        </Row>
                    </Container>
                </CardContent>
            </Card>
        </div>
    }

    PaymentDetails() {
        const data = this.props.state
        return <div>
            <Card>
                <CardHeader
                    actAsExpander={false}
                    showExpandableButton={false}
                    title={window.t.en('Contract Payment Summary')}
                    subtitle={window.t.en('Contract payment details.')}
                    icon={<Icon iclass={'custom-icons'} iname={'vehicle_category'} />}
                    style={styles.row}
                >
                </CardHeader>
                <CardContent>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Total Rental Amount:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{parseFloat(data.monthly_excl.value).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                            <Col xl={3} style={styles.fieldLabel}>{'Rental Vat Amount:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{parseFloat(data.monthly_vat.value).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                        </Row>
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Rental Amount:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{parseFloat(data.monthly_incl.value).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                            <Col xl={3} style={styles.fieldLabel}>{'STR Rental Amount:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{parseFloat(data.monthly_str.value).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                        </Row>
                    </Container>
                </CardContent>
            </Card>
        </div>
    }


    AccessoriesList() {
        window.glyco.log('this.props.state.surrogateitems.grid')
        window.glyco.log(this.props.state.surrogateitems)
        const data = this.props.state.surrogateitems.grid.filter(x => parseFloat(x.rateperday) > 0)

        window.glyco.log('AccessoriesList')
        window.glyco.log(data)
        return data.length
            ? <Card style={styles.workflowTaskStepComponentCard} expandable={false}>
                <CardHeader
                    title={window.t.en('Accessory List')}
                    subtitle={window.t.en('Selected vehicle accessories')}
                    actAsExpander={false}
                    style={styles.row}
                />
                <CardContent expandable={false}>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={7} style={styles.selectedFieldName}>
                                {'Accessory Name'}
                            </Col>
                            <Col xl={2} style={styles.selectedFieldName}>
                                {'Rate Per Rate'}
                            </Col>
                            <Col xl={3} style={styles.numericFieldsHeader}>
                                {'Total Price'}
                            </Col>
                        </Row>
                        {data.map((x, i) => {
                            return <Row>
                                <Col xl={7}>
                                    {x.accessoryname}
                                </Col>
                                <Col xl={2}>
                                    {parseInt(x.rateperday)}
                                </Col>
                                <Col xl={3} style={styles.numericFields}>
                                    {parseFloat(x.totalprice.toString()).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                </Col>
                            </Row>
                        })
                        }
                    </Container>
                </CardContent>
            </Card>
            : ''
    }

    documentsList() {
        const documents = this.props.state.upload.documents
        return documents.length
            ? <Card>
                <CardHeader
                    actAsExpander={false}
                    showExpandableButton={false}
                    title={window.t.en('Documents')}
                    subtitle={window.t.en('Documents list')}
                    style={styles.row}
                >
                </CardHeader>
                <CardContent>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={12}>
                                {
                                    documents
                                        ? <PdfViewer
                                            key={'quotation_viewer_'}
                                            documents={documents}
                                            multiple={true}
                                            height={500}
                                            edit={false}
                                        />
                                        : <Loading message={'Retrieving Purchase Order document...'} />
                                }
                            </Col>
                        </Row>
                    </Container>
                </CardContent>
            </Card>
            : ''
    }

    renderView() {
        return <div>
            <Card>
                <CardHeader
                    actAsExpander={false}
                    showExpandableButton={false}
                    title={window.t.en('')}
                    subtitle={window.t.en('')}
                >
                </CardHeader>
                <CardContent>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={12}>
                                {this.ContractDetails()}
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                {this.PaymentDetails()}
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                {this.AccessoriesList()}
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                {this.documentsList()}
                            </Col>
                        </Row>
                    </Container>
                </CardContent>
            </Card>
        </div>
    }

    render() {

        window.glyco.log('CaptureSTRConfirm')
        return (
            this.renderView()
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.captureSTR,
            workflowTaskInput: {
                ...state.workflowTaskInput
            },
            upload: {
                ...state.upload
            },
            surrogateitems: {
                ...state.strItemGrid
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CaptureSTRConfirm)