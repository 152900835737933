import moment from 'moment'
//import validator from '../validate'
import * as _ from 'lodash'

import * as appTypes from '../../App/types'
import * as types from './types'

export const getSupplierList = (search) => {
    return {
        type: types.GET_SUPPLIER_LIST,
        payload: {
            search_text: search
        }
    }
}
export const setSupplierList = (payload) => {
    let data = []
    data = payload.data.map(x => {
        return {
            text: x.suppliername + ' | ' + x.accountnumber,
            value: x.supplier_id
        }
    })
    return {
        type: types.SET_ANY_DATA_LIST,
        payload: {
            data: data,
            component: 'suppliertransactioncustom',
            field: 'supplier_id'
        }
    }
}

export const setLookupValue = (field, value, text) => {
    return {
        type: types.SET_ANY_SELECTED_ITEM,
        payload: {
            value: value,
            text: text,
            prop: field,
            component: 'suppliertransactioncustom'
        }
    }
}

export const getAllTransactions = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_SUPPLIER_TRANSACTION_LIST,
            payload: {
                type: "GET_SUPPLIER_TRANSACTION_LIST",
                id: Number(id)
            }
        })
    }
}

export const setSupplierTransactionList = (payload) => {
    let data = []

    return {
        type: types.SET_SUPPLIER_TRANSACTION_LIST,
        payload: {
            data: payload.data,
            component: 'suppliertransactioncustom',
            prop: 'suppliertransactioncustom_id'
        }
    }
}

export const excelExport = (component) => {
    return (dispatch, getState) => {
        const header = [ {
                key: 'suppliertransactiondate',
                name: 'Date',
                default: ' ',
            }, {
                key: 'age',
                name: 'Age',
                default: '0',
            }, {
                key: 'transactiontype',
                name: 'Type',
                default: ' ',
            }, {
                key: 'reference',
                name: 'Reference',
                default: ' ',
            }, {
                key: 'description',
                name: 'Description',
                default: ' ',
            }, {
                key: 'debit',
                name: 'Debit',
                default: '0',
            }, {
                key: 'credit',
                name: 'Credit',
                default: '0',
            }, {
                key: 'allocation',
                name: 'Allocation',
                default: 'None',
            }, {
                key: 'allocationamount',
                name: 'Allocation Amount',
                default: '0',
            }, {
                key: 'allocated',
                name: 'Allocated',
                default: false,
            }]

        dispatch({
            type: types.GET_EXCEL_GRID_EXPORT,
            payload: {
                type: 'GET_EXCEL_GRID_EXPORT',
                component: component.name,
                data: component.data.suppliertransactioncustom_id.data,
                header: header
            }
        })
    }
}