import * as actions from './actions'
import * as teamActions from '../../Teams/actions'
import * as clientActions from '../../Clients/actions'
import * as progressBarActions from '../../ProgressBar/actions'
import * as requestActions from '../../Request/actions'
import * as routerActions from '../../Redirect/actions'
import * as types from './types'
import * as requestTypes from '../../Request/types'
import { cognitoUser } from '../../../cognito'
import * as config from '../../../config'
    
export const saveTeam = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(
        ofType(types.SAVE_TEAM_COMPONENT_CUSTOMER_DATA),
        mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/team',
                method: 'POST',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'Content-Type': 'application/json'
                },
                body: payload
            })
            .pipe(
                mergeMap(payload => [
                    actions.saveTeamDone(payload.response),
                    requestActions.requestSucceeded(config.system.api + '/components/team')
                ]),
                startWith(progressBarActions.progress(true)),
                catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/team', error)))
        )),
        catchError((error) =>
            of$(routerActions.route('/support', false, error))
        ))
        
        
// export const deleteReseller = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
// action$.pipe(
//     ofType(types.DELETE_COMPONENT_RESELLER_RECORD),
//     mergeMap(({ payload }) =>
//         ajax$({
//             url: config.system.api + '/components/reseller',
//             method: 'DELETE',
//             headers: {
//                 'tokenx': store.value.user.user_id,
//                    'auth': store.value.cognito.authenticateUser.token,
//                 'salt': store.value.cognito.authenticateUser.jwt,
//                 'datetime': store.value.cognito.authenticateUser.datetime,
//                 'Content-Type': 'application/json'
//             },
//             body: payload
//         })
//         .pipe(
//             mergeMap(payload => [
//                 actions.deleteResellerDone(payload.response),
//                 requestActions.requestSucceeded(config.system.api + '/components/reseller/query')
//             ]),
//             startWith(progressBarActions.progress(true)),
//             catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/reseller/query', error)))
//     )),
//     catchError((error) =>
//         of$(routerActions.route('/support', false, error))
//     ))

export const getComponentFieldLookupData = (
    action$,
    store,
    { ajax$, of$, startWith,  map, mergeMap, delay, catchError, ofType, takeUntil }
  ) =>
    action$.pipe(
      ofType(types.GET_TEAM_COMPONENT_FIELD_LOOKUP_DATA),
      delay(0),
      mergeMap(({ payload }) =>
        ajax$({
          url:
            config.system.api +
            "/components/" +
            payload.field.component_reference.name +
            "/search" +
            payload.query,
          method: "GET",
          headers: {
            tokenx: store.value.user.user_id,
            auth: store.value.cognito.authenticateUser.token,
            salt: store.value.cognito.authenticateUser.jwt,
            datetime: store.value.cognito.authenticateUser.datetime,
            "client-id": store.value.accounts.selectedAccount,
            related: "", //payload.related
            order: payload.field.search ? payload.field.search : "",
          },
        }).pipe(
          mergeMap((result) => [
            actions.setComponentFieldLookupData(
              payload.component,
              payload.field,
              result.response
            ),
            progressBarActions.progress(false),
            requestActions.requestSucceeded(
              config.system.api +
                "/components/" +
                payload.field.component_reference.name +
                "/search" +
                payload.query
            ),
          ]),
          //,startWith(progressBarActions.progress(true))
          catchError((error) =>
            of$(
              requestActions.requestFailed(
                config.system.api + "/components/component?client_id=2&menu=true",
                error
              )
            )
          ),
          //takeUntil(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
      ),
      catchError((error) => of$(routerActions.route("/support", false, error)))
    );
  
    
export const getTreeViewData = (
    action$,
    store,
    { ajax$, of$, startWith,  map, mergeMap, delay, catchError, ofType, takeUntil }
  ) =>
    action$.pipe(
      ofType(types.GET_COMPONENT_FORM_TREE_VIEW_CONTROL_DATA),
      mergeMap(({ payload }) =>
        ajax$({
          url: config.system.api + "/components/component/other", // + payload.query,
          method: "POST",
          headers: {
            tokenx: store.value.user.user_id,
            auth: store.value.cognito.authenticateUser.token,
            salt: store.value.cognito.authenticateUser.jwt,
            datetime: store.value.cognito.authenticateUser.datetime,
            "Content-Type": "application/json",
          },
          body: payload
        }).pipe(
          mergeMap((result) => [
            actions.setTreeViewData(result.response, payload),
            progressBarActions.progress(false),
          ]),
          startWith(progressBarActions.progress(true)),
        //   catchError((error) =>
        //     of$(actions.componentDataSaveFailed(payload.component))
        //   ),
          catchError((error) =>
            of$(
              requestActions.requestFailed(
                config.system.api + "/components/customer/other",
                error
              )
            )
          ),
          //takeUntil(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
      ),
      catchError((error) => of$(routerActions.route("/support", false, error)))
    );
  