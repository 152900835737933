
import * as colours from '@mui/material/colors'

export const fieldContainer = {
    display: 'table',
}

export const inputWidth = {
    width: '100%'
}

export const fieldTitle = {
    width: 500,
    display: 'table-cell',
    verticalAlign: 'middle',
    fontSize: '16px',
    textAlign: 'right',
    paddingRight: 10
}

export const fieldContent = {
    display: 'table-cell',
    width: '65%'
}

export const headerButton = {
    width: 250
}

export const right = {
    float: 'right'
}

export const toggle = {
    width: '100%'
}

export const textBox = {
    width: '100%'
}

export const row = {
    fontSize: 16,
    textAlign: 'left',
    //backgroundColor: '#dcd6d7',
    //opacity: '0.5'
}

export const selectedFieldName = {
    fontWeight: 'bold',

}

export const numericFields = {
    textAlign: 'right',
    fontWeight: 'bold',
}

export const numericFieldName = {
    textAlign: 'right',
}

export const radiored = {
    color: colours.red[600]
}

export const radiocontainer = {
    width: '70%',
    margin: '0 auto',
    marginTop: 60,
    paddingLeft: '10%',
    position: 'relative'
}

export const radiotitle = {
   
    fontSize: 18,
    marginBottom: 35,
    marginTop: 30,
    marginLeft: '-7.5%',
    position: 'relative',
    width: 500,
  
}

export const offsetcommentbox = {
    
    content: '',
    display: 'block',
    marginBottom:  18
   
 }

 export const caption = {
   
    
    marginBottom: 35,
    marginTop: 50,
    fontSize: 20,
   
 }
 
 

 
 

