export const latitude = '-25.975587'
export const longitude = '28.105916'
export const zoom = 6

export const data = []

export const source = {
    source: 'lastposition',
    icon: 'vehicles',
    map: 'vehicle',
    title: 'Vehicles',
    display: 'vehicle.licenseplateno',
    single: true,
    latitude: 'currentlatitude',
    longitude: 'currentlongitude'
}

export const sources = [
    {
        source: 'lastposition',
        title: 'Vehicles',
        icon: 'vehicles',
        map: 'vehicle',
        display: 'vehicle.licenseplateno',
        single: true,
        latitude: 'currentlatitude',
        longitude: 'currentlongitude'
    },
    {
        source: 'customer',
        title: 'Customers',
        icon: 'debtor',
        map: 'customeraddress',
        display: 'customername',
        single: false,
        latitude: 'latitude',
        longitude: 'longitude'
    },
    {
        source: 'supplier',
        title: 'Suppliers',
        icon: 'creditor',
        map: 'supplieraddress',
        display: 'suppliername',
        single: false,
        latitude: 'latitude',
        longitude: 'longitude'
    },
    {
        source: 'supplier',
        title: 'Road Side Assistance',
        icon: 'toll',
        map: 'supplieraddress',
        key: 'suppliertype',
        query: 'Tow',
        display: 'suppliername',
        single: false,
        latitude: 'latitude',
        longitude: 'longitude'
    },
    {
        source: 'supplier',
        title: 'Isuzu Dealers',
        icon: 'creditors',
        map: 'supplieraddress',
        key: 'suppliertype',
        query: 'Isuzu Dealer',
        display: 'suppliername',
        single: false,
        latitude: 'latitude',
        longitude: 'longitude'
    },
    {
        source: 'supplier',
        title: 'Yellow Equipment',
        icon: 'operations',
        map: 'supplieraddress',
        key: 'suppliertype',
        query: 'Yellow Equipment',
        display: 'suppliername',
        single: false,
        latitude: 'latitude',
        longitude: 'longitude'
    },
    {
        source: 'supplier',
        title: 'Workshops',
        icon: 'tyre_make',
        map: 'supplieraddress',
        key: 'suppliertype',
        query: 'Workshop',
        display: 'suppliername',
        single: false,
        latitude: 'latitude',
        longitude: 'longitude'
    },
    {
        source: 'business',
        title: 'AInfleetDN Branches',
        icon: 'business_units',
        single: true,
        map: '',
        display: 'businessname',
        latitude: 'latitude',
        longitude: 'longitude'
    }
]