import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.SAY_HELLO:
            return {
                ...state,
                input: action.payload
            }
        default:
            return state
    }
}