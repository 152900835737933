import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'
import _ from 'lodash'

import { Container, Row, Col } from 'react-grid-system'
import Icon from '../Icon/component'
import { Card, CardHeader, CardContent, TextField, Button } from '@mui/material'

import AutoComplete from '../../controls/autocomplete'
import DateControl from '../../controls/dateControl'
import CheckBoxControl from '../../controls/checkbox'
import TreeSelect from '../../controls/treeselect'
import "react-table/react-table.css"
import Loading from '../Loading/component'



import * as actions from './actions'
import * as styles from './styles'

class BudgetManagementForm extends Component {

    componentDidMount() {
        if(this.props.state.budgetmanagement.component_field) {
            this.props.actions.setComponentFieldInput(this.props.state.budgetmanagement.component_field.filter(x => x.name === 'periodto')[0], moment().format('YYYY-MM-DD'))
            this.props.actions.setComponentFieldInput(this.props.state.budgetmanagement.component_field.filter(x => x.name === 'periodfrom')[0], moment().format('YYYY-MM-DD'))
        }
    }

    getFormFieldValue(field, index) {
       return  <TextField 
                id={'txt_' + field.name}
                key={'txt_' + field.name}
                label={'Enter ' + field.title}
                fullWidth={true}
                variant="outlined"
                color="secondary"
                value={this.props.state.budgetmanagement.data[field.name].input}
                onChange={
                    (event, value) => {
                        this.props.actions.setComponentFieldInput(field, event.target.value)
                    }
                }
            />
    }

    
    getFormFieldValue(field, index) {
        switch (field.component_field_display_type.name) {
            case 'treeSelect':
                return <TreeSelect
                    name={field.name}
                    title={field.hint}
                    data={[{customername: ''}]}
                    hierarchy={this.props.state[field.name+"_data"] ? this.props.state[field.name+"_data"] : []}
                    value={this.props.state.budgetmanagement.data[field.name].text}
                    multiple={false}
                    valuefield={'customer_id'}
                    displayfield={'customername'}
                    onSelect={(args) => {
                        this.props.actions.setComponentFieldInputAndText(field, args['customer_id'], args['customername'])
                    }}
                    onClick={() => {
                        //console.log('TreeSelect Data',this.props.state[field.name+"_data"])
                        this.props.actions.getTreeViewData(field.name, field.datasource, this.props.state.user.customer_id ? this.props.state.user.customer_id : 1)
                    }}
                    onFilter={(args) => console.log()} 
                /> 
            case 'autoComplete':
                return <AutoComplete
                    name={field.name}
                    title={field.hint}
                    info={{
                        show: true,
                        component: field.component_reference.name,
                        title: field.component_reference.title,
                        field: field.name,
                        value: this.props.state.budgetmanagement.data[field.name].input
                    }}
                    component_reference={field.component_reference.name}
                    data={this.props.state.budgetmanagement.data[field.name].data}
                    value={this.props.state.budgetmanagement.data[field.name].text}
                    onSelect={(args) => 
                        //this.props.actions.setComponentFieldInput(field, args.value)
                        this.props.actions.setComponentFieldInputAndText(field, args.value, args.text)
                    }
                    onClick={() => this.props.actions.getComponentFieldLookupData(field, this.props.state.budgetmanagement.data[field.name].text ? this.props.state.budgetmanagement.data[field.name].text : '')}
                    onFilter={(args) => this.props.actions.getComponentFieldLookupData(field, args)} 
                />
                case 'datePicker':
                    return <div style={{padding: 0, margin: 0, marginTop: -15}}>
                        <DateControl
                            id={this.props.state.budgetmanagement.data[field.name].key}
                            key={this.props.state.budgetmanagement.data[field.name].key}
                            name={this.props.state.budgetmanagement.data[field.name].key}
                            label={window.t.en(field.hint)}
                            field={field}
                            value={this.props.state.budgetmanagement.data[field.name].input}
                            onSelect={(args) => {
                                //console.log('args date', args)
                                this.props.actions.setComponentFieldInput(field, args)
                            }}
                            type={'date'}
                        />
                    </div>
                case 'hidden':
                    return <input
                        key={'component_' + this.component.name + '_' + field.name + '_' + this.props.state.budgetmanagement.data[field.name]}
                        type='hidden'
                        value={this.props.state.budgetmanagement.data[field.name].input}
                    />
                case 'timePicker':
                    return <DateControl
                        id={this.props.state.budgetmanagement.data[field.name].key}
                        key={this.props.state.budgetmanagement.data[field.name].key}
                        name={this.props.state.budgetmanagement.data[field.name].key}
                        label={window.t.en(field.hint)}
                        field={field}
                        value={this.props.state.budgetmanagement.data[field.name].input}
                        setComponentFieldInput={
                            this.props.actions.setComponentFieldInput
                        }
                        type={'time'}
                    />
                case 'textBox':
                    return <TextField
                        id={this.props.state.budgetmanagement.data[field.name].key}
                        key={this.props.state.budgetmanagement.data[field.name].key}
                        label={window.t.en(field.hint)}
                        fullWidth={true}
                        variant="outlined"
                        color="secondary"
                        //error={}
                        value={this.props.state.budgetmanagement.data[field.name].input}
                        onChange={
                            (event, value) => {
                                this.props.actions.setComponentFieldInput(field, event.target.value)
                            }
                        }
                    />
                case 'toggle':
                    return <div>
                        <CheckBoxControl
                            key={this.props.state.budgetmanagement.data[field.name].key}
                            id={this.props.state.budgetmanagement.data[field.name].key}
                            checked={this.props.state.budgetmanagement.data[field.name].input != null ? this.props.state.budgetmanagement.data[field.name].input : false}
                            field={field}
                            // setComponentFieldInput={this.props.actions.setComponentFieldInput}
                            onCheck={(checked) => {
                                this.props.actions.setComponentFieldInput(field, checked)
                            }}
                        />
                        {
                            this.props.state.budgetmanagement.data[field.name].validation.message
                                ? [
                                    <br />,
                                    <div style={{ color: 'red' }}>{this.props.state.budgetmanagement.data[field.name].validation.message}</div>
                                ]
                                : ''
                        }
                    </div>
                default:
                    return
            }
    }


    getField(field, index) {
        return <div style={styles.fieldContainer}>
                <div style={styles.fieldTitle}>{field.title}</div>
                <div style={styles.fieldContent}>{this.getFormFieldValue(field, index)}</div>
            </div>
    }
    
    toggleField(field) {
        let show = true
        let toggle_name = null
        try{
            toggle_name = this.props.state.budgetmanagement.data.budgetlevel_id.data.filter(x => x.value === this.props.state.budgetmanagement.data.budgetlevel_id.input)[0].text
        }
        catch(err){}

        if(field.name === 'customer_id' && toggle_name !== 'Cost Centre') 
            return false
        else if(field.name === 'vehicle_id' && toggle_name !== 'Registration Number') 
            return false
        else 
            return true
    }

    getSection(section) {
        let fields = this.props.state.budgetmanagement.component_field
            .filter(x => x.form === true)
            .filter(x => x.component_section_id === section.component_section_id)
            .map((x, i) => {
                
                return <Col style={styles.visibility(this.toggleField(x))} key={'col_' + x.name} xl={6}>{this.getField(x, i)}</Col>
            })

        return <Card key={'card_' + section.name} initiallyExpanded={true}>
            <CardHeader
                avatar={<Icon iclass={'material-icons'} iname={'edit'} />}
                title={section.title}
                subtitle={section.description}
            />
            <CardContent expandable={true}>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        {fields}
                    </Row>
                </Container>
            </CardContent>
        </Card>
    }

    getSave() {
        return <Card key={'card_btn'} initiallyExpanded={true}>
            <CardHeader
                avatar={<Icon iclass={'material-icons'} iname={'edit'} />}
                title={"Save"}
                subtitle={"Save Budget Management"}
            />
            <CardContent expandable={true}>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row style={styles.pullright}>
                    <Button
                        onClick={(event) => {
                            event.preventDefault()
                            for(let x of this.props.state.budgetmanagement.component_field){
                                this.props.actions.setComponentFieldInput(x,null)
                            }

                            this.props.actions.changeTabView(0)
                        }}
                        variant="contained"
                        className="btn-warning pull-right d-40 py-0 px-4 w-auto mx-0 mr-3 mr-lg-0 mx-lg-3 "
                        >
                        {<Icon iclass={'material-icons'} iname={'cancel'} />} &nbsp; {'Cancel'}
                    </Button>
                    <Button
                        onClick={(event) => {
                            event.preventDefault()
                            this.props.actions.saveBudgetManagement(this.props.state.budgetmanagement, this.props.state.user)
                        }}
                        variant="contained"
                        className="d-40 py-0 px-4 w-auto mx-0 mr-3 mr-lg-0 mx-lg-3 "
                        >
                        {<Icon iclass={'material-icons'} iname={'save'} />} &nbsp; {'Save Account'}
                    </Button>
                    </Row>
                </Container>
            </CardContent>
        </Card>
    }
   
    getForm() {
        return this.props.state.budgetmanagement.component_section
            .map((x) => {
                return this.getSection(x)
            })
    }

    render() {
        return [this.getForm(),this.getSave()]
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.budgetmanagement,
            budgetmanagement: {
                ...state.components.budgetmanagement
            },
            user: {
                ...state.user
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BudgetManagementForm)