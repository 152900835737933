import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.SHOW_DETAIL_DASHBOARD:
            return {
                ...state,
                dashboard: action.payload
            }
        case types.CHANGE_DETAIL_COMPONENT_TAB:
            return {
                ...state,
                tab: action.payload
            }
        case types.READ_COMPONENT_SET_STATUS:
            return {
                ...state,
                loading: !state.loading
            }
        default:
            return state
    }
}