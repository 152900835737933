import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.GET_ASSIGNED_TASK_COUNT:
            return {
                ...state,
                ['tasks_' + action.payload.team_id + '_count']: 0
            }
        case types.SET_ASSIGNED_TASK_COUNT:
            return {
                ...state,
                ['tasks_' + action.payload.team_id + '_count']: action.payload.data[0].count
            }
        default:
            return state
    }
}