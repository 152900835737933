export const CLEAR_ACCESSORY_RECEIPT_PO_DOCUMENT_PREVIEW = 'CLEAR_ACCESSORY_RECEIPT_PO_DOCUMENT_PREVIEW'
export const SET_ACCESSORY_PO_DOCUMENTS_PREVIEW = 'SET_ACCESSORY_PO_DOCUMENTS_PREVIEW'
export const SET_ACCESSORY_RECEIPT_PO_DOCUMENT_PREVIEW = 'SET_ACCESSORY_RECEIPT_PO_DOCUMENT_PREVIEW'
export const SET_ACCESSORY_RECEIPT_PO_DOCUMENT_FILENAME = 'SET_ACCESSORY_RECEIPT_PO_DOCUMENT_FILENAME'
export const GET_ACCESSORY_PO_DOCUMENTS = 'GET_ACCESSORY_PO_DOCUMENTS'
export const SET_ACCESSORY_PO_DOCUMENTS_GRID_DATA = 'SET_ACCESSORY_PO_DOCUMENTS_GRID_DATA'
export const UPLOAD_ACCESSORY_RECEIPT_PO_DOCUMENT = 'UPLOAD_ACCESSORY_RECEIPT_PO_DOCUMENT'
export const SUBMIT_UPDATE_WORKFLOW_TASK_INPUT = 'SUBMIT_UPDATE_WORKFLOW_TASK_INPUT'
export const CONFIRM_ACCESSORY_RECEIPT_PO_DOCUMENT_UPLOAD = 'CONFIRM_ACCESSORY_RECEIPT_PO_DOCUMENT_UPLOAD'
export const UPLOAD_COMPONENT_ADD_BLANK_UPLOAD = 'UPLOAD_COMPONENT_ADD_BLANK_UPLOAD'
export const UPLOAD_COMPONENT_SET_FIELD_VALUE = 'UPLOAD_COMPONENT_SET_FIELD_VALUE'
export const SET_UPLOAD_DOCUMENT_PREVIEW = 'SET_UPLOAD_DOCUMENT_PREVIEW'
export const UPLOAD_MULTIPLE_DOCUMENT_COMPONENT_UPLOADER = 'UPLOAD_MULTIPLE_DOCUMENT_COMPONENT_UPLOADER'
export const GET_UPLOAD_COMPONENT_DOCUMENTS_TYPES_LOOKUP_DATA = 'GET_UPLOAD_COMPONENT_DOCUMENTS_TYPES_LOOKUP_DATA'
export const SET_UPLOAD_COMPONENT_DOCUMENTS_TYPES_LOOKUP_DATA = 'SET_UPLOAD_COMPONENT_DOCUMENTS_TYPES_LOOKUP_DATA'
export const SET_UPLOAD_COMPONENT_FILE_NAME_VALUE = 'SET_UPLOAD_COMPONENT_FILE_NAME_VALUE'
export const UPLOAD_COMPONENT_SET_LIMIT = 'UPLOAD_COMPONENT_SET_LIMIT'
export const API_COMPONENT_UPLOADER = 'API_COMPONENT_UPLOADER'