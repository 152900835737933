import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Container, Row, Col } from 'react-grid-system'
import { Card, CardHeader, CardContent } from '@mui/material'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'

import Icon from '../../Icon/component'
import Loading from '../../Loading/component'

import * as actions from './actions'
import * as styles from './styles'

class AdhocComponentFieldInput extends Component {
    componentDidMount() {
        this.props.actions.getAdhocFieldInputData(
            this.props.id,
            this.props.parent,
            this.props.parentFilters,
            this.props.child,
            this.props.childFilters,
            this.props.adhocFieldInput,
            this.props.extractChildData
        )
    }

    render() {
        return <div>{""}</div>
    }

    renderoff() {
        const component = this.props.state.components[this.props.extractChildData ? this.props.child : this.props.parent]

        return !this.props.state[this.props.id] || !component || this.props.state[this.props.id].loading
            ? <Loading message={'Retrieving ' + this.props.extractChildData ? this.props.child : this.props.parent + ' data...'} />
            : <Container style={{ margin: 0 }} className="custom-container">
                        {
                            this.props.state[this.props.id].data.map((x, i) => {
                                return <Row key={'adhoc_component_field_input_' + i}>
                                    {
                                        [
                                            this.props.selectMode
                                                ? <Col xl={1} style={styles.verticalMiddle}>
                                                    <Checkbox
                                                        checked={this.props.state[this.props.id].data[i].selected}
                                                        onCheck={(event,checked) => {
                                                            this.props.actions.chkAdhocComponentFieldInput(this.props.id, this.props.state[this.props.id].data, undefined, undefined, i, checked)
                                                        }} />
                                                </Col>
                                                : '',
                                                this.props.adhocFieldInput.map((y, g) => {
                                                    let field = null 
                                                    try {
                                                        field = component.component_field.filter(x => x.name === y)[0] 
                                                    } catch (error) {
                                                        
                                                    }

                                                    return this.props.extractChildData && field
                                                        ? <Col key={field.key + '_col_' + i + '_' + g}>
                                                            <TextField
                                                                variant={"outlined"}
                                                                key={field.key + '_' + i + '_' + g}
                                                                disabled={x.original}
                                                                hintText={field.hint}
                                                                value={x[this.props.child][y]}
                                                                errorText={''}
                                                                onChange={
                                                                    (event, value) => {
                                                                        this.props.actions.setAdhocComponentFieldInput(this.props.id, this.props.state[this.props.id].data, field, value, i, false)
                                                                    }
                                                                }
                                                            />
                                                        </Col> : ''
                                            })
                                        ]
                                    }
                                </Row>
                            })
                        }
                        {
                            this.props.addMode
                                ? <Row>
                                    <Col style={styles.right}>
                                        <br />
                                       < Button className={"global_button"} variant="contained"
                                            key={'add_more_button'}
                                            label={'Add More'}
                                            primary={true}
                                            onClick={(event) => {
                                                event.preventDefault()
                                                this.props.actions.addAdhocFieldInputData(this.props.id, this.props.state[this.props.id].data)
                                            }}
                                             >{window.t.en('Add More')}</Button>
                                    </Col>
                                </Row>
                                : ''
                        }
                    </Container>
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.customAdhocComponentFieldInput,
            components: state.components
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdhocComponentFieldInput)