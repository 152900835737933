export const transaction_data = null
export const transaction_type = null
export const accountingitems = []
export const customers = []
export const salestypes = []
export const accountingitemlinks = []
export const customername = null
export const accountingitem = null

export const columns = [{
    type: 'string',
    name: 'salestype',
    title: 'Sales Type'
},{
    type: 'string',
    name: 'description',
    title: 'Description'
},{
    type: 'string',
    name: 'customer',
    title: 'Customer Name'
}]
