import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment';

import { Card, CardHeader, CardContent, Tabs,Box, Tab, Button, List, ListItem, Tooltip, Dialog, TextField, IconButton } from '@mui/material';

import ReactTable from "react-table"
import "react-table/react-table.css"

import Icon from '../Icon/component'

import * as actions from './actions'
import * as styles from './styles'


class CUSTOMERDERIVATIVECATEGORYGRID extends Component {

    getCommands(row) {
        //console.log('getCommands',row)
        let commands = []
        commands.push(
            <IconButton key={'command_view_' +  row.original[this.props.field]} style={styles.iconButton} iconStyle={styles.actionIcon}
            tooltip={'Delete ' +  row.original[this.props.field]} color="primary"
            onClick={(event) => { 
                    //console.log('')
                    this.props.actions.delDataList({ prop: this.props.prop, value: row.original[this.props.field] })
                }
            }>
                <Icon  istyle={{ fontSize: '22px', color: '#253053' }}  color="primary" ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'delete_forever'} />
            </IconButton>
        )

        return commands
    }

    getValue(row) {  
        return row.original[this.props.field]
    }

    getFormFieldLabel(row) {
          return <div
            style={{
                width: '100%',
                marginTop: '3px',
                verticalAlign: 'baseline',
                fontSize: '15px'
            }} >{this.getValue(row)}</div>
    }

    getColumns() {
        const fields = this.props.state.fields
        let columns = fields.map(x => {
                return {
                    meta: x.name,
                    Header: this.props.title,
                    accessor: this.props.field,
                    filterable: true,
                    headerStyle: {
                        fontSize: '13px',
                        fontWeight: 'bold',
                        textAlign: 'left',
                    },
                    style: {
                        verticalAlign: 'middle',
                        fontSize: 12,
                        letterSpacing: 0,
                        color: 'black',
                        //: x.bold ? 'bold' : 'normal',
                        textAlign:  'left'
                    },
                    Cell: (row) => (
                          this.getFormFieldLabel(row)
                    )
                }
            })

            columns.unshift({
                //Header: '',
                accessor: 'options',
                filterable: false,
                maxWidth: 60,
                width: 60,
                Cell: (row) => (
                    this.getCommands(row)
                )
            })
        return columns
    }

    getGrid() {
        return <div>
            <ReactTable
                data={this.props.data}
                columns={this.getColumns()}
                manual
                loading={false}
                minRows={1}
                showPageJump={true}
                showPaginationTop={false}
                style={{
                    height: '100%',
                    width: '100%'
                }}
                onFetchData={
                    (state) => { }
                }
            >
                {(state, render, instance) => {
                    return (
                        render()
                    )
                }}
            </ReactTable>

        </div>
    }

    render() {
        return this.getGrid()
    }
}

const mapStateToProps = (state) => {
    return {
        state: { 
            ...state.customerDerivativeCategory,
            customerderivative: {
                ...state.components.customerderivative.selected[0]  //customerderivative.original.customerderivative_id
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CUSTOMERDERIVATIVECATEGORYGRID)