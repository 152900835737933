import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as actions from './actions'
import WorkflowCreateQuote from './WorkflowCreateQuote/component'
import WorkflowReviewQuote from './WorkflowReviewQuote/component'
import WorkflowApproveQuote from './WorkflowApproveQuote/component'

class WorkflowFMLQuote extends Component {
    getView() {
        return <div>
            {this.props.task === "tsk_full_maintenance_lease_create" && <WorkflowCreateQuote mode={this.props.mode} />}
            {this.props.task === "tsk_full_maintenance_lease_review" && <WorkflowReviewQuote workflow_queue_data={this.props.workflow_queue_data} />}
            {this.props.task === "tsk_full_maintenance_lease_approve" && <WorkflowApproveQuote workflow_queue_data={this.props.workflow_queue_data} />}
        </div>
    }

    getLayout() {
        return this.getView()
    }
    render() {
        return (
            this.getLayout()
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.workflowFMLQuote,
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowFMLQuote)