import moment from "moment"

export const buffer = null
export const url = null
export const filter = null
export const dateField = false
export const dateTo = moment().format('YYYY-MM-DD HH:mm:ss')
export const dateFrom = moment().format('YYYY-MM-DD HH:mm:ss')
export const dateColumn = null

