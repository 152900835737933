import * as colours from '@mui/material/colors'
import Theme from '../../theme'

export const theme = Theme

export const icon = {
    color: colours.red[500]
}

export const related = {
    //fontSize: '10px'
}

export const fieldContainer = {
    display: 'table',
    width: '90%'
}

export const materialDL = {
    fontFamily: "Material Icons",
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: 54,
    lineHeight: 1,
    zIndex: 99999,
    letterSpacing: 'normal',
    textTransform: 'none',
    display: 'inline-block',
    whiteSpace: 'nowrap',
    wordWrap: 'normal',
    direction: 'ltr',
  }

export const fieldTitle = {
    width: 400,
    display: 'table-cell',
    verticalAlign: 'middle',
    fontSize: '16px',
    textAlign: 'right',
    paddingRight: 10
}

export const fieldContent = {
    display: 'table-cell',
    width: '70%'
}

export const headerButton = {
    width: 250
}

export const right = {
    float: 'right'
}

export const toggle = {
    width: '100%'
}

export const textBox = {
    width: '100%'
}

export const row = {
    fontSize: 16,
    textAlign: 'left',
}