import moment from 'moment'
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system'
import Card from '../../../controls/card'
import AutoComplete from '../../../controls/autocomplete'
import DateControl from '../../../controls/dateControl'
import * as actions from './actions'
import * as styles from './styles'
import PdfViewer from '../../PDF/component'
// import TextField from '@mui/material/TextField'
import TextField from '../../../controls/textField'
import Checkbox from '../../../controls/checkbox'
import * as _ from 'lodash'
import ViewVehicle from '../../../controls/view_vehicle'


class DefleetGenerateChecksheet extends Component {
    componentDidMount() {
        let wf_data = this.props.state.workflowView
        const meta = [
            {
                header: true,
                title: 'Vehicle',
                description: 'Vehicle',
                meta_data: wf_data.selectedVehicleDetails.data[0].licenseplateno
            },
            {
                title: 'De-fleet Number',
                description: 'De-fleet Number',
                meta_data: wf_data.defleetNum
            },
        ]
        const data = {
            ...this.props.state.workflowView,
            selectedDefleetType: wf_data.selectedDefleetType,
            write_off: wf_data.selectedDefleetType.value === 1 ? true : (wf_data.selectedDefleetType.value === 3 ? true : false),
            registration: wf_data.selectedVehicleDetails.data[0].licenseplateno,
            vehicle_id: wf_data.selectedVehicleDetails.data[0].vehicle_id,
            defleetNumber: wf_data.defleetNum,
            user: this.props.state.user,
            filename: this.props.state.defleetpdf_filename,
            datestamp: new Date(),
            documents: this.props.state.upload.documents
        }

        this.props.actions.setupWorkflowTaskInput(data, meta, 'defleet', 'tsk_defleet_generate_checksheet')
    }

    getDateDiff(d1, d2) {
        var t2 = d2.getTime();
        var t1 = d1.getTime();
        var d1Y = d1.getFullYear();
        var d2Y = d2.getFullYear();
        var d1M = d1.getMonth();
        var d2M = d2.getMonth();
        var inDays = parseInt((t2 - t1) / (24 * 3600 * 1000));
        var inWeeks = parseInt((t2 - t1) / (24 * 3600 * 1000 * 7));
        var inMonths = (d2M + 12 * d2Y) - (d1M + 12 * d1Y);
        var inYears = d2.getFullYear() - d1.getFullYear();

        return {
            inDays: inDays,
            inWeeks: inWeeks,
            inMonths: inMonths,
            inYears: inYears
        }
    }
    
    getTypesForm(type) {
        switch(type) {
            case 'EOC termination':
            case 'EOC purchase':
            case 'Early purchase':
            case 'Early Return':
                return <>
                <Col xl={12}>
                     <PdfViewer
                        key={'EOC'}
                        name={'EOC'}
                        workflow={'EOC'}
                        task={'EOC'}
                        documents={this.props.state.upload.documents}
                        edit={false}
                        default_document={'Authorisation'}
                    />
                </Col>
                <Col xl={4}>
                    <Checkbox
                        id={'sight_of_payment'}
                        key={'sight_of_payment'}
                        name={'sight_of_payment'}
                        checked={this.props.state.workflowView.sight_of_payment}
                        labelPlacement={'end'}
                        label={'Sight of Payment'}
                        disabled={true}
                    /></Col>
                </>
            case 'EOC Return':
                return <>
                <Col xl={12}>
                    <PdfViewer
                       key={'vehicle_return_form'}
                       name={'vehicle_return_form'}
                       workflow={'vehicle_return_form'}
                       task={'vehicle_return_form'}
                       documents={this.props.state.upload.documents}
                       edit={false}
                   />
                </Col>
                
                <Col xl={4}>
                    <Checkbox
                        id={'vehicle_return_form'}
                        key={'vehicle_return_form'}
                        name={'vehicle_return_form'}
                        checked={this.props.state.workflowView.vehicle_return_form}
                        labelPlacement={'end'}
                        disabled={true}
                        label={'Vehicle Return Form'}
                    />
                </Col>
                <Col xl={4}>
                    <Checkbox
                        id={'make_good_cost_invoice'}
                        key={'make_good_cost_invoice'}
                        name={'make_good_cost_invoice'}
                        disabled={true}
                        checked={this.props.state.workflowView.make_good_cost_invoice}
                        labelPlacement={'end'}
                        label={'Make Good Costs Invoice'}
                    /></Col>
                </>
            case 'Breach of Contract':
                return <>
                <Col xl={12}>
                    <PdfViewer
                       key={'notification_from_collection'}
                       name={'notification_from_collection'}
                       workflow={'notification_from_collection'}
                       task={'notification_from_collection'}
                       documents={this.props.state.upload.documents}
                       edit={false}
                   />
                </Col>
                </>
            case 'Early settlement':
            case 'Early Purchase':
                return <>
                <Col xl={4}>
                    <TextField
                            variant='outlined'
                            id={'net_book_value'}
                            disabled={true}
                            label={'Enter Net Book Value'}
                            fullWidth={true}
                            value={this.props.state.workflowView.net_book_value}
                            onChange={(event, value) => {
                            this.props.actions.setValue('net_book_value', event.target.value)
                        }}
                    />
                </Col>
                <Col xl={4}>
                    <TextField
                            variant='outlined'
                            id={'retail_value'}
                            label={'Enter Retail Value'}
                            disabled={true}
                            fullWidth={true}
                            value={this.props.state.workflowView.retail_value}
                            onChange={(event, value) => {
                            this.props.actions.setValue('retail_value', event.target.value)
                        }}
                    />
                </Col>
                <Col xl={4}>
                    <TextField
                            variant='outlined'
                            id={'trade_value'}
                            disabled={true}
                            label={'Enter Trade Value'}
                            fullWidth={true}
                            value={this.props.state.workflowView.trade_value}
                            onChange={(event, value) => {
                            this.props.actions.setValue('trade_value', event.target.value)
                        }}
                    />
                </Col>
                </>
        }
    }

    renderView(workflow_queue_data) {
        const vehicle = workflow_queue_data.selectedVehicleDetails.data
        //console.log("vehicle :", vehicle)
        let activecontract = vehicle && vehicle.length ? vehicle[0].customercontract.filter(x => x.activated == true && x.terminated == false) : null 
        let contract = activecontract && activecontract.length ? activecontract[0] : null
        //console.log("contract :", contract ? contract : ' NONE')
        var dString = contract ? contract.startdate : new Date();
        var date_from = new Date(dString);
        var date_to = new Date();
        var holder = this.getDateDiff(date_from, date_to)
        var months = holder ? holder.inMonths : null
        return <div>
            {
                vehicle
                    ? <Card
                        title={'Vehicle Defleet'}
                        subtitle={'Selected vehicle to defleet'}
                        content={<Container style={{ margin: 0 }} className="custom-container">
                            <Row>
                                <Col xl={4}>
                                            <TextField
                                                id={'txtcommentchecksheet'}
                                                disabled={true}
                                                label={'Vehicle Registration'}
                                                fullWidth={true}
                                                multiLine={false}
                                                variant="outlined"
                                                value={vehicle[0].licenseplateno}
                                                onChange={(event, value) => {
                                                }}
                                            />
                                </Col>
                                <Col xl={4}>
                                            <TextField
                                                id={'txtcommentchecksheet'}
                                                disabled={true}
                                                fullWidth={true}
                                                multiLine={false}
                                                label={'De-fleet Request Date'}
                                                variant="outlined"
                                                value={moment(this.props.state.workflowView.defleetrequestdate).format("YYYY-MM-DD")}
                                                onChange={(event, value) => {
                                                }}
                                            />
                                </Col>
                                <Col xl={4}>
                                            <TextField
                                                id={'txtcommentchecksheet'}
                                                disabled={true}
                                                fullWidth={true}
                                                multiLine={false}
                                                variant="outlined"
                                                label={'De-fleet Type'}
                                                value={this.props.state.workflowView.selectedDefleetType.text}
                                                onChange={(event, value) => {
                                                }}
                                            />
                                </Col>
                                <Col xl={4}>
                                            <TextField
                                                id={'txtcommentchecksheet'}
                                                disabled={true}
                                                fullWidth={true}
                                                multiLine={false}
                                                variant="outlined"
                                                label={'De-fleet Reason'}
                                                value={this.props.state.workflowView.selectedDefleetReason.text}
                                                onChange={(event, value) => {
                                                }}
                                            />
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={12}>      
                                 <TextField
                                                id={'txtcommentchecksheet'}
                                                disabled={true}
                                                fullWidth={true}
                                                multiLine={false}
                                                variant="outlined"
                                                label={'De-fleet Comments/Details'}
                                                value={this.props.state.workflowView.comments}
                                                onChange={(event, value) => {
                                                }}
                                            />
                                </Col>
                            </Row>         
                            <Row>
                                <Col xl={12}></Col>
                            </Row>
                            <Row>
                                
                            <Col xl={12}>
                                {
                                    this.getTypesForm(this.props.state.workflowView.selectedDefleetType.text)
                                    ? <Card
                                    title={this.props.state.workflowView.selectedDefleetType.text}
                                    subtitle={'Complete the form'}
                                    content={<Container style={{ margin: 0 }} className="custom-container"><Row>{this.getTypesForm(this.props.state.workflowView.selectedDefleetType.text)}</Row></Container>} />
                                    : ''
                                }
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={12}>
                                    {
                                        months < 12 && contract
                                            ? <div style={styles.redbox}>
                                                <h2>{'Vehicle Not Eligible For De-fleet'}</h2>
                                                <div style={styles.redbox}>
                                                    {'The selected contract does not qualify for a defleet based on the '}<b>{'Contract Age.'}</b>
                                                    <br></br>
                                                    {'Please note that '}<b>{'Early Termination Penalty '}</b>  {'might be due for this contract.'}<br></br>
                                                    <br></br>
                                                    <b>{'Please contact the Finance Department before you proceed!'}</b>
                                                    <br></br>
                                                    <br></br>
                                                    <b>{'Contract Type: '}</b>{contract.contracttype}<br></br>
                                                    <b>{'Contract Start Date: '}</b>{moment(contract.startdate).format('YYYY-MM-DD')}<br></br>
                                                    <b>{'Contract Age: '}</b>{'[' + months + '] Months'}
                                                </div>
                                            </div>
                                            : ''
                                    }
                                </Col>
                            </Row>
                        </Container>} />
                    : <strong style={styles.box1}>{'Kindly check if your not logged in twice, if the error persist please contact system administrator.'}</strong>
            }
            {vehicle && vehicle.length ? <ViewVehicle key={vehicle[0].vehicle_id} id={vehicle[0].vehicle_id} /> : ''}
        </div>

    }

    render() {
        let obj = {
            ...this.props.state.workflowView,
            user: this.props.state.user
        }

        return obj
            ? this.renderView(obj)
            : 'Loading, Please wait...'
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.defleetGenerateChecksheetView,
            workflowView: {
                ...state.workflowDefleetView
            },
            task: {
                ...state.workflowTaskInput.task
            },
            imageUploader: {
                ...state.imageUploader,
            },
            upload: {
                ...state.upload,
            },
            user: {
                ...state.user,
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DefleetGenerateChecksheet)