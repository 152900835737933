import * as types from './types'

export const getConsentUrl = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_XERO_CONSENT_URL,
            payload: {
                type: 'XERO_API_CLIENT_AUTH'
            }
        })
    }
}

export const setConsentUrl = (payload) => {
    //console.log('setConsentUrl',payload)
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_XERO_CONSENT_URL,
            payload: payload.data.consentUrl
        })
    }
}

export const getContacts = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_XERO_CONTACTS_LIST,
            payload: {
                type: 'XERO_API_CONTACTS_PUSH'
            }
        })
    }
}

export const setContacts = (payload) => {
    //console.log('setContacts',payload)
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_XERO_CONTACTS_LIST,
            payload: payload.data
        })
    }
}

export const getInvoice = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.XERO_API_INVOICE_PUSH,
            payload: {
                type: 'XERO_API_INVOICE_PUSH'
            }
        })
    }
}

export const setInvoice = (payload) => {
    //console.log('setContacts',payload)
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_XERO_API_INVOICE_PUSH,
            payload: payload.data
        })
    }
}

export const getXeroTransactions = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_XERO_TRANSACTIONS,
            payload: {
                type: 'GET_XERO_TRANSACTIONS'
            }
        })
    }
}

export const setXeroTransactions = (payload) => {
    //console.log('setXeroTransactions',payload)
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_XERO_TRANSACTIONS,
            payload: payload.data.data
        })
    }
}