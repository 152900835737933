
export const comments = ''
export const completeddate = null
export const supplier_invoiceno = ''
export const isinvoice_uploaded = false


export const fields = {
    comments: {
        errorText: 'Enter comments',
        value: '',
        valid: false,
        empty: true,
    },
    completeddate: {
        errorText: 'Enter completed date',
        value: null,
        valid: false,
        empty: true,
    },
    isinvoice_uploaded: {
        errorText: 'Enter quote date',
        value: false,
        valid: true,
        empty: true,
    }
}