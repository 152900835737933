import * as types from './types'



export const setSearchText = (search) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_INVOICE_SEARCH_TEXT,
            payload: search
        })
    }
}


export const loadBillingData = (search) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_COMPONET_BILLING_RUN_DATA,
            payload: search
        })
    }
}

export const downlaodAllDocuments = (data) => {
    return (dispatch, getState) => {
        for (let x of data) {
            dispatch({
                type: types.GET_COMPONET_BILLING_RUN_INVOICE_PDF,
                payload: {
                    type: 'GET_COMPONET_BILLING_RUN_INVOICE_PDF',
                    key: x.document.key
                }
            })
        }
    }
}

export const downlaodDocuments = (filename) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_COMPONET_BILLING_RUN_INVOICE_PDF,
            payload: {
                type: 'GET_COMPONET_BILLING_RUN_INVOICE_PDF',
                key: filename
            }
        })
    }
}

export const viewPdfDocument = (data) => {
    const getFile = () => {
        const blob = new Blob([data.data.content.Body], { type: 'octet/stream' })
        return window.URL.createObjectURL(blob)
    }

    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'

        return (data, name) => {
            const blob = new Blob(data, { type: 'octet/stream' })
            const url = window.URL.createObjectURL(blob)

            a.href = url
            a.download = name
            a.click()
            window.URL.revokeObjectURL(url)
        }
    }())

    window.glyco.log('data.data.document')
    window.glyco.log(data.data)

    saveByteArray([data.data.content.Body], 'Schedule1_Preview.pdf')

    return (dispatch, getState) => {
        dispatch({
            type: types.SET_INVOICE_PDF_DOCUMENT_VIEW,
            payload: {
                url: data.data.document.key
            }
        })
    }
}

export const setBillingRunData = (payload) => {
    return (dispatch, getState) => {
        window.glyco.log('setBillingRunData')
        window.glyco.log(payload)
        dispatch({
            type: types.SET_COMPONET_BILLING_RUN_DATA,
            payload: payload.data
        })
    }
}