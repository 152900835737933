export const contacttypes = [{text: 'Fleet Manage', value: 1},{text: 'Fleet Account', value: 2}]
export const vatable_data = [{text: 'VAT Inclusive', value: 1},{text: 'VAT Exclusive', value: 2}]
export const services = []
export const sundries = []
export const accessories = []
export const contacts = []
export const supplier = {}
export const sundry_data = []
export const service_data = []
export const accessory_data = []
export const empty = true

export const fields = {
  documents: {
    errorText: 'Please add a document and select document type',
    data: false,
    valid: false,
    empty: true,
    popup: true,
    type: 'documents',
  },

  servicesSaved: {
    errorText: "Please ensure that you have saved the Service items you've added, if you do not want to add an item, please remove it before proceeding",
    state: 'getState().accessoryMasterCapture.services?.length ? (!getState().accessoryMasterCapture.services?.find(x=>x.editing === true) ? true : false) : true',
    valid: false
  },
  sundriesSaved: {
    errorText: "Please ensure that you have saved the Sundry items you've added, if you do not want to add an item, please remove it before proceeding",
    state: 'getState().accessoryMasterCapture.sundries?.length ? (!getState().accessoryMasterCapture.sundries?.find(x=>x.editing === true) ? true : false) : true',
    valid: false
  },
  accessoriesSaved: {
    errorText: "Please ensure that you have saved the Accessory items you've added, if you do not want to add an item, please remove it before proceeding",
    state: 'getState().accessoryMasterCapture.accessories?.length ? (!getState().accessoryMasterCapture.accessories?.find(x=>x.editing === true) ? true : false) : true',
    valid: false
  },

  serviceVatable: {
    errorText: 'Please input Service Vat Type',
    state: 'getState().accessoryMasterCapture.services?.length ?  getState().accessoryMasterCapture.services : true',
    valid: false,
    type: 'table',
    tableFields: ['vatable']
  },
  serviceExpiryDate: {
    errorText: 'Please input Service Expiry Date',
    state: 'getState().accessoryMasterCapture.services?.length ?  getState().accessoryMasterCapture.services : true',
    valid: false,
    type: 'table',
    tableFields: ['expirydate']
  },
  serviceSellingPrice: {
    errorText: 'Please input Service Selling Price',
    state: 'getState().accessoryMasterCapture.services?.length ?  getState().accessoryMasterCapture.services : true',
    valid: false,
    type: 'table',
    tableFields: ['sellingprice'],
    validations: [
      {
        'schema': 'decimal',
        'required': true,
        'maxLength': null,
        'errorText': 'Please input a valid Service Selling Price'
      },
      {
        'schema': 'greaterThanZero',
        'required': true,
        'maxLength': null,
        'errorText': 'Please input a valid Service Selling Price'
      }
    ]
  },
  serviceCostPrice: {
    errorText: 'Please input Service Cost Price',
    state: 'getState().accessoryMasterCapture.services?.length ?  getState().accessoryMasterCapture.services : true',
    valid: false,
    type: 'table',
    tableFields: ['costprice'],
    validations: [
      {
        'schema': 'decimal',
        'required': true,
        'maxLength': null,
        'errorText': 'Please input a valid Service Cost Price'
      },
      {
        'schema': 'greaterThanZero',
        'required': true,
        'maxLength': null,
        'errorText': 'Please input a valid Service Cost Price'
      }
    ]
  },
  serviceType: {
    errorText: 'Please select Service Type',
    state: 'getState().accessoryMasterCapture.services?.length ?  getState().accessoryMasterCapture.services : true',
    valid: false,
    type: 'table',
    tableFields: ['servicedescription']
  },

  sundryVatable: {
    errorText: 'Please input Sundry Vat Type',
    state: 'getState().accessoryMasterCapture.sundries?.length ?  getState().accessoryMasterCapture.sundries : true',
    valid: false,
    type: 'table',
    tableFields: ['vatable']
  },
  sundryExpiryDate: {
    errorText: 'Please input Sundry Expiry Date',
    state: 'getState().accessoryMasterCapture.sundries?.length ?  getState().accessoryMasterCapture.sundries : true',
    valid: false,
    type: 'table',
    tableFields: ['expirydate']
  },
  sundrySellingPrice: {
    errorText: 'Please input Sundry Selling Price',
    state: 'getState().accessoryMasterCapture.sundries?.length ?  getState().accessoryMasterCapture.sundries : true',
    valid: false,
    type: 'table',
    tableFields: ['sellingprice'],
    validations: [
      {
        'schema': 'decimal',
        'required': true,
        'maxLength': null,
        'errorText': 'Please input a valid Sundry Selling Price'
      },
      {
        'schema': 'greaterThanZero',
        'required': true,
        'maxLength': null,
        'errorText': 'Please input a valid Sundry Selling Price'
      }
    ]
  },
  sundryCostPrice: {
    errorText: 'Please input Sundry Cost Price',
    state: 'getState().accessoryMasterCapture.sundries?.length ?  getState().accessoryMasterCapture.sundries : true',
    valid: false,
    type: 'table',
    tableFields: ['costprice'],
    validations: [
      {
        'schema': 'decimal',
        'required': true,
        'maxLength': null,
        'errorText': 'Please input a valid Sundry Cost Price'
      },
      {
        'schema': 'greaterThanZero',
        'required': true,
        'maxLength': null,
        'errorText': 'Please input a valid Sundry Cost Price'
      }
    ]
  },
  sundryType: {
    errorText: 'Please select Sundry Type',
    state: 'getState().accessoryMasterCapture.sundries?.length ?  getState().accessoryMasterCapture.sundries : true',
    valid: false,
    type: 'table',
    tableFields: ['description']
  },

  accessoryVatable: {
    errorText: 'Please input Accessory Vat Type',
    state: 'getState().accessoryMasterCapture.accessories?.length ?  getState().accessoryMasterCapture.accessories : true',
    valid: false,
    type: 'table',
    tableFields: ['vatable']
  },
  accessoryExpiryDate: {
    errorText: 'Please input Accessory Expiry Date',
    state: 'getState().accessoryMasterCapture.accessories?.length ?  getState().accessoryMasterCapture.accessories : true',
    valid: false,
    type: 'table',
    tableFields: ['expirydate']
  },
  accessorySellingPrice: {
    errorText: 'Please input Accessory Selling Price',
    state: 'getState().accessoryMasterCapture.accessories?.length ?  getState().accessoryMasterCapture.accessories : true',
    valid: false,
    type: 'table',
    tableFields: ['sellingprice'],
    validations: [
      {
        'schema': 'decimal',
        'required': true,
        'maxLength': null,
        'errorText': 'Please input a valid Accessory Selling Price'
      },
      {
        'schema': 'greaterThanZero',
        'required': true,
        'maxLength': null,
        'errorText': 'Please input a valid Accessory Selling Price'
      }
    ]
  },
  accessoryCostPrice: {
    errorText: 'Please input Accessory Cost Price',
    state: 'getState().accessoryMasterCapture.accessories?.length ?  getState().accessoryMasterCapture.accessories : true',
    valid: false,
    type: 'table',
    tableFields: ['costprice'],
    validations: [
      {
        'schema': 'decimal',
        'required': true,
        'maxLength': null,
        'errorText': 'Please input a valid Accessory Cost Price'
      },
      {
        'schema': 'greaterThanZero',
        'required': true,
        'maxLength': null,
        'errorText': 'Please input a valid Accessory Cost Price'
      }
    ]
   
  },
  accessoryType: {
    errorText: 'Please select Accessory Type',
    state: 'getState().accessoryMasterCapture.accessories?.length ?  getState().accessoryMasterCapture.accessories : true',
    valid: false,
    type: 'table',
    tableFields: ['accessoryname']
  },

  accessorySundriesServices: {
    errorText: 'Please add at least one item for Accessories, Sundries or Services',
    state: 'getState().accessoryMasterCapture.accessories?.length || getState().accessoryMasterCapture.sundries?.length || getState().accessoryMasterCapture.services?.length',
    valid: false
  }
}