import _ from 'lodash'

export const embedScript = (uri) => {
    const script = document.createElement('script');

    script.src = uri
    script.async = true

    document.body.appendChild(script)
}

export const getComponentDescription = (component) => {
    return getComponentDisplayText(component, component.concatDescription)
}

export const getComponentTitle = (component) => {
    //console.log('getComponentTitle',component)
    return getComponentDisplayText(component, component ? component.concatTitle : '')
}

const getComponentDisplayText = (component, key) => {
    if (key == null) return ''

    const fields = key.split(',').map(x => {
        if (x.indexOf('_id') > -1 && x.indexOf('.') > -1) {
            const field = component.component_field.filter(y => y.name === x.split('.').pop())[0]

            const accessors = field.display.split('|')
            const parts = accessors[0].split('.')[0]

            return accessors.map(y => {
                return y
                    .split(',')
                    .map(z => {
                        return _.get(component.data[parts], z.replace(parts + '.', ''))
                    })
                    .join(' ')
            }).join(', ')
        }
        else {
            return component.data[x.replace(component.name + '.', '')]
                ? component.data[x.replace(component.name + '.', '')].input
                : ''
        }
    })

    return fields.join(' ')
}