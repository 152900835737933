import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.RESET_COMPONENT:
            return {
                ...state,
                fields: props.fields
        }
        case types.PO_SUBCOMPONENT_ACTION:
            return {
                ...state
            }
        // case types.SET_BOOKING_COMPONENT_FIELD_INPUT_VALUE:
        //     return {
        //         ...state,
        //         [action.payload.prop]: action.payload.value
        //     }
        case types.SET_BOOKING_COMPONENT_FIELD_INPUT_VALUES:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    [action.payload.prop]: {
                        ...state.fields[action.payload.prop],
                        value: action.payload.value,
                        valid: action.payload.valid
                    }
                }
            }
        case types.SET_VEHICLE_SERVICE_DUE_FUNCTION:
            return {
                ...state,
                servicedata: action.payload
            }
        default:
            return state
    }
}