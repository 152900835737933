import * as props from './props'
import * as types from './types'
import _ from 'lodash/get'

export default (state = props, action) => {
    switch (action.type) {
        case types.SET_TRACKING_DATA:
            /*             const data = action.payload.data
                            .filter(
                                x => x[action.payload.latitude] !== null && x[action.payload.longitude] !== null
                            )
                            .map(x => {
                                return {
                                    [action.payload.latitude]: x[action.payload.latitude],
                                    [action.payload.longitude]: x[action.payload.longitude],
                                    icon: action.payload.icon,
                                    display: _(x, action.payload.display, 'Unknown Data')
                                }
                            })
            
             */

            return {
                ...state,
                data: action.payload.single
                    ? action.payload.data
                        .filter(
                            x => x[action.payload.latitude] !== null && x[action.payload.longitude] !== null
                        )
                        .map(x => {
                            return {
                                [action.payload.latitude]: x[action.payload.latitude],
                                [action.payload.longitude]: x[action.payload.longitude],
                                icon: action.payload.icon,
                                display: _(x, action.payload.display, 'Unknown Data')
                            }
                        })
                    : action.payload.data
                        .filter(x => x[action.payload.map].length > 0)
                        .filter(x => x[action.payload.map][0][action.payload.latitude] !== null && x[action.payload.map][0][action.payload.longitude] !== null)
                        .map(x => {
                            return {
                                [action.payload.latitude]: x[action.payload.map][0][action.payload.latitude],
                                [action.payload.longitude]: x[action.payload.map][0][action.payload.longitude],
                                icon: action.payload.icon,
                                display: _(x, action.payload.display, 'Unknown Data')
                            }
                        })
            }
        case types.SET_TRACKING_SOURCE:
            return {
                ...state,
                source: action.payload,
                data: []
            }
        case types.SET_TRACKER_ADDRESS_LOOKUP:
            return {
                ...state,
                address_data: action.payload
            }
        case types.SET_TRACKER_ADDRESS_LOCATION:
            return {
                ...state,
                latitude: action.payload.latitude,
                longitude: action.payload.longitude,
                zoom: action.payload.zoom,
            }
        case types.SET_TRACKER_ADDRESS_MARKER:
            return {
                ...state,
                data: action.payload
            }
        case types.SET_TRACKER_COMPONENT_INPUT_VALUE:
            return {
                ...state,
                [action.payload.prop]: action.payload.value
            }
        default:
            return state
    }
}